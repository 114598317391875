import { useEffect, useRef, useState } from "react";
import { ReactComponent as Illustar02 } from "../../assets/icons/illustra_02.svg";
import { ReactComponent as Illustra02 } from "../../assets/icons/illustra_02.svg";
import { ReactComponent as Illustra0 } from "../../assets/icons/illustra_0.svg";
import { ReactComponent as Illustar04 } from "../../assets/icons/illustra_04.svg";
import { ReactComponent as Illustar06 } from "../../assets/icons/illustra_06.svg";
import { ReactComponent as CautionIcon } from "../../assets/icons/caution.svg";
import { ReactComponent as SendIcon } from "../../assets/icons/send.svg";
import { ReactComponent as Illustra00 } from "../../assets/icons/illustra_00.svg";
import { ReactComponent as MailIcon } from "../../assets/icons/mail.svg";
import { ReactComponent as Illustra01 } from "../../assets/icons/illustra_01.svg";
import ActionMenu from "./ActionMenu";
import Popup from "../molecules/Popup";
import Button from "../atoms/Button";
import BottomBarLayout from "./BottomBarLayout";
import LeftBarLayout from "./LeftBarLayout";
import RightBarLayout from "./RightBarLayout";
import TopBarLayout from "./TopBarLayout";
import { useSelector } from "react-redux";
import { layout } from "../../config";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import React from "react";
import axios from "axios";
import pirsch from "../../Analytics";
import { BACKEND_URL } from "../../config";
import jwtDecode from "jwt-decode";
import ForgotPasswordCard from "../organismes/popup/ForgotPasswordCard";
import CheckEmailCard from "../organismes/popup/CheckEmailCard";
import { useDispatch } from "react-redux";
import "../../styles/main.scss";
import PopupContainer from "../molecules/PopupContainer";
import {
  setOpenLoginPopup,
  setUser,
  setOpenSignupPopup,
} from "../../redux/actions/user";
import LoginCard from "../organismes/popup/LoginCard";
import { forgetPassword, login } from "../../services/authServices";
import Input from "../atoms/Input";

function Layout({
  selectedMenu = "TOOLS",
  filter,
  children,
  isLogin,
  id,
  classNameActionMenu,
  myProfile = false,
  showRightBar,
  showLeftBar,
  setShowRightBar,
  setShowLeftBar,
  leftMessages,
  actionMenu = true,
  currentPage,
  messageNom,
  selectedChat,
  loading,
  setIsSoonPopup,
}) {
  const numberItems = useSelector((state) => state.numberItems);
  const scrollRef = useRef();
  const lang = useSelector((state) => state.lang);
  const token = localStorage.getItem("token");
  const [openCheckEmailPopup, setOpenCheckEmailPopup] = useState(false);
  const [messageNo, setMessageNo] = useState("");
  const user = useSelector((state) => state.user);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const openLoginPopup = useSelector((state) => state.openLoginPopup);
  const [email, setEmail] = useState();
  const [popMessage, setPopMessage] = useState("");
  const [popIcon, setPopIcon] = useState("");
  const [popType, setPopType] = useState("");
  const [IsShowPopup, settIsShowPopup] = useState(false);
  const [isScrollable, setIsScrollable] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const openSignupPopup = useSelector((state) => state.openSignupPopup);
  const [forgetPasswordPopup, setForgetPasswordPopup] = useState(false);
  const [checkEmailPopup, setCheckEmailPopup] = useState(false);
  const [emailRecent, setEmailRecent] = useState("");
  const [showWelcomePopup, setShowWelcomePopup] = useState(false);
  const [error, setError] = useState({});

  const isValidEmail = (email) => {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    );
  };
  function popups(message, type, icon) {
    settIsShowPopup(true);
    setPopType(type);
    setPopIcon(icon);
    setPopMessage(message);
    setTimeout(() => {
      settIsShowPopup(false);
    }, 4000);
  }
  const handleLogin = (email, password) => {
    popups("Loading...", "loading");

    login(email, password, lang)
      .then((result) => {
        if (result.data.message) {
          console.log(result.data.message);
          popups(result.data.message, "error");
          setError({ ...error, login: true });
        } else {
          dispatch(setOpenLoginPopup(false));
          settIsShowPopup(false);
          dispatch(setUser(result.data.myToken));
          if (token) {
            setShowWelcomePopup(true);
          }
        }
      })
      .catch((error) => {
        popups(error?.response?.data.message, "error");
      });
  };

  useEffect(() => {
    scrollRef.current.scrollTop = 0;
  }, [selectedMenu]);

  useEffect(() => {
    if (token != undefined) {
      const req = async () => {
        return axios.get(`${BACKEND_URL}/chats/`, {
          headers: {
            Authorization: `Bearer ${token.slice(1, -1)}`,
          },
        });
      };

      req()
        .then((result) => {
          if (result != undefined) {
            let counter = 0;
            result.data.activeChatsWithContent?.map((el) => {
              if (el.latestMessage) {
                if (
                  el.latestMessage.isRead == false &&
                  el.latestMessage.sender._id != jwtDecode(user).id
                ) {
                  counter++;
                }
              }
            });
            setMessageNo(counter);
          }
        })
        .catch(() => {});
    }
  }, leftMessages);
  return (
    <>
      {" "}
      <div
        id="layout"
        style={
          currentPage === "home"
            ? {
                "--primary-color":
                  layout[selectedMenu.toLowerCase()].backgroundColor,
              }
            : {
                "--primary-color": "#FF6A56",
              }
        }
      >
        <Helmet>
          <meta
            name="theme-color"
            content={
              currentPage == "home"
                ? layout[selectedMenu.toLowerCase()].backgroundColor
                : "#FF6A56"
            }
          />
        </Helmet>

        <div className="layout-container">
          <TopBarLayout
            title={selectedMenu}
            currentPage={currentPage}
            statistique={
              currentPage != "chat"
                ? numberItems[selectedMenu.toLowerCase()]
                : null
            }
            setShowLeftBar={() => setShowLeftBar(true)}
            setShowRightBar={() => setShowRightBar(true)}
            myProfile={myProfile}
            selectedChat={selectedChat}
            loading={loading}
            showLeftBar={showLeftBar}
          />

          <LeftBarLayout
            selectedMenu={selectedMenu}
            currentPage={currentPage}
          />
          {actionMenu && (
            <ActionMenu
              selectedMenu={selectedMenu}
              currentPage={currentPage}
              showLeftBar={showLeftBar}
              setShowBarMobile={() => setShowLeftBar(false)}
              className={classNameActionMenu}
              leftMessages={leftMessages}
            >
              {filter}
            </ActionMenu>
          )}

          <div
            className={`content-wrapper ${
              selectedMenu === "chat" ? "overflow-none" : ""
            } ${
              currentPage === "settings" && !showLeftBar
                ? "md:absolute settings-content-border md:left-0 md:top-0 "
                : ""
            }
          ${currentPage === "settings" ? "md:z-0" : ""}
          `}
            id={id}
            ref={scrollRef}
          >
            {children}
          </div>

          <RightBarLayout
            isLogin={isLogin}
            currentPage={currentPage}
            showBarMobile={showRightBar}
            setShowBarMobile={setShowRightBar}
            selectedMenu={selectedMenu}
            messageNo={messageNo}
            messageNom={messageNom}
            setIsSoonPopup={(res) => setIsSoonPopup(res)}
          />
          <BottomBarLayout
            selectedMenu={selectedMenu}
            currentPage={currentPage}
          />
        </div>
      </div>
      {openSignupPopup && (
        <PopupContainer
          className={"signup_top_hero"}
          cardClass={"mail_signup"}
          windowClass={"mail_signup_card"}
          innerClass={"inner_card"}
          title={lang === "en" ? "Sign up" : "サインアップ"}
          logo={<Illustra01 />}
          onClose={() => dispatch(setOpenSignupPopup(false))}
          big={true}
          illustration={<Illustra00 />}
        >
          <p className="signup-text">
            {lang === "en" ? (
              <>
                Enter your email to begin the sign-up process. Follow the link
                we send to complete your account setup.
              </>
            ) : (
              <>
                メールアドレスを入力して、アカウント作成のために送信するリンクに従ってください！
              </>
            )}
          </p>
          <div className="signup_top_box">
            <Input
              leftIcon={<MailIcon />}
              className="user-form-body-email"
              placeholder={lang === "en" ? "Email Address" : "Eメールアドレス"}
              type="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              error={emailError}
              id="id100"
            />
            <Button
              rightIcon={<SendIcon />}
              onClick={() => {
                if (!email) {
                  setEmailError("Email field is empty!");
                  popups("Email field is empty!", "error", <CautionIcon />);
                } else if (!isValidEmail(email)) {
                  setEmailError("Check your email and try again!");
                  popups(
                    "Check your email and try again!",
                    "error",
                    <CautionIcon />
                  );
                } else {
                  popups("Loading...", "loading");
                  axios
                    .post(`${BACKEND_URL}/auth/signup_request`, {
                      email: email,
                      lang: lang,
                    })
                    .then((res) => {
                      if (res.data.status == true) {
                        setOpenCheckEmailPopup(true);
                        dispatch(setOpenSignupPopup(false));
                        settIsShowPopup(false);
                      } else {
                        popups(res.data.message, "error", <CautionIcon />);
                        setEmailError(true);
                      }
                    });
                }
              }}
              data-lang={lang}
            >
              {lang === "en" ? "Send" : "送信"}
            </Button>
          </div>
          <div className="new-account">
            <span className="back-link" data-lang={lang}>
              {lang === "en" ? (
                <>
                  Already a member?
                  <span
                    className="text-link"
                    onClick={() => {
                      dispatch(setOpenLoginPopup(true));
                      dispatch(setOpenSignupPopup(false));

                      pirsch("RM-Login");
                    }}
                  >
                    {" "}
                    Login
                  </span>
                </>
              ) : (
                <>
                  アカウントをお持ち方は
                  <span
                    className="text-link"
                    onClick={() => {
                      dispatch(setOpenLoginPopup(true));
                      dispatch(setOpenSignupPopup(false));

                      pirsch("RM-Login");
                    }}
                  >
                    {" "}
                    ログイン
                  </span>
                </>
              )}
            </span>
          </div>
        </PopupContainer>
      )}
      {IsShowPopup ? (
        <Popup
          message={popMessage}
          type={popType}
          icon={popIcon}
          closePopup={() => settIsShowPopup(false)}
        />
      ) : null}
      {openLoginPopup && (
        <PopupContainer
          title={t("popup.login.title")}
          logo={<Illustar06 />}
          big={true}
          onClose={() => dispatch(setOpenLoginPopup(false))}
          className={"signup_top_hero"}
          cardClass={"mail_signup"}
          windowClass={"mail_signup_card"}
          innerClass={"inner_card"}
          illustration={<Illustra0 />}
        >
          <LoginCard
            error={error.login}
            onLogin={(email, password) => handleLogin(email, password)}
            onForgetPassword={() => {
              dispatch(setOpenLoginPopup(false));
              setForgetPasswordPopup(true);
            }}
          />
        </PopupContainer>
      )}
      {forgetPasswordPopup && (
        <PopupContainer
          title={t("popup.forgotPassword.title")}
          logo={<Illustar04 />}
          onClose={() => setForgetPasswordPopup(false)}
        >
          <ForgotPasswordCard
            error={error.forget}
            onBack={() => {
              setForgetPasswordPopup(false);
              dispatch(setOpenLoginPopup(true));
            }}
            onSubmit={(email) => {
              popups("Loading...", "loading");
              setEmailRecent(email);
              forgetPassword(email, lang)
                .then((value) => {
                  if (value.data.message === "This email doesn't exist!!") {
                    setError({ forget: true });
                    popups(value.data.message, "error");
                  } else {
                    setForgetPasswordPopup(false);
                    setCheckEmailPopup(true);
                  }
                })
                .catch((error) => {
                  if (error.response.data.messageError) {
                    popups(error.response.data.messageError, "error");
                  }
                  console.log(error);
                });
            }}
          />
        </PopupContainer>
      )}
      {checkEmailPopup && (
        <PopupContainer
          title={t("popup.checkEmail.title")}
          logo={<Illustar02 />}
          onClose={() => setCheckEmailPopup(false)}
        >
          <CheckEmailCard
            email={emailRecent}
            onRecent={() => {
              forgetPassword(emailRecent)
                .then(() => {
                  console.log("ok");
                })
                .catch((error) => {
                  console.log(error);
                });
            }}
            onBack={() => {
              setCheckEmailPopup(false);
              dispatch(setOpenLoginPopup(true));
            }}
          />
        </PopupContainer>
      )}
      {openCheckEmailPopup && (
        <PopupContainer
          title={lang === "en" ? "Check your Email" : "メールを確認"}
          logo={<Illustra02 />}
          onClose={() => {
            setOpenCheckEmailPopup(false);
          }}
        >
          <div className="checkemail-popup-text-cont">
            {lang === "en" ? (
              <>
                <p>
                  Thank you! <br />
                  YourEmail <span>{email}</span> has been submitted.
                </p>
                <p>
                  Check your inbox for a confirmation Email to complete the sign
                  up process.
                </p>
                <p>
                  Please note, the confirmation link expires in{" "}
                  <span>48 hours.</span>
                </p>
              </>
            ) : (
              <>
                <p>
                  ご登録をありがとうございます！ <br />
                  お使いのメールアドレス <span>{email}</span> が送信されました。
                </p>
                <p>
                  受信箱に確認メールが届いているか確認してサインアップを完了してください
                  プロセス。
                </p>
                <p>
                  確認リンクの有効期限は次のとおりです。 <span>48時間。</span>
                </p>
              </>
            )}
          </div>
        </PopupContainer>
      )}
    </>
  );
}

export default Layout;
