import { ReactComponent as PlaceholderIcon } from "../../assets/icons/logo_placeholder.svg";
import { useEffect, useRef, useState } from "react";
import { getItemImage } from "../../services/DataService";
import { getImageProfile } from "../../services/UserService";
import { useDispatch, useSelector } from "react-redux";
import { setImages } from "../../redux/actions/images";

const useIntersectionObserver = () => {
  const [isVisible, setIsVisible] = useState(false);
  const domRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => setIsVisible(entry.isIntersecting));
    });

    const { current } = domRef;

    if (current) {
      observer.observe(current);
    }

    return () => {
      if (current) {
        observer.unobserve(current);
      }
    };
  }, []);

  return [isVisible, domRef];
};

function ItemImage({ id_item, image_item, type = "item" }) {
  const dispatch = useDispatch();
  const images = useSelector((state) => state.images);
  const [image, setImage] = useState("");
  const [lastID, setLastId] = useState("");
  const [isVisible, domRef] = useIntersectionObserver();

  useEffect(() => {
    function getItemImageData(id) {
      if (type === "item") {
        getItemImage(id)
          .then((res) => {
            dispatch(
              setImages({
                [`${id}`]:
                  res !==
                  "eyJtZXNzYWdlRXJyb3IiOiJTb210aGluZyBnb2VzIHdyb25nIGluIGJhY2shIn0="
                    ? res
                    : "",
                ...images,
              })
            );
            setImage(
              res !==
                "eyJtZXNzYWdlRXJyb3IiOiJTb210aGluZyBnb2VzIHdyb25nIGluIGJhY2shIn0="
                ? res
                : ""
            );
          })
          .catch(() => {});
      } else if (type === "uxer") {
        getImageProfile(id)
          .then((res) => {
            dispatch(
              setImages({
                [`${id}`]:
                  res !==
                  "eyJtZXNzYWdlRXJyb3IiOiJTb210aGluZyBnb2VzIHdyb25nIGluIGJhY2shIn0="
                    ? res
                    : "",
                ...images,
              })
            );
            setImage(
              res !==
                "eyJtZXNzYWdlRXJyb3IiOiJTb210aGluZyBnb2VzIHdyb25nIGluIGJhY2shIn0="
                ? res
                : ""
            );
          })
          .catch(() => {});
      }
    }

    if (lastID !== id_item) {
      if (!image_item) {
        setImage("");
      }
      if (isVisible) {
        setLastId(id_item);
        getItemImageData(id_item);
      }
    }
  }, [id_item, isVisible]);

  return (
    <div ref={domRef}>
      {image_item ? (
        isVisible && (
          <img
            className="item-image"
            data-value="redirect"
            src={image_item}
            alt="img"
          />
        )
      ) : image ? (
        isVisible && (
          <img
            className="item-image"
            data-value="redirect"
            src={
              image
                ? `data:image/${
                    type === "item" ? "svg+xml" : "png"
                  };base64,${image}`
                : ""
            }
            alt="img"
          />
        )
      ) : (
        <PlaceholderIcon className="item-image" />
      )}
    </div>
  );
}

export default ItemImage;
