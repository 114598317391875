import { useSelector } from "react-redux/es/hooks/useSelector";

function StaticPageArticle({
  text,
  imgSrc1Large,
  imgSrc1Small,
  title,
  className="",
}) {
  const lang = useSelector((state) => state.lang);

  return (
    <>

      <div className={`sp-parag-box ${className}`}>
        <div className="paragraph-text-cont">
          <h2 className="paragraph-title" data-lang={lang}>{title}</h2>
          <p className="paragraph">{text}</p>
        </div>
        <picture>
          <source media="(max-width: 960px)" srcset={imgSrc1Small} />
          <img src={imgSrc1Large} className="paragraph-image" />
        </picture>
      </div>
      
    </>
  );

}

export default StaticPageArticle;
