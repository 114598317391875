import { useEffect, useRef, useState } from "react";
import RoundImage from "../atoms/RoundImage";
import { fetchImage } from "../../services/itemsServices";
import countires from "../../data/countires.json";
import { createConverssation } from "../../services/ChatService";
import { ReactComponent as Glob } from "../../assets/icons/globe.svg";
import { ReactComponent as Blog } from "../../assets/icons/blog.svg";
import { ReactComponent as Twitter } from "../../assets/icons/twitter.svg";
import { ReactComponent as Facebook } from "../../assets/icons/facebook.svg";
import { ReactComponent as Logo } from "../../assets/icons/uud.svg";

import { ReactComponent as Instagram } from "../../assets/icons/instagram.svg";
import { ReactComponent as Linkedin } from "../../assets/icons/linkedin.svg";
import { ReactComponent as Dribble } from "../../assets/icons/dribbble.svg";
import { ReactComponent as Behance } from "../../assets/icons/behance.svg";
import { ReactComponent as DefaultItem } from "../../assets/icons/default_item.svg";
import { ReactComponent as EditIcon } from "../../assets/icons/edit.svg";
import { ReactComponent as MailIcon } from "../../assets/icons/mail.svg";
import React from "react";
import { useSelector } from "react-redux/es/hooks/useSelector";
import Flag from "../molecules/Flag";

import CustomIcon from "../../compo/IconCompo";

function UxerCard({
  id,
  fullName,
  username,
  subRole,
  experience,
  intro,
  profileImage,
  personalSite,
  personalBlog,
  mediaLinks,
  national,
  edit,
  contact,
  classNameCard,
  isMe,
  test = false,
}) {
  const [isVisible, setIsVisible] = useState(false);
  const [image, setImage] = useState("");
  const [nationality, setNationality] = useState("");
  const lang = useSelector((state) => state.lang);
  const itemRef = useRef();

  // detect language
  const detectScript = (text) => {
    const latinRegex =
      /^[a-zA-Záåéíóúüñàèìòùâêîôøûçëïöäśćążę0-9.\s!?"¿¡,-/']+$/i;
    const arabicRegex = /[\u0600-\u06FF]/;

    if (arabicRegex.test(text)) {
      return "arabic";
    } else if (latinRegex.test(text)) {
      return "latin";
    } else {
      return "asian";
    }
  };

  // if is observe
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      {
        rootMargin: "0px 0px 2000px 0px",
      }
    );

    if (itemRef.current) {
      observer.observe(itemRef.current);
    }

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    let cancelRequest = false;
    setImage("");
    setNationality("");

    countires.forEach((country) => {
      if (country.value === national) {
        setNationality(country.label);
      }
    });

    if (isVisible) {
      fetchImage("profile", profileImage)
        .then((res) => {
          if (!cancelRequest) {
            setImage(res);
          }
        })
        .catch((err) => {});
    }
  }, [id, isVisible, national]);

  return (
    <div className={`uxer-card ${classNameCard}`} ref={itemRef}>
      {edit && (
        <div
          className="uxer-card-edit-btn"
          onClick={() => (window.location = "/settings")}
        >
          <EditIcon />
        </div>
      )}

      {/*contact && (
        <div className="uxer-card-contact-btn">
          <MailIcon
            onClick={async () => {
              await createConverssation(id).then(
                () => (window.location = "/chat")
              );
            }}
          />
        </div>
      )*/}

      {!test ? (
        <RoundImage
          imageFull={true}
          className={`uxer-profile ${isMe ? "uxer-profile-is-me" : ""}`}
          imageData={profileImage}
          noImage={
            <DefaultItem data-value="redirect" className="default-item-photo" />
          }
        />
      ) : profileImage.props || profileImage == null ? (
        <div className="signup-mockup-logo-cont">
          <Logo className="signup-mockup-logo" />
        </div>
      ) : (
        <RoundImage
          imageFull={true}
          className={`uxer-profile ${isMe ? "uxer-profile-is-me" : ""}`}
          imageData={profileImage}
          noImage={
            <DefaultItem data-value="redirect" className="default-item-photo" />
          }
        />
      )}

      <div className="uxer-info">
        {fullName ? (
          <div className="uxer-fullname" data-lang={lang}>
            {fullName}
          </div>
        ) : (
          <div className="uxer-fullname-empty" data-lang={lang}>
            Your Name
          </div>
        )}
        {subRole ? (
          <div className="uxer-rolexp">
            <span
              className={`${
                subRole?.toLowerCase().includes("designer")
                  ? "uxer-role"
                  : "no-designer-role"
              }`}
              data-lang={lang}
            >
              {subRole}
            </span>
            {subRole != "Student" && subRole != "Others" && experience && (
              <span className="uxer-experience">{experience}</span>
            )}
          </div>
        ) : (
          <div className="uxer-rolexp">
            <span
              className={`${
                subRole?.toLowerCase().includes("designer")
                  ? "uxer-role"
                  : "no-designer-role"
              }`}
              data-lang={lang}
            >
              Your Role
            </span>
          </div>
        )}
        {national ? (
          <div className="uxer-nationality">
            <Flag code={national} />
            <span data-lang={lang}>
              {" "}
              {nationality.length > 20
                ? nationality.substring(0, 20) + ".."
                : nationality}
            </span>
          </div>
        ) : (
          <div className=" uxercard-location-empty-cont">
            <div className=" uxercard-location-empty-flag"></div>
            <p className="uxercard-location-empty-text">Location</p>
          </div>
        )}
      </div>

      {intro ? (
        <p
          className={
            test
              ? `test-p uxer-intro-${detectScript(intro)}`
              : `uxer-intro-${detectScript(intro)}`
          }
          data-lang={lang}
        >
          {intro}
        </p>
      ) : (
        <div className=" uxer-intro-empty-cont">
          <div className=" uxer-intro-empty-line "></div>
          <div className=" uxer-intro-empty-line"></div>
          <div className=" uxer-intro-empty-line"></div>
          <div className=" uxer-intro-empty-line"></div>
          <div className=" uxer-intro-empty-line"></div>
          <div className="uxer-intro-empty-line-2"></div>
        </div>
      )}

      <div className="uxer-subinfo">
        {username ? (
          test ? (
            <div
              className="uxer-username"
              href={`/${username}`}
              data-lang={lang}
            >
              <span>
                <CustomIcon iconName="username" className="small_icon" />
              </span>
              {username}
            </div>
          ) : (
            <a className="uxer-username" href={`/${username}`} data-lang={lang}>
              <span>
                <CustomIcon iconName="username" className="small_icon" />
              </span>
              {username}
            </a>
          )
        ) : (
          <div className="uxer-username" data-lang={lang}>
            username
          </div>
        )}

        {personalSite ||
        personalBlog ||
        mediaLinks[0] ||
        mediaLinks[1] ||
        mediaLinks[2] ||
        mediaLinks[3] ||
        mediaLinks[4] ||
        mediaLinks[5] ||
        mediaLinks[6] ||
        mediaLinks[7] ||
        mediaLinks[8] ||
        mediaLinks[9] ||
        mediaLinks[10] ? (
          <div className="uxer-links">
            {personalSite != "undefined" &&
              personalSite != "" &&
              personalSite != null && (
                <a href={personalSite} target="_blank">
                  <CustomIcon iconName="web_line" className="icon" />
                </a>
              )}
            {personalBlog != "undefined" &&
              personalBlog != "" &&
              personalBlog != null && (
                <a href={personalBlog} target="_blank">
                  <CustomIcon iconName="blog_line" className="icon" />
                </a>
              )}
            {mediaLinks && (
              <>
                {mediaLinks[7] != "undefined" &&
                  mediaLinks[7] != "" &&
                  mediaLinks[7] != null && (
                    <a href={mediaLinks[7]} target="_blank">
                      <CustomIcon iconName="figma_line" className="icon" />
                    </a>
                  )}
                {mediaLinks[3] != "undefined" &&
                  mediaLinks[3] != "" &&
                  mediaLinks[3] != null && (
                    <a href={mediaLinks[3]} target="_blank">
                      <CustomIcon iconName="dribbble_line" className="icon" />
                    </a>
                  )}
                {mediaLinks[4] != "undefined" &&
                  mediaLinks[4] != "" &&
                  mediaLinks[4] != null && (
                    <a href={mediaLinks[4]} target="_blank">
                      <CustomIcon iconName="behance_line" className="icon" />
                    </a>
                  )}
                {mediaLinks[1] != "undefined" &&
                  mediaLinks[1] != "" &&
                  mediaLinks[1] != null && (
                    <a href={mediaLinks[1]} target="_blank">
                      <CustomIcon iconName="instagram_line" className="icon" />
                    </a>
                  )}
                {mediaLinks[2] != "undefined" &&
                  mediaLinks[2] != "" &&
                  mediaLinks[2] != null && (
                    <a href={mediaLinks[2]} target="_blank">
                      <CustomIcon iconName="linkedin_line" className="icon" />
                    </a>
                  )}
                {mediaLinks[9] != "undefined" &&
                  mediaLinks[9] != "" &&
                  mediaLinks[9] != null && (
                    <a href={mediaLinks[9]} target="_blank">
                      <CustomIcon iconName="twitter_line" className="icon" />
                    </a>
                  )}
                {mediaLinks[0] != "undefined" &&
                  mediaLinks[0] != "" &&
                  mediaLinks[0] != null && (
                    <a href={mediaLinks[0]} target="_blank">
                      <CustomIcon iconName="facebook_line" className="icon" />
                    </a>
                  )}
                {mediaLinks[5] != "undefined" &&
                  mediaLinks[5] != "" &&
                  mediaLinks[5] != null && (
                    <a href={mediaLinks[5]} target="_blank">
                      <CustomIcon iconName="threads_line" className="icon" />
                    </a>
                  )}
                {mediaLinks[8] != "undefined" &&
                  mediaLinks[8] != "" &&
                  mediaLinks[8] != null && (
                    <a href={mediaLinks[8]} target="_blank">
                      <CustomIcon iconName="tiktok_line" className="icon" />
                    </a>
                  )}
                {mediaLinks[6] != "undefined" &&
                  mediaLinks[6] != "" &&
                  mediaLinks[6] != null && (
                    <a href={mediaLinks[6]} target="_blank">
                      <CustomIcon iconName="youtube_line" className="icon" />
                    </a>
                  )}
                {/* {mediaLinks[10] != "undefined" &&
                  mediaLinks[10] != "" &&
                  mediaLinks[10] != null && (
                    <a href={mediaLinks[10]} target="_blank">
                      <CustomIcon iconName="behance_line" className="icon" />
                    </a>
                  )} */}
              </>
            )}
          </div>
        ) : (
          <div className="uxercard-links-empty-cont">
            <div className="uxercard-links-empty"></div>
            <div className="uxercard-links-empty"></div>
            <div className="uxercard-links-empty"></div>
            <div className="uxercard-links-empty"></div>
          </div>
        )}
      </div>
    </div>
  );
}

export default UxerCard;
