import React, { useEffect, useState } from "react";
import Home from "./pages/Home";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Compos from "./pages/Compos";
import { getItems, getUxers } from "./services/itemsServices";
import { useDispatch, useSelector } from "react-redux";
import {
  setFavsItems,
  setItems,
  setNumberItems,
  setUxers,
} from "./redux/actions/items";
import { setSelectLang } from "./redux/actions/env";
import axios from "axios";
import { getLikedItems } from "./services/userServices";
import data_tools from "./config/data_test_tools.json";
import data_resos from "./config/data_test_resos.json";
import data_ideaz from "./config/data_test_ideaz.json";
import SignUp from "./pages/SignUp";
import Chat from "./pages/Chat";
import Settings from "./pages/Settings";
import Profile from "./pages/Profile";
import AboutUs from "./pages/AboutUs";
import HomePage from "./pages/HomePage";
import News from "./pages/News";
import Blog from "./pages/Blog";
import SignUpSoon from "./pages/SignUpSoon";
import UxerSoon from "./pages/UxerSoon";
import Privacy from "./pages/Privacy";
import Servicez from "./pages/Servicez";
import ContactUs from "./pages/ContactUs";
import Dashboard from "./pages/Dashboard";
import ComingSoon from "./pages/ComingSoon";
import Error404 from "./pages/Error404";
import Soon from "./pages/Soon";
import "../src/styles/scroll.scss";
import VerifyEmail from "./pages/VerifyEmail";
import AdminLogin from "./pages/AdminLogin";
// import Event from "./pages/events/eventTemp";
import Event from "./pages/events/EventTemp";
import EventContact from "./pages/events/EventContact";
import Future from "./pages/events/Future";
import NoEvent from "./pages/events/NoEvent";
import About from "./pages/events/About";
import Past from "./pages/events/Past";

import { setUser } from "./redux/actions/user";

function App() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const lang = useSelector((state) => state.lang);
  const [popups, setPopups] = useState([]);
  /*axios.interceptors.response.use(
    (response) => {
      return response;
    },
    function (error) {
      if (error.response.status === 404) {
        window.location = "/404";
      }
      return Promise.reject(error.response);
    }
  );*/
  // items
  useEffect(() => {
    let cancelRequest = false;

    if (user) {
      // load all data
      const toolsData = getItems("tools");
      const resosData = getItems("resos");
      const ideazData = getItems("ideaz");
      const appsData = getItems("apps");

      Promise.all([toolsData, resosData, ideazData, appsData]).then(
        (result) => {
          // get all data of (tools, resos, ideaz)
          if (!cancelRequest) {
            dispatch(
              setItems({
                tools: result[0].items || [],
                resos: result[1].items || [],
                ideaz: result[2].items || [],
                apps: result[3].items || [],
              })
            );
            dispatch(
              setNumberItems({
                tools: result[0].totalItems || 0,
                resos: result[1].totalItems || 0,
                ideaz: result[2].totalItems || 0,
                apps: 0,
              })
            );
          }
        }
      );
    } else {
      const toolsData = getItems("tools").catch((err) => {
        if (err.response.status == 404) {
          window.location = "/404";
        }
      });
      const appsData = getItems("apps").catch((err) => {
        if (err.response.status == 404) {
          window.location = "/404";
        }
      });
      const resosData = getItems("resos").catch((err) => {
        if (err.response.status == 404) {
          window.location = "/404";
        }
      });
      const ideazData = getItems("ideaz").catch((err) => {
        if (err.response.status == 404) {
          window.location = "/404";
        }
      });

      Promise.all([toolsData, resosData, ideazData, appsData]).then(
        (result) => {
          // get all data of (tools, resos, ideaz)
          if (!cancelRequest) {
            dispatch(
              setItems({
                tools: result[0].items.slice(0, 60) || [],
                resos: result[1].items.slice(0, 60) || [],
                ideaz: result[2].items.slice(0, 60) || [],
                apps: result[3].items.slice(0, 60) || [],
              })
            );
            dispatch(
              setNumberItems({
                tools: 60 || 0,
                resos: 60 || 0,
                ideaz: 60 || 0,
                apps: 0,
              })
            );
          }
        }
      );
    }

    return () => {
      let source = axios.CancelToken.source();
      source.cancel("Component got unmounted");
      cancelRequest = true;
    };
  }, [user]);

  // liked items
  useEffect(() => {
    if (user) {
      getLikedItems().then((res) => {
        if (res != undefined) {
          dispatch(setFavsItems(res));
        }
      });
    }
  }, [user]);

  // lang
  useEffect(() => {
    if (localStorage.getItem("language")) {
      if (localStorage.getItem("language") === "en") {
        dispatch(setSelectLang("en"));
      } else if (localStorage.getItem("language") === "jp") {
        dispatch(setSelectLang("jp"));
      }
    } else {
      dispatch(setSelectLang("en"));
    }
  }, [lang]);

  // uxers
  useEffect(() => {
    getUxers().then((res) => {
      dispatch(setUxers(res));
    });
  }, []);

  // popups
  const newPopup = (type, message, timer) => {
    const id = Date.now() + popups.length * 1000000010000000; // use the current timestamp as a unique id

    setPopups((prevPopups) => [{ id, type, message }]);

    if (timer) {
      setTimeout(() => {
        closePopup();
      }, 3000);
    }

    return id;
  };

  const closePopup = (id) => {
    setPopups([]);
  };
  useEffect(() => {
    dispatch(setUser(localStorage.getItem("token")));
  }, []);
  return (
    <React.Fragment>
      <Router>
        <Routes>
          <Route
            path="/home"
            element={
              <Home
                newPopup={newPopup}
                popups={popups}
                closePopup={closePopup}
              />
            }
          />
          {/* <Route
            path="/index.php"
            newPopup={newPopup}
            popups={popups}
            closePopup={closePopup}
            element={
              <Home
                newPopup={newPopup}
                popups={popups}
                closePopup={closePopup}
              />
            }
          />

          <Route
            path="/tools.php"
            newPopup={newPopup}
            popups={popups}
            closePopup={closePopup}
            element={
              <Home
                newPopup={newPopup}
                popups={popups}
                closePopup={closePopup}
                menu={"Tools"}
              />
            }
          />
          <Route
            path="/resos.php"
            newPopup={newPopup}
            popups={popups}
            closePopup={closePopup}
            element={
              <Home
                newPopup={newPopup}
                popups={popups}
                closePopup={closePopup}
                menu={"Resos"}
              />
            }
          />
          <Route
            path="/ideaz.php"
            newPopup={newPopup}
            popups={popups}
            closePopup={closePopup}
            element={
              <Home
                newPopup={newPopup}
                popups={popups}
                closePopup={closePopup}
                menu={"Ideaz"}
              />
            }
          /> */}
          <Route path="/index.php" element={<Navigate to="/" />} />
          <Route
            path="/tools.php"
            element={<Navigate to="/tools" />} // Redirect to '/tools'
          />
          <Route path="/resos.php" element={<Navigate to="/resos" />} />
          <Route
            path="/ideaz.php"
            element={<Navigate to="/ideaz" />} // Redirect to '/tools'
          />
          <Route
            path="/admin/login"
            newPopup={newPopup}
            popups={popups}
            closePopup={closePopup}
            element={
              <AdminLogin
                newPopup={newPopup}
                popups={popups}
                closePopup={closePopup}
              />
            }
          />
          <Route
            path="/tools"
            newPopup={newPopup}
            popups={popups}
            closePopup={closePopup}
            element={
              <Home
                newPopup={newPopup}
                popups={popups}
                closePopup={closePopup}
                menu={"Tools"}
              />
            }
          />
          <Route
            path="/appps"
            newPopup={newPopup}
            popups={popups}
            closePopup={closePopup}
            element={
              <Home
                newPopup={newPopup}
                popups={popups}
                closePopup={closePopup}
                menu={"Apps"}
              />
            }
          />
          <Route
            path="/resos"
            newPopup={newPopup}
            popups={popups}
            closePopup={closePopup}
            element={
              <Home
                newPopup={newPopup}
                popups={popups}
                closePopup={closePopup}
                menu={"Resos"}
              />
            }
          />
          <Route
            path="/ideaz"
            newPopup={newPopup}
            popups={popups}
            closePopup={closePopup}
            element={
              <Home
                newPopup={newPopup}
                popups={popups}
                closePopup={closePopup}
                menu={"Ideaz"}
              />
            }
          />
          <Route
            path="/uxers"
            newPopup={newPopup}
            popups={popups}
            closePopup={closePopup}
            element={
              <Home
                newPopup={newPopup}
                popups={popups}
                closePopup={closePopup}
                menu={"Uxers"}
              />
            }
          />
          <Route
            path="/welcome/:token"
            element={
              <Home
                newPopup={newPopup}
                popups={popups}
                closePopup={closePopup}
              />
            }
          />
          <Route
            path="/recreate-password/:resetToken/:id"
            element={
              <Home
                newPopup={newPopup}
                popups={popups}
                closePopup={closePopup}
              />
            }
          />
          <Route
            path="/signup/:token/email/:email"
            element={
              <SignUp
                newPopup={newPopup}
                popups={popups}
                closePopup={closePopup}
              />
            }
          />
          <Route
            path="/compos"
            element={
              <Compos
                newPopup={newPopup}
                popups={popups}
                closePopup={closePopup}
              />
            }
          />
          <Route path="events/event" element={<Event joinus={false} />} />
          <Route
            path="/events/event/joinus"
            element={<Event joinus={true} />}
          />
          <Route path="events/mailus" element={<EventContact />} />
          <Route path="events/future" element={<Future />} />
          <Route path="events/noevent" element={<NoEvent />} />
          <Route path="events/About" element={<About />} />
          <Route path="events/Past" element={<Past />} />
          <Route
            path="/signup"
            element={
              <SignUp
                newPopup={newPopup}
                popups={popups}
                closePopup={closePopup}
              />
            }
          />
          <Route
            path="/signup/:token"
            element={
              <SignUp
                newPopup={newPopup}
                popups={popups}
                closePopup={closePopup}
              />
            }
          />
          <Route path="/:username" element={<Profile />} />
          <Route path="/chat" element={<Chat />} />
          <Route
            path="/settings"
            newPopup={newPopup}
            popups={popups}
            closePopup={closePopup}
            element={<Settings />}
          />
          <Route
            path="/settings/email"
            newPopup={newPopup}
            popups={popups}
            closePopup={closePopup}
            element={<Settings selectEmail={true} />}
          />
          <Route
            path="/aboutus"
            newPopup={newPopup}
            popups={popups}
            closePopup={closePopup}
            element={<AboutUs />}
          />
          <Route
            path="/"
            newPopup={newPopup}
            popups={popups}
            closePopup={closePopup}
            element={<HomePage />}
          />
          <Route
            path="/news"
            newPopup={newPopup}
            popups={popups}
            closePopup={closePopup}
            element={<News />}
          />
          <Route
            path="/blog"
            newPopup={newPopup}
            popups={popups}
            closePopup={closePopup}
            element={<Blog />}
          />
          <Route
            path="/signupsoon"
            newPopup={newPopup}
            popups={popups}
            closePopup={closePopup}
            element={<SignUpSoon />}
          />
          <Route
            path="/privacy"
            newPopup={newPopup}
            popups={popups}
            closePopup={closePopup}
            element={<Privacy />}
          />
          <Route
            path="/privacy/terms"
            newPopup={newPopup}
            popups={popups}
            closePopup={closePopup}
            element={<Privacy terms={true} />}
          />
          <Route
            path="/servicez"
            newPopup={newPopup}
            popups={popups}
            closePopup={closePopup}
            element={<Servicez />}
          />
          <Route
            path="/contact"
            newPopup={newPopup}
            popups={popups}
            closePopup={closePopup}
            element={<ContactUs />}
          />
          <Route
            path="/dashboard"
            newPopup={newPopup}
            popups={popups}
            closePopup={closePopup}
            element={<Dashboard />}
          />
          <Route
            path="/uxersoon"
            newPopup={newPopup}
            popups={popups}
            closePopup={closePopup}
            element={<UxerSoon />}
          />
          <Route
            path="/comingsoon"
            newPopup={newPopup}
            popups={popups}
            closePopup={closePopup}
            element={<ComingSoon />}
          />
          <Route
            path="/soooon"
            newPopup={newPopup}
            popups={popups}
            closePopup={closePopup}
            element={<Soon />}
          />
          <Route
            path="/index.php "
            newPopup={newPopup}
            popups={popups}
            closePopup={closePopup}
            element={<Home />}
          />
          <Route path="/404" element={<Error404 />} />
        </Routes>
      </Router>
    </React.Fragment>
  );
}

export default App;
