export const setSelectMenu = (menu) => {
  return {
    type: "SETSELECTMENU",
    payload: menu,
  };
};
export const setSelectLang = (lang) => {
  return {
    type: "SETSELECTLANG",
    payload: lang,
  };
};
