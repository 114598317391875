import RoundImage from "../atoms/RoundImage";
import { ReactComponent as FlagIcon } from "../../assets/icons/flag.svg";
import Flag from "../molecules/Flag";
import { ReactComponent as SaveIcon } from "../../assets/icons/ok.svg";
import { useSelector } from "react-redux/es/hooks/useSelector";
import jwtDecode from "jwt-decode";
import { useEffect, useState } from "react";
import { ReactComponent as DefaultProfile } from "../../assets/icons/default_profile.svg";
import { getUserInfo } from "../../services/authServices";
import { fetchImage } from "../../services/itemsServices";
import { seSelector } from "react-redux/es/hooks/useSelector";
import React from "react";

function ChatAvatar({
  selectedMenu,
  size,
  hide,
  country,
  name,
  role,
  image,
  username,
  clickable = false,
}) {
  const user = useSelector((state) => state.user);
  const [images, setImages] = useState("");

  useEffect(() => {
    if (image) {
      fetchImage("profile", image)
        .then((result) => {
          if (result != undefined) {
            setImages(result);
          }
        })
        .catch((err) => {
          setImages("");
        });
    }
  }, [image]);
  return (
    <>
      <div className={`chat-avatar-small ${hide && "md:hidden"}`}>
        <RoundImage
          imageFull={false}
          imageData={images}
          noImage={
            <DefaultProfile
              data-value="redirect"
              className="default-item-photo"
            />
          }
          alt="Cropped Avatar"
          size={size}
          grey="grey-border"
          className="cursor-pointer"
          onClick={clickable ? () => (window.location = `/${username}`) : null}
        />
        <div className="chat-avatar-info">
          <p
            className={`chat-avatar-info-name ${
              selectedMenu === "chat" && "!text-white !text-sm !font-medium"
            } text-left cursor-pointer`}
            onClick={
              clickable ? () => (window.location = `/${username}`) : null
            }
          >
            {name}
          </p>
          <p
            className={`chat-avatar-info-title ${
              selectedMenu === "chat" && "!text-white !text-sm"
            } text-left `}
            style={country && { color: "var(--primary-color)" }}
          >
            {role}
          </p>
          {country ? (
            <div className="chat-avatar-info-country-cont">
              <div className="chat-avatar-country-flag-cont">
                <Flag
                  code={country}
                  height="28"
                  className="chat-avatar-country-flag"
                />
              </div>

              <p className="chat-avatar-info-country">{country}</p>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
}

export default ChatAvatar;
