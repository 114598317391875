import React from "react";
import { useDispatch, useSelector } from "react-redux";

function LangugeSwitch({
  language,
  className = "",
  color = "",
  ...otherProps
}) {
  const lang = useSelector((state) => state.lang);

  return (
    <div
      style={{ color: color }}
      className={`language-switch` }
      {...otherProps}
      data-lang={lang}
    >
      {language === "jp" ? "English" : "日本語"}
    </div>
  );
}

export default LangugeSwitch;
