import "../../styles/events.scss";
import PropTypes from "prop-types";
import StaticPageBigImage from "../../components/organismes/StaticPageBigImage";
import { Link } from 'react-router-dom';

function PastCardCompo({
  date,
  month,
  year,
  day,
  time,
  place,
  city,
  priceLabel,
  price,
  title,
  tags,
  buttonLink,
  imgSrc1Small,
  imgSrc1Large,
}) {
  return (
    <Link to={buttonLink} className="past_card_hero">
      <div className="past_card_info_box">
        <div className="date_box">
          <div className="date">
            {date}
            <div className="m_y">
              {month}
              <h5>{year}</h5>
            </div>
          </div>

          <div className="place">
            <span>
              {place}
              <h5>{city}</h5>
            </span>
            <div className="price">
              {priceLabel}
              <h5>{price}</h5>
            </div>
          </div>
        </div>
        <div className="space"></div>
        <div
          className="title"
          dangerouslySetInnerHTML={{ __html: title }}
        ></div>
        <div className="tags_box">
          {tags.map((tag, index) => (
            <span key={index}>{tag}</span>
          ))}
        </div>
      </div>
      <div className="past_img_box">
        <StaticPageBigImage
          imgSrc1Small={imgSrc1Small}
          imgSrc1Large={imgSrc1Large}
        />
      </div>
    </Link>
  );
}

// Define default props
PastCardCompo.defaultProps = {
  date: "01",
  month: "JAN",
  year: "2024",
  day: "MONDAY",
  time: "00:00~00:00",
  place: "Default Place",
  // city: "Default City",
  priceLabel: "Price",
  price: "FREE",
  title: "Default Event Title",
  tags: ["Tag1", "Tag2"],
  buttonLink: "#",
  imgSrc1Small: "default-small.png",
  imgSrc1Large: "default-large.png",
};

// Define prop types
PastCardCompo.propTypes = {
  date: PropTypes.string,
  month: PropTypes.string,
  year: PropTypes.string,
  day: PropTypes.string,
  time: PropTypes.string,
  place: PropTypes.string,
  city: PropTypes.string,
  priceLabel: PropTypes.string,
  price: PropTypes.string,
  title: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
  buttonLink: PropTypes.string,
  imgSrc1Small: PropTypes.string,
  imgSrc1Large: PropTypes.string,
};

export default PastCardCompo;
