import React, { useEffect, useState } from "react";
import AvatarEditor from "react-avatar-editor";
import RoundImage from "../atoms/RoundImage";
import PopupContainer from "./PopupContainer";
import { Slider } from "@material-ui/core";
import Button from "../atoms/Button";
import { ReactComponent as Upload } from "../../assets/icons/upload2.svg";

function AvatarCrop(props) {
  const [editor, setEditor] = useState(null);
  const [image, setImage] = useState(null);
  const [croppedImage, setCroppedImage] = useState();
  const [zoom, setZoom] = useState(1);
  useEffect(() => {}, [image]);
  const handleImageChange = (e) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      setImage(event.target.result);
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  const handleCrop = () => {
    if (editor) {
      const canvas = editor.getImageScaledToCanvas().toDataURL();
      setCroppedImage(canvas);
      setImage(null); // Clear the selected image
      props.onChange(canvas);
    }
  };

  const handleClean = () => {
    setImage(null);
    props.onChange("");
  };
  useEffect(() => {
    if (props.croppedImage) {
      setCroppedImage(
        props.fullSrc
          ? props.croppedImage
          : `data:image/${props.png};base64,${props.croppedImage}`
      );
    }
  }, [props.croppedImage]);
  return (
    <>
      <div className={`avatar_box ${props.error ? "avatar_box_error" : ""}`}>
        <div className={props.className}>
          <RoundImage
            imageFull={true}
            imageData={croppedImage}
            alt="Cropped Avatar"
            error={props.error}
          />
          {!croppedImage && props.logo}
          {props.addIcon && !croppedImage && (
            <label htmlFor="profile" className="avatar-icon icon">
              {props.addIcon}
              <input
                type="file"
                id="profile"
                onChange={handleImageChange}
                style={{ display: "none" }}
              />
            </label>
          )}
          {croppedImage && (
            <div
              className="avatar-icon icon trash-icon"
              onClick={() => {
                setCroppedImage(null);
                props.onDelete();
              }}
            >
              {props.deleteIcon}
            </div>
          )}
        </div>
      </div>

      {image && (
        <div className="container-editor">
          <PopupContainer
            title={"Upload Photo"}
            onClose={handleClean}
            className={"upload-avatar-popup "}
            windowClass={"upload-avatar-window"}
            titleClass={"upload-avatar-title"}
          >
            <AvatarEditor
              ref={(ref) => setEditor(ref)}
              image={image}
              className="editor"
              width={160}
              height={160}
              borderRadius={100}
              border={0}
              rotate={0}
              scale={zoom}
            />
            <Slider
              aria-label="raceSlider"
              value={zoom}
              min={1}
              max={4}
              step={0.1}
              onChange={(e, value) => setZoom(value)}
            ></Slider>
            <Button onClick={handleCrop} rightIcon={<Upload />}>
              Upload
            </Button>
          </PopupContainer>
        </div>
      )}
    </>
  );
}

export default AvatarCrop;
