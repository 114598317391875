const selectMenuReducer = (state = "Tools", action) => {
  switch (action.type) {
    case "SETSELECTMENU":
      return action.payload;
    default:
      return state;
  }
};
const selectLangReducer = (state = "en", action) => {
  switch (action.type) {
    case "SETSELECTLANG":
      return action.payload;
    default:
      return state;
  }
};

export { selectMenuReducer, selectLangReducer };
