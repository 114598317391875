import { ReactComponent as LogoIcon } from "../../assets/icons/logo_05.svg";
import { ReactComponent as NoResultIcon } from "../../assets/icons/noresult.svg";
import { ReactComponent as Logo07Icon } from "../../assets/icons/no_item_found.svg";
import { ReactComponent as IllusIcon } from "../../assets/icons/illustra_07.svg";
import React from "react";

import { useDispatch, useSelector } from "react-redux";
import ItemCard from "../organismes/ItemCard";
import TheEndItems from "../molecules/TheEndItems";
import { useEffect, useMemo, useState } from "react";
import LoadMoreItems from "../molecules/LoadMoreItems";
import { setFavsItems } from "../../redux/actions/items";
import { likeItem } from "../../services/userServices";
import NoLoggedMessage from "../molecules/NoLoggedMessage";
import NoResultFoundCard from "../molecules/NoResultFoundCard";
import LoadingCard from "../molecules/LoadingCard";
import {
  setOpenLoginPopup,
  setOpenSignupPopup,
} from "../../redux/actions/user";

function CardGrid({
  categorie = "Tools",
  filterValue,
  setIsSoonPopup,
  setFilterLength,
  filterLength,
}) {
  const dispatch = useDispatch();
  const items = useSelector((state) => state.items);
  const user = useSelector((state) => state.user);
  const favsItems = useSelector((state) => state.favsItems);
  const [favorites, setFavorites] = useState([]);
  const [part, setPart] = useState(1);
  const lang = useSelector((state) => state.lang);

  const favsCategory = (category) => {
    const capitalized = category.charAt(0).toUpperCase() + category.slice(1);
    return "my" + capitalized;
  };

  const filterItems = useMemo(() => {
    const usedItems = filterValue.tags === "Favs" ? favsItems : items;

    return usedItems[
      filterValue.tags === "Favs"
        ? favsCategory(categorie.toLowerCase())
        : categorie.toLowerCase()
    ]?.filter((item) => {
      if (filterValue["search"]) {
        let inName =
          item.name &&
          item.name.toLowerCase().includes(filterValue["search"].toLowerCase());
        let inDescription =
          item.description[0] &&
          item.description[0]
            .toLowerCase()
            .includes(filterValue["search"].toLowerCase());
        let inDescriptionJp =
          item.description[1] &&
          item.description[1].includes(filterValue["search"].toLowerCase());
        let inPlatform =
          item.platform &&
          item.platform.includes(filterValue["search"].toLowerCase());
        let inPrice =
          item.price &&
          item.price
            .toLowerCase()
            .includes(filterValue["search"].toLowerCase());
        let inTags =
          item.tags && item.tags.includes(filterValue["search"].toLowerCase());
        let inLink =
          item.link &&
          item.link.toLowerCase().includes(filterValue["search"].toLowerCase());

        if (
          !(
            inName ||
            inDescription ||
            inDescriptionJp ||
            inPlatform ||
            inPrice ||
            inTags ||
            inLink
          )
        ) {
          return false;
        }
      }

      if (
        filterValue["tags"] &&
        filterValue["tags"] !== "Favs" &&
        filterValue["tags"] !== "All"
      ) {
        if (
          !(
            item.filterTag &&
            item.filterTag
              .map((x) => x.toLowerCase())
              .includes(filterValue["tags"].toLowerCase())
          )
        ) {
          return false;
        }
      }

      if (filterValue["platform"]) {
        if (!item.platform?.includes(filterValue["platform"])) {
          return false;
        }
      }

      if (filterValue["prices"] && filterValue["prices"]?.length > 0) {
        if (!filterValue["prices"]?.includes(item.price)) {
          return false;
        }
      }
      return true;
    });
  }, [filterValue, items, favsItems]);

  useEffect(() => {
    setPart(1);
  }, [categorie, filterValue]);

  useEffect(() => {
    setFavorites(favsItems[favsCategory(categorie.toLowerCase())]);
  }, [categorie, favsItems]);

  const onToggleLike = (status, item) => {
    if (user) {
      if (status) {
        dispatch(
          setFavsItems({
            ...favsItems,
            [favsCategory(categorie)]:
              favorites != null ? [...favorites, item] : [item],
          })
        );
        likeItem(item._id, categorie.toLowerCase());
      } else {
        dispatch(
          setFavsItems({
            ...favsItems,
            [favsCategory(categorie)]: favorites.filter(
              (x) => x._id !== item._id
            ),
          })
        );
        likeItem(item._id, categorie.toLowerCase());
      }
    } else {
      dispatch(setOpenLoginPopup(true));
    }
  };
  useEffect(() => {
    filterItems ? setFilterLength(filterItems?.length) : setFilterLength(0);
  }, [filterItems]);
  return (
    <div
      className={`card-grid ${
        filterItems?.length === 0 ? "min-h-full h-full" : ""
      }`}
    >
      {filterItems?.slice(0, 120 * part).map((item, key) => {
        return (
          <ItemCard
            category={categorie.toLowerCase()}
            linkAndroid={item.linkAndroid}
            linkWindows={item.linkWindows}
            className={
              categorie.toLowerCase() !== "tools" ? "item-resos-ideaz" : ""
            }
            favsOnClick={() => {
              dispatch(setOpenSignupPopup(true));
            }}
            _id={item._id}
            key={key}
            image_src={item.img}
            title={item.name}
            subtitle={lang == "en" ? item.description[0] : item.description[1]}
            platform={
              categorie.toLowerCase() === "tools" ||
              categorie.toLowerCase() === "apps"
                ? item.platform
                : []
            }
            price={
              categorie.toLowerCase() === "tools" ||
              categorie.toLowerCase() === "apps"
                ? item.price
                : ""
            }
            tags={item.tags}
            link={item.link}
            like={
              favorites != null
                ? favorites.map((x) => x._id).includes(item._id)
                : null
            }
            setLike={(status) => onToggleLike(status, item)}
          />
        );
      })}

      {!filterItems || filterItems.length === 0 ? (
        <>
          {items["tools"].length === 0 ? (
            <>
              <LoadingCard />
            </>
          ) : (
            <>
              {user ? (
                <>
                  <NoResultFoundCard icon={<Logo07Icon />} />
                </>
              ) : (
                <>
                  <NoLoggedMessage
                    // icon={<IllusIcon />}
                    data-lang={lang}
                    title=""
                  >
                    {lang === "en" ? (
                      <>
                        {/* <div className="noLogged-title">
                        Don't Miss Out - Full Access Soon!
                        </div> */}
                        Unlock over{" "}
                        <span className="especial-text">1000+ </span>{" "}
                        resourceful links and gain access to exclusive content!{" "}
                        <br />
                        Create your personalized{" "}
                        <span className="especial-text">Page </span>,
                        <span className="especial-text">Save</span> and{" "}
                        <span className="especial-text">Share </span> your
                        favorites, and much more.
                        <br />
                        Get started now - log in or sign up.
                      </>
                    ) : (
                      <>
                        {/* <div className="noLogged-title" data-lang={lang}>
                         まもなく
                          <span style={{ fontFamily: "Outfit", fontSize:"25px",fontWeight:"400" }}>SIGN UP</span>
                          をスタート!
                        </div> */}
                        全てのコンテンツ（1000以上のリソースリンク）へのアクセス、追加のサービス＆機能をまもなく提供します。{" "}
                        <br />
                        最初に知るために今すぐ待機リストに参加してください -
                        早期アクセスとお気に入りのユーザーネームができます。
                      </>
                    )}
                  </NoLoggedMessage>
                </>
              )}
            </>
          )}
        </>
      ) : (
        <>
          {filterItems?.length >= 120 ? (
            filterItems?.length > 120 * part ? (
              <>
                <LoadMoreItems loadMore={() => setPart(part + 1)} />
              </>
            ) : user ? (
              <TheEndItems />
            ) : (
              <NoLoggedMessage
                icon={<Logo07Icon />}
                title=""
                className="min-h-full"
                data-lang={lang}
              >
                {lang === "en"
                  ? "To explore our vast library of resources, tools and more, Please log in or sign up."
                  : "広大なライブラリのリソース、ツール、およびその他を探索するには、ログインまたはサインアップしてください！！！"}
              </NoLoggedMessage>
            )
          ) : (
            <>
              {user ? (
                <></>
              ) : (
                <>
                  <NoLoggedMessage
                    // icon={<IllusIcon />}
                    data-lang={lang}
                    title=""
                  >
                    {lang === "en" ? (
                      <>
                        Unlock over{" "}
                        <span className="especial-text">1000+ </span>{" "}
                        resourceful links and gain access to exclusive content!{" "}
                        <br />
                        Create your personalized{" "}
                        <span className="especial-text">Page</span>,
                        <span className="especial-text"> Save</span> and{" "}
                        <span className="especial-text">Share </span> your
                        favorites, and much more.
                        <br />
                        Get started now - log in or sign up.
                      </>
                    ) : (
                      <>
                        <span className="especial-text">1000以上</span>
                        のリンクを解除して独占コンテンツにアクセス!
                        <span className="especial-text">マイページ</span>
                        を作成し、お気に入りを
                        <span className="especial-text">保存</span>して
                        <span className="especial-text">共有</span>
                        し、その他たくさんの機能を利用しましょう。
                        <br />
                        ログインまたはサインして今すぐ始めましょう!
                      </>
                    )}
                  </NoLoggedMessage>
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}

export default CardGrid;
