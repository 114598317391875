import { ReactComponent as LogoFull1 } from "../../../assets/icons/logo_full1.svg";
import { ReactComponent as Logout } from "../../../assets/icons/logout_b.svg";
import { ReactComponent as SettingsIcon } from "../../../assets/icons/setting_b.svg";
import { ReactComponent as ItemsIcon } from "../../../assets/icons/services.svg";
import { ReactComponent as User } from "../../../assets/icons/user_bb.svg";
import { ReactComponent as Info } from "../../../assets/icons/analytics_bb.svg";
import { removeLocalStorageItem } from "../../../services/localStorage";

function LeftBar({ selectedTab, setSelectedTab }) {

  const logout = () => {
    removeLocalStorageItem('admin-token');
    removeLocalStorageItem('role');
    window.location.href = '/'
  }
  return (
    <div className="left-menu-bar">
      <LogoFull1 className="logo" />
      <div className="left-bar-hr-hide"></div>
      <div className="left-bar-links">
        <div
          className="left-bar-cont"
          onClick={() => {
            setSelectedTab("items");
          }}
        >
          <ItemsIcon
            className={`left-bar-icon ${
              selectedTab == "items" && "left-bar-link selected"
            } `}
          />
          <div
            className={`left-bar-link ${selectedTab == "items" && "selected"} `}
          >
            ITEMS
          </div>
        </div>
        <div
          className="left-bar-cont"
          onClick={() => {
            setSelectedTab("users");
          }}
        >
          <User
            className={`left-bar-icon ${
              selectedTab == "users" && "left-bar-link selected"
            } `}
          />
          <div
            className={`left-bar-link ${selectedTab == "users" && "selected"} `}
          >
            USERS
          </div>
        </div>
        <div
          className="left-bar-cont"
          onClick={() => {
            setSelectedTab("info");
          }}
        >
          <Info
            className={`left-bar-icon ${
              selectedTab == "info" && "left-bar-link selected"
            } `}
          />
          <div
            className={`left-bar-link ${selectedTab == "info" && "selected"} `}
          >
            INFO
          </div>
        </div>
      </div>
      <div className="left-bar-ibuttons">
        <SettingsIcon />
        <Logout onClick={logout} />
      </div>
    </div>
  );
}

export default LeftBar;
