import { useEffect, useState } from "react";
import { t } from "i18next";
import PageTitle from "../atoms/PageTitle";
import LeftBarMessage from "../organismes/leftBarMessage";
import React from "react";
import jwtDecode from "jwt-decode";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { deleteChat, getChatInfo } from "../../services/ChatService";
import { ReactComponent as User } from "../../assets/icons/user.svg";
import { ReactComponent as Arrow } from "../../assets/icons/small_arrow.svg";
import { ReactComponent as Uxser } from "../../assets/icons/uxer_2_on.svg";
import { ReactComponent as Password } from "../../assets/icons/password.svg";
import { ReactComponent as Mail } from "../../assets/icons/mail2.svg";
import { ReactComponent as Account } from "../../assets/icons/account.svg";
import { ReactComponent as Setting } from "../../assets/icons/setting.svg";

import { getUserInfo } from "../../services/authServices";

function SettingsLeftBar({ selected, setSelected, handleClick }) {
  const lang = useSelector((state) => state.lang);

  const leftBarData = [
    {
      title: lang === "en" ? "Edit Profile" : "プロフィール編集",
      icon: (
        <User
          className={`settings-leftbar-link-icon ${
            selected === 0 ? "text-orange" : ""
          } `}
        />
      ),
    },
    // {
    //   title: lang === "en" ? "UXER Profile" : "UXERプロフィール",
    //   icon: (
    //     <Uxser
    //       className={`settings-leftbar-link-icon ${
    //         selected === 1 ? "text-orange" : ""
    //       } `}
    //     />
    //   ),
    // },
    {
      title: lang === "en" ? "Change Email" : "メールアドレス変更",
      icon: (
        <Mail
          className={`settings-leftbar-link-icon ${
            selected === 1 ? "text-orange" : ""
          } `}
        />
      ),
    },
    {
      title: lang === "en" ? "Change Password" : "パスワード変更",
      icon: (
        <Password
          className={`settings-leftbar-link-icon ${
            selected === 2 ? "text-orange" : ""
          } `}
        />
      ),
    },
    {
      title: lang === "en" ? "Account" : "パスワード変更",
      icon: (
        <Account
          className={`settings-leftbar-link-icon ${
            selected === 3 ? "text-orange" : ""
          } `}
        />
      ),
    },
  ];

  const leftBarTitle = leftBarData.map((el, index) => {
    return (
      <div className="settings-leftbar-link" onClick={() => handleClick(index)}>
        <div className="flex gap-2 items-center">
          {el.icon}
          <p
            className={`settings-leftbar-link-text ${
              selected === index ? "text-orange" : ""
            }`}
            data-lang={lang}
          >
            {el.title}
          </p>
        </div>
        <Arrow
          className={`settings-leftbar-link-icon ${
            selected === index ? "text-orange" : ""
          }`}
        />
      </div>
    );
  });

  return (
    <>
      <div className="setting_menu_box" data-lang={lang}>
        <div className="setting_title_box">
          {
            <PageTitle
              iconLeft={<Setting />}
              title={lang === "en" ? "Settings" : "設定"}
              className="md:hidden"
            />
          }
        </div>
        <div className="flex flex-col">{leftBarTitle}</div>
      </div>
    </>
  );
}

export default SettingsLeftBar;
