import "../../styles/events.scss";
import EventContent from "./EventContent";
import HeaderCompo from "./HeaderEvent";
import PastCardCompo from "./PastCard";

function Past() {
  return (
    <>
      <HeaderCompo />

      <div className="event_hero contact_hero">
        <div className="past_card_list_hero">
          <div className="title_page">
            PAST EVENTS <span>3</span>
          </div>
          {/* <PastCardCompo
            date="14"
            month="SEP"
            year="2024"
            place="高田馬場"
            city="TOKYO"
            price="FREE"
            title="デザインシステムの謎を解く!より深い理解のためのインサイト"
            tags={["Design System", "UI Design", "Figma"]}
            imgSrc1Small="past_14_8_2024.png"
            imgSrc1Large="past_14_8_2024.png"
            buttonLink="/events/future"
          /> */}
          <PastCardCompo
            date="13"
            month="AUG"
            year="2024"
            place="高田馬場"
            city="TOKYO"
            price="FREE"
            title="UXデザインの基礎、理論&実例 - ユーザー中心デザインの秘訣"
            tags={["UX Design", "Design Process", "User Center Design"]}
            imgSrc1Small="past_13_08_2024.png"
            imgSrc1Large="past_13_08_2024.png"
            // buttonLink="/events/past_13_8_2024"
          />
          <PastCardCompo
            date="22"
            month="JUN"
            year="2024"
            place="高田馬場"
            city="TOKYO"
            price="FREE"
            title="UIデザインスキルを 劇的に向上させる秘訣-レシピのアプリのUIデザイン作成!"
            tags={["UI Design", "Tips & Tricks", "Figma", "Before & After"]}
            imgSrc1Small="past_22_06_2024.png"
            imgSrc1Large="past_22_06_2024.png"
            // buttonLink="/events/future"
          />
          <PastCardCompo
            date="09"
            month="JUL"
            year="2022"
            place="ONLINE"
            // city="TOKYO"
            price="FREE"
            title="FIGMA新機能の紹介+AutoLayout説明/コツ+ProtoPieの紹介/プレゼント"
            tags={["Figma", "Autolayout", "Tips & Tricks", "Protopie"]}
            imgSrc1Small="past_09_07_2022.png"
            imgSrc1Large="past_09_07_2022.png"
            // buttonLink="/events/future"
          />
        </div>
      </div>
    </>
  );
}

export default Past;
