import axios from "axios";
import { adminApi } from "./axios";


export function isAdmin(user) {
  return user?.role?.name === "admin";
}
// items dashboard
export async function getAllItems() {
  return await adminApi.get(`/items/filter`, {
    params: {
      limit: 100000,
    }
  });
}

export async function deleteItem(id_item) {
  return await adminApi.delete(`/items/${id_item}`);
}

export function addManyItems(file) {
  var form = new FormData();
  form.append("csvFile", file);
  console.log("form", form);
  return adminApi.post(`/items/create-many`, form, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
// add item
export async function addItem(data, image) {
  var form = new FormData();

  const picRes = await axios.get(image, {
    responseType: "blob",
  });

  const blob = picRes.data;

  const mimeType = blob.type;
  const fileName = `${data?.name || "image_all"}.${mimeType.substring(6)}`;
  const file = new File([blob], fileName, { type: mimeType });

  form.append("itemImg", file);
  form.append("name", data?.name);
  form.append("link", data?.link);
  form.append("tags", data?.tags);
  form.append("filterTag", data?.filterTag);
  form.append("platform", data?.platform);
  form.append("price", data?.price);
  form.append("engDescription", data?.engDescription);
  form.append("jpDescription", data?.jpDescription);
  form.append("linkAndroid", data?.linkAndroid || '');
  form.append("linkWindows", data?.linkWindows || '');
  form.append(
    "category_id",
    data?.category === "tools"
      ? "64761102ba22fed734b9de7a"
      : data?.category === "resos"
      ? "64761222ba22fed734b9de7d" :
      data?.category === "apps" ? 
      "65a2912599ed3cb8b9886940"
      : "64761240ba22fed734b9de81"
  );

  const res = await adminApi.post(`/items/`, form);

  return res;
}

// update item
export async function editItem(data, image) {
  var form = new FormData();
  if (image) {
    const picRes = await axios.get(image, {
      responseType: "blob",
    });
    console.log("pic res", picRes, image);

    const blob = picRes.data;

    const mimeType = blob.type;
    const fileName = `${data?.name || "image_all"}.${mimeType.substring(6)}`;
    const file = new File([blob], fileName, { type: mimeType });

    form.append("itemImg", file);

    for (var pair of form.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }
  }
  form.append("name", data?.name);
  form.append("link", data?.link);
  form.append("tags", data?.tags);
  form.append("filterTag", data?.filterTag);
  form.append("platform", data?.platform);
  form.append("price", data?.price);
  form.append("engDescription", data?.engDescription);
  form.append("jpDescription", data?.jpDescription);
  form.append("linkAndroid", data?.linkAndroid || '');
  form.append("linkWindows", data?.linkWindows || '');
  console.log("rr", data);
  form.append(
    "category_id",
    data?.category === "tools"
      ? "64761102ba22fed734b9de7a"
      : data?.category === "resos"
      ? "64761222ba22fed734b9de7d" :
      data?.category === "apps" ? 
      "65a2912599ed3cb8b9886940"
      : "64761240ba22fed734b9de81"
  );

  const result = adminApi.post(`/items/${data._id}`, form);

  console.log(result);

  return result;
}

// upload many images
export async function uploadManyImage(images) {
  const batchSize = 5;
  const uploadPromises = [];

  for (let i = 0; i < images.length; i += batchSize) {
    const batch = images.slice(i, i + batchSize);
    const formData = new FormData();

    for (let j = 0; j < batch.length; j++) {
      formData.append('itemsImg', batch[j], batch[j].name);
    }

    const uploadPromise = adminApi.post(`/items/add-many-imgs`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    uploadPromises.push(uploadPromise);
  }

  return await Promise.allSettled(uploadPromises);
}


// user dashboard
export function getPendingUxer() {
  return adminApi.get(`/admin/pending-uxers`);
}
export async function getAllUsers() {
  return await adminApi.get(`/admin/get-users`);
}
export async function deleteUser(id) {
  return await adminApi.post(
    `/admin/delete-user/${id}`,
    {},
  );
}

export async function getPendingUxers() {
  return await adminApi.get(`/admin/pending-uxers`);
}

export async function acceptUxer(id) {
  return await adminApi.post(
    `/admin/accept-uxer-request/${id}`,
    {}
  );
}
export async function rejectUxer(id) {
  return await adminApi.post(
    `/admin/reject-uxer-request/${id}`,
    {}
  );
}
