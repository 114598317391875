
import React from 'react';


import { ReactComponent as about } from '../assets/icons/about.svg';
import { ReactComponent as about_b } from '../assets/icons/about_b.svg';
import { ReactComponent as about_big } from '../assets/icons/about_big.svg';
import { ReactComponent as account } from '../assets/icons/account.svg';
import { ReactComponent as add } from '../assets/icons/add.svg';
import { ReactComponent as add_1 } from '../assets/icons/add_1.svg';
import { ReactComponent as add_project } from '../assets/icons/add_project.svg';
import { ReactComponent as all } from '../assets/icons/all.svg';
import { ReactComponent as analytics } from '../assets/icons/analytics.svg';
import { ReactComponent as analytics_b } from '../assets/icons/analytics_b.svg';
import { ReactComponent as analytics_bb } from '../assets/icons/analytics_bb.svg';
import { ReactComponent as android } from '../assets/icons/android.svg';
import { ReactComponent as android_fill } from '../assets/icons/android_fill.svg';
import { ReactComponent as anime } from '../assets/icons/anime.svg';
import { ReactComponent as apple } from '../assets/icons/apple.svg';
import { ReactComponent as apple_fill } from '../assets/icons/apple_fill.svg';
import { ReactComponent as apps } from '../assets/icons/apps.svg';
import { ReactComponent as archive } from '../assets/icons/archive.svg';
import { ReactComponent as arrow } from '../assets/icons/arrow.svg';
import { ReactComponent as attach } from '../assets/icons/attach.svg';
import { ReactComponent as b_logo } from '../assets/icons/b_logo.svg';
import { ReactComponent as back } from '../assets/icons/back.svg';
import { ReactComponent as bag } from '../assets/icons/bag.svg';
import { ReactComponent as banner_bg } from '../assets/icons/banner_bg.svg';
import { ReactComponent as behance } from '../assets/icons/behance.svg';
import { ReactComponent as behance_line } from '../assets/icons/behance_line.svg';
import { ReactComponent as beta } from '../assets/icons/beta.svg';
import { ReactComponent as blocked } from '../assets/icons/blocked.svg';
import { ReactComponent as blog } from '../assets/icons/blog.svg';
import { ReactComponent as blog_b } from '../assets/icons/blog_b.svg';
import { ReactComponent as blog_line } from '../assets/icons/blog_line.svg';
import { ReactComponent as book } from '../assets/icons/book.svg';
import { ReactComponent as broken_link } from '../assets/icons/broken_link.svg';
import { ReactComponent as browser } from '../assets/icons/browser.svg';
import { ReactComponent as cal } from '../assets/icons/cal.svg';
import { ReactComponent as cal2 } from '../assets/icons/cal2.svg';
import { ReactComponent as camera } from '../assets/icons/camera.svg';
import { ReactComponent as caution } from '../assets/icons/caution.svg';
import { ReactComponent as chat } from '../assets/icons/chat.svg';
import { ReactComponent as check } from '../assets/icons/check.svg';
import { ReactComponent as clear } from '../assets/icons/clear.svg';
import { ReactComponent as close } from '../assets/icons/close.svg';
import { ReactComponent as close_s } from '../assets/icons/close_s.svg';
import { ReactComponent as company } from '../assets/icons/company.svg';
import { ReactComponent as danger } from '../assets/icons/danger.svg';
import { ReactComponent as default_item } from '../assets/icons/default_item.svg';
import { ReactComponent as default_item_ } from '../assets/icons/default_item_.svg';
import { ReactComponent as default_profile } from '../assets/icons/default_profile.svg';
import { ReactComponent as delete_account } from '../assets/icons/delete_account.svg';
import { ReactComponent as delete_icn } from '../assets/icons/delete_icn.svg';
import { ReactComponent as delete_icon2 } from '../assets/icons/delete_icon2.svg';
import { ReactComponent as designers_home } from '../assets/icons/designers_home.svg';
import { ReactComponent as download } from '../assets/icons/download.svg';
import { ReactComponent as dribbble } from '../assets/icons/dribbble.svg';
import { ReactComponent as dribbble_line } from '../assets/icons/dribbble_line.svg';
import { ReactComponent as drop_down } from '../assets/icons/drop_down.svg';
import { ReactComponent as drop_up } from '../assets/icons/drop_up.svg';
import { ReactComponent as edit } from '../assets/icons/edit.svg';
import { ReactComponent as en } from '../assets/icons/en.svg';
import { ReactComponent as error404 } from '../assets/icons/error404.svg';
import { ReactComponent as error404_02 } from '../assets/icons/error404_02.svg';
import { ReactComponent as error500 } from '../assets/icons/error500.svg';
import { ReactComponent as error500_02 } from '../assets/icons/error500_02.svg';
import { ReactComponent as event } from '../assets/icons/event.svg';
import { ReactComponent as experience } from '../assets/icons/experience.svg';
import { ReactComponent as eye_off } from '../assets/icons/eye_off.svg';
import { ReactComponent as eye_on } from '../assets/icons/eye_on.svg';
import { ReactComponent as facebook } from '../assets/icons/facebook.svg';
import { ReactComponent as facebook_ } from '../assets/icons/facebook_.svg';
import { ReactComponent as facebook_line } from '../assets/icons/facebook_line.svg';
import { ReactComponent as fav } from '../assets/icons/fav.svg';
import { ReactComponent as fav_f } from '../assets/icons/fav_f.svg';
import { ReactComponent as fav_line } from '../assets/icons/fav_line.svg';
import { ReactComponent as figma } from '../assets/icons/figma.svg';
import { ReactComponent as figma_line } from '../assets/icons/figma_line.svg';
import { ReactComponent as flag } from '../assets/icons/flag.svg';
import { ReactComponent as free } from '../assets/icons/free.svg';
import { ReactComponent as freemium } from '../assets/icons/freemium.svg';
import { ReactComponent as full_name } from '../assets/icons/full_name.svg';
import { ReactComponent as future } from '../assets/icons/future.svg';
import { ReactComponent as globe } from '../assets/icons/globe.svg';
import { ReactComponent as gmail } from '../assets/icons/gmail.svg';
import { ReactComponent as hafid } from '../assets/icons/hafid.svg';
import { ReactComponent as hand1 } from '../assets/icons/hand1.svg';
import { ReactComponent as hand2 } from '../assets/icons/hand2.svg';
import { ReactComponent as hand3 } from '../assets/icons/hand3.svg';
import { ReactComponent as hand4 } from '../assets/icons/hand4.svg';
import { ReactComponent as heart } from '../assets/icons/heart.svg';
import { ReactComponent as home } from '../assets/icons/home.svg';
import { ReactComponent as home_logo } from '../assets/icons/home_logo.svg';
import { ReactComponent as hum } from '../assets/icons/hum.svg';
import { ReactComponent as hum2 } from '../assets/icons/hum2.svg';
import { ReactComponent as human } from '../assets/icons/human.svg';
import { ReactComponent as id } from '../assets/icons/id.svg';
import { ReactComponent as id1 } from '../assets/icons/id1.svg';
import { ReactComponent as id_icon } from '../assets/icons/id_icon.svg';
import { ReactComponent as idea } from '../assets/icons/idea.svg';
import { ReactComponent as illus_soon } from '../assets/icons/illus_soon.svg';
import { ReactComponent as illus_soon2 } from '../assets/icons/illus_soon2.svg';
import { ReactComponent as illus_soon_copy } from '../assets/icons/illus_soon_copy.svg';
import { ReactComponent as illust_01 } from '../assets/icons/illust_01.svg';
import { ReactComponent as illustra_0 } from '../assets/icons/illustra_0.svg';
import { ReactComponent as illustra_00 } from '../assets/icons/illustra_00.svg';
import { ReactComponent as illustra_01 } from '../assets/icons/illustra_01.svg';
import { ReactComponent as illustra_02 } from '../assets/icons/illustra_02.svg';
import { ReactComponent as illustra_03 } from '../assets/icons/illustra_03.svg';
import { ReactComponent as illustra_04 } from '../assets/icons/illustra_04.svg';
import { ReactComponent as illustra_05 } from '../assets/icons/illustra_05.svg';
import { ReactComponent as illustra_06 } from '../assets/icons/illustra_06.svg';
import { ReactComponent as illustra_07 } from '../assets/icons/illustra_07.svg';
import { ReactComponent as illustra_08 } from '../assets/icons/illustra_08.svg';
import { ReactComponent as image } from '../assets/icons/image.svg';
import { ReactComponent as instagram } from '../assets/icons/instagram.svg';
import { ReactComponent as instagram_line } from '../assets/icons/instagram_line.svg';
import { ReactComponent as interv } from '../assets/icons/interv.svg';
import { ReactComponent as invitation } from '../assets/icons/invitation.svg';
import { ReactComponent as items } from '../assets/icons/items.svg';
import { ReactComponent as items_b } from '../assets/icons/items_b.svg';
import { ReactComponent as job } from '../assets/icons/job.svg';
import { ReactComponent as jobs } from '../assets/icons/jobs.svg';
import { ReactComponent as juusando_spo } from '../assets/icons/juusando_spo.svg';
import { ReactComponent as kome_mark } from '../assets/icons/kome_mark.svg';
import { ReactComponent as language } from '../assets/icons/language.svg';
import { ReactComponent as left_arrow } from '../assets/icons/left_arrow.svg';
import { ReactComponent as link_ext } from '../assets/icons/link_ext.svg';
import { ReactComponent as linkdin } from '../assets/icons/linkdin.svg';
import { ReactComponent as linkedin } from '../assets/icons/linkedin.svg';
import { ReactComponent as linkedin_line } from '../assets/icons/linkedin_line.svg';
import { ReactComponent as linux } from '../assets/icons/linux.svg';
import { ReactComponent as load } from '../assets/icons/load.svg';
import { ReactComponent as loading } from '../assets/icons/loading.svg';
import { ReactComponent as loading_ani } from '../assets/icons/loading_ani.svg';
import { ReactComponent as loading_card } from '../assets/icons/loading_card.svg';
import { ReactComponent as loading_card_new } from '../assets/icons/loading_card_new.svg';
import { ReactComponent as lock } from '../assets/icons/lock.svg';
import { ReactComponent as login } from '../assets/icons/login.svg';
import { ReactComponent as logo } from '../assets/icons/logo.svg';
import { ReactComponent as logo_05 } from '../assets/icons/logo_05.svg';
import { ReactComponent as logo_06 } from '../assets/icons/logo_06.svg';
import { ReactComponent as logo_07 } from '../assets/icons/logo_07.svg';
import { ReactComponent as logo_full } from '../assets/icons/logo_full.svg';
import { ReactComponent as logo_full1 } from '../assets/icons/logo_full1.svg';
import { ReactComponent as logo_placeholder } from '../assets/icons/logo_placeholder.svg';
import { ReactComponent as logo_type } from '../assets/icons/logo_type.svg';
import { ReactComponent as logo_type_en } from '../assets/icons/logo_type_en.svg';
import { ReactComponent as logo_type_jp } from '../assets/icons/logo_type_jp.svg';
import { ReactComponent as logout } from '../assets/icons/logout.svg';
import { ReactComponent as logout_b } from '../assets/icons/logout_b.svg';
import { ReactComponent as mac } from '../assets/icons/mac.svg';
import { ReactComponent as mail } from '../assets/icons/mail.svg';
import { ReactComponent as mailus } from '../assets/icons/mailus.svg';
import { ReactComponent as mail2_1 } from '../assets/icons/mail2_1.svg';
import { ReactComponent as mail_fill } from '../assets/icons/mail_fill.svg';
import { ReactComponent as mails } from '../assets/icons/mails.svg';
import { ReactComponent as map } from '../assets/icons/map.svg';
import { ReactComponent as meetup } from '../assets/icons/meetup.svg';
import { ReactComponent as message_back } from '../assets/icons/message_back.svg';
import { ReactComponent as messenger } from '../assets/icons/messenger.svg';
import { ReactComponent as mic } from '../assets/icons/mic.svg';
import { ReactComponent as mob_hum } from '../assets/icons/mob_hum.svg';
import { ReactComponent as money } from '../assets/icons/money.svg';
import { ReactComponent as mute_off } from '../assets/icons/mute_off.svg';
import { ReactComponent as mute_on } from '../assets/icons/mute_on.svg';
import { ReactComponent as name_card } from '../assets/icons/name_card.svg';
import { ReactComponent as namecard } from '../assets/icons/namecard.svg';
import { ReactComponent as new_mail } from '../assets/icons/new_mail.svg';
import { ReactComponent as new_password } from '../assets/icons/new_password.svg';
import { ReactComponent as news } from '../assets/icons/news.svg';
import { ReactComponent as news_1 } from '../assets/icons/news_1.svg';
import { ReactComponent as newsletter } from '../assets/icons/newsletter.svg';
import { ReactComponent as newz } from '../assets/icons/newz.svg';
import { ReactComponent as no_favs } from '../assets/icons/no_favs.svg';
import { ReactComponent as no_favs2 } from '../assets/icons/no_favs2.svg';
import { ReactComponent as no_item_found } from '../assets/icons/no_item_found.svg';
import { ReactComponent as no_logo } from '../assets/icons/no_logo.svg';
import { ReactComponent as noresult } from '../assets/icons/noresult.svg';
import { ReactComponent as norif_off } from '../assets/icons/norif_off.svg';
import { ReactComponent as norif_on } from '../assets/icons/norif_on.svg';
import { ReactComponent as ok } from '../assets/icons/ok.svg';
import { ReactComponent as order_az } from '../assets/icons/order_az.svg';
import { ReactComponent as order_za } from '../assets/icons/order_za.svg';
import { ReactComponent as paid } from '../assets/icons/paid.svg';
import { ReactComponent as password } from '../assets/icons/password.svg';
import { ReactComponent as past } from '../assets/icons/past.svg';
import { ReactComponent as pause } from '../assets/icons/pause.svg';
import { ReactComponent as peatix } from '../assets/icons/peatix.svg';
import { ReactComponent as phone } from '../assets/icons/phone.svg';
import { ReactComponent as pinjob_spo } from '../assets/icons/pinjob_spo.svg';
import { ReactComponent as popup } from '../assets/icons/popup.svg';
import { ReactComponent as post } from '../assets/icons/post.svg';
import { ReactComponent as press } from '../assets/icons/press.svg';
import { ReactComponent as press_kit } from '../assets/icons/press_kit.svg';
import { ReactComponent as privacy } from '../assets/icons/privacy.svg';
import { ReactComponent as privacy_s } from '../assets/icons/privacy_s.svg';
import { ReactComponent as project } from '../assets/icons/project.svg';
import { ReactComponent as project_s } from '../assets/icons/project_s.svg';
import { ReactComponent as pull_down } from '../assets/icons/pull_down.svg';
import { ReactComponent as pull_up } from '../assets/icons/pull_up.svg';
import { ReactComponent as register } from '../assets/icons/register.svg';
import { ReactComponent as report } from '../assets/icons/report.svg';
import { ReactComponent as request } from '../assets/icons/request.svg';
import { ReactComponent as reset } from '../assets/icons/reset.svg';
import { ReactComponent as resos } from '../assets/icons/resos.svg';
import { ReactComponent as restart } from '../assets/icons/restart.svg';
import { ReactComponent as right_arrow } from '../assets/icons/right_arrow.svg';
import { ReactComponent as robot } from '../assets/icons/robot.svg';
import { ReactComponent as save } from '../assets/icons/save.svg';
import { ReactComponent as screen } from '../assets/icons/screen.svg';
import { ReactComponent as scroll_anima } from '../assets/icons/scroll_anima.svg';
import { ReactComponent as search } from '../assets/icons/search.svg';
import { ReactComponent as send } from '../assets/icons/send.svg';
import { ReactComponent as services } from '../assets/icons/services.svg';
import { ReactComponent as services_1 } from '../assets/icons/services_1.svg';
import { ReactComponent as services_bb } from '../assets/icons/services_bb.svg';
import { ReactComponent as servicez } from '../assets/icons/servicez.svg';
import { ReactComponent as setting } from '../assets/icons/setting.svg';
import { ReactComponent as setting_b } from '../assets/icons/setting_b.svg';
import { ReactComponent as shake_hands } from '../assets/icons/shake_hands.svg';
import { ReactComponent as signup } from '../assets/icons/signup.svg';
import { ReactComponent as signup_banner } from '../assets/icons/signup_banner.svg';
import { ReactComponent as small_arrow } from '../assets/icons/small_arrow.svg';
import { ReactComponent as soon } from '../assets/icons/soon.svg';
import { ReactComponent as star } from '../assets/icons/star.svg';
import { ReactComponent as stop } from '../assets/icons/stop.svg';
import { ReactComponent as subject } from '../assets/icons/subject.svg';
import { ReactComponent as subtract } from '../assets/icons/subtract.svg';
import { ReactComponent as tag } from '../assets/icons/tag.svg';
import { ReactComponent as terms } from '../assets/icons/terms.svg';
import { ReactComponent as threads_line } from '../assets/icons/threads_line.svg';
import { ReactComponent as threedots_on } from '../assets/icons/threedots_on.svg';
import { ReactComponent as tiktok } from '../assets/icons/tiktok.svg';
import { ReactComponent as tiktok_line } from '../assets/icons/tiktok_line.svg';
import { ReactComponent as timer } from '../assets/icons/timer.svg';
import { ReactComponent as tool } from '../assets/icons/tool.svg';
import { ReactComponent as trash } from '../assets/icons/trash.svg';
import { ReactComponent as treedots_off } from '../assets/icons/treedots_off.svg';
import { ReactComponent as treedots_on } from '../assets/icons/treedots_on.svg';
import { ReactComponent as treeline } from '../assets/icons/treeline.svg';
import { ReactComponent as twetter_line } from '../assets/icons/twetter_line.svg';
import { ReactComponent as twitter } from '../assets/icons/twitter.svg';
import { ReactComponent as twitter_line } from '../assets/icons/twitter_line.svg';
import { ReactComponent as unarchive } from '../assets/icons/unarchive.svg';
import { ReactComponent as upload } from '../assets/icons/upload.svg';
import { ReactComponent as upload2 } from '../assets/icons/upload2.svg';
import { ReactComponent as upload_1 } from '../assets/icons/upload_1.svg';
import { ReactComponent as user } from '../assets/icons/user.svg';
import { ReactComponent as user_1 } from '../assets/icons/user_1.svg';
import { ReactComponent as user_2 } from '../assets/icons/user_2.svg';
import { ReactComponent as user_bb } from '../assets/icons/user_bb.svg';
import { ReactComponent as user_circle } from '../assets/icons/user_circle.svg';
import { ReactComponent as username } from '../assets/icons/username.svg';
import { ReactComponent as users } from '../assets/icons/users.svg';
import { ReactComponent as users2 } from '../assets/icons/users2.svg';
import { ReactComponent as users_b } from '../assets/icons/users_b.svg';
import { ReactComponent as uud_spo } from '../assets/icons/uud_spo.svg';
import { ReactComponent as uud } from '../assets/icons/uud.svg';
import { ReactComponent as uud1 } from '../assets/icons/uud1.svg';
import { ReactComponent as uud1_ } from '../assets/icons/uud1_.svg';
import { ReactComponent as uud1_ani } from '../assets/icons/uud1_ani.svg';
import { ReactComponent as uud3 } from '../assets/icons/uud3.svg';
import { ReactComponent as uud_dh } from '../assets/icons/uud_dh.svg';
import { ReactComponent as uud_icon } from '../assets/icons/uud_icon.svg';
import { ReactComponent as uud_icon2 } from '../assets/icons/uud_icon2.svg';
import { ReactComponent as uud_line } from '../assets/icons/uud_line.svg';
import { ReactComponent as uxer_2_on } from '../assets/icons/uxer_2_on.svg';
import { ReactComponent as uxer_simple } from '../assets/icons/uxer_simple.svg';
import { ReactComponent as uxers } from '../assets/icons/uxers.svg';
import { ReactComponent as view } from '../assets/icons/view.svg';
import { ReactComponent as view_link } from '../assets/icons/view_link.svg';
import { ReactComponent as web_line } from '../assets/icons/web_line.svg';
import { ReactComponent as windows } from '../assets/icons/windows.svg';
import { ReactComponent as windows_fill } from '../assets/icons/windows_fill.svg';
import { ReactComponent as xjobs } from '../assets/icons/xjobs.svg';
import { ReactComponent as youtube } from '../assets/icons/youtube.svg';
import { ReactComponent as youtube_line } from '../assets/icons/youtube_line.svg';

const iconComponents = {

  'about': about,
  'about_b': about_b,
  'about_big': about_big,
  'account': account,
  'add': add,
  'add_1': add_1,
  'add_project': add_project,
  'all': all,
  'analytics': analytics,
  'analytics_b': analytics_b,
  'analytics_bb': analytics_bb,
  'android': android,
  'android_fill': android_fill,
  'anime': anime,
  'apple': apple,
  'apple_fill': apple_fill,
  'apps': apps,
  'archive': archive,
  'arrow': arrow,
  'attach': attach,
  'b_logo': b_logo,
  'back': back,
  'bag': bag,
  'banner_bg': banner_bg,
  'behance': behance,
  'behance_line': behance_line,
  'beta': beta,
  'blocked': blocked,
  'blog': blog,
  'blog_b': blog_b,
  'blog_line': blog_line,
  'book': book,
  'broken_link': broken_link,
  'browser': browser,
  'cal': cal,
  'cal2': cal2,
  'camera': camera,
  'caution': caution,
  'chat': chat,
  'check': check,
  'clear': clear,
  'close': close,
  'close_s': close_s,
  'company': company,
  'danger': danger,
  'default_item': default_item,
  'default_item_': default_item_,
  'default_profile': default_profile,
  'delete_account': delete_account,
  'delete_icn': delete_icn,
  'delete_icon2': delete_icon2,
  'designers_home': designers_home,
  'download': download,
  'dribbble': dribbble,
  'dribbble_line': dribbble_line,
  'drop_down': drop_down,
  'drop_up': drop_up,
  'edit': edit,
  'en': en,
  'error404': error404,
  'error404_02': error404_02,
  'error500': error500,
  'error500_02': error500_02,
  'event': event,
  'experience': experience,
  'eye_off': eye_off,
  'eye_on': eye_on,
  'facebook': facebook,
  'facebook_': facebook_,
  'facebook_line': facebook_line,
  'fav': fav,
  'fav_f': fav_f,
  'fav_line': fav_line,
  'figma': figma,
  'figma_line': figma_line,
  'flag': flag,
  'free': free,
  'freemium': freemium,
  'full_name': full_name,
  'future': future,
  'globe': globe,
  'gmail': gmail,
  'hafid': hafid,
  'hand1': hand1,
  'hand2': hand2,
  'hand3': hand3,
  'hand4': hand4,
  'heart': heart,
  'home': home,
  'home_logo': home_logo,
  'hum': hum,
  'hum2': hum2,
  'human': human,
  'id': id,
  'id1': id1,
  'id_icon': id_icon,
  'idea': idea,
  'illus_soon': illus_soon,
  'illus_soon2': illus_soon2,
  'illus_soon_copy': illus_soon_copy,
  'illust_01': illust_01,
  'illustra_0': illustra_0,
  'illustra_00': illustra_00,
  'illustra_01': illustra_01,
  'illustra_02': illustra_02,
  'illustra_03': illustra_03,
  'illustra_04': illustra_04,
  'illustra_05': illustra_05,
  'illustra_06': illustra_06,
  'illustra_07': illustra_07,
  'illustra_08': illustra_08,
  'image': image,
  'instagram': instagram,
  'instagram_line': instagram_line,
  'interv': interv,
  'invitation': invitation,
  'items': items,
  'items_b': items_b,
  'job': job,
  'jobs': jobs,
  'juusando_spo': juusando_spo,
  'kome_mark': kome_mark,
  'language': language,
  'left_arrow': left_arrow,
  'link_ext': link_ext,
  'linkdin': linkdin,
  'linkedin': linkedin,
  'linkedin_line': linkedin_line,
  'linux': linux,
  'load': load,
  'loading': loading,
  'loading_ani': loading_ani,
  'loading_card': loading_card,
  'loading_card_new': loading_card_new,
  'lock': lock,
  'login': login,
  'logo': logo,
  'logo_05': logo_05,
  'logo_06': logo_06,
  'logo_07': logo_07,
  'logo_full': logo_full,
  'logo_full1': logo_full1,
  'logo_placeholder': logo_placeholder,
  'logo_type': logo_type,
  'logo_type_en': logo_type_en,
  'logo_type_jp': logo_type_jp,
  'logout': logout,
  'logout_b': logout_b,
  'mac': mac,
  'mail': mail,
  'mailus': mailus,
  'mail2_1': mail2_1,
  'mail_fill': mail_fill,
  'mails': mails,
  'map': map,
  'meetup': meetup,
  'message_back': message_back,
  'messenger': messenger,
  'mic': mic,
  'mob_hum': mob_hum,
  'money': money,
  'mute_off': mute_off,
  'mute_on': mute_on,
  'name_card': name_card,
  'namecard': namecard,
  'new_mail': new_mail,
  'new_password': new_password,
  'news': news,
  'news_1': news_1,
  'newsletter': newsletter,
  'newz': newz,
  'no_favs': no_favs,
  'no_favs2': no_favs2,
  'no_item_found': no_item_found,
  'no_logo': no_logo,
  'noresult': noresult,
  'norif_off': norif_off,
  'norif_on': norif_on,
  'ok': ok,
  'order_az': order_az,
  'order_za': order_za,
  'paid': paid,
  'password': password,
  'past': past,
  'pause': pause,
  'peatix': peatix,
  'phone': phone,
  'pinjob_spo': pinjob_spo,
  'popup': popup,
  'post': post,
  'press': press,
  'press_kit': press_kit,
  'privacy': privacy,
  'privacy_s': privacy_s,
  'project': project,
  'project_s': project_s,
  'pull_down': pull_down,
  'pull_up': pull_up,
  'register': register,
  'report': report,
  'request': request,
  'reset': reset,
  'resos': resos,
  'restart': restart,
  'right_arrow': right_arrow,
  'robot': robot,
  'save': save,
  'screen': screen,
  'scroll_anima': scroll_anima,
  'search': search,
  'send': send,
  'services': services,
  'services_1': services_1,
  'services_bb': services_bb,
  'servicez': servicez,
  'setting': setting,
  'setting_b': setting_b,
  'shake_hands': shake_hands,
  'signup': signup,
  'signup_banner': signup_banner,
  'small_arrow': small_arrow,
  'soon': soon,
  'star': star,
  'stop': stop,
  'subject': subject,
  'subtract': subtract,
  'tag': tag,
  'terms': terms,
  'threads_line': threads_line,
  'threedots_on': threedots_on,
  'tiktok': tiktok,
  'tiktok_line': tiktok_line,
  'timer': timer,
  'tool': tool,
  'trash': trash,
  'treedots_off': treedots_off,
  'treedots_on': treedots_on,
  'treeline': treeline,
  'twetter_line': twetter_line,
  'twitter': twitter,
  'twitter_line': twitter_line,
  'unarchive': unarchive,
  'upload': upload,
  'upload2': upload2,
  'upload_1': upload_1,
  'user': user,
  'user_1': user_1,
  'user_2': user_2,
  'user_bb': user_bb,
  'user_circle': user_circle,
  'username': username,
  'users': users,
  'users2': users2,
  'users_b': users_b,
  'uud_spo': uud_spo,
  'uud': uud,
  'uud1': uud1,
  'uud1_': uud1_,
  'uud1_ani': uud1_ani,
  'uud3': uud3,
  'uud_dh': uud_dh,
  'uud_icon': uud_icon,
  'uud_icon2': uud_icon2,
  'uud_line': uud_line,
  'uxer_2_on': uxer_2_on,
  'uxer_simple': uxer_simple,
  'uxers': uxers,
  'view': view,
  'view_link': view_link,
  'web_line': web_line,
  'windows': windows,
  'windows_fill': windows_fill,
  'xjobs': xjobs,
  'youtube': youtube,
  'youtube_line': youtube_line,
};

const CustomIcon = ({ iconName, ...props }) => {
  const SelectedIcon = iconComponents[iconName];
  if (!SelectedIcon) {
    console.warn(`Icon "${iconName}" not found`);
    return null; // Or a default icon
  }

  return <SelectedIcon {...props} />;
};

export default CustomIcon;
