import React from "react";
import Button from "../atoms/Button";
import { useDispatch, useSelector } from "react-redux";

export function UxersTermsCard({ onClick, showButton = false }) {
  return (
    <div className="uxer_terms_card">
      <span className="uxer_terms_title">UUD Terms of use</span>
      <p className="uxer_terms_content">
        Terms of Use for UX/UI Design Community
        <br />
        Welcome to UUD!
        <br /> By using our platform, you agree to the following terms and{" "}
      </p>
      <p className="uxer_terms_content">
        <span className="uxer_terms_content_primary">Conditions:</span>
        <br />
        Welcome to our curated web design inspiration catalog! Please read these
        terms and conditions carefully before using our website.
        <br />
        <br />
        <ol>
          <li>
            <span className="uxer_terms_content_primary">
              Acceptance of Terms and Conditions.
            </span>{" "}
            By accessing and using our website, you agree to be bound by these
            terms and conditions. If you do not agree with any of these terms,
            you are prohibited from using or accessing this site.
          </li>
          <li>
            <span className="uxer_terms_content_primary">
              Use of Our Website.
            </span>{" "}
            You may use our website for personal, non-commercial purposes only.
            You may not use any content from our website for any commercial
            purpose without our prior written consent.
          </li>
          <li>
            <span className="uxer_terms_content_primary">
              Intellectual Property Rights:
            </span>
            All content on our website, including but not limited to images,
            text, and screenshots, is our property or the property of our
            licensors. You may not copy, reproduce, distribute, or create
            derivative works based on our content without our or their prior
            written consent.
          </li>
          <li>
            <span className="uxer_terms_content_primary">User Accounts.</span>{" "}
            In order to use certain features of our website, such as saving
            websites to your favorites and suggesting new websites, you may need
            to create an account. You are responsible for maintaining the
            confidentiality of your account information and for all activities
            that occur under your account.
          </li>
          <li>
            <span className="uxer_terms_content_primary">
              User-Generated Content.
            </span>{" "}
            We may allow users to submit comments and suggestions on our
            website. By submitting any content to us, you grant us a
            non-exclusive, worldwide, perpetual, irrevocable, royalty-free,
            sublicensable right to use, reproduce, modify, adapt, publish,
            translate, create derivative works from, distribute, and display
            such content in any media.
          </li>
          <li>
            <span className="uxer_terms_content_primary">
              Disclaimer of Warranties.
            </span>{" "}
            We do not warrant that our website will be error-free or
            uninterrupted, or that any defects will be corrected. We make no
            representations or warranties about the accuracy, reliability,
            completeness, or timeliness of the content on our website.
          </li>
          <li>
            <span className="uxer_terms_content_primary">
              Limitation of Liability.
            </span>{" "}
            In no event shall we be liable for any damages arising out of the
            use or inability to use our website, including but not limited to
            damages for loss of data, loss of profits, or any other monetary
            loss.
          </li>
          <li>
            <span className="uxer_terms_content_primary">Governing Law.</span>{" "}
            These terms and conditions shall be governed by and construed in
            accordance with the laws of Armenia, without regard to its conflicts
            of law provisions.
          </li>
        </ol>
      </p>
      {showButton && <Button onClick={onClick}>Next</Button>}
    </div>
  );
}

export function SignupTermsCard({ onClick, showButton = false }) {
  const lang = useSelector((state) => state.lang);

  return lang == "en" ? (
    <div className="uxer_terms_card">
      <span className="uxer_terms_title">UUD Terms of use</span>
      <p className="uxer_terms_content">
        Welcome to UXUI Designers (UUD), your go-to resource platform for UX/UI
        designers. By accessing or using our services, you agree to comply with
        and be bound by the following terms and conditions. Please read these
        Terms of Use carefully before using our platform.
        <br />
        <br />
        <span>1. Acceptance of Terms</span>
        <br />
        <p>
          By accessing or using UUD, you agree to these Terms of Use and our
          Privacy Policy. If you do not agree with these terms, please do not
          use our services.
        </p>
        <br />
        <span>2. User Responsibilities</span>
        <br />
        <p>
          You must not engage in any of the following activities while using
          UUD:{" "}
        </p>
        <ul>
          <li>
            <span>Unauthorized Access:</span> You must not attempt to gain
            unauthorized access to any part of UUD, other user accounts, or
            computer systems or networks connected to UUD through hacking,
            password mining, or any other means.
          </li>
          <li>
            <span>Malicious Activities:</span> You must not engage in any
            activity that disrupts, interferes with, or harms the integrity or
            performance of UUD or its users' experience, including but not
            limited to distributing malware, spamming, or engaging in
            denial-of-service attacks.
          </li>
          <li>
            <span>Content Violations:</span> You must not upload, post, or
            transmit any content that is unlawful, harmful, threatening,
            abusive, defamatory, obscene, or otherwise objectionable.
          </li>
          <li>
            <span>Impersonation:</span> You must not impersonate any person or
            entity, or falsely state or otherwise misrepresent your affiliation
            with a person or entity.
          </li>
        </ul>
        <br />
        <span>3. Data Removal and Deletion</span>
        <br />
        <p>
          UUD reserves the right to remove, delete, or modify any data, content,
          or information on the platform without prior notice, at our sole
          discretion. This includes, but is not limited to, user accounts,
          posts, and uploaded files.
        </p>
        <br />
        <span>4. User Relations and Information Accuracy</span>
        <br />
        <p>
          UUD is not responsible for the relationships established between users
          or the accuracy, reliability, or validity of the information provided
          by users. Users are solely responsible for verifying the authenticity
          of the information exchanged with other users on UUD.
        </p>
        <br />
        <span>5. Disclaimer of Warranties</span>
        <br />
        <p>
          UUD provides its services on an "as-is" and "as-available" basis
          without any warranty or condition, express, implied, or statutory. We
          do not warrant that the services will be uninterrupted, timely,
          secure, or error-free.
        </p>
        <br />
        <span>6. Limitation of Liability</span>
        <br />
        <p>
          In no event shall UUD be liable for any indirect, incidental, special,
          consequential, or punitive damages, or any loss of profits or
          revenues, whether incurred directly or indirectly, or any loss of
          data, use, goodwill, or other intangible losses, resulting from your
          use or inability to use our services.
        </p>
        <br />
        <span>7. Changes to Terms of Use</span>
        <br />
        <p>
          We reserve the right to modify these Terms of Use at any time. We will
          notify you of any changes by posting the updated terms on UUD. Your
          continued use of our services after the effective date of such changes
          constitutes your acceptance of the modified terms.
        </p>
        <br />
        <span>8. Contact Information</span>
        <br />
        <p>
          If you have any questions about these Terms of Use, please contact us
          at: [policy@uud.io]
          <br />
          <br />
          Thank you for using UUD (UXUI Designers). We hope you find our
          services valuable and enjoy your experience on our platform.
        </p>
      </p>
      {showButton && <Button onClick={onClick}>Next</Button>}
    </div>
  ) : (
    <div className="uxer_terms_card">
      <span className="uxer_terms_title">UUD Terms of use</span>
      <>
                          {/* ---------------------- JAPANESE */}
                          <p
                            className="paragraph contact-text"
                            data-lang={lang}
                          >
                            <span>ようこそ、UXUIデザイナーズ（UUD）へ</span>
                            <br />
                            UX/UIデザイナーのためのリソースプラットフォーム、UXUIデザイナーズ（UUD）へようこそ。当サービスをご利用いただく際には、以下の利用規約に同意いただく必要があります。ご利用の前に、これらの利用規約をよくお読みください。
                          </p>
                          <br />

                          <span>1. 利用規約の受諾</span>
                          <br />
                          <p
                            className="paragraph contact-text"
                            data-lang={lang}
                          >
                            UUDにアクセスするか、またはUUDのサービスを利用することで、これらの利用規約とプライバシーポリシーに同意したものとみなされます。これらの条件に同意いただけない場合は、当サービスをご利用いただけません。
                          </p>
                          <br />

                          <span>2. ユーザーの責任</span>
                          <br />
                          <p
                            className="paragraph contact-text"
                            data-lang={lang}
                          >
                            UUDを利用する際、以下の活動には一切関与しないでください：
                          </p>
                          <p
                            className="paragraph contact-text"
                            data-lang={lang}
                          >
                            <ul>
                              <li>
                                <span>未許可のアクセス：</span>{" "}
                                UUDの一部、他のユーザーアカウント、またはUUDに接続されたコンピューターシステムやネットワークへの未許可のアクセスを試みてはいけません。これには、ハッキング、パスワードの採掘、その他の手段による試みが含まれます。
                              </li>
                              <li>
                                <span>悪意のある活動：</span>{" "}
                                UUDの整合性やパフォーマンスを妨げ、妨害し、または害する活動には参加してはいけません。これには、マルウェアの配布、スパムの送信、およびサービスの妨害を目的とした攻撃などが含まれます。
                              </li>
                              <li>
                                <span>コンテンツの違反：</span>{" "}
                                違法、有害、脅迫的、虐待的、中傷的、わいせつ、または他に反感を抱かせるようなコンテンツをアップロード、投稿、または送信してはいけません。
                              </li>
                              <li>
                                <span>なりすまし：</span>{" "}
                                他の人物や団体をなりすますことはできません。また、自分が特定の人物や団体と関係があると虚偽の陳述をしたり、他の方法で自身の所属関係を誤って表示してはいけません。
                              </li>
                            </ul>
                          </p>
                          <br />

                          <span>3. データの削除と削除</span>
                          <br />
                          <p
                            className="paragraph contact-text"
                            data-lang={lang}
                          >
                            UUDは、予告なく、独自の裁量でプラットフォーム上のデータ、コンテンツ、または情報を削除、削除、または変更する権利を留保します。これには、ユーザーアカウント、投稿、およびアップロードされたファイルが含まれます。
                          </p>
                          <br />

                          <span>4. ユーザー関係と情報の正確性</span>
                          <br />
                          <p
                            className="paragraph contact-text"
                            data-lang={lang}
                          >
                            UUDは、ユーザー間で確立された関係や、ユーザーが提供する情報の正確性、信頼性、または妥当性について責任を負いません。ユーザーは、UUD上で他のユーザーと交換する情報の真正性を確認する責任を負います。
                          </p>
                          <br />

                          <span>5. 保証の免責事項</span>
                          <br />
                          <p
                            className="paragraph contact-text"
                            data-lang={lang}
                          >
                            UUDは、明示、黙示、または法的な保証または条件なしで、サービスを提供します。サービスが中断されないこと、タイムリーであること、安全であること、またはエラーがないことを保証しません。
                          </p>
                          <br />

                          <span>6. 責任の制限</span>
                          <br />
                          <p
                            className="paragraph contact-text"
                            data-lang={lang}
                          >
                            UUDは、あなたのサービスの利用または利用不能に起因する、間接的な損害、付随的な損害、特別な損害、結果的な損害、罰則的な損害、または利益や収益の損失、直接的または間接的に生じた場合でも、一切の責任を負いません。また、データ、利用、善意、または他の無形の損失の喪失も含まれます。
                          </p>
                          <br />

                          <span>7. 利用規約の変更</span>
                          <br />
                          <p
                            className="paragraph contact-text"
                            data-lang={lang}
                          >
                            当社は、これらの利用規約をいつでも変更する権利を留保します。変更がある場合は、更新された条件をUUDに掲示することでお知らせいたします。変更後も当サービスをご利用いただくことは、変更後の利用規約に同意したものとみなされます。
                          </p>
                          <br />

                          <span>8. 連絡先情報</span>
                          <br />
                          <p
                            className="paragraph contact-text"
                            data-lang={lang}
                          >
                            これらの利用規約に関するご質問がある場合は、以下の連絡先までお問い合わせください：[policy@uud.io]
                            <br />
                            <br />
                            UUD（UXUIデザイナーズ）のご利用ありがとうございます。当プラットフォームでのご利用が有益で、お楽しみいただけることを願っています。
                          </p>
                          {/* -------------------------------- JAPANESE  END*/}
                        </>


      {showButton && <Button onClick={onClick}>Next</Button>}
    </div>
  );
}
