import { NewsLetterPopup } from "../../components/molecules/NewsLetterPopup";
import "../../styles/events.scss";
import ContactEvent from "../ContactEvent";
import EventContent from "./EventContent";
import HeaderCompo from "./HeaderEvent";
import InfoEvent from "./InfoEvent";
import JoinForm from "./JoinForm";

function EventTemp() {
  return (
    <>
      <HeaderCompo />

      <div className="event_hero contact_hero">
        <EventContent
          title="CONTACT US"
          text="
              コミュニティ活動に参加したい方、またはイベントやワークショップを開催したい方は、以下のフォームからお気軽にご連絡ください。
              フィードバックやコラボレーションの提案も大歓迎です。皆様と共に、デザインの未来を築いていけることを楽しみにしています。
                "
        >
        <ContactEvent />
        </EventContent>
      </div>
    </>
  );
}

export default EventTemp;
