import { useState } from "react";
import { ReactComponent as Namecard } from "../.././../assets/icons/namecard.svg";
import { ReactComponent as MailIcon } from "../.././../assets/icons/mail.svg";
import { ReactComponent as PasswordIcon } from "../../../assets/icons/password.svg";
import { ReactComponent as LoginIcon } from "../../../assets/icons/login.svg";
import { ReactComponent as EyeOnIcon } from "../../../assets/icons/eye_on.svg";
import { ReactComponent as EyeOffIcon } from "../../../assets/icons/eye_off.svg";
import Input from "../../atoms/Input";
import Button from "../../atoms/Button";
import { useTranslation } from "react-i18next";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

function AdminLoginCard({ className, onLogin, error = false }) {
  const [login, setLogin] = useState("");
  const [password, setpassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [t, i18n] = useTranslation();
  const lang = useSelector((state) => state.lang);

  return (
    <div className={`login-card ${className}`}>
      <div className="login-card-form">
        <Input
          placeholder={
            lang === "en" ? "Email / Username" : "Eメール / ユーザーネーム"
          }
          error={error}
          value={login}
          onChange={(e) => {
            setLogin(e.target.value);
          }}
          leftIcon={<MailIcon />}
        />
        <Input
          placeholder={lang === "en" ? "Password" : "パスワード"}
          error={error}
          type={showPassword ? "text" : "password"}
          value={password}
          onChange={(e) => {
            setpassword(e.target.value);
          }}
          leftIcon={<PasswordIcon />}
          rightIcon={
            showPassword ? (
              <EyeOnIcon className="eyeIcon" />
            ) : (
              <EyeOffIcon className="eyeIcon" />
            )
          }
          onRightIconClick={() => setShowPassword(!showPassword)}
        />
      </div>
      {/* <span className="link-to" onClick={onForgetPassword} data-lang={lang}>
        {lang === "en" ? "Forgot Password?" : "パスワードを忘れた方"}
      </span> */}
      <Button
        rightIcon={<LoginIcon />}
        onClick={() => onLogin(login, password)}
        data-lang={lang}
      >
        {lang === "en" ? "Log in" : "ログイン"}
      </Button>

      {/* <div className="new-account">
        <span className="outfit" data-lang={lang}>
          {lang === "en" ? "Already have an account? " : "アカウントをお持ち方"}
        </span>
        <Button
          rightIcon={<Namecard />}
          onClick={() => (window.location = "/signup")}
          className="signup_button"
          data-lang={lang}
        >
          {lang === "en" ? "Sign up" : "サインアップ"}
        </Button>
      </div> */}
    </div>
  );
}

export default AdminLoginCard;
