import { ReactComponent as UserIcon } from "../../assets/icons/user_circle.svg";
import { ReactComponent as MailIcon } from "../../assets/icons/mail.svg";
import { ReactComponent as Mail2Icon } from "../../assets/icons/mail.svg";
import { ReactComponent as SettingIcon } from "../../assets/icons/setting.svg";
import { ReactComponent as UUDIcon } from "../../assets/icons/uud1.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import { ReactComponent as Logout } from "../../assets/icons/logout.svg";
import { ReactComponent as Press } from "../../assets/icons/press_kit.svg";
import { ReactComponent as Privacy } from "../../assets/icons/privacy_s.svg";
import { ReactComponent as News } from "../../assets/icons/news.svg";
import { ReactComponent as Servicez } from "../../assets/icons/services.svg";
import { ReactComponent as LoginIcon } from "../../assets/icons/login.svg";
import { ReactComponent as Add } from "../../assets/icons/add.svg";
import { ReactComponent as SignupIcon } from "../../assets/icons/signup.svg";
import { ReactComponent as ComingSoonIcon } from "../../assets/icons/uud3.svg";
import { ReactComponent as Newsletter } from "../../assets/icons/newsletter.svg";
import { getUserInfo } from "../../services/authServices";

import CustomIcon from "../../compo/IconCompo";

import { useDispatch, useSelector } from "react-redux";
import { setSelectMenu } from "../../redux/actions/env";
import RoundImage from "../atoms/RoundImage";
import LangugeSwitch from "../atoms/LangugeSwitch";
import ExtraIcon from "../atoms/ExtraIcon";
import {
  setOpenLoginPopup,
  setOpenSignupPopup,
} from "../../redux/actions/user";
import jwtDecode from "jwt-decode";
import React, { useEffect, useState } from "react";
import SoonPopup from "../organismes/popup/SoonPopup";
import { useTranslation } from "react-i18next";
import { setSelectLang } from "../../redux/actions/env";
import { fetchImage } from "../../services/itemsServices";
import "../../styles/main.scss";
import LargePopupContainer from "../molecules/LargePopupContainer";
import { NewsLetterPopup } from "../molecules/NewsLetterPopup";
import Button from "../atoms/Button";
import pirsch from "../../Analytics";

function RightBarLayout({
  showBarMobile,
  setShowBarMobile,
  isLogin,
  currentPage,
  messageNo = false,
  messageNom = false,
}) {
  const dispatch = useDispatch();
  const [isSoonPopup, setIsSoonPopup] = useState(false);
  const user = useSelector((state) => state.user);
  const [userInfo, setUserInfo] = useState("");
  const lang = useSelector((state) => state.lang);
  const [t, i18n] = useTranslation();
  const [image, setImage] = useState("");
  const [openTerms, setOpenTerms] = useState(false);

  useEffect(() => {
    if (user) {
      getUserInfo(jwtDecode(user).id).then((res) => {
        if (res.data) {
          if (res.data.myUser) {
            res.data = res.data.myUser;
          }

          setUserInfo(res.data);
          /*  fetchImage("profile", res.data.profile_img)
            .then((result) => {
              if (result != undefined) {
                setImage(result);
              }
            })
            .catch((err) => {
              setImage("");
            });*/
        }
      });
    }
  }, [user]);

  const handleClickProfile = () => {
    if (currentPage == "signup") {
      window.location = "/";
    }
    if (!isLogin) {
      dispatch(setOpenLoginPopup(true));
      setShowBarMobile(false);
    } else {
      window.location = `/${jwtDecode(user).username}`;
    }
  };
  const changeMenu = (menu) => {
    window.location = "/chat";
  };
  useEffect(() => {}, [userInfo]);

  return (
    <>
      <div className={`right-bar ${showBarMobile ? "show-mobile" : ""}`}>
        <RoundImage
          className="layout-profile-image"
          onClick={handleClickProfile}
          imageFull={true}
          imageData={userInfo ? userInfo.profile_img : null}
        />

        <div className="right-bar-main-navigation">
          {!isLogin && (
            <div className="access_box">
              <div className="tooltip access">
                <ExtraIcon
                  data-lang={lang}
                  title="Log in"
                  onClick={() => {
                    if (currentPage == "signup") {
                      window.location = "/home";
                    }
                    dispatch(setOpenLoginPopup(true));
                    pirsch("RM-Login");
                  }}
                ></ExtraIcon>

                <span className="tooltiptext" data-lang={lang}>
                  {lang === "en" ? "Welcome Back!" : "おかえりなさい!"}
                </span>
              </div>

              {currentPage == "signup" ? null : (
                <div className="tooltip access lined">
                  {/* <ExtraIcon
                    data-lang={lang}
                    title="Sign up"
                    onClick={() => {
                      dispatch(setOpenSignupPopup(true))
                      window.location = "/";
                      pirsch("RM-Signup");
                    }}
                  >
                  </ExtraIcon> */}

                  <ExtraIcon
                    data-lang={lang}
                    title="Sign up"
                    onClick={() => {
                      dispatch(setOpenSignupPopup(true));
                      pirsch("Index - ABOUT");
                    }}

                  ></ExtraIcon>

                  <span className="tooltiptext" data-lang={lang}>
                    {lang === "en" ? "Joint us!" : "いらっしゃいませ!"}
                  </span>
                </div>
              )}
            </div>
          )}

          {isLogin && (
            <>
              <div className="tooltip">
                <ExtraIcon>
                  <UserIcon onClick={handleClickProfile} />
                </ExtraIcon>
                <span className="tooltiptext">My Page</span>
              </div>

              {/* <ExtraIcon>
                <div className="flex relative items-center">
                  <MailIcon
                    onClick={() => {
                      changeMenu("chat");
                      pirsch("RM-Message");
                    }}
                    style={
                      currentPage == "chat"
                        ? { color: "var(--primary-color)" }
                        : null
                    }
                  />
                  {messageNo > 0 || messageNom > 0 ? (
                    <div className="icon-number">
                      {currentPage == "chat" && messageNom != ""
                        ? messageNom
                        : messageNo}
                    </div>
                  ) : null}
                </div>
              </ExtraIcon> */}

              <div className="tooltip">
                <ExtraIcon
                  onClick={() => {
                    window.location = "/settings";
                    pirsch("RM-Setting");
                  }}
                >
                  <SettingIcon />
                </ExtraIcon>
                <span className="tooltiptext">Setting</span>
              </div>

              {/* {isLogin ? (
                <ExtraIcon
                  onClick={() => {
                    localStorage.removeItem("token");
                    localStorage.removeItem("persist:persist-key");
                    window.location = "/";
                    pirsch("RM-Logout");
                  }}
                >
                  <Logout />
                </ExtraIcon>
              ) : null} */}
            </>
          )}
        </div>

        {/* ----------------------------------- FOOTER------------------------------------ */}

        <div className="right-bar-bottom-part">
          <div className="right-bar-second-navigation">
            <div className="tooltip">
              <ExtraIcon
                data-lang={lang}
                title={lang === "en" ? "About" : "UUDとは"}
                onClick={() => {
                  window.location = "/aboutus";
                  pirsch("RM-About");
                }}
              >
                <UUDIcon />
              </ExtraIcon>
              <span className="tooltiptext" data-lang={lang}>
                {lang === "en" ? "About UUD" : "UUDとは"}
              </span>
            </div>

            {/* <div className="tooltip">
              <ExtraIcon
                data-lang={lang}
                title={lang === "en" ? "Blog" : "ブログ"}
                onClick={() => {
                  window.location = "/blog";
                  pirsch("RM-Blog");
                }}
              >
                <CustomIcon iconName="blog_b" className="rightbar_icon" />
              </ExtraIcon>
              <span className="tooltiptext" data-lang={lang}>
                {lang === "en" ? "Blog" : "ブログ"}
              </span>
            </div> */}

            <div className="tooltip">
              <ExtraIcon
                data-lang={lang}
                title={lang === "en" ? "Services" : "サービス"}
                onClick={() => {
                  window.location = "/servicez";
                  pirsch("RM-Services");
                }}
              >
                <Servicez />
              </ExtraIcon>
              <span className="tooltiptext" data-lang={lang}>
                {lang === "en" ? "UUD Services" : "サービス"}
              </span>
            </div>

            {/* <div className="tooltip">
              <ExtraIcon
                onClick={() => {
                  window.location = "/news";
                  pirsch("RM-News");
                }}
              >
                <News />
              </ExtraIcon>
              <span className="tooltiptext">UUD News</span>
            </div> */}

            {/* <div className="tooltip">
              <ExtraIcon
                title="Press Kit"
                onClick={() => {
                  window.location = "/press";
                  pirsch("RM-Press");
                }}
              >
                <Press />
              </ExtraIcon>
              <span className="tooltiptext">Press Kit</span>
            </div> */}

            <div className="tooltip">
              <ExtraIcon
                data-lang={lang}
                title={lang === "en" ? "Policy" : "ポリシー"}
                onClick={() => {
                  window.location = "/privacy";
                  pirsch("RM-Privacy");
                }}
              >
                <Privacy />
              </ExtraIcon>
              <span className="tooltiptext" data-lang={lang}>
                {lang === "en" ? "Policy" : "ポリシー"}
              </span>
            </div>

            <div className="tooltip">
              <ExtraIcon
                data-lang={lang}
                title={lang === "en" ? "Contact" : "お問合せ"}
                onClick={() => {
                  window.location = "/contact";
                  pirsch("RM-Contact");
                }}
              >
                <Mail2Icon />
              </ExtraIcon>
              <span className="tooltiptext" data-lang={lang}>
                {lang === "en" ? "Contact" : "お問い合わせ"}
              </span>
            </div>

            {/* <div className="tooltip">
              <ExtraIcon
                onClick={() => {
                  window.location = "/uxersoon";
                  pirsch("UXER-Soon");
                }}
              >
                <CustomIcon iconName="uxer_2_on" />
              </ExtraIcon>
              <span className="tooltiptext">
                {
                  <span>
                    UXERS
                    <br />
                    Coming Soon
                  </span>
                }
              </span>
            </div> */}

            {/* ------------- News Letter ------------------------------------------- */}

            {openTerms && (
              <LargePopupContainer onClose={() => setOpenTerms(false)}>
                <NewsLetterPopup
                  onClick={() => {
                    setOpenTerms(false);
                  }}
                  type="signup"
                />
              </LargePopupContainer>
            )}

            {/* ----------------------------------------------------- */}
          </div>

          {/* <div className="right-bar-copywrite">
            <span>©UUD</span>
            <span >2023</span>
          </div>
        </div> */}
          <div className="right-bar-copywrite">
            <span
              // onClick={() => {
              //   window.location = "/signup";
              //   pirsch("RM-Signup");
              // }}
              // onClick={() => dispatch(setOpenSignupPopup(true))}
            >
              ©UUD
            </span>

            <span
              // onClick={() => {
              //   dispatch(setOpenLoginPopup(true));
              //   pirsch("RM-Login");
              // }}
            >
              2024
            </span>
          </div>
        </div>

        {/* ------------- FOOTER THE END ------------------------------------------- */}
      </div>

      {showBarMobile && (
        <div className="right-bar-mobile-container">
          <div className="close-btn">
            <CloseIcon
              onClick={() => {
                setShowBarMobile(false);
                pirsch("RM-Mob-MenuCloseBtn");
              }}
              className="close-button"
            />
          </div>
          <div>
            <LangugeSwitch
              language={i18n.language}
              onClick={() => {
                localStorage.setItem(
                  "language",
                  i18n.language === "en" ? "jp" : "en"
                );
                i18n.changeLanguage(i18n.language === "en" ? "jp" : "en");

                if (lang === "en") {
                  dispatch(setSelectLang("jp"));
                } else if (lang === "jp") {
                  dispatch(setSelectLang("en"));
                }
              }}
              color="var(--primary-color)"
            />
          </div>
        </div>
      )}
      {isSoonPopup && (
        <SoonPopup setIsSoonPopup={(res) => setIsSoonPopup(res)} />
      )}
    </>
  );
}

export default RightBarLayout;
