import React from "react";
import Button from "../atoms/Button";
import StaticPageSubscribe from "../../components/organismes/StaticPageSubscribe";

export function NewsLetterPopup({ onClick, showButton = false }) {
  return (
    <div className="uxer_terms_card">
      <StaticPageSubscribe link="https://script.google.com/macros/s/AKfycbwImvO_Bf4wFEC5XrNrRyEysfK40vienH8Fq1K60FuvkclahIRn96FIuctrHP5wEcfr2A/exec" />
      {showButton && <Button onClick={onClick}>Next</Button>}
    </div>
  );
}
