import { useSelector } from "react-redux/es/hooks/useSelector";
import { ReactComponent as Resos } from "../../assets/icons/resos.svg";
import React from "react";

function StaticPageTitle({ text, icon }) {
  return (
    <div className="page-title-box">
      {icon} <h2>{text}</h2>
    </div>
  );
}

export default StaticPageTitle;
