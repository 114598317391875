import { useTranslation } from "react-i18next";
import { ReactComponent as Mail2Icon } from "../../../assets/icons/mail2.svg";
import Button from "../../atoms/Button";
import React from "react";

function CheckEmailCard({ onOpenEmailApp, email, onRecent, onBack }) {
  const [t, i18n] = useTranslation();

  return (
    <div className="check-email-card no-gap">
      <span className="check-email-instruction">
        {t("popup.checkEmail.subTitle")}
        <span className="check-email-email">{email}</span>
      </span>

      <div className="check-email-footer">
        <span className="back-link">
          {t("popup.checkEmail.recent.0")}{" "}
          <span className="link-to" onClick={onRecent}>
            {t("popup.checkEmail.recent.1")}
          </span>
        </span>
        <span className="back-link">
          {t("popup.checkEmail.link.0")}{" "}
          <span className="link-to" onClick={onBack}>
            {t("popup.checkEmail.link.1")}
          </span>
        </span>
      </div>
    </div>
  );
}

export default CheckEmailCard;
