import { useSelector } from "react-redux/es/hooks/useSelector";
import React from "react";

function TextArea({
  name,
  label,
  leftIcon,
  rightIcon,
  placeholder,
  maxLength,
  error,
  value = "",
  className,
  children,
  onChange = () => { },
}) {
  const lang = useSelector((state) => state.lang);

  return (
    <div className={`textarea ${error ? "textarea-error" : ""} ${className}`}>
      <div className="textarea-header">
        {leftIcon && <div className="textarea-left-icon">{leftIcon}</div>}
        <div className="textarea-title" data-lang={lang}>
          {label}
        </div>
        {rightIcon && <div className="textarea-right-icon">{rightIcon}</div>}
      </div>
      <textarea
        name={name}
        placeholder={placeholder}
        maxLength={maxLength}
        className="text-area"
        value={value}
        data-lang={lang}
        onChange={onChange}

      >

        {children}
      </textarea>
    </div>
  );
}

export default TextArea;
