import { ReactComponent as LoadingIcon } from "../../assets/icons/loading_card.svg";
import React from "react";

function LoadingCard() {
  return (
    <div className="loading_card">
      <LoadingIcon />
    </div>
  );
}

export default LoadingCard;
