import { ReactComponent as CheckIcon } from "../../assets/icons/check.svg";

import React from "react";
import { useSelector } from "react-redux/es/hooks/useSelector";

function CheckBox({
  text,
  checked = false,
  icon = "",
  onClick = () => {},
  className,
  green,
}) {
  const lang = useSelector((state) => state.lang);

  return (
    <div className="checkbox" onClick={onClick} data-lang={lang}>
      <div
        className={`check-box ${
          checked ? `checked ${green && "checked-green"}` : ""
        } ${className} `}
      >
        <CheckIcon />
      </div>
      <span
        className={`checkbox-text ${green && "checked-green-text"}`}
        data-lang={lang}
      >
        {text}
      </span>
      {icon && <div className="checkbox-right-icon">{icon}</div>}
    </div>
  );
}

export default CheckBox;
