import { useEffect } from "react";
import { ReactComponent as DefaultProfile } from "../../assets/icons/default_profile.svg";
import React from "react";

function RoundImage({
  imageData,
  imageFull,
  type = "png",
  noImage = <DefaultProfile data-value="redirect" />,
  error,
  className = "",
  size,
  grey,
  ...otherProps
}) {
  return (
    <div
      className={`round-profile-image ${className} ${
        error ? "round-profile-image-error" : ""
      } ${size} ${grey}`}
      {...otherProps}
    >
      {imageData && imageFull && imageData.includes("https") ? (
        <img
          data-value="redirect"
          src={imageData}
          alt="profile"
          className="object-cover"
        />
      ) : imageData ? (
        <img
          data-value="redirect"
          src={imageFull ? imageData : `data:image/${type};base64,${imageData}`}
          alt="profile"
          className="object-cover"
        />
      ) : (
        noImage
      )}
    </div>
  );
}

export default RoundImage;
