import { ReactComponent as Close } from "../../../assets/icons/close.svg";

function InfoPopup({
  icon,
  title,
  onClose,
  bgColor = "#00E166",
  color = "#FFFFFF",
}) {
  return (
    <div className="infoPopup" style={{ color, background: bgColor }}>
      {icon}
      <span>{title}</span>
      <div className="close" onClick={onClose}>
        <Close />
      </div>
    </div>
  );
}

export default InfoPopup;
