import { useEffect, useRef, memo, useReducer } from "react";

const useIntersectionObserver = () => {
  const [isVisible, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case "SHOW":
        return true;
      default:
        return state;
    }
  }, false);

  const domRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        dispatch({ type: "SHOW" });
        observer.unobserve(entry.target);
      }
    });

    const currentRef = domRef.current;

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  return [isVisible, domRef];
};

const LazyLoadRow = memo(({ rowData }) => {
  const [isVisible, domRef] = useIntersectionObserver();

  return (
    <tr ref={domRef}>
      {rowData.map((cell, index) => (
        <td key={index}>{isVisible ? cell : <span>Loading...</span>}</td>
      ))}
    </tr>
  );
});


function DashboardTable({ header, values, className, theadClassName }) {

  
  return (
    <table className={className}>
      <thead className={`${theadClassName}`}>
        <tr>
          {header.map((tab, index) => (
            <th onClick={() => (tab.sort ? tab?.sort(index) : "")}>
              <label className={`${!tab.rightIcon && "th-label "} `}>
                {tab.label} {tab.rightIcon && tab.rightIcon}
              </label>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {values.map((row, rowIndex) => (
          <LazyLoadRow key={rowIndex} rowData={row} />
        ))}
      </tbody>
    </table>
  );
}

export default DashboardTable;
