import { useTranslation } from "react-i18next";
import { ReactComponent as RegisterIcon } from "../../assets/icons/register.svg";
import Input from "../atoms/Input";
import { ReactComponent as UsernameIcon } from "../../assets/icons/user.svg";
import { ReactComponent as MailIcon } from "../../assets/icons/mail.svg";
import { ReactComponent as Ok } from "../../assets/icons/ok.svg";
import { ReactComponent as BagIcon } from "../../assets/icons/bag.svg";
import { ReactComponent as CautionIcon } from "../../assets/icons/caution.svg";
import { ReactComponent as Loading } from "../../assets/icons/loading.svg";
import Select from "../atoms/Select";
import { ReactComponent as Back } from "../../assets/icons/back.svg";
import { ReactComponent as FlagIcon } from "../../assets/icons/flag.svg";
import { ReactComponent as DropIcon } from "../../assets/icons/drop_down.svg";
import { ReactComponent as BgIllusIcon } from "../../assets/icons/banner_bg.svg";

import { useDispatch, useSelector } from "react-redux";
import Button from "../atoms/Button";
import DropDown from "../atoms/DropDown";
import LocationDrop from "../atoms/LocationDrop";
import countires from "../../data/countires.json";
import Popup from "../molecules/Popup";
import { useEffect, useState } from "react";
import React from "react";
import { ReactComponent as LogoIcon } from "../../assets/icons/illus_soon.svg";
import PopupContainer from "../molecules/PopupContainer";
import axios from "axios";
import pirsch from "../../Analytics";
import { setSelectMenu } from "../../redux/actions/env";

function SingupSoonPage({ setIsSoonPopup }) {
  useEffect(() => {
    document.title = "UUD - Signup Soon";
    return () => {
      document.title = "UUD";
    };
  }, []);

  const rolesOptions = [
    { value: "Product Designer", label: "Product Designer" },
    { value: "UX Designer", label: "UX Designer" },
    { value: "UI Designer", label: "UI Designer" },
    { value: "Engineer", label: "Engineer" },
    { value: "Manager", label: "Manager" },
    { value: "Director", label: "Director" },
    { value: "Executive", label: "Executive" },
    { value: "Entrepreneur", label: "Entrepreneur" },
    { value: "Human Resource", label: "Human Resource" },
    { value: "Sales", label: "Sales" },
    { value: "Marketer", label: "Marketer" },
    { value: "Customer Service", label: "Customer Service" },
    { value: "Student", label: "Student" },
    { value: "Others", label: "Others" },
  ];
  const [t, i18n] = useTranslation();
  const lang = useSelector((state) => state.lang);
  const [data, setData] = useState({});
  const countries = countires;
  const [errors, setErrors] = useState({});
  const [popIcon, setPopIcon] = useState("");
  const [popType, setPopType] = useState("");
  const [IsShowPopup, settIsShowPopup] = useState(false);
  const [popMessage, setPopMessage] = useState("");
  const [done, setDone] = useState(false);

  function popups(message, type, icon) {
    settIsShowPopup(true);
    setPopType(type);
    setPopIcon(icon);
    setPopMessage(message);
    setTimeout(() => {
      settIsShowPopup(false);
    }, 3000);
  }
  const [country, setCountry] = useState("");
  const dispatch = useDispatch();

  //creating function to load ip address from the API
  // const getData = async () => {
  //   const res = await axios.get("https://geolocation-db.com/json/");
  //   console.log(res.data);
  //   setIP(res.data.IPv4);
  // };

  // Updated Code
  useEffect(() => {
    axios
      .get("https://api.country.is/")
      .then((res) => {
        if (res.data.country) {
          setCountry(res.data.country);
        }
      })
      .catch(() => {});
  }, []);
  const handleChange = async (element, dataP) => {
    console.log(dataP);
    const newData = { ...data, [`${element}`]: dataP };
    setData(newData);
  };
  const handleSubmit = () => {
    let errors = validateForm(data);

    if (Object.keys(errors).length === 0) {
      const date = new Date();
      const formData = new FormData();
      let fullCountry = countries.find((el) => {
        return el.iso2.toLowerCase() == country.toLowerCase();
      });

      formData.append("country", country ? fullCountry.label : "- - - -");
      formData.append("name", data.name ? data.name : "- - - -");
      formData.append("email", data.email ? data.email : "- - - -");
      formData.append(
        "nationality",
        data.nationality ? data.nationality : "- - - -"
      );
      formData.append(
        "date",
        `${date.getDate()}/${
          date.getMonth() + 1
        }/${date.getFullYear()} - ${date.getHours()}:${
          date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()
        }:${
          date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds()
        }`
      );
      formData.append(
        "timezone",
        `${date
          .toString()
          .match(/\((.*)\)/)
          .pop()}`
      );

      formData.append("role", data.subRole ? data.subRole : "- - - -");

      popups("Loading...", "loading", <Loading />);
      axios
        .post(
          "https://script.google.com/macros/s/AKfycbx6EN0gbYSfV0yWyoIGlvVBlBeFxc31_n1C656pyMs8uZ0EmPZSBgAkNyX7ltGC1X-T/exec",
          formData
        )

        .then((response) => {
          settIsShowPopup(false);
          if (response.data.result == "success") {
            popups("Data sent successfully", "success", <CautionIcon />);
            setDone(true);

            setTimeout(() => {
              setDone(false);
              setData((prev) => {
                prev.email = "";
                prev.name = "";
                prev.subRole = "";
                prev.nationality = "";
                return prev;
              });
            }, 1000);
          } else {
            popups("Error occurred", "error", <CautionIcon />);
          }
        })
        .catch((error) => console.error("Error!", error.message));
    } else {
      if (Object.keys(errors).length > 1) {
        popups(
          lang === "en"
            ? "Please fill out the required fields"
            : "必須項目を入力してください。",
          "error",
          <CautionIcon />
        );
      } else {
        Object.keys(errors).map(() => {
          popups(Object.values(errors)[0], "error", <CautionIcon />);
        });
      }
    }
  };

  const validateForm = (data) => {
    const errors = {};

    if (!data?.email) {
      errors.email = "Email is required";
    } else if (!isValidEmail(data.email)) {
      errors.email = "Invalid email address";
    }

    if (!data?.name) {
      errors.name = "Name is required";
    }

    if (
      data?.nationality?.replaceAll(" ", "")?.replace(/(\r\n|\n|\r)/gm, "") ==
      ""
    ) {
      console.log(data.nationality);
      errors.nationality = true;
    }

    if (!data?.subRole) {
      errors.subRole = "Role is required";
    }

    setErrors(errors);
    return errors;
  };

  const isValidEmail = (email) => {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    );
  };

  // START -------------------------------------------------------

  return (
    <>
      <div className="signup_soon_box">
        <div className="big_title">
          Early Access <br /> Registration
        </div>

        <div className="soon-paragraph-cont" data-lang={lang}>
          <p className="soon-paragraph" data-lang={lang}>
            {lang === "en" ? (
              <>
                Thank you for your interest in joining UUD ! <br />
                To get a chance to be among the first users, please submit your
                email address below. <br />
                We'll review your application and gradually send out invitations
                in the upcoming days.
                <br />
              </>
            ) : (
              <>
                UUDへのご興味、ありがとうございます！
                <br />
                最初のユーザーの一員になるチャンスを得るために、下記にメールアドレスをご提出ください。
                <br />
                お申込み内容を確認し、近日中に招待状を段階的にお送りいたします。
                <br />


                UUDへご興味を持っていただきありがとうございます！<br />
                アーリーユーザーの1人になるチャンスを得るために、下記よりメールアドレスを送信して下さい。<br />
                お申込み内容を確認し、近日中に招待状を順番にお送りいたします。<br />
                
              </>
            )}
          </p>
        </div>

        <div className="soon-form-cont" data-lang={lang}>
          <div className="soon-inputs-cont" data-lang={lang}>
            <Input
              data-lang={lang}
              className="soon-name-input"
              leftIcon={<UsernameIcon />}
              placeholder={lang === "en" ? "Name" : "お名前"}
              rightIcon={null}
              onPaste={(e) => {
                e.preventDefault();
              }}
              value={data.name}
              onChange={(value) => handleChange("name", value.target.value)}
              error={errors.name}
              id="id1"
            />
            <Input
              id="email"
              data-lang={lang}
              leftIcon={<MailIcon />}
              placeholder={lang === "en" ? "Email" : "メールアドレス"}
              rightIcon={null}
              onPaste={(e) => {
                e.preventDefault();
              }}
              value={data.email}
              onChange={(value) => handleChange("email", value.target.value)}
              error={errors.email}
            />
            <Select
              mainclass="soon-role-input"
              leftIcon={<BagIcon />}
              options={rolesOptions}
              onChange={(value) => handleChange("subRole", value)}
              value={data.subRole}
              defaultValue={lang === "en" ? "Role" : "役職"}
              error={errors.subRole}
            />
            <LocationDrop
              countries={countries}
              onChange={(value) => handleChange("nationality", value)}
              defaultValue={null}
              clear={done ? true : false}
              leftIcon={<FlagIcon />}
              error={errors.nationality}
              className="soon-location-input"
            />

            <Button
              rightIcon={done ? <Ok /> : <RegisterIcon />}
              onClick={() => {
                handleSubmit();
                pirsch("SignupSoon-Register");
              }}
              data-lang={lang}
              className={`soon-button ${done && "soon-button-done"}`}
            >
              {done
                ? lang === "en"
                  ? "Done"
                  : "送信完了"
                : lang === "en"
                ? "Register"
                : "登録する"}
            </Button>

            <span className="soon-paragraph-small" data-lang={lang}>
              {lang === "en" ? (
                <>
                  We care about protecting your data. Here is our{" "}
                  <span
                    className="link"
                    onClick={() => {
                      window.location = "/Privacy";
                      pirsch("SignupSoon-Privacy-E");
                    }}
                  >
                    Privacy Policy
                  </span>
                  .
                </>
              ) : (
                <>
                  データの保護について細心の注意を払っています。以下が当社の{" "}
                  <span
                    className="link"
                    data-lang={lang}
                    onClick={() => {
                      window.location = "/Privacy";
                      pirsch("SignupSoon-Privacy-J");
                    }}
                  >
                    プライバシーポリシー
                  </span>
                  です。
                </>
              )}
            </span>
          </div>
        </div>

        <div className="soon-paragraph-cont" data-lang={lang}>
          <p className="soon-paragraph" data-lang={lang}>
            {lang === "en" ? (
              <>
                Why should you be excited about this? Well, signing up will open
                up a world of possibilities for you:
                <br />
                <ol>
                  <li>
                    <span>Reserve Your Username: </span>
                    Secure your preferred username before anyone else.
                    (uud.io/yourusername)
                  </li>
                  <li>
                    <span>Exclusive Access: </span>
                    Gain early access to explore all our content.
                  </li>
                </ol>
                <br />
                <p>We're excited to have you join us on this journey!</p>
              </>
            ) : (
              <>
                これにワクワクする理由は何でしょうか？例えば、サインアップすると以下の可能性が広がります：
                <br />
                <ol>
                  <li>
                    <span>ユーザー名を予約：</span>
                    他の誰よりも先に好きなユーザー名を確保できます。（uud.io/yourusername）
                  </li>
                  <li>
                    <span>限定アクセス：</span>
                    早期アクセスを取得して、すべてのコンテンツをご覧ください。
                  </li>
                </ol>
                <br />
                <p>
                私たちはこの旅に参加していただけることを楽しみにしています！
                </p>

              </>
            )}

            {/* <Button
              rightIcon={<LinkIcon />}
              onClick={() => {
                window.location = "/Servicez";
                pirsch("SignupSoon-Services");
              }}
              data-lang={lang}
              className="button-lined"
            >
              {lang === "en" ? "More about UUD Services" : "詳細はこちらへ"}
            </Button> */}
          </p>
        </div>

        <div className="to_top">
          <Button
            rightIcon={<Back />}
            className="to_top_btn"
            onClick={() => {
              window.location = "/";
              dispatch(setSelectMenu("Tools"));
            }}
          >
            Back to HOME!
          </Button>
        </div>
      </div>
      {IsShowPopup ? (
        <Popup
          message={popMessage}
          type={popType}
          icon={popIcon}
          closePopup={() => settIsShowPopup(false)}
        />
      ) : null}
    </>
  );
}

export default SingupSoonPage;
