import { ReactComponent as LoadingIcon } from "../../assets/icons/loading_card_new.svg";

const Loading = () => {
    return (
        <div className="loading_box">
        <LoadingIcon />
        </div>
    )
}

export default Loading;