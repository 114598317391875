import { ReactComponent as DefaultProfile } from "../../assets/icons/b_logo.svg";
import { ReactComponent as LogoBad } from "../../assets/icons/logo_06.svg";
import { ReactComponent as Logo } from "../../assets/icons/logo.svg";
import { ReactComponent as MailIcon } from "../../assets/icons/mail.svg";
import { ReactComponent as LockIcon } from "../../assets/icons/lock.svg";
import { ReactComponent as TimerIcon } from "../../assets/icons/timer.svg";

import { ReactComponent as UserNameIcon } from "../../assets/icons/id_icon.svg";
import { ReactComponent as FullNameIcon } from "../../assets/icons/full_name.svg";
import { ReactComponent as GlobeIcon } from "../../assets/icons/globe.svg";
import { ReactComponent as BlogIcon } from "../../assets/icons/blog.svg";
import { ReactComponent as Send } from "../../assets/icons/send.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import { ReactComponent as BagIcon } from "../../assets/icons/bag.svg";
import { ReactComponent as ImgIcon } from "../../assets/icons/image.svg";
import { ReactComponent as FlagIcon } from "../../assets/icons/flag.svg";
import { ReactComponent as Trash } from "../../assets/icons/trash.svg";
import { ReactComponent as EyeOnIcon } from "../../assets/icons/eye_on.svg";
import { ReactComponent as EyeOffIcon } from "../../assets/icons/eye_off.svg";

import { ReactComponent as TwitterIcon } from "../../assets/icons/twitter.svg";
import { ReactComponent as FacebookIcon } from "../../assets/icons/facebook.svg";
import { ReactComponent as InstagramIcon } from "../../assets/icons/instagram.svg";
import { ReactComponent as LinkedinIcon } from "../../assets/icons/linkedin.svg";
import { ReactComponent as DribbbleIcon } from "../../assets/icons/dribbble.svg";
import { ReactComponent as BehanceIcon } from "../../assets/icons/behance.svg";
import { ReactComponent as UUDIcon } from "../../assets/icons/uud_dh.svg";
import React from "react";

import Input from "../atoms/Input";
import LocationDrop from "../atoms/LocationDrop";
import Select from "../atoms/Select";
import TextArea from "../atoms/TextArea";
import AvatarCrop from "./AvatarCorp";
import { useEffect, useState } from "react";
import countires from "../../data/countires.json";
import Button from "../atoms/Button";
import { useSelector } from "react-redux";
import { getUserInfo } from "../../services/authServices";
import jwtDecode from "jwt-decode";
import RadioBoxGroup from "./RadioBoxGroup";
import CheckBoxGroup from "./CheckBoxGroup";
import Switch from "../atoms/Switch";
import { fetchImage } from "../../services/itemsServices";

function UxerForm({ handleCancle, onSubmit, setUxerErrors }) {
  const [data, setData] = useState({});
  const [image, setImage] = useState();
  const [errors, setErrors] = useState({});
  const [emptyLink, setEmptyLink] = useState(6);
  const [nationality, setNationality] = useState();
  const countries = countires;
  const reg = new RegExp("^[0-9]$");
  const rolesOptions = [
    { value: "Product Designer", label: "Product Designer" },
    { value: "UX Designer", label: "UX Designer" },
    { value: "UI Designer", label: "UI Designer" },
    { value: "Engineer", label: "Engineer" },
    { value: "Manager", label: "Manager" },
    { value: "Director", label: "Director" },
    { value: "Executive", label: "Executive" },
    { value: "Entrepreneur", label: "Entrepreneur" },
    { value: "Human Resource", label: "Human Resource" },
    { value: "Sales", label: "Sales" },
    { value: "Marketer", label: "Marketer" },
    { value: "Customer Service", label: "Customer Service" },
    { value: "Student", label: "Student" },
    { value: "Others", label: "Others" },
  ];

  const user = useSelector((state) => state.user);
  const lang = useSelector((state) => state.lang);

  const handleChange = async (element, dataP) => {
    console.log(data);
    const newData = { ...data, [`${element}`]: dataP };
    setData(newData);

    setEmptyLink(
      6 -
        [
          newData.twitterLink,
          newData.facebookLink,
          newData.instagramLink,
          newData.linkedinLink,
          newData.dribbleLink,
          newData.behanceLink,
        ].filter((x) => x).length
    );
  };

  const handleSubmit = (data) => {
    let errors = validateForm(data);

    if (Object.keys(errors).length === 0) {
      onSubmit(data);
    }
  };

  const validateForm = (data) => {
    const errors = {};

    if (!data.profile_img) {
      errors.profile_img = "Profile Image is required";
    }

    if (!data.email) {
      errors.email = "Email is required";
    } else if (!isValidEmail(data.email)) {
      errors.email = "Invalid email address";
    }

    if (!data.username) {
      errors.username = "Username is required";
    } else if (data.username.length > 16 || data.username.length < 5) {
      errors.username = "Username must be between 5 and 16 characters";
    } else if (!isUsernameValid(data.username)) {
      errors.username =
        "Username can only contain letters, numbers, and underscores";
    }

    if (!data.fullName) {
      errors.fullName = "Full name is required";
    }

    if (!data.experience) {
      errors.experience = "Experience is required";
    }

    if (!data.position) {
      errors.position = "Position is required";
    }
    if (data.skills?.length == 0 || data.skills == undefined) {
      errors.skills = "Skill is required";
    }

    if (parseInt(data.experience) < 0 || parseInt(data.experience) > 60) {
      errors.experience = "Enter a valid Experience";
    }

    if (!data.nationality) {
      errors.nationality = "Location is required";
    }

    if (!data.subRole) {
      errors.subRole = "Role is required";
    }

    if (!data.intro) {
      errors.intro = "Introduction is required";
    } else if (data.intro.length > 240 || data.intro.length < 30) {
      errors.intro = "Introduction must be between 30 and 240 characters";
    }

    if (data.personalSite && !isValidURL(data.personalSite)) {
      errors.personalSite = "Enter a valid URL";
    }

    if (data.personalBlog && !isValidURL(data.personalBlog)) {
      errors.personalBlog = "Enter a valid URL";
    }

    if (data.twitterLink && !isValidSocialMediaLink(data.twitterLink)) {
      errors.twitterLink = "Enter a valid URL";
    }

    if (data.facebookLink && !isValidSocialMediaLink(data.facebookLink)) {
      errors.facebookLink = "Enter a valid URL";
    }

    if (data.instagramLink && !isValidSocialMediaLink(data.instagramLink)) {
      errors.instagramLink = "Enter a valid URL";
    }

    if (data.linkedinLink && !isValidSocialMediaLink(data.linkedinLink)) {
      errors.linkedinLink = "Enter a valid URL";
    }

    if (data.dribbleLink && !isValidSocialMediaLink(data.dribbleLink)) {
      errors.dribbleLink = "Enter a valid URL";
    }

    if (data.behanceLink && !isValidSocialMediaLink(data.behanceLink)) {
      errors.behanceLink = "Enter a valid URL";
    }
    setUxerErrors(errors);
    setErrors(errors);
    return errors;
  };

  const isUsernameValid = (username) => {
    return /^[a-zA-Z0-9_]+$/.test(username);
  };

  const isValidEmail = (email) => {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    );
  };

  const isValidSocialMediaLink = (url) => {
    return /^(https?|ftp):\/\/([^\s\/]+)((\/\w+)*\/)([\w\-\.]+[^#?\s]+)(.*)?(#[\w\-]+)?$/.test(
      url
    );
  };

  const isValidURL = (url) => {
    return /^(http(s)?:\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)?$/.test(
      url
    );
  };

  const getNationality = (national) => {
    let res = "";
    countires.forEach((country) => {
      if (country.value === national) {
        res = country;
      }
    });

    return res;
  };

  useEffect(() => {
    getUserInfo(jwtDecode(user).id)
      .then((res) => {
        if (res.data.myUser) {
          res.data = res.data.myUser;
        }
        console.log("info get id", res.data);
        setData({
          ...res.data,
          personalBlog:
            res.data.personalBlog != "undefined" ? res.data.personalBlog : "",
          personalSite:
            res.data.personalSite != "undefined" ? res.data.personalSite : "",
          subRole: res.data.sub_role,
          twitterLink:
            res.data.mediaLinks[0] != "undefined" ? res.data.mediaLinks[0] : "",
          facebookLink:
            res.data.mediaLinks[1] != "undefined" ? res.data.mediaLinks[1] : "",
          instagramLink:
            res.data.mediaLinks[2] != "undefined" ? res.data.mediaLinks[2] : "",
          linkedinLink:
            res.data.mediaLinks[3] != "undefined" ? res.data.mediaLinks[3] : "",
          dribbleLink:
            res.data.mediaLinks[4] != "undefined" ? res.data.mediaLinks[4] : "",
          behanceLink:
            res.data.mediaLinks[5] != "undefined" ? res.data.mediaLinks[5] : "",
          nationality: getNationality(res.data.nationality),
          experience: "",
        });
        setNationality(getNationality(res.data.nationality));

        setEmptyLink(
          6 -
            [
              res.data.mediaLinks[0],
              res.data.mediaLinks[1],
              res.data.mediaLinks[2],
              res.data.mediaLinks[3],
              res.data.mediaLinks[4],
              res.data.mediaLinks[5],
            ].filter((x) => x != "undefined" && x != "").length
        );

        if (res.data.profile_img) {
          fetchImage("profile", res.data.profile_img)
            .then((res) => {
              if (res != undefined) {
                setImage(res);
              }
            })
            .catch((err) => {
              console.log("rrmme", err);
            });
        }
      })
      .catch((err) => {
        console.log("rrmme", err);
      });
  }, [user]);

  useEffect(() => {
    if (data) {
    }
  }, [data]);

  return (
    <div className="user-form user-form-uxer">
      {/*<div className="user-form-top-bar">
        <div>
          <UUDIcon className="user-form-header-left-logo" />
        </div>
      </div>*/}
      <div className="user-form-body">
        <div className="user-form-body-header">
          <span className="user-form-body-header-title" data-lang={lang}>
            {lang === "en" ? "Join UXERS" : "UXERSにジョイン"}
          </span>

          <p className="user-form-body-header-info" data-lang={lang}>
            {lang === "en"
              ? "Please complete your profile before submitting your request."
              : "リクエストを送信する前に、プロフィールを完了してください。"}
            <br />
            <span className="user-form-body-header-label">
              {lang === "en"
                ? "All fields are required."
                : "すべての項目は必須です。"}
            </span>
          </p>
        </div>
        <div className="user-form-body-required">
          <div className="user-form-body-more-info">
            <p className="user-form-body-header-info" data-lang={lang}>
              {lang === "en"
                ? "Add your experience years below."
                : "デザインの経験年数を以下に追加してください。"}
            </p>

            <div className="user-form-col-2">
              <Select
                leftIcon={<BagIcon />}
                options={rolesOptions}
                onChange={(value) => handleChange("subRole", value)}
                value={data.sub_role}
                error={errors.subRole}
                disable={true}
              />
              <Input
                value={data.experience}
                onChange={(e) => {
                  if (
                    (/^[0-9]+$/.test(e.target.value) && e.target.value < 101) ||
                    e.target.value.length == 0
                  ) {
                    console.log(/^[0-9]+$/.test(e.target.value));
                    handleChange("experience", e.target.value);
                  }
                }}
                leftIcon={<TimerIcon />}
                className="experience-input"
                placeholder=""
                error={errors.experience}
                type="text"
              />
            </div>
            <div className="user-form-group-box">
              <div className="user-form-group">
                <span className="user-form-label" data-lang={lang}>
                  {lang === "en" ? "Position" : "職位"}
                </span>
                <span className="text-red-500 outfit pl-3"></span>
                <RadioBoxGroup
                  className={"check-half check-w-auto"}
                  data-lang={lang}
                  checks={[
                    {
                      label: "employee",
                      text: lang === "en" ? <>Employee </> : <>社員</>,
                    },
                    {
                      label: "ceo",
                      text:
                        lang === "en" ? (
                          <>Self-Employee / CEO </>
                        ) : (
                          <>自営業者 / CEO</>
                        ),
                    },
                    {
                      label: "freelancer",
                      text:
                        lang === "en" ? <>Freelancer </> : <>フリーランサー</>,
                    },
                  ]}
                  value={data.position}
                  required={true}
                  onChange={(values) => handleChange("position", values)}
                  error={errors.position}
                />
              </div>
              <div className="user-form-group">
                <span className="user-form-label" data-lang={lang}>
                  {lang === "en" ? "Skill" : "スキル"}{" "}
                </span>
                <span className="text-red-500 outfit pl-3"></span>
                <CheckBoxGroup
                  className="check-half"
                  data-lang={lang}
                  checks={[
                    {
                      label: "animation",
                      text:
                        lang === "en" ? <>Animation</> : <>アニメーション</>,
                    },
                    {
                      label: "branding",
                      text: lang === "en" ? <>Branding</> : <>ブランディング</>,
                    },
                    {
                      label: "illustration",
                      text:
                        lang === "en" ? (
                          <>Illustration</>
                        ) : (
                          <>イラストレーション</>
                        ),
                    },
                    {
                      label: "writer",
                      text:
                        lang === "en" ? <>Other Design</> : <>その他デザイン</>,
                    },
                  ]}
                  value={data.skills}
                  onChange={(values) => handleChange("skills", values)}
                  error={errors.skills}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="user-form-body-buttons">
          <Button
            rightIcon={<CloseIcon />}
            onClick={() => handleCancle()}
            className="noLogged_button noLogged_button_outline w-auto"
            data-lang={lang}
          >
            {lang === "en" ? "Cancel" : "キャンセル"}
          </Button>
          <Button
            rightIcon={<Send />}
            className="noLogged_button"
            data-lang={lang}
            onClick={() => handleSubmit(data)}
          >
            {lang === "en" ? "Submit Your Request" : "リクエストを送信する"}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default UxerForm;
