import { useEffect, useState } from "react";
import { t } from "i18next";
import PageTitle from "../atoms/PageTitle";
import LeftBarMessage from "../organismes/leftBarMessage";
import React from "react";
import jwtDecode from "jwt-decode";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { deleteChat, getChatInfo } from "../../services/ChatService";
import { getUserInfo } from "../../services/authServices";

function ChatLeftBar({
  setShowLeftBar,
  leftMessages,
  handleChatChange,
  selectedChat,
  showarchive,
  title,
  icon,
  messageNo,
  archiveNo,
}) {
  const user = useSelector((state) => state.user);
  const [mute, setMute] = useState([
    true,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  function changeMute(index) {
    if (mute[index]) {
      setMute((prev) => {
        prev[index] = false;

        return [...prev];
      });
    } else {
      setMute((prev) => {
        prev[index] = true;
        return [...prev];
      });
    }
  }

  if (leftMessages.length != 0) {
    leftMessages.activeChatsWithContent.sort(function (a, b) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return new Date(b.updatedAt) - new Date(a.updatedAt);
    });
    leftMessages.archivedChatsWithContent.sort(function (a, b) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return new Date(b.updatedAt) - new Date(a.updatedAt);
    });
  }
  useEffect(() => {
    console.log(leftMessages ? leftMessages : null);
  }, [leftMessages]);
  return (
    <>
      {
        <div className={`chat-left-bar ${title != "message" && "hidden"}`}>
          <PageTitle
            title={title}
            icon={icon}
            showarchive={showarchive}
            messageNo={messageNo}
            archiveNo={archiveNo}
          />
          <div className="chat-left-bar-messages">
            {leftMessages.activeChatsWithContent !== undefined
              ? leftMessages.activeChatsWithContent.length !== 0
                ? leftMessages.activeChatsWithContent.map((el, index) => {
                    return (
                      <LeftBarMessage
                        opened={false}
                        archive={false}
                        mute={false}
                        changeMute={() => changeMute(0)}
                        back={false}
                        handleChatChange={() => {
                          handleChatChange(el);
                        }}
                        selected={selectedChat._id == el._id}
                        setShowLeftBar={(res) => setShowLeftBar(res)}
                        name={
                          el.users.length != 0
                            ? el.users.find((other_user) => {
                                return (
                                  other_user.username !==
                                  jwtDecode(user).username
                                );
                              })
                              ? el.users.find((other_user) => {
                                  return (
                                    other_user.username !==
                                    jwtDecode(user).username
                                  );
                                }).fullName
                              : ""
                            : ""
                        }
                        role={el != undefined ? el.users[0].sub_role : ""}
                        latestMessage={
                          el.latestMessage ? el.latestMessage.content : ""
                        }
                        isSeen={
                          el.latestMessage
                            ? el.latestMessage.sender._id != jwtDecode(user).id
                              ? el.latestMessage.isRead
                              : true
                            : null
                        }
                        latestMessagedate={el.updatedAt ? el.updatedAt : ""}
                        image={
                          el.users.length != 0
                            ? el.users[0].profile_img
                            : "avatar.png"
                        }
                        userId={
                          el.users.length != 0
                            ? el.users.find((other_user) => {
                                return (
                                  other_user.username !==
                                  jwtDecode(user).username
                                );
                              }) != undefined
                              ? el.users.find((other_user) => {
                                  return (
                                    other_user.username !==
                                    jwtDecode(user).username
                                  );
                                })._id
                              : ""
                            : ""
                        }
                        key={el._id}
                      />
                    );
                  })
                : null
              : null}
          </div>
        </div>
      }
      {
        <div className={`chat-left-bar ${title != "archive" && "hidden"}`}>
          <PageTitle
            title={title}
            icon={icon}
            showarchive={showarchive}
            messageNo={messageNo}
            archiveNo={archiveNo}
          />
          <div className="chat-left-bar-messages">
            {leftMessages.archivedChatsWithContent !== undefined
              ? leftMessages.archivedChatsWithContent.length !== 0
                ? leftMessages.archivedChatsWithContent.map((el, index) => {
                    return (
                      <LeftBarMessage
                        opened={false}
                        archive={false}
                        mute={false}
                        changeMute={() => changeMute(0)}
                        back={false}
                        handleChatChange={() => {
                          handleChatChange(el);
                        }}
                        selected={selectedChat._id == el._id}
                        setShowLeftBar={(res) => setShowLeftBar(res)}
                        name={
                          el.users.length != 0
                            ? el.users.find((other_user) => {
                                return (
                                  other_user.username !==
                                  jwtDecode(user).username
                                );
                              })
                              ? el.users.find((other_user) => {
                                  return (
                                    other_user.username !==
                                    jwtDecode(user).username
                                  );
                                }).fullName
                              : ""
                            : ""
                        }
                        role={el != undefined ? el.users[0].sub_role : ""}
                        latestMessage={
                          el.latestMessage ? el.latestMessage.content : ""
                        }
                        isSeen={
                          el.latestMessage
                            ? el.latestMessage.sender._id != jwtDecode(user).id
                              ? el.latestMessage.isRead
                              : true
                            : null
                        }
                        latestMessagedate={el.updatedAt ? el.updatedAt : ""}
                        image={
                          el.users.length != 0
                            ? el.users[0].profile_img
                            : "avatar.png"
                        }
                        userId={
                          el.users.length != 0
                            ? el.users.find((other_user) => {
                                return (
                                  other_user.username !==
                                  jwtDecode(user).username
                                );
                              }) != undefined
                              ? el.users.find((other_user) => {
                                  return (
                                    other_user.username !==
                                    jwtDecode(user).username
                                  );
                                })._id
                              : ""
                            : ""
                        }
                        key={el._id}
                      />
                    );
                  })
                : null
              : null}
          </div>
        </div>
      }
    </>
  );
}

export default ChatLeftBar;
