import { ReactComponent as ToolsIcon } from "./assets/icons/tool.svg";
import { ReactComponent as ResosIcon } from "./assets/icons/resos.svg";
import { ReactComponent as IdeazIcon } from "./assets/icons/idea.svg";
import { ReactComponent as UxersIcon } from "./assets/icons/uxers.svg";
import { ReactComponent as AppsIcon } from "./assets/icons/apps.svg";
// import { useSelector } from "react-redux";

// export const BACKEND_URL = "https://api.uud.io";
export const BACKEND_URL = "https://api.uud.io";
// const lang = useSelector((state) => state.lang);
// structure layout
export const layout = {
  tools: {
    backgroundColor: "#15A6F8",
    icon: <ToolsIcon />,
    eventTags: [
      "Tag-UX Design-Tools-E",
      "Tag-UI Design-Tools-E",
      "Tag-Image-Tools-E",
      "Tag-Color-Tools-E",
      "Tag-Icon-Tools-E",
      "Tag-Font-Tools-E",
      "Tag-Video-Tools-E",
      "Tag-Audio-Tools-E",
      "Tag-Vector-Tools-E",
      "Tag-AI-Tools-E",
      "Tag-Animation-Tools-E",
      "Tag-No Code-Tools-E",
      "Tag-Productivity-Tools-E",
      "Tag-Others-Tools-E",
    ],
    tags: [
      "UX Design",
      "UI Design",
      "Image",
      "Color",
      "Icon",
      "Font",
      "Video",
      "Audio",
      "Vector",
      "AI",
      "Animation",
      "No Code",
      "Productivity",
      "Others",
    ],
  },
  apps: {
    backgroundColor: "#4FDFC2",
    icon: <AppsIcon />,
    eventTags: [
      "Tag-UX Design-Tools-E",
      "Tag-UI Design-Tools-E",
      "Tag-Image-Tools-E",
      "Tag-Color-Tools-E",
      "Tag-Icon-Tools-E",
      "Tag-Font-Tools-E",
      "Tag-Video-Tools-E",
      "Tag-Audio-Tools-E",
      "Tag-Vector-Tools-E",
      "Tag-AI-Tools-E",
      "Tag-Animation-Tools-E",
      "Tag-No Code-Tools-E",
      "Tag-Productivity-Tools-E",
      "Tag-Others-Tools-E",
    ],
    tags: [
      "UX Design",
      "UI Design",
      "Image",
      "Color",
      "Icon",
      "Font",
      "Video",
      "Audio",
      "Vector",
      "AI",
      "Animation",
      "No Code",
      "Productivity",
      "Others",
    ],
  },
  resos: {
    backgroundColor: "#9648DE",
    icon: <ResosIcon />,
    eventTags: [
      "Tag-UX Design-Resos-E",
      "Tag-UI Design-Resos-E",
      "Tag-Color-Resos-E",
      "Tag-Icon-Resos-E",
      "Tag-Font-Resos-E",
      "Tag-Image-Resos-E",
      "Tag-Video-Resos-E",
      "Tag-Audio-Resos-E",
      "Tag-Vector-Resos-E",
      "Tag-Animation-Resos-E",
      "Tag-Data-Resos-E",
      "Tag-Others-Resos-E",
    ],
    tags: [
      "UX Design",
      "UI Design",
      "Color",
      "Icon",
      "Font",
      "Image",
      "Video",
      "Audio",
      "Vector",
      "Animation",
      "Data",
      "Others",
    ],
  },
  ideaz: {
    backgroundColor: "#00E165",
    icon: <IdeazIcon />,
    eventTags: [
      "Tag-UX Design-Ideaz-E",
      "Tag-UI Design-Ideaz-E",
      "Tag-Website-Ideaz-E",
      "Tag-App-Ideaz-E",
      "Tag-Icon-Ideaz-E",
      "Tag-Font-Ideaz-E",
      "Tag-Mockup-Ideaz-E",
      "Tag-Branding-Ideaz-E",
      "Tag-Vector-Ideaz-E",
      "Tag-Animation-Ideaz-E",
      "Tag-Art-Ideaz-E",
      "Tag-Other-Ideaz-E",
    ],
    tags: [
      "UX Design",
      "UI Design",
      "Website",
      "App",
      "Icon",
      "Font",
      "Mockup",
      "Branding",
      "Vector",
      "Animation",
      "Art",
      "Others",
    ],
  },
  uxers: {
    backgroundColor: "#FFA300",
    icon: <UxersIcon />,
  },
  signup: {
    backgroundColor: "#FF6956",
  },
  profile: {
    backgroundColor: "#FF6956",
  },
  chat: {
    backgroundColor: "#FF6A56",
  },
};

export const layoutJp = {
  tools: {
    backgroundColor: "#15A6F8",
    icon: <ToolsIcon />,
    eventTags: [
      "Tag-UX Design-Tools-J",
      "Tag-UI Design-Tools-J",
      "Tag-Image-Tools-J",
      "Tag-Color-Tools-J",
      "Tag-Icon-Tools-J",
      "Tag-Font-Tools-J",
      "Tag-Video-Tools-J",
      "Tag-Audio-Tools-J",
      "Tag-Vector-Tools-J",
      "Tag-Ai-Tools-J",
      "Tag-Animation-Tools-J",
      "Tag-No Code-Tools-J",
      "Tag-Productivity-Tools-J",
      "Tag-Others-Tools-J",
    ],
    tags: [
      "UXデザイン",
      "UIデザイン",
      "カラー",
      "アイコン",
      "フォント",
      "画像",
      "動画",
      "ベクター",
      "音声",
      "AI",
      "アニメーション",
      "ノーコード",
      "生産性向",
      "その他",
    ],
  },
  resos: {
    backgroundColor: "#9648DE",
    icon: <ResosIcon />,
    eventTags: [
      "Tag-UX Design-Resos-J",
      "Tag-UI Design-Resos-J",
      "Tag-Color-Resos-J",
      "Tag-Icon-Resos-J",
      "Tag-Font-Resos-J",
      "Tag-Image-Resos-J",
      "Tag-Video-Resos-J",
      "Tag-Audio-Resos-J",
      "Tag-Vector-Resos-J",
      "Tag-Animation-Resos-J",
      "Tag-Data-Resos-J",
      "Tag-Others-Resos-J",
    ],
    tags: [
      "UXデザイン",
      "UIデザイン",
      "カラー",
      "アイコン",
      "フォント",
      "画像",
      "動画",
      "ベクター",
      "音声",
      "アニメーション",
      "データ",
      "その他",
    ],
  },
  ideaz: {
    backgroundColor: "#00E165",
    icon: <IdeazIcon />,
    eventTags: [
      "Tag-UX Design-Ideaz-J",
      "Tag-UI Design-Ideaz-J",
      "Tag-Website-Ideaz-J",
      "Tag-App-Ideaz-J",
      "Tag-Icon-Ideaz-J",
      "Tag-Font-Ideaz-J",
      "Tag-Mockup-Ideaz-J",
      "Tag-Branding-Ideaz-J",
      "Tag-Vector-Ideaz-J",
      "Tag-Animation-Ideaz-J",
      "Tag-Art-Ideaz-J",
      "Tag-Other-Ideaz-J",
    ],
    tags: [
      "UXデザイン",
      "UIデザイン",
      "ウェブ",
      "アプリ",
      "アイコン",
      "ベクター",
      "フォント",
      "ブランド",
      "アニメーション",
      "アート",
      "その他",
    ],
  },
  uxers: {
    backgroundColor: "#FFA300",
    icon: <UxersIcon />,
  },
  signup: {
    backgroundColor: "#FF6956",
  },
  profile: {
    backgroundColor: "#FF6956",
  },
  chat: {
    backgroundColor: "#FF6A56",
  },
};
