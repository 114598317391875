import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import React from "react";

function ActionMenu({
  wrapperType = "default",
  showLeftBar,
  setShowBarMobile,
  children,
  className,
  selectedMenu,
  currentPage,
}) {
  return (
    <>
      <div
        className={`action-menu action-menu-${wrapperType} ${
          showLeftBar ? "show-mobile " : "md:!hidden"
        } ${className ? className : ""} ${
          currentPage === "chat" ? "!p-0 border-orange" : ""
        } ${
          currentPage === "settings" ? "md:!relative action-menu-settings" : ""
        }`}
      >
        {currentPage === "chat" || currentPage == "settings" ? null : (
          <div className="close-btn" onClick={setShowBarMobile}>
            <CloseIcon className="" />
          </div>
        )}

        {children}
      </div>
    </>
  );
}

export default ActionMenu;
