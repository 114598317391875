import React from "react";
import { useSelector } from "react-redux";


function ExtraIcon({
  title,
  children,
  className = "",
  notification = false,
  ...otherProps
}) {

  const lang = useSelector((state) => state.lang);

  return (
    
    <div className={`extra-icon ${className}`} {...otherProps}>
       {children}
       <span className="extra-title" data-lang={lang}>{title}</span>

    </div>
  );
}

export default ExtraIcon;
