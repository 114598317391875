import axios from "axios";
import { BACKEND_URL } from "../config";

const token = localStorage.getItem("token");

// log in
export const login = async (email, password, lang) => {
  const res = await axios.post(`${BACKEND_URL}/auth/login`, {
    email,
    password,
    lang,
  });
  await localStorage.setItem("token", JSON.stringify(res.data.myToken));

  return res;
};

// forget password
export const forgetPassword = async (email, lang) => {
  const res = await axios.post(`${BACKEND_URL}/profile/forget-password`, {
    email,
    lang,
  });
  return res;
};

// create New password
export const createNewPassword = async (values, id, lang) => {
  const res = await axios.post(`${BACKEND_URL}/auth/${id}/reset-password`, {
    ...values,
    lang,
  });

  if (res) {
    localStorage.setItem("token", JSON.stringify(res.data.myToken));
    return res;
  }

  return false;
};

// get info
export const getUserInfo = async (user_id) => {
  const res = await axios.get(`${BACKEND_URL}/profile/${user_id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return res;
};

// signup
export const signup = async (data, lang, image) => {
  const reqData = {
    image: image,
    username: data.username,
    fullName: data.fullName,
    signup_request_id: data.signup_request_id,
    nationality: data.nationality,
    intro: data.intro,
    email: data.email,
    password: data.password,
    personalSite: data.personalSite,
    personalBlog: data.personalBlog,
    lang: lang,
    sub_role: data.subRole,
    experience: !data.experience ? 0 : data.experience,
    mediaLinks: [
      {
        type: "facebook",
        link: data.facebook,
      },
      {
        type: "instagram",
        link: data.instagram,
      },
      {
        type: "linkedin",
        link: data.linkedin,
      },
      {
        type: "dribbble",
        link: data.dribbble,
      },
      {
        type: "behance",
        link: data.behance,
      },
      {
        type: "threads",
        link: data.threads,
      },
      {
        type: "youtube",
        link: data.youtube,
      },
      {
        type: "figma",
        link: data.figma,
      },
      {
        type: "tiktok",
        link: data.tiktok,
      },
      {
        type: "x",
        link: data.x,
      },
    ],
  };
  const response = await axios.post(
    `${BACKEND_URL}/auth/register`,
    reqData,
    {}
  );
  if (response) {
    localStorage.setItem("token", JSON.stringify(response.data.myToken));
    return response;
  } else {
    return "error";
  }
};
