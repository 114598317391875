import React from 'react';
import "../../styles/main.scss";
import PropTypes from 'prop-types';

const GridItem = ({ text, child, icon: IconComponent, className, onClick }) => {
  const combinedClassName = `grid-item ${className || ''}`;

  return (
    <div className={combinedClassName} onClick={onClick}>
      {IconComponent && <IconComponent className="icon" />}
      {text && <span>{text}</span>}
      {child}
    </div>
  );
};

GridItem.propTypes = {
  text: PropTypes.string,
  child: PropTypes.element,
  icon: PropTypes.object, // Icon prop as a component
  className: PropTypes.string,
  onClick: PropTypes.func, // onClick prop
};

export default GridItem;
