import axios from "axios";
import { BACKEND_URL } from "../config";
const token = localStorage.getItem("token");

export const getChatInfo = async (userId) => {
  try {
    return await axios.post(
      `${BACKEND_URL}/chats/`,
      { userId },
      {
        headers: {
          Authorization: `Bearer ${token.slice(1, -1)}`,
        },
      }
    ).data;
  } catch (error) {
    console.error("my_error", error);
    return "error";
  }
};

export const fetchChats = async () => {
  try {
    return await axios.get(`${BACKEND_URL}/chats/`, {
      headers: {
        Authorization: `Bearer ${token.slice(1, -1)}`,
      },
    });
  } catch (error) {
    console.error("my_error", error);
    return "error";
  }
};

export const createConverssation = async (user_id) => {
  return await axios.post(
    `${BACKEND_URL}/chats`,
    {
      userId: user_id,
    },
    {
      headers: {
        Authorization: `Bearer ${token.slice(1, -1)}`,
      },
    }
  );
};

// archive toggle
export const toggleArchive = async (chatId, archive) => {
  console.log("archive", archive);
  if (archive) {
    return await axios.post(
      `${BACKEND_URL}/chats/disarchive-chat/${chatId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token.slice(1, -1)}`,
        },
      }
    );
  } else {
    return await axios.post(
      `${BACKEND_URL}/chats/archive-chat/${chatId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token.slice(1, -1)}`,
        },
      }
    );
  }
};

// delete chat
export const deleteChat = async (chatId) => {
  return await axios.post(
    `${BACKEND_URL}/chats/delete-chat/${chatId}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token.slice(1, -1)}`,
      },
    }
  );
};

// toggle block
export const toggleBlock = async (user_id, value) => {
  if (value) {
    return await axios.post(
      `${BACKEND_URL}/chats/unblock/${user_id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token.slice(1, -1)}`,
        },
      }
    );
  } else {
    return await axios.post(
      `${BACKEND_URL}/chats/block/${user_id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token.slice(1, -1)}`,
        },
      }
    );
  }
};

export const report = async (user_id, value) => {
  return await axios.post(
    `${BACKEND_URL}/chats/report/${user_id}`,
    {
      message: value,
    },
    {
      headers: {
        Authorization: `Bearer ${token.slice(1, -1)}`,
      },
    }
  );
};
