import { NewsLetterPopup } from "../../components/molecules/NewsLetterPopup";
import "../../styles/events.scss";
import EventContent from "./EventContent";
import HeaderCompo from "./HeaderEvent";
import InfoEvent from "./InfoEvent";
import JoinForm from "./JoinForm";
import StaticPageSubscribe from "../../components/organismes/StaticPageSubscribe";
import EventNewLetterComp from "./EventNewLetter";
import CustomIcon from "../../compo/IconCompo";

function NoEvent() {
  return (
    <>
      <HeaderCompo />

      <div className="event_hero">

          <div className="event_content_hero_form shorter noevent">
          <CustomIcon iconName="soon" className="illus" />
            <h5>FUTURE EVENT <span> NOT FOUND!</span></h5>
            <EventContent
              text="最新ニュースや今後のイベント情報をお届けします。メールアドレスを入力して、ニュースレターにご登録ください。"
            >
              <EventNewLetterComp link="https://script.google.com/macros/s/AKfycbwImvO_Bf4wFEC5XrNrRyEysfK40vienH8Fq1K60FuvkclahIRn96FIuctrHP5wEcfr2A/exec" />
            </EventContent>
         
        </div>
      <div className="footer"></div>

      </div>

    </>
  );
}

export default NoEvent;
