import { useDispatch, useSelector } from "react-redux";
import "../styles/main.scss";
import { useEffect, useState } from "react";
import React from "react";
import Layout from "../components/layout/Layout";
import StaticPageTitle from "../components/atoms/StaticPageTitle";
import CustomIcon from "../compo/IconCompo";
import StaticPageParagraph from "../components/organismes/StaticPageParagraph";

import BlogCard from "../components/organismes/BlogCard";

function Blog({}) {
  useEffect(() => {
    document.title = "UUD - Blog";
    return () => {
      document.title = "Blog";
    };
  }, []);

  const user = useSelector((state) => state.user);
  const [showRightBar, setShowRightBar] = useState(false);
  const [showLeftBar, setShowLeftBar] = useState(false);
  const lang = useSelector((state) => state.lang);

  return (
    <Layout
      id="blog"
      selectedMenu={""}
      currentPage="blog"
      isLogin={user !== "" && user}
      setShowLeftBar={(res) => setShowLeftBar(res)}
      setShowRightBar={(res) => setShowRightBar(res)}
      showRightBar={showRightBar}
      showLeftBar={showLeftBar}
      actionMenu={false}
    >
      <div className="static-page-hero">
        <div className="static-page">
          <StaticPageTitle
            icon={<CustomIcon iconName="blog_b" />}
            text={"BLOG"}
          />
          <div className="blog_cards_box">
            <BlogCard
              imageUrl="blog_img_02.png"
              category="UX Design"
              title="A Beginner’s Guide to the 10 Most Popular Programming Languages"
              authorImage="author_01.png"
              authorName="Hafid"
              date="Nov 13, 2023"
              link="https://example.com"
            />

            <BlogCard
              imageUrl="blog_img_03.png"
              category="UX Design"
              title="A Beginner’s Guide to the 10 Most!"
              authorImage="author_01.png"
              authorName="Hafid"
              date="Nov 13, 2023"
              link="https://example.com"
            />

            <BlogCard
              imageUrl="blog_img_02.png"
              category="UX Design"
              title="A Beginner’s Guide to the!"
              authorImage="author_01.png"
              authorName="Hafid"
              date="Nov 13, 2023"
              link="https://example.com"
            />

            <BlogCard
              imageUrl="blog_img_02.png"
              category="UX Design"
              title="A Beginner’s Guide to the 10 Most Popular Programming Languages, a looog title goes here"
              authorImage="author_01.png"
              authorName="Hafid"
              date="Nov 13, 2023"
              link="https://example.com"
            />
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Blog;
