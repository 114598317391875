import { useDispatch, useSelector } from "react-redux";
import RoundImage from "../atoms/RoundImage";
import Popup from "../molecules/Popup";
import Button from "../atoms/Button";
import DashboardTable from "../organismes/DashboardTable";
import CategoryItems from "../molecules/dashboard/categoryItems";
import UxerCard from "../organismes/uxerCard";
import { ReactComponent as DefaultProfile } from "../../assets/icons/default_profile.svg";
import ItemCard from "../organismes/ItemCard";
import { ReactComponent as Hum2 } from "../../assets/icons/hum2.svg";
import Input from "../atoms/Input";
// import { fetchImage } from "../../services/itemsServices";
import "../../styles/dashboard.scss";
import { ReactComponent as Logo } from "../../assets/icons/logo.svg";
import Flag from "../molecules/Flag";
import { ReactComponent as MailIcon } from "../../assets/icons/mail.svg";
import { ReactComponent as ClearIcon } from "../../assets/icons/clear.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import { ReactComponent as LoginIcon } from "../../assets/icons/login.svg";
import { ReactComponent as Caution } from "../../assets/icons/caution.svg";
import { ReactComponent as Hand2 } from "../../assets/icons/hand2.svg";
import { ReactComponent as UxersIcon } from "../../assets/icons/uxer_2_on.svg";
import { ReactComponent as User } from "../../assets/icons/namecard.svg";
import { ReactComponent as UserCard} from "../../assets/icons/name_card.svg";
import { ReactComponent as SearchIcon } from "../../assets/icons/search.svg";
import { ReactComponent as OrderAZ } from "../../assets/icons/order_az.svg";
import { ReactComponent as OrderZA } from "../../assets/icons/order_za.svg";
import { ReactComponent as ViewLinkIcon } from "../../assets/icons/view_link.svg";
import { ReactComponent as TrashIcon } from "../../assets/icons/trash.svg";
import { ReactComponent as Services } from "../../assets/icons/services.svg";
import { ReactComponent as EditIcon } from "../../assets/icons/edit.svg";
import { ReactComponent as AddProjectIcon } from "../../assets/icons/add_project.svg";
import { ReactComponent as AddIcon } from "../../assets/icons/add_1.svg";
import { ReactComponent as Download } from "../../assets/icons/download.svg";
import { ReactComponent as Flags } from "../../assets/icons/flag.svg";
import { ReactComponent as Fav } from "../../assets/icons/fav_line.svg";
import { ReactComponent as Cal } from "../../assets/icons/cal.svg";
import { ReactComponent as Click } from "../../assets/icons/hand4.svg";

import { ReactComponent as IdIcon } from "../../assets/icons/id_icon.svg";
import { ReactComponent as NamecardIcon } from "../../assets/icons/namecard.svg";
import { ReactComponent as BagIcon } from "../../assets/icons/bag.svg";
import { ReactComponent as Ok } from "../../assets/icons/ok.svg";
import { ReactComponent as Setting } from "../../assets/icons/setting.svg";
import { ReactComponent as UserIcon } from "../../assets/icons/users2.svg";
import InfoPopupContainer from "../organismes/popup/InfoPopupContainer";
import InfoPopup from "../organismes/popup/InfoPopup";
import { ReactComponent as ToolsIcon } from "../../assets/icons/tool.svg";
import { ReactComponent as ResosIcon } from "../../assets/icons/resos.svg";
import { ReactComponent as IdeazIcon } from "../../assets/icons/idea.svg";
import {
  addItem,
  addManyItems,
  deleteUser,
  editItem,
  uploadManyImage,
  getPendingUxers,
  acceptUxer,
  rejectUxer,
  getAllUsers,
} from "../../services/AdminService";
import { getUserInfo } from "../../services/authServices";
import { useEffect, useMemo, useRef, useState } from "react";
import ItemImage from "../molecules/ItemImage";
import DropDown from "../atoms/DropDown";
import AddItemDashboardPopup from "../molecules/AddItemDashboardPopup";
import DashboardLayout from "../layout/DashboardLayout";
import PopupContainer from "../molecules/PopupContainer";
import Loading from "../atoms/Loading";
import axios from "axios";
import { BACKEND_URL } from "../../config";

function DashboardUsers({}) {
  const user = useSelector((state) => state.user);
  const [showRightBar, setShowRightBar] = useState(false);
  const [showLeftBar, setShowLeftBar] = useState(false);
  const [sortConfig, setSortConfig] = useState(null);
  const [data, setData] = useState(null);
  const [images, setImages] = useState({});
  const [filterText, setFilterText] = useState("");
  const [filterCategory, setFilterCategory] = useState("all");
  const [openAddData, setOpenAddData] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const inputFileRef = useRef();
  const lang = useSelector((state) => state.lang);
  const inputFileImagesRef = useRef();
  const parentPopupRef = useRef();
  const [infoPopup, setInfoPopup] = useState([]);
  const [uxerDelete, setUxerDelete] = useState(false);
  const [uxerRequest, setUxerRequest] = useState(false);
  const [pendingUxers, setPendingUxers] = useState(false);
  const [popMessage, setPopMessage] = useState("");
  const [popIcon, setPopIcon] = useState("");
  const [popType, setPopType] = useState("");
  const [IsShowPopup, settIsShowPopup] = useState(false);
  const [loading, setLoading] = useState(false);

  const defaultUserFavs = {
    toolsItem: 0,
    resosItem: 0,
    ideazItem: 0,
  };
  const [userFavs, setUserFavs] = useState(defaultUserFavs);

  const [uxerPopup, setUxerPopup] = useState(false);

  const handleDeleteItem = (id) => {
    deleteUser(id).then((val) => {
      setConfirmDelete(false);
      if (val.data) {
        getData();
        setConfirmDelete(false);
        popups("item deleted successfully", "success", <Ok />);
      }
    });
  };
  function isPending(username) {
    let x = false;
    pendingUxers.map((el) => {
      if (el.username === username) {
        x = true;
      }
    });
    return x;
  }
  function popups(message, type, icon) {
    settIsShowPopup(true);
    setPopType(type);
    setPopIcon(icon);
    setPopMessage(message);
    setTimeout(() => {
      settIsShowPopup(false);
    }, 4000);
  }
  const openNewInfoPopup = (type, value) => {
    const idTime = new Date().getTime();

    if (type === "success") {
      setInfoPopup([
        ...infoPopup,
        {
          color: "#FFFFFF",
          bgColor: "#00E165",
          title: value,
          id: idTime,
        },
      ]);
    } else if (type === "error") {
      setInfoPopup([
        ...infoPopup,
        {
          color: "#FFFFFF",
          bgColor: "#FF6A56",
          title: value,
          id: idTime,
        },
      ]);
    }

    setTimeout(() => {
      setInfoPopup(infoPopup.filter((x) => x.id !== idTime));
    }, 2000);
  };

  const handleAddItem = (data, image) => {
    addItem(data, image).then((val) => {
      getData();
    });
  };

  const handleAddImages = (event) => {
    const filesObject = Object.values(event.target.files).filter(
      (x) => x.type === "image/svg+xml" || x.type === "image/svg"
    );

    if (!filesObject) return;

    event.target.value = null;

    var myFiles = {};

    const filesPromises = filesObject.map((file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsBinaryString(file);

        reader.onload = function (event) {
          // handle reader success
          myFiles[file.name] = `data:${file.type};base64,${btoa(
            event.target.result
          )}`;
          resolve();
        };

        reader.onerror = function () {
          console.log("couldn't read the file");
          reject();
        };
      });
    });

    Promise.all(filesPromises).then(() => {
      uploadManyImage(myFiles)
        .then((val) => {
          openNewInfoPopup("success", "Image added successfully");
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const handleUpdateItem = (data, image) => {
    editItem(data, image).then((val) => {
      if (val.messageError) {
        openNewInfoPopup("error", "Item not update");
        return;
      }

      openNewInfoPopup("success", "Item update successfully");
      getData();
    });
  };

  const convertToLocale = (utcDateTime) => {
   try {
    const date = new Date(utcDateTime + 'Z');
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
      timeZone: userTimeZone,
    };
  
    const formatter = new Intl.DateTimeFormat('en-US', options);
    const [
      { value: day },
      ,
      { value: month },
      ,
      { value: year },
      ,
      { value: hour },
      ,
      { value: minute },
    ] = formatter.formatToParts(date);
  
    return `${month}.${day}.${year} ${hour}:${minute}`;
   } catch (error) {
    return '00.00.0000 00:00'
    
   }
  };

  const parseDateString = (dateString) => {
    dateString = convertToLocale(dateString);
    const [day, month, year, time] = dateString.split(/\.|\s/);
    const [hours, minutes] = time.split(":");
    const formattedDate = new Date(
      `${year}-${month}-${day}T${hours}:${minutes}:00`
    );
    return formattedDate.getTime();
  };

  const rolePriority = {
    "Customer Service": 14,
    Director: 13,
    Engineer: 12,
    Entrepreneur: 11,
    Executive: 10,
    "Human Resource": 9,
    Manager: 8,
    Marketer: 7,
    Others: 6,
    "Product Designer": 5,
    Sales: 4,
    Student: 3,
    "UI Designer": 12,
    "UX Designer": 1,
  };

  const sortedItems = useMemo(() => {
    let sortableItems = Array.isArray(data) ? [...data] : [];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (
          sortConfig.key === "category_id" &&
          sortConfig.key in a &&
          sortConfig.key in b
        ) {
          if (
            a[sortConfig.key].length > 0 &&
            b[sortConfig.key].length > 0 &&
            a[sortConfig.key][0]._id < b[sortConfig.key][0]._id
          ) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
          if (
            a[sortConfig.key].length > 0 &&
            b[sortConfig.key].length > 0 &&
            a[sortConfig.key][0]._id > b[sortConfig.key][0]._id
          ) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
        }
        // For last access sorting
        else if (sortConfig.key === "last_access") {
          if (!a.last_access && !b.last_access) {
            return 0;
          } else if (!a.last_access) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          } else if (!b.last_access) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          } else {
            const aDate = parseDateString(a.last_access);
            const bDate = parseDateString(b.last_access);
            return sortConfig.direction === "ascending"
              ? bDate - aDate
              : aDate - bDate;
          }
        }
        // For joined date
        else if (sortConfig.key === "joined_date") {
          if (!a.joinedDate && !b.joinedDate) {
            return 0;
          } else if (!a.joinedDate) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          } else if (!b.joinedDate) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          } else {
            const aDate = parseDateString(a.joinedDate);
            const bDate = parseDateString(b.joinedDate);
            return sortConfig.direction === "ascending"
              ? bDate - aDate
              : aDate - bDate;
          }
        }
        // For role sorting
        else if (sortConfig.key === "role") {
          const roleA = a["sub_role"];
          const roleB = b["sub_role"];
          const priorityA = rolePriority[roleA] || Number.MAX_SAFE_INTEGER;
          const priorityB = rolePriority[roleB] || Number.MAX_SAFE_INTEGER;

          if (priorityA === priorityB) {
            return 0;
          }
          return sortConfig.direction === "ascending"
            ? priorityA - priorityB
            : priorityB - priorityA;
        }

        // general sorting
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }

        return 0;
      });
    }
    return sortableItems;
  }, [data, sortConfig]);

  // function fetchImages(image, index) {
  //   fetchImage("profile", image)
  //     .then((result) => {
  //       if (result != undefined) {
  //         setImages((prev) => {
  //           return { ...prev, [`${index}`]: result };
  //         });
  //       }
  //     })
  //     .catch((err) => {
  //       return false;
  //     });
  // }

  const requestSort = (key) => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const handleChangeResearch = (e) => {
    const value = e.target.value;
    setFilterText(value);
  };

  const handleAddFile = (event) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }

    if (fileObj.type !== "text/csv") {
      return;
    }

    console.log("fileObj is", fileObj);

    event.target.value = null;

    addManyItems(fileObj)
      .then((res) => {
        openNewInfoPopup("success", res.data);
      })
      .catch((error) => {
        console.log(error);
        openNewInfoPopup("error", error.message);
      });
  };

  const getData = () => {
    setLoading(true);
    getAllUsers()
      .then((val) => {
        if (val.data) {
          setData(val.data);
          setLoading(false);
          // val.data.map((el, index) => {
          //   fetchImages(el.image, el.username);
          // });
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const getDataForInterval = () => {
    getAllUsers()
      .then((val) => {
        if (val.data) {
          setData(val.data);
          // val.data.map((el, index) => {
          //   fetchImages(el.image, el.username);
          // });
        }
      })
      .catch(() => {});
  };

  const addNewItem = () => {
    setOpenAddData(
      <AddItemDashboardPopup
        onClose={() => setOpenAddData(false)}
        card={{}}
        onSave={(data, image) => handleAddItem(data, image)}
      />
    );
  };
  useEffect(() => {
    getData();
    const interval = setInterval(getDataForInterval, 30000);
    return () => clearInterval(interval);
  }, []);
  const modifyItem = (data) => {
    setOpenAddData(
      <AddItemDashboardPopup
        onClose={() => setOpenAddData(false)}
        card={{
          ...data,
          engDescription:
            typeof data.description === "object"
              ? data.description[0]
              : data.description,
          jpDescription:
            typeof data.description === "object" ? data.description[1] : "",
        }}
        onSave={(data, image) => handleUpdateItem(data, image)}
      />
    );
  };
  function handleRequest(id, type) {
    setUserFavs(defaultUserFavs);
    getUserInfo(id)
      .then((res) => {
        axios.get(`${BACKEND_URL}/profile/favs/${id}`).then((res2) => {
          const favs = {
            toolsItem: res2.data.myTools.length,
            resosItem: res2.data.myResos.length,
            ideazItem: res2.data.myIdeaz.length,
          };
          setUserFavs(favs);
        });
        if (res.data.myUser) {
          res.data = res.data.myUser;
        }

        setUxerPopup({ ...res.data, id: id });
        if (type == 1) {
          setUxerDelete(true);
        } else {
          setUxerRequest(true);
        }
      })
      .catch((err) => {});
  }
  useEffect(() => {
    setPendingUxers([]);
    // getPendingUxers()
    //   .then((res) => {
    //     if (res.data.status) {
    //       setPendingUxers(res.data.data);
    //     } else {
    //       setPendingUxers([]);
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  }, []);
  
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <div className="dashboard-filter-items" ref={parentPopupRef}>
            <div className="dashboard-button-group">
              <Button
                rightIcon={<Download className="icon" />}
                className="primary-green"
                onClick={() => inputFileRef.current.click()}
              >
                CSV
              </Button>
            </div>

            <div className="dashboard-button-group">
              {" "}
              <DropDown
                leftIcon={null}
                rightIcon={<Hum2 className="dashboard-select-right-icon" />}
                placeHolder={
                  <div className="dashboard-select-placeholder-cont">
                    ALL
                    <span className="dashbaord-select-indicator">
                      {data?.length}
                    </span>
                  </div>
                }
                defaultValue={filterCategory}
                onChange={(value) => setFilterCategory(value)}
                options={[
                  {
                    label: (
                      <div className="dashboard-select-option-cont">
                        <span>ALL</span>{" "}
                        <span className="dashbaord-select-indicator">
                          {data?.length}
                        </span>
                      </div>
                    ),
                    value: "all",
                  },
                  {
                    label: (
                      <div className="dashboard-select-option-cont">
                        <span>Product Designer</span>{" "}
                        <span className="dashbaord-select-indicator">
                          {
                            data?.filter(
                              (item) =>
                                item?.sub_role.toLowerCase() ===
                                "Product Designer".toLowerCase()
                            ).length
                          }
                        </span>
                      </div>
                    ),
                    value: "Product Designer",
                  },
                  {
                    label: (
                      <div className="dashboard-select-option-cont">
                        <span>UX Designer</span>{" "}
                        <span className="dashbaord-select-indicator">
                          {
                            data?.filter(
                              (item) =>
                                item?.sub_role.toLowerCase() ===
                                "UX Designer".toLowerCase()
                            ).length
                          }
                        </span>
                      </div>
                    ),
                    value: "UX Designer",
                  },
                  {
                    label: (
                      <div className="dashboard-select-option-cont">
                        <span>UI Designer</span>{" "}
                        <span className="dashbaord-select-indicator">
                          {
                            data?.filter(
                              (item) =>
                                item?.sub_role.toLowerCase() ===
                                "UI Designer".toLowerCase()
                            ).length
                          }
                        </span>
                      </div>
                    ),
                    value: "UI Designer",
                  },
                  {
                    label: (
                      <div className="dashboard-select-option-cont">
                        <span>Engineer</span>{" "}
                        <span className="dashbaord-select-indicator">
                          {
                            data?.filter(
                              (item) =>
                                item?.sub_role.toLowerCase() ===
                                "Engineer".toLowerCase()
                            ).length
                          }
                        </span>
                      </div>
                    ),
                    value: "Engineer",
                  },
                  {
                    label: (
                      <div className="dashboard-select-option-cont">
                        <span>Manager</span>{" "}
                        <span className="dashbaord-select-indicator">
                          {
                            data?.filter(
                              (item) =>
                                item?.sub_role.toLowerCase() ===
                                "Manager".toLowerCase()
                            ).length
                          }
                        </span>
                      </div>
                    ),
                    value: "Manager",
                  },
                  {
                    label: (
                      <div className="dashboard-select-option-cont">
                        <span>Director</span>{" "}
                        <span className="dashbaord-select-indicator">
                          {
                            data?.filter(
                              (item) =>
                                item?.sub_role.toLowerCase() ===
                                "Director".toLowerCase()
                            ).length
                          }
                        </span>
                      </div>
                    ),
                    value: "Director",
                  },
                  {
                    label: (
                      <div className="dashboard-select-option-cont">
                        <span>Executive</span>{" "}
                        <span className="dashbaord-select-indicator">
                          {
                            data?.filter(
                              (item) =>
                                item?.sub_role.toLowerCase() ===
                                "Executive".toLowerCase()
                            ).length
                          }
                        </span>
                      </div>
                    ),
                    value: "Executive",
                  },
                  {
                    label: (
                      <div className="dashboard-select-option-cont">
                        <span>Entrepreneur</span>{" "}
                        <span className="dashbaord-select-indicator">
                          {
                            data?.filter(
                              (item) =>
                                item?.sub_role.toLowerCase() ===
                                "Entrepreneur".toLowerCase()
                            ).length
                          }
                        </span>
                      </div>
                    ),
                    value: "Entrepreneur",
                  },
                  {
                    label: (
                      <div className="dashboard-select-option-cont">
                        <span>Human Resource</span>{" "}
                        <span className="dashbaord-select-indicator">
                          {
                            data?.filter(
                              (item) =>
                                item?.sub_role.toLowerCase() ===
                                "Human Resource".toLowerCase()
                            ).length
                          }
                        </span>
                      </div>
                    ),
                    value: "Human Resource",
                  },
                  {
                    label: (
                      <div className="dashboard-select-option-cont">
                        <span>Sales</span>{" "}
                        <span className="dashbaord-select-indicator">
                          {
                            data?.filter(
                              (item) =>
                                item?.sub_role.toLowerCase() ===
                                "Sales".toLowerCase()
                            ).length
                          }
                        </span>
                      </div>
                    ),
                    value: "Sales",
                  },
                  {
                    label: (
                      <div className="dashboard-select-option-cont">
                        <span>Marketer</span>{" "}
                        <span className="dashbaord-select-indicator">
                          {
                            data?.filter(
                              (item) =>
                                item?.sub_role.toLowerCase() ===
                                "Marketer".toLowerCase()
                            ).length
                          }
                        </span>
                      </div>
                    ),
                    value: "Marketer",
                  },
                  {
                    label: (
                      <div className="dashboard-select-option-cont">
                        <span>Customer Service</span>{" "}
                        <span className="dashbaord-select-indicator">
                          {
                            data?.filter(
                              (item) =>
                                item?.sub_role.toLowerCase() ===
                                "Customer Service".toLowerCase()
                            ).length
                          }
                        </span>
                      </div>
                    ),
                    value: "Customer Service",
                  },
                  {
                    label: (
                      <div className="dashboard-select-option-cont">
                        <span>Student</span>{" "}
                        <span className="dashbaord-select-indicator">
                          {
                            data?.filter(
                              (item) =>
                                item?.sub_role.toLowerCase() ===
                                "Student".toLowerCase()
                            ).length
                          }
                        </span>
                      </div>
                    ),
                    value: "Student",
                  },
                  {
                    label: (
                      <div className="dashboard-select-option-cont">
                        <span>Others</span>{" "}
                        <span className="dashbaord-select-indicator">
                          {
                            data?.filter(
                              (item) =>
                                item?.sub_role.toLowerCase() ===
                                "Others".toLowerCase()
                            ).length
                          }
                        </span>
                      </div>
                    ),
                    value: "Others",
                  },
                ]}
              />
              <Input
                leftIcon={<SearchIcon />}
                rightIcon={
                  filterText ? (
                    <div className="flex">
                      {" "}
                      <span className="dashboard-search-number">
                        {
                          sortedItems.filter(
                            (item) =>
                              item?.fullName
                                ?.toLowerCase()
                                .includes(filterText?.toLowerCase()) ||
                              item?.nationality
                                ?.toLowerCase()
                                .includes(filterText.toLowerCase()) ||
                              item?.username
                                ?.toLowerCase()
                                .includes(filterText.toLowerCase()) ||
                              item?.last_access?.includes(filterText) ||
                              item?.image
                                ?.toLowerCase()
                                .includes(filterText.toLowerCase()) ||
                              item.sub_role
                                .toLowerCase()
                                .includes(filterText.toLowerCase()) ||
                              item.intro
                                .toLowerCase()
                                .includes(filterText.toLowerCase())
                          ).length
                        }
                      </span>
                      <ClearIcon
                        className="dashboard-clear-icon"
                        onClick={() => {
                          setFilterText("");
                        }}
                      />
                    </div>
                  ) : null
                }
                className="dashboard-search"
                IconClickEvent={(e) => {}}
                rotateIcon={true}
                placeHolder={"Search"}
                value={filterText}
                primaryColor={"Orange"}
                onChange={handleChangeResearch}
              />
            </div>
            <input
              style={{ display: "none" }}
              type="file"
              name="csvFile"
              ref={inputFileRef}
              onChange={handleAddFile}
            />
          </div>

          <DashboardTable
            className="dashboard-table"
            theadClassName="users-thead"
            header={[
              {
                label: <UserCard className="dashboard-table-header-icon" />,
                align: "center",
                rightIcon:
                  sortConfig?.key === "category_id" ? (
                    sortConfig.direction === "descending" ? (
                      <OrderZA />
                    ) : (
                      <OrderAZ />
                    )
                  ) : (
                    <OrderAZ />
                  ),
                sort: () => requestSort("category_id"),
              },
              {
                label: <Flags className="dashboard-table-header-icon" />,
                align: "center",
                rightIcon:
                  sortConfig?.key === "nationality" ? (
                    sortConfig.direction === "descending" ? (
                      <OrderZA />
                    ) : (
                      <OrderAZ />
                    )
                  ) : (
                    <OrderAZ />
                  ),
                sort: () => requestSort("nationality"),
              },
              {
                label: (
                  <div className="dashboard-table-header-items">
                    <UserIcon className="dashboard-table-header-icon" />{" "}
                  </div>
                ),
                align: "center",

                rightIcon: null,

                sort: null,
              },
              {
                label: (
                  <div className="dashboard-table-header-items">
                    <NamecardIcon className="dashboard-table-header-icon" />{" "}
                    <p>Name</p>
                  </div>
                ),
                align: "left",
                rightIcon:
                  sortConfig?.key === "fullName" ? (
                    sortConfig.direction === "descending" ? (
                      <OrderZA />
                    ) : (
                      <OrderAZ />
                    )
                  ) : (
                    <OrderAZ />
                  ),
                sort: () => requestSort("fullName"),
              },
              {
                label: (
                  <div className="dashboard-table-header-items">
                    <IdIcon className="dashboard-table-header-icon" />{" "}
                    <p>Username</p>
                  </div>
                ),
                align: "left",
                rightIcon:
                  sortConfig?.key === "username" ? (
                    sortConfig.direction === "descending" ? (
                      <OrderZA />
                    ) : (
                      <OrderAZ />
                    )
                  ) : (
                    <OrderAZ />
                  ),
                sort: () => requestSort("username"),
              },
              {
                label: (
                  <div className="dashboard-table-header-items">
                    <BagIcon className="dashboard-table-header-icon" />{" "}
                    <p>role</p>
                  </div>
                ),
                align: "left",
                rightIcon:
                  sortConfig?.key === "role" ? (
                    sortConfig.direction === "descending" ? (
                      <OrderZA />
                    ) : (
                      <OrderAZ />
                    )
                  ) : (
                    <OrderAZ />
                  ),
                sort: () => requestSort("role"),
              },
              {
                label: (
                  <div className="dashboard-table-header-items">
                    <Fav className="dashboard-table-header-icon" />
                  </div>
                ),
                align: "right",
                rightIcon:
                  sortConfig?.key === "favs" ? (
                    sortConfig.direction === "descending" ? (
                      <OrderZA />
                    ) : (
                      <OrderAZ />
                    )
                  ) : (
                    <OrderAZ />
                  ),
                sort: () => requestSort("favs"),
              },
              {
                label: (
                  <div className="dashboard-table-header-items">
                    <Click className="dashboard-table-header-icon" />{" "}
                  </div>
                ),
                align: "right",
                rightIcon:
                  sortConfig?.key === "clicks" ? (
                    sortConfig.direction === "descending" ? (
                      <OrderZA />
                    ) : (
                      <OrderAZ />
                    )
                  ) : (
                    <OrderAZ />
                  ),
                sort: () => requestSort("clicks"),
              },
              {
                label: (
                  <div className="dashboard-table-header-items">
                    <Cal className="dashboard-table-header-icon" />{" "}
                    <p className="th-label-text">Joined Date</p>
                  </div>
                ),
                align: "left",
                rightIcon:
                  sortConfig?.key === "joined_date" ? (
                    sortConfig.direction === "descending" ? (
                      <OrderZA />
                    ) : (
                      <OrderAZ />
                    )
                  ) : (
                    <OrderAZ />
                  ),
                sort: () => requestSort("joined_date"),
              },
              {
                label: (
                  <div className="dashboard-table-header-items">
                    <Cal className="dashboard-table-header-icon" />{" "}
                    <p className="th-label-text">Last Access</p>
                  </div>
                ),
                align: "left",
                rightIcon:
                  sortConfig?.key === "last_access" ? (
                    sortConfig.direction === "descending" ? (
                      <OrderZA />
                    ) : (
                      <OrderAZ />
                    )
                  ) : (
                    <OrderAZ />
                  ),
                sort: () => requestSort("last_access"),
              },
              {
                label: <Setting className="dashboard-table-header-icon" />,
                align: "center",

                sort: () => requestSort("updatedAt"),
              },
            ]}
            values={
              sortedItems?.length > 0
                ? sortedItems
                    .filter(
                      (item) =>
                        item?.fullName
                          ?.toLowerCase()
                          .includes(filterText?.toLowerCase()) ||
                        item?.nationality
                          ?.toLowerCase()
                          .includes(filterText.toLowerCase()) ||
                        item?.username
                          .toLowerCase()
                          .includes(filterText.toLowerCase()) ||
                        item?.last_access?.includes(filterText) ||
                        item?.image
                          ?.toLowerCase()
                          .includes(filterText.toLowerCase()) ||
                        item.sub_role
                          .toLowerCase()
                          .includes(filterText.toLowerCase()) ||
                        item?.intro
                          .toLowerCase()
                          .includes(filterText.toLowerCase())
                    )
                    .filter((item) =>
                      filterCategory === "all"
                        ? true
                        : item?.sub_role === filterCategory
                        ? true
                        : false
                    )
                    .map((item, index) => {
                      // category={item?.category_id?.length > 0 ? item.category_id[0].name : "")}
                      return [
                        <div className="uxer-button-cont">
                          {item?.uxer ? (
                            !isPending(item?.username) ? (
                              <UxersIcon
                                className="uxer-button"
                                onClick={() => {
                                  handleRequest(item._id, 1);
                                }}
                              />
                            ) : (
                              <div
                                className="uxer-request-button-cont "
                                onClick={() => {
                                  handleRequest(item._id, 2);
                                }}
                              >
                                <Hand2 className="uxer-request-button" />
                              </div>
                            )
                          ) : null}
                        </div>,

                        <div className="item-image-table">
                          <Flag
                            code={item.nationality.toLowerCase()}
                            height="18"
                            className="dropdown-select-inner__flag"
                            fallback={<span>- - -</span>}
                          />
                        </div>,
                        <div className="item-image-table">
                          <RoundImage
                            className="item-avatar-table"
                            imageFull={true}
                            imageData={item.image}
                            size={"!h-11 !w-11"}
                            noImage={
                              <DefaultProfile
                                data-value="redirect"
                                className="default-item-photo"
                              />
                            }
                            alt="Cropped Avatar"
                            grey="grey-border"
                          />
                        </div>,
                        item?.fullName,
                        <a
                          className="dashboard-uxer-username"
                          href={`/${item?.username}`}
                          data-lang={lang}
                        >
                          {item?.username}
                        </a>,
                        item?.sub_role,
                        <span className="table-number"> {item?.favs} </span>,
                        <span className="table-number"> {item?.clicks} </span>,
                        <span className="table-date-time">
                          {" "}
                          { convertToLocale(item?.joinedDate) }{" "}
                          {/* {item?.last_access}{" "} */}
                        </span>,
                         <span className="table-date-time">
                         {" "}
                         { convertToLocale(item?.last_access) }{" "}
                       </span>,

                        <div className="action-icons">
                          <TrashIcon
                            onClick={() => setConfirmDelete(item?._id)}
                          />
                        </div>,
                      ];
                    })
                : []
            }
          />
        </>
      )}

      {confirmDelete && (
        <PopupContainer title={"Confiramtion!"} logo={<Logo />}>
          <div className="w-full">
            <p className="dashboard-delete-info-1">
              Do you really want to remove this user definitely?
            </p>
            <div className="user-form-body-buttons">
              <Button
                rightIcon={<CloseIcon />}
                onClick={() => {
                  setConfirmDelete(false);
                }}
                className="dashboard-delete-button-1 "
                data-lang={lang}
              >
                {lang === "en" ? "Cancel" : "キャンセル"}
              </Button>

              <Button
                rightIcon={<LoginIcon />}
                className="dashboard-delete-button-2 "
                onClick={() => handleDeleteItem(confirmDelete)}
                data-lang={lang}
              >
                {lang === "en" ? "Delete " : "サインアップ"}
              </Button>
            </div>
          </div>
        </PopupContainer>
      )}
      {uxerDelete && (
        <PopupContainer
          cardClass="user_card_view_popup_box"
          windowClass="uxer-request-window"
          innerClass="user_card_view_box"
          onClose={() => {
            setUxerDelete(false);
          }}
        >
          <div className="uxer-request-card-cont">
            <UxerCard
            classNameCard={"uxer_card_view"}
              id={uxerPopup._id}
              profileImage={uxerPopup.profile_img}
              username={uxerPopup.username}
              fullName={uxerPopup.fullName}
              experience={uxerPopup.experience}
              subRole={uxerPopup.sub_role}
              intro={uxerPopup.intro}
              personalSite={uxerPopup.personalSite}
              personalBlog={uxerPopup.personalBlog}
              mediaLinks={uxerPopup.mediaLinks?.map((ele) => ele?.link)}
              national={uxerPopup.nationality}
              contact={uxerPopup.newMessageEmail}
            />
          </div>

          <div className="user_card_view_email_box">
            <MailIcon />
            <p>{uxerPopup.email}</p>
          </div>

          <div className="user_card_view_clicks_box">
            <div className="clicks_box">
              <ToolsIcon />
              <span> {userFavs?.toolsItem} </span>
            </div>

            <div className="clicks_box">
              <ResosIcon />
              <span> {userFavs?.resosItem} </span>
            </div>

            <div className="clicks_box">
              <IdeazIcon />
              <span> {userFavs?.ideazItem} </span>
            </div>
          </div>

          {/* <div className="uxer-request-button-cont-2">
            <Button
              rightIcon={<CloseIcon />}
              onClick={() => {
                rejectUxer(uxerPopup.id).then((res) => {
                  if (res.data) {
                    popups("User removed successfully", "success", <Ok />);
                    setUxerDelete(false);
                    getData();
                  }
                });
              }}
              className=" "
              data-lang={lang}
            >
              {lang === "en" ? "Delete" : "キャンセル"}
            </Button>
          </div> */}
        </PopupContainer>
      )}
      {uxerRequest && (
        <PopupContainer
          cardClass="user_card_view_popup_box"
          windowClass="uxer-request-window"
          innerClass="user_card_view_box"
          onClose={() => {
            setUxerRequest(false);
          }}
        >
          <div className="uxer-request-card-cont">
            <UxerCard
              id={uxerPopup._id}
              profileImage={uxerPopup.profile_img}
              username={uxerPopup.username}
              fullName={uxerPopup.fullName}
              experience={uxerPopup.experience}
              subRole={uxerPopup.sub_role}
              intro={uxerPopup.intro}
              personalSite={uxerPopup.personalSite}
              personalBlog={uxerPopup.personalBlog}
              mediaLinks={uxerPopup.mediaLinks?.map((ele) => ele?.link)}
              national={uxerPopup.nationality}
              contact={uxerPopup.newMessageEmail}
            />
            <div className="flex items-center mt-4 flex-col gap-3">
              <p className="uxer-request-position">{uxerPopup.sub_role}</p>
              <div className="uxer-request-tag-cont">
                <p className="uxer-request-tag">Animator</p>
                <p className="uxer-request-tag">Animator</p>
                <p className="uxer-request-tag">Animator</p>
                <p className="uxer-request-tag">Animator</p>
              </div>
            </div>
          </div>
          <div className="uxer-request-button-cont-3">
            <Button
              rightIcon={<CloseIcon />}
              onClick={() => {
                rejectUxer(uxerPopup.id).then((res) => {
                  if (res.data) {
                    popups("Request rejected successfully", "success", <Ok />);
                    setUxerRequest(false);
                    getData();
                  }
                });
              }}
              className="dashboard-delete-button-1"
              data-lang={lang}
            >
              {lang === "en" ? "Decline" : "キャンセル"}
            </Button>
            <Button
              rightIcon={<CloseIcon />}
              onClick={() => {
                acceptUxer(uxerPopup.id).then((res) => {
                  if (res.data) {
                    popups("Request approved successfully", "success", <Ok />);
                    setUxerRequest(false);
                    getData();
                  }
                });
              }}
              className=" "
              data-lang={lang}
            >
              {lang === "en" ? "Approve" : "キャンセル"}
            </Button>
          </div>
        </PopupContainer>
      )}
      {confirmDelete && (
        <PopupContainer title={"Confiramtion!"} logo={<Logo />}>
          <div className="w-full">
            <p className="dashboard-delete-info-1">
              Do you really want to remove this user definitely?
            </p>
            <div className="user-form-body-buttons">
              <Button
                rightIcon={<CloseIcon />}
                onClick={() => {
                  setConfirmDelete(false);
                }}
                className="dashboard-delete-button-1 "
                data-lang={lang}
              >
                {lang === "en" ? "Cancel" : "キャンセル"}
              </Button>

              <Button
                rightIcon={<LoginIcon />}
                className="dashboard-delete-button-2 "
                onClick={() => handleDeleteItem(confirmDelete)}
                data-lang={lang}
              >
                {lang === "en" ? "Delete " : "サインアップ"}
              </Button>
            </div>
          </div>
        </PopupContainer>
      )}
      {IsShowPopup ? (
        <Popup
          message={popMessage}
          type={popType}
          icon={popIcon}
          closePopup={() => settIsShowPopup(false)}
        />
      ) : null}
    </>
  );
}

export default DashboardUsers;
