import { NewsLetterPopup } from "../../components/molecules/NewsLetterPopup";
import "../../styles/events.scss";
import EventContent from "./EventContent";
import HeaderCompo from "./HeaderEvent";
import InfoEvent from "./InfoEvent";
import JoinForm from "./JoinForm";
import StaticPageSubscribe from "../../components/organismes/StaticPageSubscribe";
import EventNewLetterComp from "./EventNewLetter";
import CustomIcon from "../../compo/IconCompo";



function EventTemp() {
  return (
    <>
      <HeaderCompo />
      <picture className={"big_img_box"}>
        <source media="(max-width: 700px)" srcset={"event14-08-2024-2.png"} />
        <img src={"event14-08-2024-1.png"} />
      </picture>

      <div className="event_hero">
        <InfoEvent
          date="14"
          month="SEP"
          year="2024"
          day="SATURDAY"
          time="13:00~15:00"
          place="高田馬場"
          city="TOKYO"
          price="FREE"
          title="デザインシステムの謎を解く!<br/>より深い理解のためのインサイト"
          tags={[
            "Design System",
            "UI Design",
            "Figma",
            "Beginner",
            "Advanced",
            "Tips & Tricks",
          ]}
          buttonText="参加する"
          buttonIcon="invitation"
          imgSrc1Small="event14-08-2024-2.png"
          imgSrc1Large="event14-08-2024-1.png"
        />
      </div>


      <div className="event_hero">
        <EventContent
          title="INTRO"
          text="
                このワークショップは、デザイナー、開発者、プロダクトマネージャーを対象に、デザインシステムの包括的な理解を深めることを目的としています。セッションでは、基礎的な定義から、応用的なヒントやベストプラクティスまで、幅広く取り扱います。<br/><br/>
                デザインシステムとは何か、その種類やレベル、成功するために必要なステップについて学び、効果的なデザインシステムを構築・維持するための実践的な洞察を得られます。また、デザインシステムを強化するための有益なヒントや、プロセスを支援するツールやリソースもご紹介します。<br/>
                このイベントでは、デザインシステムの複雑な側面を明確にし、簡素化することで、より深い理解を得るための貴重なインサイトを提供します。<br/><br/>
                デザイナーであるかどうかにかかわらず、このイベントはデザインシステムに対する理解を深め、新たな視点を提供します。プロジェクトにおけるデザインシステムの作成、管理、スケールを向上させるための貴重なインサイトを得る機会です。<br/>
                スキルを高め、同じ志を持つ専門家とのネットワーキング広げるこの機会をお見逃しなく。皆様のご参加をお待ちしております。
          "
        />

        <EventContent
          title="DATE & TIME"
          listItems={[
            {
              label: "日時：",
              value: "2023年9月14日 土曜日 / 13:00~15:00 (休憩10分)",
            },
            {
              label: "場所：",
              value: "高田馬場駅 - 徒歩6分 (OFFLINEイベント)",
            },
            {
              label: "参加対象者：",
              value:
                "デザイナー、開発者、プロダクトマネージャーなどのUXUIデザインに興味のある全ての方",
            },
            {
              label: "費用：",
              value:
                "デザイナー、開発者、プロダクトマネージャーなどのUXUIデザインに興味のある全ての方",
            },
          ]}
        />
        <EventContent
          title="TIMELINE"
          customClass="event_content_hero"
          listItems={[
            {
              label: "13:00 -",
              value: "高田馬場駅 - 徒歩6分 (OFFLINEイベント)",
            },
            { label: "13:05 -", value: "受付・自己紹介・イベントの流れ説明" },
            { label: "13:20 -", value: "デザインシステムの基本概念の紹介" },
            { label: "13:35 -", value: "デザインシステムの種類とレベルの説明" },
            { label: "14:00 -", value: "デザインシステムとアトミックデザイン" },
            { label: "14:10 -", value: "休憩" },
            {
              label: "14:20 -",
              value: "デザインシステム作成のステップ（ヒントとコツ）",
            },
            { label: "14:20 -", value: "役立つリソース、情報、ツールの紹介" },
            { label: "14:45 -", value: "まとめと質疑応答" },
            { label: "15:00 -", value: "イベント終了" },
          ]}
        />
        <EventContent
          title="SPEAKER"
          customClass="event_content_hero speaker"
          text="
          UXUIの専門家であり、JUUSANDO!でプロダクトデザイナー(UXUIデザイン)としても豊富な経験を持つスピーカーが登壇します。<br/>
          デザイン学校での講師経験が豊富であり、法人向けの研修も10年以上にわたって行っています。<br/>
          また、2015年にはUX&UIデザイナーズ(UUD)コミュニティを設立し、日本における海外標準のUI&UXデザイナーの育成にも力を注いでいます。
          "
        >
          <div className="speaker_hero">
            <div className="speaker_img">
              <CustomIcon iconName="hafid" />
            </div>
            <div className="speaker_name">
              はふぃど<span>Product Designer</span>
            </div>
            <div className="speaker_sns_box">
              <a onClick={() => window.open("https://uud.io/hafid", "_blank")}>
                <CustomIcon iconName="uud_icon2" />
              </a>
              <a onClick={()  => window.open( "https://figma.com/@hafido", "_blank")}>
                <CustomIcon iconName="figma" />
              </a>
              <a onClick={() => window.open( "https://dibbble.com/juusando", "_blank")}>
                <CustomIcon iconName="dribbble_line" />
              </a>
              <a onClick={()  => window.open( "https://x.com/hafidbourhim", "_blank")}>
                <CustomIcon iconName="twetter_line" />
              </a>
              <a onClick={()  => window.open("https://juusando.com", "_blank")}>
                <CustomIcon iconName="web_line" />
              </a>
            </div>
          </div>

        </EventContent>

        {/* <picture className={"big_event_img_box"}>
        <source media="(max-width: 700px)" srcset={"about-img02.png"} />
        <img src={"about-img01.png"} />
      </picture> */}

        <div className="forms_hero">
          <div className="event_content_hero_form" id="joinus">
            <EventContent title="JOIN US" customClass="event_content_hero" >
              <JoinForm />
            </EventContent>
          </div>

          <div className="event_content_hero_form shorter">
            <EventContent
              title="KEEP IN TOUCH"
              text="このワークショップは、デザイナー、開発者、プロダクトマネージャーを対象に"
            >
              <EventNewLetterComp link="https://script.google.com/macros/s/AKfycbwImvO_Bf4wFEC5XrNrRyEysfK40vienH8Fq1K60FuvkclahIRn96FIuctrHP5wEcfr2A/exec" />
            </EventContent>
          </div>
        </div>

        <EventContent title="PARTNERS & SPONSOR">
          <div className="partner_hero">
          <a onClick={() => window.open("https://juusando.com", "_blank")}>
          <CustomIcon iconName="juusando_spo" />
            </a>
            <a onClick={() => window.open("https://uud.io", "_blank")}>
            <CustomIcon iconName="uud_spo" />
            </a>
            <a onClick={() => window.open("https://pinjob.me", "_blank")}>
            <CustomIcon iconName="pinjob_spo" />
            </a>
          </div>
        </EventContent>
      </div>



      <div className="footer"></div>
    </>
  );
}

export default EventTemp;
