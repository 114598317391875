import { Buffer } from "buffer";
import axios from "axios";
import { BACKEND_URL } from "../config";

const TOOLS_URL = `${BACKEND_URL}/items/filter/tools/64761102ba22fed734b9de7a`;
const RESOS_URL = `${BACKEND_URL}/items/filter/resos-ideaz/64761222ba22fed734b9de7d`;
const IDEAZ_URL = `${BACKEND_URL}/items/filter/resos-ideaz/64761240ba22fed734b9de81`;

const token = localStorage.getItem("token");

export const likeItem = async (id_item, categorie) => {
  let url = `${BACKEND_URL}/items/${id_item}`;

  if (categorie === "Tools") {
    url += "/64761102ba22fed734b9de7a";
  } else if (categorie === "Resos") {
    url += "/64761222ba22fed734b9de7d";
  } else {
    url += "/64761240ba22fed734b9de81";
  }

  const res = await axios.post(
    url,
    {},
    {
      headers: {
        Authorization: `Bearer ${token.slice(1, -1)}`,
      },
    }
  );

  return res;
};

export const getUxers = async () => {
  const res = await axios.get(`${BACKEND_URL}/uxers/filter`, {
    params: {
      limit: 1000,
    },
  });

  return res.data;
};

export const getNumberItems = async () => {
  const res1 = await axios.get(TOOLS_URL);
  const res2 = await axios.get(RESOS_URL);
  const res3 = await axios.get(IDEAZ_URL);

  return {
    Tools: res1.data.totalItems,
    Resos: res2.data.totalItems,
    Ideaz: res3.data.totalItems,
  };
};

export const getItems = async ({
  category = "Tools",
  tags = "",
  search = "",
  platform = [],
  price = [],
  page = 1,
}) => {
  if (!["Tools", "Resos", "Ideaz"].includes(category)) {
    return { noData: true };
  }
  if (tags === "Favs") {
    const res = await axios.get(`${BACKEND_URL}/profile/my-favs`, {
      headers: {
        Authorization: `Bearer ${token.slice(1, -1)}`,
      },
    });

    if (category === "Tools") {
      return {
        totalItems: res.data.myTools.length,
        items: res.data.myTools,
        page: page,
      };
    }

    if (category === "Resos") {
      return {
        totalItems: res.data.myResos.length,
        items: res.data.myResos,
        page: page,
      };
    }

    if (category === "Ideaz") {
      return {
        totalItems: res.data.myIdeaz.length,
        items: res.data.myIdeaz,
        page: page,
      };
    }
  } else {
    const url =
      category === "Tools"
        ? TOOLS_URL
        : category === "Resos"
        ? RESOS_URL
        : IDEAZ_URL;

    const res = await axios.get(url, {
      params: {
        tag: tags,
        search: search,
        platform: platform.join(","),
        price: price.join(","),
        limit: 60,
        page: page,
      },
    });

    return { ...res.data, page: page };
  }
};

export const getItemImage = async (item_id) => {
  const res = await axios.get(`${BACKEND_URL}/items/images/${item_id}`, {
    responseType: "arraybuffer",
    responseEncoding: "base64",
  });
  const buffer = Buffer.from(res.data, "base64");
  let binary = "";
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return btoa(binary);
};

export const getNumberOfItems = async (category) => {
  if (!["Tools", "Resos", "Ideaz"].includes(category)) {
    return { noData: true };
  }

  const url =
    category === "Tools"
      ? TOOLS_URL
      : category === "Resos"
      ? RESOS_URL
      : IDEAZ_URL;

  const res = await axios.get(url);

  return res.data?.totalItems;
};

export const clickItem = async (item_id) => {
  return await axios.post(
    `${BACKEND_URL}/items/click-item/${item_id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token.slice(1, -1)}`,
      },
    }
  );
};
