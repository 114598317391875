import React, { useState, useEffect } from 'react';
import '../../styles/dashboard.scss'; // External CSS file for styling

const DashboardDateCompo = () => {
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const [userCity, setUserCity] = useState('Loading...');

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000); // Update every second

    const fetchUserLocation = async () => {
      try {
        const response = await fetch('https://ipapi.co/json/');
        if (!response.ok) {
          throw new Error('Failed to fetch');
        }
        const data = await response.json();
        setUserCity(data.city || 'Unknown');
      } catch (error) {
        console.error('Error fetching location:', error);
        setUserCity('Unknown');
      }
    };

    fetchUserLocation();

    return () => clearInterval(interval);
  }, []);

  const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const monthsOfYear = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ];

  const year = currentDateTime.getFullYear();
  const month = monthsOfYear[currentDateTime.getMonth()];
  const day = daysOfWeek[currentDateTime.getDay()];
  const dayNumber = currentDateTime.getDate();
  const hour = currentDateTime.getHours().toString().padStart(2, '0');
  const minute = currentDateTime.getMinutes().toString().padStart(2, '0'); 
  // const second = currentDateTime.getSeconds().toString().padStart(2, '0');

  return (

    <div className="date_time_container">
    <div className="year_month_box">
    <div className="day_number">{dayNumber}</div>
      <div className="month">{month}</div>
    </div>

    <div className="day_hour_box">
    <div className="year">{year}</div>
      <div className="day">{day}</div>
      <div className="hour">{hour}:{minute}</div>
    </div>
  </div>

  );
};

export default DashboardDateCompo;
