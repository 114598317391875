import { ReactComponent as UUDIcon } from "../../assets/icons/uud.svg";
import { ReactComponent as BetaIcon } from "../../assets/icons/beta.svg";
import { ReactComponent as LogoTypeIcon } from "../../assets/icons/logo_type.svg";
import React from "react";

function HomeIcon({ ...props }) {
  return (
    <div className="home-icon" {...props}>
      <UUDIcon className="uud" />
      <LogoTypeIcon className="logoType" />
      <BetaIcon className="betaIcon" />
    </div>
  );
}

export default HomeIcon;
