import { useSelector } from "react-redux";
import "../styles/main.scss";
import { ReactComponent as ServiceIcon } from "../assets/icons/services_bb.svg";
import React from "react";
import Layout from "../components/layout/Layout";
import StaticPageTitle from "../components/atoms/StaticPageTitle";
import StaticPageArticle from "../components/organismes/StaticPageArticle";
import StaticPageParagraph from "../components/organismes/StaticPageParagraph";
import { useEffect, useState } from "react";



function Servicez({}) {

  useEffect(() => {
    document.title = "UUD - Services";
    return () => {
      document.title = "UUD";
    };
  }, []);


  const user = useSelector((state) => state.user);
  const [showRightBar, setShowRightBar] = useState(false);
  const [showLeftBar, setShowLeftBar] = useState(false);
  const lang = useSelector((state) => state.lang);


  return (
    <Layout
      id="servicez"
      selectedMenu={""}
      currentPage="servicez"
      isLogin={user !== "" && user}
      setShowLeftBar={(res) => setShowLeftBar(res)}
      setShowRightBar={(res) => setShowRightBar(res)}
      showRightBar={showRightBar}
      showLeftBar={showLeftBar}
      actionMenu={false}
    >
      <div className="static-page-hero">
        <div className="static-page">
          <StaticPageTitle
            // icon={<Resos />}
            icon={<ServiceIcon />}
            text={"UUD SERVICES"}
          />
          <StaticPageParagraph
            title=""
            text=
            {<div className="paragraph_static_page" data-lang={lang}>

              {lang === "en" ? (<>
                Welcome to our suite of services tailored exclusively for UX/UI designers. Currently, we offer a selection of essential tools and services, meticulously designed to cater to the unique needs and aspirations of UX/UI designers. 
                <br /><br />
                Our mission is simple: provide UX/UI designers with essential tools, resources, and support, empowering you to enhance your skills, simplify your tasks, and achieve success in every aspect of your professional journey. 
                <br /><br />
                Below are the services we offer for now, but there is more on the way. We will keep doing regular updates and add new ones.             
                 </>) : (
                <>
                UX/UIデザイナーのために特別にあつらえられたサービスへようこそ。<br />
                現在、私たちはUX/UIデザイナーのユニークなニーズと志向に応える、厳選されたツールとサービスを提供しています。
                <br /><br />
                私たちの使命はシンプルです、UX/UIデザイナーに必要なツール、リソース、サポートを提供し、スキルを向上させ、タスクを簡素化し、プロフェッショナルが成功を収める為の道のりをあらゆる面からサポートすることです。
                <br /><br />
                以下に、現在提供しているサービスがありますが、これに加えてさらに新しいものを用意しています。<br />
                定期的な更新と新しいサービスの追加を行い続けます。                
                </>)}

            </div>}
          />

          {/* <StaticPageParagraph
            title={lang === "en" ? (<>Our Main Services & Features </>) : (<>当社の主なサービスと特長 </>)}
            text=
            {<div className="paragraph_static_page" data-lang={lang}>

              {lang === "en" ? (
              <>
                Dive into our extensive directory featuring over 2000 meticulously curated links across three categories – TOOLS, RESOS (Resources), and IDEAZ (Inspiration).<br />
                  This collection is meticulously crafted to fuel your creativity and enhance your workflow, providing a source of endless ideas and innovation. 
              </>) : (
                <>
                  初回のローンチでは、以下のサービスと特長を提供することを楽しみにしており、近い将来にはオファリングを拡大する予定です。
                </>)}

            </div>}
          /> */}

          <StaticPageArticle
            className="services-box"
            title={lang === "en" ? (<>Resources </>) : (<>リソース</>)}
            imgSrc1Small={"resos-img01.png"}
            imgSrc1Large={"resos-img01.png"}

            text={
              <div className="paragraph_static_page" data-lang={lang}>

                {lang === "en" ? (
                <>
                  Explore our extensive directory featuring over 2000 meticulously curated links across three categories.
                  This collection is designed to fuel your creativity and enhance your workflow, providing an endless source of ideas and innovation.
                  <br /><br />
                  <span className="bold">TOOLS: </span>  Discover a wide range of software and tools tailored to assist you as UXUI designers.
                  <br />
                  <span className="bold">RESOS: </span>  Short for [Resource + SOS] this section is here to HELP you by providing materials, assets, and more, whenever you need them.
                  <br /> 
                  <span className="bold">IDEAZ: </span>  Whenever you're seeking inspiration or an idea, you can find what you're looking for.
                  <br /> 
                   {/* <span className="bold">FAVS (Bookmarks): </span> Save your frequently used resource links effortlessly, simplifying your routine and enhancing productivity.                 */}
                </>
                ) : (
                  <>
                <>
                3つのカテゴリにまたがる厳選された2000以上のリンクを特集した広範なディレクトリ。このコレクションは、あなたの創造性を刺激し、ワークフローを向上させることを目的に、アイデアと革新の源となります。                 
                <br /><br />
                  <span className="bold">TOOLS: </span>  あなたをサポートするためにカスタマイズされた、さまざまなソフトウェアやツールが見つかります。これらは間違いなくUXUIデザイナーの頼もしいアシスタントとなります。
                  <br />
                  <span className="bold">RESOS: </span>  [リソース + SOS]を組み合わせた造語で、必要なときに必要なものを提供するこのセクションは、いつでもあなたをサポートするためにあります。
                  <br /> 
                  <span className="bold">IDEAZ: </span>  インスピレーションやアイデアを求めるとき、ここを開いてください。きっと探し物が見つかるでしょう！
                  <br /> 
                   {/* <span className="bold">FAVS (Bookmarks): </span> お気に入りのブックマーク / 頻繁に利用するリソースのリンクを手軽に保存し、よりシンプルなルーチンにすることで生産性を向上させましょう。 */}
                </>
                  </>)}
              </div>
            }
          />
          <StaticPageArticle
            className="services-box"
            imgSrc1Small={"favs-img01.png"}
            imgSrc1Large={"favs-img01.png"}
            title={lang === "en" ? (<>Favs (Bookmarks) </>) : (<>Favs (お気に入り) </>)}
            text={
              <div className="paragraph_static_page" data-lang={lang}>

                {lang === "en" ? (<>
                  Save your favorite links effortlessly. Your frequently used resources are just a click away, streamlining your routine and boosting productivity.                </>) : (
                  <>
                    お気に入りのブックマーク / 頻繁に利用するリソースのリンクを手軽に保存し、よりシンプルなルーチンにすることで生産性を向上させましょう。
                  </>)}
              </div>
            }
          />

          <StaticPageArticle
            className="services-box"
            imgSrc1Small={"mypage-img01.png"}
            imgSrc1Large={"mypage-img01.png"}
            title={lang === "en" ? (<>My Page </>) : (<>マイページ</>)}
            text={
              <div className="paragraph_static_page" data-lang={lang}>

                {lang === "en" ? (<>
                  our centralized hub for all your favorite links, intelligently organized into categories, ensures effortless resource retrieval, streamlining your routine and supercharging your productivity with ease.
                  <br /> 
                  Plus, you can share your favorite resources using your unique page link. 
                  Think of it as your own Linktree – a page to share your information, social media, and personal links.
                   {/* fostering collaboration and knowledge exchange among designers.                */}
                   </>) : (
                  <>
                  カテゴリーごとに明確に整理された、あなたのブックマークを集めたあなた専用のページです。
                  <br /> 
                  それだけでなく、お気に入りのリソースを独自のリンクを使って共有できます。<br /> 
                  あなた自身のLinktreeのように、情報やSNS、個人のリンクを共有するページとして使えます。
                  {/* デザイナーたちのコラボレーションと知識の交換を促進します。                   */}
                  </>)}


              </div>
            }
          />
          {/* <StaticPageArticle
            className="services-box"
            imgSrc1Small={"uxers-img01.png"}
            imgSrc1Large={"uxers-img01.png"}
            title="UXERS"
            text={
              <div className="paragraph_static_page" data-lang={lang}>

                {lang === "en" ? (<>
                  UXERS is a global directory featuring UX/UI designers of all levels.<br />
                  It's a place where you can connect, follow, collaborate, and build friendships with fellow UX/UI designers.
                  <br />
                  Expand your professional network, explore new opportunities, get inspired, and find mentors easily.
                   </>) : (
                  <>
                  UXERSは、さまざまなレベルのUX/UIデザイナーを紹介するインターナショナルなディレクトリです。ここでは、仲間のUX/UIデザイナーと簡単に繋がったり、フォローしたり、協力したり、友情を築いたりすることができます。
                  <br />
                  プロのネットワークを広げ、新しいチャンスを探し、インスピレーションを受けたり、メンターを簡単に見つけることができます。<br />
                  </>)}
              </div>
            }/> */}
          
                  <br /><br />
          <StaticPageParagraph
            title={lang === "en" ? (<>Upcoming Services </>) : (<>今後のサービス </>)}
            text=
            {<div>
              <div className="paragraph_static_page" data-lang={lang}>

                {lang === "en" ? (<>
                  In addition to our current offerings, we are actively working on expanding our services and enhancing existing ones with exciting new features. Here's a sneak peek at what's in the pipeline:
                  <br /><br />
                  <span className="bold">Job Board: </span> A dedicated space exclusively for UX/UI Designers to discover job opportunities tailored to their skills and expertise.<br />
                  <span className="bold">Messaging Feature:  </span>  Users will be able to communicate directly within the platform, fostering collaboration and interaction among our community members.<br />
                  <span className="bold">UX/UI Designers Interviews: </span> Gain valuable insights from interviews with UX/UI designers of all levels, offering unique perspectives, experience, and knowledge.<br />
                  <span className="bold">Expanded Resource Directory: </span>We will add more directories such as UX/UI design-related mobile apps, books, blogs, vlogs, and much more.<br />
                  Stay tuned for these exciting additions that will further enrich your experience on our platform!               
                   </>) : (
                  <>
                <>
                  現在提供中のサービスに加えて、新しいエキサイティングな機能を追加し、既存のサービスを拡充しています。<br />以下、今後の展開の一部をお見せします：
                  <br /><br /> 
                  <span className="bold">求人情報ボード: </span> UX/UIデザイナー専用の求人スペースで、彼らのスキルや専門知識に合わせた求人情報を探すことができます。<br />
                  <span className="bold">メッセージ機能:  </span>  ユーザーはプラットフォーム内で直接コミュニケーションを取ることができ、コミュニティメンバー間の協力と交流を促進します。<br />
                  <span className="bold">UX/UIデザイナーのインタビュー: </span> さまざまな経験レベルのUX/UIデザイナーによる貴重な気づきを得ることができ、独自の視点や知識が提供されます。<br />
                  <span className="bold">拡充されたリソースディレクトリ: </span>UX/UIデザインに関連するモバイルアプリ、書籍、ブログ、動画など、さまざまなディレクトリを追加します。<br />
                  今後の追加機能にご期待ください。プラットフォーム上での体験がさらに充実します！              
                   </>
                  </>)}

              </div>
              
            </div>}
          />


        </div>
      </div>
    </Layout>
  );
}

export default Servicez;
