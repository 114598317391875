import React from "react";

function StaticPageSlogan({ text }) {
  return (
    <div className="slogan-box">
      <h3>{text}</h3>
    </div>
  );
}

export default StaticPageSlogan;
