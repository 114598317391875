import React, { useEffect, useRef } from "react";
import { ReactComponent as Logo05 } from "../../assets/icons/logo_05.svg";

function LoadMoreItems({ loadMore }) {
  const loadMoreRef = useRef(null);

  useEffect(() => {
    let timeoutId;

    const handleIntersection = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          timeoutId = setTimeout(() => {
            loadMore();
          }, 1000);
          observer.unobserve(entry.target);
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection);
    if (loadMoreRef.current) {
      observer.observe(loadMoreRef.current);
    }

    // Cleanup
    return () => {
      if (loadMoreRef.current) {
        observer.unobserve(loadMoreRef.current);
      }
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  });

  return (
    <div className="load-more-items" ref={loadMoreRef}>
      <span className="load-more-title">Loading</span>
      <div className="load-more-animation">
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div className="load-more-logo">
        <Logo05 />
      </div>
    </div>
  );
}

export default LoadMoreItems;
