import PropTypes from 'prop-types';
import "../../styles/events.scss";
import CustomIcon from "../../compo/IconCompo";
import { useNavigate } from "react-router-dom";
import StaticPageBigImage from "../../components/organismes/StaticPageBigImage";
import pirsch from "../../Analytics";



function InfoEvent({
  date,
  month,
  year,
  day,
  time,
  place,
  city,
  priceLabel,
  price,
  title,
  tags,
  buttonText,
  buttonIcon,
  buttonLink,
  imgSrc1Small,
  imgSrc1Large
}) {

  const navigate = useNavigate();
  return (
    <div className="info_hero">
      <div className="date_box">
        <div className="date">
          {date}
          <div className="m_y">
            {month}
            <h5>{year}</h5>
          </div>
        </div>
        <div className="day_time">
          {day}
          <div className="time">{time}</div>
        </div>
        <div className="place">
          <span>
            {place}
            <h5>{city}</h5>
          </span>
          <div className="price">
            {priceLabel}
            <h5>{price}</h5>
          </div>
        </div>
      </div>

      <div className="title" dangerouslySetInnerHTML={{ __html: title }}></div>

      <div className="tags_box">
        {tags.map((tag, index) => (
          <span key={index}>{tag}</span>
        ))}
      </div>

        <div 
          onClick={() => {
            pirsch("Join Us - Anchor - BTN");
            const element = document.getElementById("joinus");
            if (element) {
              const topPosition = element.getBoundingClientRect().top + window.scrollY;
              window.scrollTo({
                top: topPosition - 30,
                behavior: "smooth",
             
              });
            } else {
              navigate("/events/event/joinus");
            }
          }}      
          className="join_btn"
            >
            <CustomIcon iconName={buttonIcon} /> {buttonText}
        </div>


      {/* <div className="image_box">
        <StaticPageBigImage imgSrc1Small={imgSrc1Small} imgSrc1Large={imgSrc1Large} />
      </div> */}
    </div>
  );
}

// Define default props
InfoEvent.defaultProps = {
  date: "01",
  month: "JAN",
  year: "2024",
  day: "MONDAY",
  time: "00:00~00:00",
  place: "Default Place",
  city: "Default City",
  priceLabel: "Price",
  price: "FREE",
  title: "Default Event Title",
  tags: ["Tag1", "Tag2"],
  buttonText: "Join Now",
  buttonIcon: "defaultIcon",
  buttonLink: "#",
  imgSrc1Small: "default-small.png",
  imgSrc1Large: "default-large.png",
};

// Define prop types
InfoEvent.propTypes = {
  date: PropTypes.string,
  month: PropTypes.string,
  year: PropTypes.string,
  day: PropTypes.string,
  time: PropTypes.string,
  place: PropTypes.string,
  city: PropTypes.string,
  priceLabel: PropTypes.string,
  price: PropTypes.string,
  title: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
  buttonText: PropTypes.string,
  buttonIcon: PropTypes.string,
  buttonLink: PropTypes.string,
  imgSrc1Small: PropTypes.string,
  imgSrc1Large: PropTypes.string,
};

export default InfoEvent;
