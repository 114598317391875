import { useDispatch, useSelector } from "react-redux";
import "../styles/main.scss";
import { useEffect, useState } from "react";
import React from "react";
import Layout from "../components/layout/Layout";
import StaticPageTitle from "../components/atoms/StaticPageTitle";
import CustomIcon from '../compo/IconCompo';
// import IconAnim from "../compo/HoverIcon"
// import ButtonTemp from "../compo/ButtonTemp";


function News({}) {
  
  useEffect(() => {
    document.title = "UUD - News";
    return () => {
      document.title = "UUD";
    };
  }, []);


  const user = useSelector((state) => state.user);
  const [showRightBar, setShowRightBar] = useState(false);
  const [showLeftBar, setShowLeftBar] = useState(false);

  return (
    <Layout
      id="news"
      selectedMenu={""}
      currentPage="news"
      isLogin={user !== "" && user}
      setShowLeftBar={(res) => setShowLeftBar(res)}
      setShowRightBar={(res) => setShowRightBar(res)}
      showRightBar={showRightBar}
      showLeftBar={showLeftBar}
      actionMenu={false}
    >
      <div className="static-page-hero">
        <div className="static-page">
          <StaticPageTitle 
          icon= {<CustomIcon iconName="newz" />}
          text={"NEWS"}
           />
           
        </div>
      </div>
    </Layout>
  );
}

export default News;
