import "../../styles/events.scss";
import StaticPageBigImage from "../../components/organismes/StaticPageBigImage";
import Input from "../../components/atoms/Input";
import CustomIcon from "../../compo/IconCompo";
import { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import RadioBoxGroup from "../../components/molecules/RadioBoxGroup";
import { ReactComponent as MailIcon } from "../../assets/icons/mail.svg";
import { ReactComponent as Loading } from "../../assets/icons/loading.svg";
import { ReactComponent as CautionIcon } from "../../assets/icons/caution.svg";
import { ReactComponent as Send } from "../../assets/icons/send.svg";
import "../../styles/main.scss";
import { ReactComponent as Subject } from "../../assets/icons/subject.svg";
import { ReactComponent as Check } from "../../assets/icons/check.svg";
import Popup from "../../components/molecules/Popup";
import { ReactComponent as Mail2 } from "../../assets/icons/mail_fill.svg";
import { ReactComponent as Phone } from "../../assets/icons/phone.svg";
import CheckBox from "../../components/atoms/CheckBox";
import PopupContainer from "../../components/molecules/PopupContainer";
import { ReactComponent as Logo } from "../../assets/icons/logo_05.svg";
import { ReactComponent as UserNameIcon } from "../../assets/icons/namecard.svg";
import { ReactComponent as Account } from "../../assets/icons/account.svg";
import { ReactComponent as Robot } from "../../assets/icons/robot.svg";
import { ReactComponent as Human } from "../../assets/icons/human.svg";
import { ReactComponent as Company } from "../../assets/icons/company.svg";
import Select from "../../components/atoms/Select";
import pirsch from "../../Analytics";

function JoinForm({
  title,
  text,
  imgSrc1Small,
  imgSrc1Large,
  customClass = "",
  listItems = [],
}) {
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});
  const lang = useSelector((state) => state.lang);
  const [popMessage, setPopMessage] = useState("");
  const [popIcon, setPopIcon] = useState("");
  const [popType, setPopType] = useState("");
  const [IsShowPopup, settIsShowPopup] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const handleChange = async (element, dataP) => {
    const newData = { ...data, [`${element}`]: dataP };
    setData(newData);
  };
  const rolesOptions1 = [
    { value: "UXUI Designer - Senior", label: "UXUI Designer - Senior" },
    { value: "UXUI Designer - Junior", label: "UXUI Designer - Junior" },
    { value: "UI Designer - Senior", label: "UI Designer - Senior" },
    { value: "UI Designer - Junior", label: "UI Designer - Junior" },
    { value: "Designer", label: "Designer" },
    { value: "Developer", label: "Developer" },
    { value: "Manager", label: "Manager" },
    { value: "Student", label: "Student" },
    { value: "その他 - IT業界", label: "その他 - IT業界" },
    { value: "その他 - IT業界意外", label: "その他 - IT業界意外" },
  ];
  function popups(message, type, icon) {
    settIsShowPopup(true);
    setPopType(type);
    setPopIcon(icon);
    setPopMessage(message);

    setTimeout(() => {
      settIsShowPopup(false);
    }, 6000);
  }
  const handleSubmit = (e) => {
    e.preventDefault();

    let errors = validateForm(data);
    console.log(errors);
    if (Object.keys(errors).length === 0) {
      const japanDate = new Date();

      // Convert date to Japan's timezone
      const date = new Date(
        japanDate.toLocaleString("en-US", { timeZone: "Asia/Tokyo" })
      );

      const formData = new FormData();

      formData.append("email", data.email ? data.email : "- - - -");
      formData.append("company", data.company ? data.company : "- - - -");
      formData.append("phone", data.phone ? data.phone : "- - - -");
      formData.append("state", data.state ? data.state : "- - - -");
      formData.append("service", data.service ? data.service : "- - - -");
      formData.append("name", data.fullName ? data.fullName : "- - - -");
      formData.append(
        "date",
        `${date.getDate()}/${
          date.getMonth() + 1
        }/${date.getFullYear()} - ${date.getHours()}:${
          date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()
        }:${
          date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds()
        }`
      );
      formData.append("timezone", "Asia/Tokyo");

      popups("送信中...", "info", <Loading />);
      axios
        .post(
          "https://script.google.com/macros/s/AKfycbwxfnC7jYFGoRh9dcL5KK8E-NTeTG9m7_vn-Rl-BuFlZpRvxsheA0UiBnZyejstbFVz/exec",
          formData
        )

        .then((response) => {
          settIsShowPopup(false);
          if (response.data.result === "success") {
            setOpenSuccess(true);
            setData({});
          } else {
            popups("Error occurred", "error", <CautionIcon />);
          }
        })
        .catch((error) => console.error("Error!", error.message));
    } else {
      if (Object.keys(errors).length > 1) {
        popups(
          lang === "en"
            ? "Please fill out the required fields"
            : "必須項目を入力してください。",
          "error",
          <CautionIcon />
        );
        console.log(errors);
      } else {
        Object.keys(errors).map(() => {
          popups(Object.values(errors)[0], "error", <CautionIcon />);
        });
      }
    }
  };
  const validateForm = (data) => {
    const errors = {};

    if (!data.email) {
      errors.email = "Email is required";
    } else if (!isValidEmail(data.email)) {
      errors.email = "Invalid email address";
    }

    if (!data.fullName) {
      errors.fullName = "FullName is required";
    }

    if (!data.state) {
      errors.state = "Radio is required";
    }
    if (!data.service) {
      errors.service = "Service is required";
    }

    if (!data.company) {
      errors.company = "Company is required";
    }

    setErrors(errors);
    return errors;
  };

  const isValidEmail = (email) => {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    );
  };

  return (
    <>
      <div className={"join_form_hero"} id="joinus">
        <div className="text">
          このワークショップは、デザイナー、開発者、プロダクトマネージャーを対象に、デザインシステムの包括的な理解を深めることを目的としています。
        </div>

        <div className="join_form_box">
          <div className="join_inputs">
            <div style={{ width: "100%" }}>
              <Input
                leftIcon={<CustomIcon iconName="user" />}
                placeholder={lang === "en" ? "Name" : "お名前"}
                type="text"
                value={data?.fullName}
                onChange={(e) => {
                  handleChange("fullName", e.target.value);
                  errors.fullName = "";
                }}
                error={errors.fullName}
              />
            </div>

            <div style={{ width: "100%" }}>
              <Input
                leftIcon={<CustomIcon iconName="mail" />}
                value={data?.email}
                onChange={(e) => {
                  handleChange("email", e.target.value);
                  errors.email = "";
                }}
                error={errors.email}
                placeholder={lang === "en" ? "Email address" : "メールアドレス"}
              />
            </div>

            <div style={{ width: "100%" }}>
              <Select
                leftIcon={<CustomIcon iconName="bag" />}
                mainclass={"event-select"}
                options={rolesOptions1}
                onChange={(value) => {
                  handleChange("company", value);
                  errors.company = "";
                }}
                value={data.company}
                defaultValue={lang === "en" ? "職業" : "職業"}
                error={errors.company}
              />
            </div>

            <div className="radio_hero">
              <span>どんなイベントサービスをご利用ですか</span>

              <RadioBoxGroup
                checks={[
                  {
                    text: "Meetup",
                    label: "Meetup",
                    icon: <CustomIcon iconName="meetup" />,
                  },
                  {
                    text: "Peatix",
                    label: "Peatix",
                    icon: <CustomIcon iconName="peatix" />,
                  },
                  {
                    text: "どっちも利用していない",
                    label: "No",
                    // icon: <CustomIcon iconName="peatix" />,
                  },
                ]}
                value={data.service}
                onChange={(result) => {
                  handleChange("service", result);
                  errors.service = "";
                }}
                className="contact-radio-box"
                error={errors.service}
              />
            </div>

            <div className="radio_hero sanka">
              <span>当イベントは初めて参加しますか？</span>
              <RadioBoxGroup
                checks={[
                  {
                    text: "初めての参加",
                    label: "Yes、First Time",
                    icon: <CustomIcon iconName="" />,
                  },
                  {
                    text: "参加したことがある",
                    label: "No, I'm Repeater",
                  },
                ]}
                value={data.state}
                onChange={(result) => {
                  handleChange("state", result);
                  errors.state = "";
                }}
                className="contact-radio-box"
                error={errors.state}
              />
            </div>
          </div>
          <div className="join_btn_box">
            <button
              href="/"
              className="join_btn clear"
              onClick={() => {
                setData({});
                pirsch("Join Us - Form - BTN");
              }}
            >
              クリア <CustomIcon iconName="clear" />
            </button>
            <button
              href="/"
              className="join_btn"
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              参加する <CustomIcon iconName="invitation" />
            </button>
          </div>
        </div>
      </div>
      {IsShowPopup ? (
        <Popup
          message={popMessage}
          type={popType}
          icon={popIcon}
          closePopup={() => settIsShowPopup(false)}
        />
      ) : null}
      {openSuccess && (
        <PopupContainer
          title={lang === "jp" ? "Mail Sent!" : "ご登録が完了!"}
          logo={<Logo />}
          onClose={() => {
            setOpenSuccess(false);
          }}
        >
          <div className="contact-success-card" data-lang={lang}>
            {lang === "en" ? (
              <>
                <br />
                <br />
                
                ご登録ありがとうございます！<br />
                当日、会場でお会いできることを楽しみにしております！
              </>
            ) : (
              <>
                お問い合わせありがとうございます。
                <br />
                担当者よりご連絡させていただきます。
                <br />
                （内容によってはお時間をいただく場合もございます。）
              </>
            )}
          </div>
        </PopupContainer>
      )}
    </>
  );
}

export default JoinForm;
