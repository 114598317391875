import "../../styles/events.scss";
import CustomIcon from "../../compo/IconCompo";
import { useNavigate } from "react-router-dom";
import pirsch from "../../Analytics";

function HeaderCompo() {
  const navigate = useNavigate();

  return (
    <div className="header_hero">
      <div className="logo_box" onClick={() => navigate("/events/future")}>
        <CustomIcon iconName="event" />
        <div className="logo_type">
          UUD <span>EVENTS</span>
        </div>
      </div>

      <div className="menu_box">
      <div className="menu_btn" onClick={() => { pirsch("Future - Menu"); navigate("/events/future")}}><CustomIcon iconName="future" /> Future</div>
      <div className="menu_btn" onClick={() => { pirsch("Past - Menu"); navigate("/events/past")}}><CustomIcon iconName="past" /> Past</div>
      <div className="menu_btn" onClick={() => { pirsch("About - Menu"); navigate("/events/about")}}><CustomIcon iconName="about" /> About</div>
      <div className="menu_btn" onClick={() => { pirsch("Mailus - Menu"); navigate("/events/mailus")}}><CustomIcon iconName="mailus" /> Mail</div>
      <div className="menu_btn uud" onClick={() => { pirsch("UUD - Menu"); navigate("/home")}}><CustomIcon iconName="uud" /> </div>

      </div>
    </div>
  );
}

export default HeaderCompo;
