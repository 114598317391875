import { NewsLetterPopup } from "../../components/molecules/NewsLetterPopup";
import "../../styles/events.scss";
import EventContent from "./EventContent";
import HeaderCompo from "./HeaderEvent";
import InfoEvent from "./InfoEvent";
import JoinForm from "./JoinForm";
import StaticPageSubscribe from "../../components/organismes/StaticPageSubscribe";
import EventNewLetterComp from "./EventNewLetter";
import CustomIcon from "../../compo/IconCompo";
import { use } from "i18next";
import { useEffect } from "react";

function EventTemp({ joinus = false }) {
  useEffect(() => {
    if (joinus) {
      const element = document.getElementById("joinus");
      // Get the top position of the element relative to the document
      const topPosition = element.getBoundingClientRect().top + window.scrollY;

      // Scroll to the position 100px above the element
      window.scrollTo({
        top: topPosition - 30,
        behavior: "smooth", // Optional: Adds smooth scrolling
      });
    }
  }, []);
  return (
    <>
      <HeaderCompo />
      <div className="event_hero">
        <InfoEvent
          date="22"
          month="SEP"
          year="2024"
          day="SUNDAY"
          time="13:00~15:00"
          place="高田馬場"
          city="東京"
          price="FREE"
          title="デザインシステムの謎を解く!"
          tags={["UX Design", "UI Design", "Design System", "Branding"]}
          buttonText="参加する"
          buttonIcon="invitation"
          buttonLink="/"
          imgSrc1Small="about-img02.png"
          imgSrc1Large="about-img01.png"
        />
        <EventContent
          title="INTRO"
          text="このワークショップは、デザイナー、開発者、プロダクトマネージャーを対象に、デザインシステムの包括的な理解を深めることを目的としています。<br/>
          セッションでは、基礎的な定義から、応用的なヒントやベストプラクティスまで、幅広く取り扱います。<br/><br/>
          デザインシステムとは何か、その種類やレベル、成功するために必要なステップについて学び、効果的なデザインシステムを構築・維持するための実践的な洞察を得られます。<br/>また、デザインシステムを強化するための有益なヒントや、プロセスを支援するツールやリソースもご紹介します。
          "
          imgSrc1Small="about-img02.png"
          imgSrc1Large="about-img01.png"
        />

        <EventContent
          title="DATE & TIME"
          listItems={[
            {
              label: "日時：",
              value: "2023年9月14日 土曜日 / 13:00~15:00 (休憩10分)",
            },
            {
              label: "場所：",
              value: "高田馬場駅 - 徒歩6分 (OFFLINEイベント)",
            },
            {
              label: "参加対象者：",
              value:
                "デザイナー、開発者、プロダクトマネージャーなどのUXUIデザインに興味のある全ての方",
            },
            {
              label: "費用：",
              value:
                "デザイナー、開発者、プロダクトマネージャーなどのUXUIデザインに興味のある全ての方",
            },
          ]}
        />
        <EventContent
          title="TIMELINE"
          customClass="event_content_hero"
          listItems={[
            {
              label: "13:00 -",
              value: "高田馬場駅 - 徒歩6分 (OFFLINEイベント)",
            },
            { label: "13:05 -", value: "受付・自己紹介・イベントの流れ説明" },
            { label: "13:20 -", value: "デザインシステムの基本概念の紹介" },
            { label: "13:35 -", value: "デザインシステムの種類とレベルの説明" },
            { label: "14:00 -", value: "デザインシステムとアトミックデザイン" },
            { label: "14:10 -", value: "休憩" },
            {
              label: "14:20 -",
              value: "デザインシステム作成のステップ（ヒントとコツ）",
            },
            { label: "14:20 -", value: "役立つリソース、情報、ツールの紹介" },
            { label: "14:45 -", value: "まとめと質疑応答" },
            { label: "15:00 -", value: "イベント終了" },
          ]}
        />
        <EventContent
          title="SPEAKER"
          customClass="event_content_hero speaker"
          text="
          UXUIの専門家であり、JUUSANDO!でプロダクトデザイナー(UXUIデザイン)としても豊富な経験を持つスピーカーが登壇します。<br/>
          デザイン学校での講師経験が豊富であり、法人向けの研修も10年以上にわたって行っています。<br/>
          また、2015年にはUX&UIデザイナーズ(UUD)コミュニティを設立し、日本における海外標準のUI&UXデザイナーの育成にも力を注いでいます。
          "
        >
          <div className="speaker_hero">
            <div className="speaker_img">
              <CustomIcon iconName="hafid" />
            </div>
            <div className="speaker_name">
              はふぃど<span>Product Designer</span>
            </div>
            <div className="speaker_sns_box">
              <a onClick={() => (window.location.href = "https://example.com")}>
                <CustomIcon iconName="uud_icon2" />
              </a>
              <a onClick={() => (window.location.href = "https://example.com")}>
                <CustomIcon iconName="figma" />
              </a>
              <a onClick={() => (window.location.href = "https://example.com")}>
                <CustomIcon iconName="dribbble_line" />
              </a>
              <a onClick={() => (window.location.href = "https://example.com")}>
                <CustomIcon iconName="twetter_line" />
              </a>
              <a onClick={() => (window.location.href = "https://example.com")}>
                <CustomIcon iconName="web_line" />
              </a>
            </div>
          </div>
        </EventContent>
        <div className="forms_hero">
          <div className="event_content_hero_form">
            <EventContent
              title="JOIN US"
              id="joinus"
              customClass="event_content_hero"
            >
              <JoinForm />
            </EventContent>
          </div>

          <div className="event_content_hero_form shorter">
            <EventContent
              title="KEEP IN TOUCH"
              text="このワークショップは、デザイナー、開発者、プロダクトマネージャーを対象に"
            >
              <EventNewLetterComp link="https://script.google.com/macros/s/AKfycbwImvO_Bf4wFEC5XrNrRyEysfK40vienH8Fq1K60FuvkclahIRn96FIuctrHP5wEcfr2A/exec" />
            </EventContent>
          </div>
        </div>

        <EventContent title="PARTNERS & SPONSOR">
          <div className="partner_hero">
            <a onClick={() => (window.location.href = "https://example.com")}>
              <CustomIcon iconName="juusando_spo" />
            </a>
            <a onClick={() => (window.location.href = "https://example.com")}>
              <CustomIcon iconName="uud_spo" />
            </a>
            <a onClick={() => (window.location.href = "https://example.com")}>
              <CustomIcon iconName="pinjob_spo" />
            </a>
          </div>
        </EventContent>
      </div>
      <div className="footer"></div>
    </>
  );
}

export default EventTemp;
