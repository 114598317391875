import React, { useState, useEffect, useRef } from "react";
import { ReactComponent as IconA } from "../../assets/icons/clear.svg";

import { ReactComponent as Hum2 } from "../../assets/icons/hum2.svg";

const Dropdown = ({ options, onChange, rightIcon, className, contClass }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const [order, setOrder] = useState(0);
  const dropdownRef = useRef(null); // Ref to the dropdown container
  const activeIcon = isOpen ? "IconB" : "IconA";

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    // Add event listener when the component is mounted
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up the event listener when the component is unmounted
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []); // Empty dependency array ensures the effect runs once after initial render

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    onChange(option.value);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    setOrder(order + 1);
    if (order == 1) {
      setSelectedOption(options[0]);
    }
  }, [options]);
  return (
    <div className={`dropdown-container ${contClass} `} ref={dropdownRef}>
      <div className={`dropdown-header ${className}`} onClick={toggleDropdown}>
        {selectedOption.label}
        {rightIcon}
      </div>

      {isOpen && (
        <div className="options-container">
          {options.map((option, index) => (
            <div
              key={index}
              className="option-item"
              onClick={() => handleOptionSelect(option)}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
