import { useDispatch, useSelector } from "react-redux";
import SecondaryLayout from "../components/layout/SecondaryLayout";
import Popup from "../components/molecules/Popup";
import { ReactComponent as Resos } from "../assets/icons/resos.svg";
import { ReactComponent as PressIcon } from "../assets/icons/press.svg";
import { ReactComponent as TimerIcon } from "../assets/icons/timer.svg";
import { ReactComponent as BagIcon } from "../assets/icons/bag.svg";
import { ReactComponent as News } from "../assets/icons/news.svg";
import "../styles/main.scss";
import { ReactComponent as Hand2 } from "../assets/icons/hand2.svg";
import Button from "../components/atoms/Button";
import Select from "../components/atoms/Select";
import Input from "../components/atoms/Input";
import { useEffect, useState } from "react";
import React from "react";
import Signup from "../components/templates/signup";
import Layout from "../components/layout/Layout";
import StaticPageTitle from "../components/atoms/StaticPageTitle";
import StaticPageSlogan from "../components/atoms/StaticPageSlogan";
import StaticPageArticle from "../components/organismes/StaticPageArticle";
import StaticPageOrderedList from "../components/organismes/StaticPageOrderedList";
import StaticPageParagraph from "../components/organismes/StaticPageParagraph";
import StaticPageParagraphImage from "../components/organismes/StaticPageParagraphImage";
import StaticPageOrderedList2 from "../components/organismes/StaticPageOrderedList2";
import StaticPageSubscribe from "../components/organismes/StaticPageSubscribe";
import SingupSoonPage from "../components/organismes/SingupSoonPage";
function SignUpSoon({}) {
  useEffect(() => {
    document.title = "UUD - Sign Soon";
    return () => {
      document.title = "UUD";
    };
  }, []);

  const user = useSelector((state) => state.user);
  const [showRightBar, setShowRightBar] = useState(false);
  const [showLeftBar, setShowLeftBar] = useState(false);

  return (
    // <Layout
    //   id="signsoon"
    //   selectedMenu={""}
    //   currentPage="signupsoon"
    //   isLogin={user !== "" && user}
    //   setShowLeftBar={(res) => setShowLeftBar(res)}
    //   setShowRightBar={(res) => setShowRightBar(res)}
    //   showRightBar={showRightBar}
    //   showLeftBar={showLeftBar}
    //   actionMenu={false}
    // >
    //   <div className="content-wrapper_centered">
    //     <SingupSoonPage />
    //   </div>
    // </Layout>

<div className="content-wrapper_centered">
<SingupSoonPage />
</div>
  );
}

export default SignUpSoon;
