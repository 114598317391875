import { ReactComponent as LogoIcon } from "../../assets/icons/logo_05.svg";
import { ReactComponent as PasswordIcon } from "../../assets/icons/password.svg";
import { BACKEND_URL } from "../../config";
import { ReactComponent as Logo07Icon } from "../../assets/icons/logo_07.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import { ReactComponent as Danger } from "../../assets/icons/danger.svg";
import { ReactComponent as Loading } from "../../assets/icons/loading.svg";
import { ReactComponent as Back } from "../../assets/icons/back.svg";
import { ReactComponent as EyeOnIcon } from "../../assets/icons/eye_on.svg";
import { ReactComponent as Delete } from "../../assets/icons/trash.svg";
import { ReactComponent as Illustra08 } from "../../assets/icons/illustra_08.svg";
import { ReactComponent as Mail } from "../../assets/icons/new_mail.svg";
import axios from "axios";
import React, { useEffect } from "react";
import { ReactComponent as Trash } from "../../assets/icons/trash.svg";
import Button from "../atoms/Button";
import { useDispatch, useSelector } from "react-redux";
import ItemCard from "../organismes/ItemCard";
import Popup from "../molecules/Popup";
import TheEndItems from "../molecules/TheEndItems";
import { ReactComponent as Ok } from "../../assets/icons/ok.svg";
import { useMemo, useState } from "react";
import { ReactComponent as CautionIcon } from "../../assets/icons/caution.svg";
import LoadMoreItems from "../molecules/LoadMoreItems";
import { setFavsItems } from "../../redux/actions/items";
import Input from "../atoms/Input";
import { likeItem } from "../../services/userServices";
import PopupContainer from "../molecules/PopupContainer";
import NoLoggedMessage from "../molecules/NoLoggedMessage";
import NoResultFoundCard from "../molecules/NoResultFoundCard";
import LoadingCard from "../molecules/LoadingCard";
import { setOpenLoginPopup } from "../../redux/actions/user";
import UxerCard from "../organismes/uxerCard";
import { getUserInfo } from "../../services/authServices";
import jwtDecode from "jwt-decode";
import Switch from "../atoms/Switch";
import { use } from "i18next";

function SettingsDelete({ display, setShowLeftBar }) {
  const user = useSelector((state) => state.user);
  const [showDelete, setShowDelete] = useState("");
  const token = localStorage.getItem("token");
  const [userInfo, setUserInfo] = useState();
  const [popType, setPopType] = useState("");
  const [IsShowPopup, settIsShowPopup] = useState(false);
  const [popMessage, setPopMessage] = useState("");
  const [popIcon, setPopIcon] = useState("");
  const [email, setEmail] = useState();
  const [showMessage, setShowMessage] = useState();
  const [updateEmail, setUpdateEmail] = useState();
  const lang = useSelector((state) => state.lang);
  const [openconfirmPopup, setOpenconfirmPopup] = useState(false);
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});
  function popups(message, type, icon) {
    settIsShowPopup(true);
    setPopType(type);
    setPopIcon(icon);
    setPopMessage(message);
    setTimeout(() => {
      settIsShowPopup(false);
    }, 4000);
  }
  useEffect(() => {
    getUserInfo(jwtDecode(user).id).then((res) => {
      if (res.data.myUser) {
        res.data = res.data.myUser;
      }
      if (res) {
        setUserInfo(res.data);
      }
    });
  }, []);

  return (
    <>
      <div
        className={`bg-white h-full settings-cont ${display ? "" : "!hidden"}`}
      >
        <div
          className="close-btn close-btn-settings"
          onClick={() => setShowLeftBar(true)}
        >
          <CloseIcon className="z-0" />
        </div>

        <div className={"settings"}>
          <div className="settings-title-cont" data-lang={lang}>
            <h2 className="settings-title" data-lang={lang}>
              {lang === "en" ? "Account" : "アカウント"}
            </h2>
          </div>

          <div className="settings-input-cont ">
            <p className="setting-text" data-lang={lang}>
              {lang === "en"
                ? "Be aware that deleting your profile will result in the permanent loss of all your data."
                : " 退会すると、すべてのデータが永久に失われます。"}
            </p>

            <div className="user-form-body-buttons">
              <Button
                rightIcon={<Danger />}
                className="noLogged_button noLogged_button_outline delete-account-button"
                onClick={() => {
                  setOpenconfirmPopup(true);
                }}
              >
                {lang === "en" ? "Delete my account!" : "退会する"}
              </Button>
            </div>
            {showMessage && (
              <p className="setting-text">
                Check your email newemailaddress@mail.comto confirm your new
                address. The change won’t take effect until confirmed.
              </p>
            )}
          </div>
        </div>
      </div>
      {IsShowPopup ? (
        <Popup
          message={popMessage}
          type={popType}
          icon={popIcon}
          closePopup={() => settIsShowPopup(false)}
        />
      ) : null}
      {openconfirmPopup && (
        <PopupContainer
          data-lang={lang}
          title={lang === "en" ? "Delete My Account" : "退会 - 確認"}
          logo={<Illustra08 />}
          onClose={() => {
            setOpenconfirmPopup(false);
            setData({ password: "", email: "" });
          }}
        >
          <div className="checkemail-popup-text-cont">
            <p class="popup-text">
              {lang === "en" ? (
                <>
                  Are you sure you want to permanently delete your account along
                  with all the stored data?
                  <br />
                  This action cannot be undone.
                </>
              ) : (
                <>
                  アカウントとすべての保存されたデータを永久に削除してもよろしいですか？
                  <br />
                  この操作は取り消すことができません。
                 </>
              )}
            </p>
          </div>
          <div className="delete-popup-button-popup delete-account-button">
            <Button
              rightIcon={<Back />}
              className="noLogged_button noLogged_button_outline delete-account-button"
              onClick={() => {
                setOpenconfirmPopup(false);
                setData({ password: "", email: "" });
              }}
            >
              {lang === "en"
                ? "Do Not Delete My Account!"
                : " 退会しない!"}
            </Button>
            <Button
              rightIcon={<Delete />}
              className="settings-delete-button delete-account-button"
              onClick={() => {
                popups("Loading...", "loading", <Loading />);

                axios
                  .post(
                    `${BACKEND_URL}/profile/delete-account`,
                    {
                      lang: lang,
                    },
                    {
                      headers: {
                        Authorization: `Bearer ${token.slice(1, -1)}`,
                      },
                    }
                  )
                  .then((res) => {
                    localStorage.removeItem("token");
                    localStorage.removeItem("persist:persist-key");

                    setTimeout(() => {
                      window.location = "/";
                    }, 1000);
                  });
              }}
            >
              {lang === "en"
                ? "Delete my Account"
                : " 退会する！"}
            </Button>
          </div>
        </PopupContainer>
      )}
      {IsShowPopup ? (
        <Popup
          message={popMessage}
          type={popType}
          icon={popIcon}
          closePopup={() => settIsShowPopup(false)}
        />
      ) : null}
    </>
  );
}

export default SettingsDelete;
