import RadioBoxGroup from "../../molecules/RadioBoxGroup";
import Switch from "../../atoms/Switch";
import Button from "../../atoms/Button";
import { ReactComponent as Report } from "../../../assets/icons/report.svg";
import { ReactComponent as Close } from "../../../assets/icons/close.svg";
import { deleteChat, toggleBlock, report } from "../../../services/ChatService";
import TextArea from "../../atoms/TextArea";
import { useEffect, useState } from "react";
import jwtDecode from "jwt-decode";
import { useSelector } from "react-redux/es/hooks/useSelector";
import React from "react";
import Popup from "../../molecules/Popup";

function PopupCard({
  title,
  desc,
  checkBox,
  bswitch,
  ShowReportCard,
  selectedChat,
  sender,
  handleRemove,
  handleblock,
  popups,
}) {
  let checks = [
    "Spam or irrelevant",
    "Adult content",
    "Fraud/Scam",
    "Offensive or illegal",
    "Imminent physical harm",
    "Infringes my rights",
    "Other",
  ];
  const [value, setValue] = useState("");
  const [checked, setChecked] = useState(false);

  const user = useSelector((state) => state.user);

  function handleClick(title) {
    if (value.includes(title)) {
      setValue("");
    } else {
      setValue(() => {
        return title;
      });
    }
  }
  function handleFunction() {
    if (title === "Delete") {
      deleteChat(selectedChat._id)
        .then((res) => {
          ShowReportCard();
          handleRemove(selectedChat);
        })
        .catch((err) => {
          ShowReportCard();
        });
    } else if (title === "Block") {
      toggleBlock(sender._id, false)
        .then((res) => {
          popups("User blocked successfully", "success");
          ShowReportCard();
          handleblock(
            selectedChat.users.find((other_user) => {
              return other_user.username !== jwtDecode(user).username;
            })._id
          );
        })
        .catch((err) => {
          ShowReportCard();
          if (err.status == 400) {
            console.log("Already blocked");
          }
        });
    } else if (title === "Report") {
      if (value != "") {
        report(sender._id, value)
          .then((res) => {
            ShowReportCard();
            popups("User reported successfully", "success");
          })
          .catch((err) => {
            ShowReportCard();
            popups("User already reported", "error");
          });

        if (checked) {
          toggleBlock(sender._id, false)
            .then((res) => {
              ShowReportCard();
              handleblock(
                selectedChat.users.find((other_user) => {
                  return other_user.username !== jwtDecode(user).username;
                })._id
              );
            })
            .catch((err) => {
              ShowReportCard();
              if (err.status == 400) {
                console.log("Already blocked");
              }
            });
        }
      } else {
        popups("you ave to select value first", "error");
      }
    }
  }

  return (
    <div className="popup-card-cont">
      <div className="popup-card">
        <Close className="popup-card-close" onClick={ShowReportCard} />
        <h3 className="popup-card-title">
          {title === "Delete" ? (
            <>{title} Message</>
          ) : title === "Block" ? (
            <>{title} User!</>
          ) : title === "Report" ? (
            title
          ) : null}
        </h3>
        <p className="popup-card-description">
          {title == "Block" ? (
            <>
              <span style={{ color: "#FF6A56" }}>“username”</span> {desc}{" "}
            </>
          ) : (
            desc
          )}
        </p>
        {checkBox && (
          <div className="popup-checkbox-cont">
            <RadioBoxGroup
              className="check-full"
              checks={[
                {
                  text: checks[0],
                },
                {
                  text: checks[1],
                },
                {
                  text: checks[2],
                },
                {
                  text: checks[3],
                },
                {
                  text: checks[4],
                },
                {
                  text: checks[5],
                },
                {
                  text: checks[6],
                },
              ]}
              value={value || []}
              onChange={(index) => {
                handleClick(index);
              }}
            />
            {bswitch && (
              <Switch
                title="Also Block this user"
                checked={checked}
                onChange={() => {
                  checked ? setChecked(false) : setChecked(true);
                }}
              />
            )}
          </div>
        )}
        <Button rightIcon={<Report />} onClick={() => handleFunction()}>
          {title === "Report" ? <>Send {title}</> : title}
        </Button>
      </div>
    </div>
  );
}

export default PopupCard;
