import { ReactComponent as Signup } from "../../assets/icons/signup.svg";
import { ReactComponent as LoginIcon } from "../../assets/icons/login.svg";
import { useDispatch, useSelector } from "react-redux";
import Button from "../atoms/Button";
import React from "react";
import { setOpenSignupPopup } from "../../redux/actions/user";
import "../../styles/main.scss";
import { useState } from "react";
import { setOpenLoginPopup } from "../../redux/actions/user";
import SoonPopup from "../organismes/popup/SoonPopup";

function NoLoggedMessage({ icon = "", title = "", children, className }) {
  const dispatch = useDispatch();

  const [isSoonPopup, setIsSoonPopup] = useState(false);
  const lang = useSelector((state) => state.lang);

  return (
    <>
      <div className={`noLogged_message_container ${className}`}>
        <div className="noLogged_message">
          <div className="noLogged_message_header">
            <div className="noLogged_message_logo">{icon}</div>
            <span className="noLogged_message_title">{title}</span>
          </div>

          <div className="noLogged_message_content" data-lang={lang}>
            {children}
          </div>

          <div className="noLogged_message_button_group">
            {/* <Button
            className="noLogged_button"
            rightIcon={<LoginIcon />}
            onClick={() => dispatch(setOpenLoginPopup(true))}
          >
            {lang === "en" ? "Log in" : "ログイン"}      
          </Button> */}
            {/* <Button
            className="noLogged_button noLogged_button_outline longer"
            rightIcon={<NameCard />}
            onClick={() => {
              window.location = "/signup";
            }}
          >
          {lang === "en" ? "Sign up" : "サインアップ"}          
          </Button> */}
            {/* 
          {lang === "en" ? (<Button
            className="noLogged_button noLogged_button_outline longer"
            rightIcon={<LinkIcon />}
            onClick={() => {
              setIsSoonPopup(true);
              pirsch("SignupSoon-MoreDetail-E");
            }}
          >
            More Details
          </Button>
          ) :
            (<Button
              className="noLogged_button noLogged_button_outline longer"
              rightIcon={<LinkIcon />}
              onClick={() => {
                setIsSoonPopup(true);
                pirsch("SignupSoon-MoreDetail-J");
              }}
            >
              もっと見る
            </Button>
            )}         */}

            <Button
              className="noLogged_button"
              rightIcon={<LoginIcon />}
              onClick={() => dispatch(setOpenLoginPopup(true))}
            >
              {/* {lang === "en" ? "Log in" : "ログイン"}       */}
              Log in
            </Button>
            <Button
              className="noLogged_button noLogged_button_outline longer"
              rightIcon={<Signup />}
              onClick={() => {
                // dispatch(setOpenSignupPopup(true));
                dispatch(setOpenSignupPopup(true));
              }}
            >
              {/* {lang === "en" ? "Sign up" : "サインアップ"} */}
              Sign up
            </Button>
          </div>
        </div>
        {isSoonPopup && (
          <SoonPopup setIsSoonPopup={(res) => setIsSoonPopup(res)} />
        )}
      </div>
    </>
  );
}

export default NoLoggedMessage;
