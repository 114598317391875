import { useState } from "react";
import { ReactComponent as Namecard } from "../.././../assets/icons/namecard.svg";
import { ReactComponent as MailIcon } from "../.././../assets/icons/mail.svg";
import { ReactComponent as PasswordIcon } from "../../../assets/icons/password.svg";
import { ReactComponent as LoginIcon } from "../../../assets/icons/login.svg";
import { ReactComponent as EyeOnIcon } from "../../../assets/icons/eye_on.svg";
import { ReactComponent as EyeOffIcon } from "../../../assets/icons/eye_off.svg";
import Input from "../../atoms/Input";
import {
  setOpenLoginPopup,
  setOpenSignupPopup,
} from "../../../redux/actions/user";
import Button from "../../atoms/Button";
import { useTranslation } from "react-i18next";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import pirsch from "../../../Analytics";

function LoginCard({ className, onForgetPassword, onLogin, error = false }) {
  const [login, setLogin] = useState("");
  const [password, setpassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [t, i18n] = useTranslation();
  const lang = useSelector((state) => state.lang);
  const dispatch = useDispatch();

  return (
    <div className={`login-card ${className}`}>
      <div className="login-card-form">
        <Input
          id="id1"
          placeholder={
            lang === "en" ? "Email / Username" : "Eメール / ユーザーネーム"
          }
          error={error}
          value={login}
          onChange={(e) => {
            setLogin(e.target.value);
          }}
          leftIcon={<MailIcon />}
        />
        <Input
          id="id2"
          placeholder={lang === "en" ? "Password" : "パスワード"}
          error={error}
          type={showPassword ? "text" : "password"}
          value={password}
          onChange={(e) => {
            setpassword(e.target.value);
          }}
          leftIcon={<PasswordIcon />}
          rightIcon={
            showPassword ? (
              <EyeOnIcon className="eyeIcon" />
            ) : (
              <EyeOffIcon className="eyeIcon" />
            )
          }
          onRightIconClick={() => setShowPassword(!showPassword)}
        />
      </div>
      <span className="link-to" onClick={onForgetPassword} data-lang={lang}>
        {lang === "en" ? "Forgot Password?" : "パスワードを忘れた方"}
      </span>
      <Button
        rightIcon={<LoginIcon />}
        onClick={() => onLogin(login, password)}
        data-lang={lang}
      >
        {lang === "en" ? "Log in" : "ログイン"}
      </Button>

      <div className="new-account">
        {/* <span className="outfit" data-lang={lang}>
          {lang === "en" ? "Already have an account? " : "アカウントをお持ち方"}
        </span>
        <Button
          rightIcon={<Namecard />}
          // onClick={() => (window.location = "/signup")}
          onClick={() => {
            window.location = "/signupsoon";
            pirsch("Index - ABOUT");
          }
        }

          className="signup_button"
          data-lang={lang}
        >
          {lang === "en" ? "Sign up" : "サインアップ"}
        </Button> */}

        <span className="back-link" data-lang={lang}>
          {lang === "en" ? (
            <>
              Already have an account!!!?
              <span
                className="text-link"
                onClick={() => {
                  dispatch(setOpenSignupPopup(true));
                  pirsch("Home - Popup - En - Signup");
                  dispatch(setOpenLoginPopup(false));
                }}
              >
                {" "}
                Signup{" "}
              </span>
            </>
          ) : (
            <>
              アカウントをお持ちない方？
              <span
                className="text-link"
                onClick={() => {
                  dispatch(setOpenSignupPopup(true));
                  pirsch("Home - Popup - Jp - Signup");
                  dispatch(setOpenLoginPopup(false));
                }}
              >
                {" "}
                Signup{" "}
              </span>
            </>
          )}
        </span>
      </div>
    </div>
  );
}

export default LoginCard;
