import { useSelector } from "react-redux/es/hooks/useSelector";
import React from "react";

function Tag({
  title = "",
  leftIcon = "",
  rightIcon = "",
  className = "",
  selected = false,
  onClick = () => {},
}) {
  const lang = useSelector((state) => state.lang);

  return (
    <div
      className={`tag ${selected ? "tag-selected" : ""} ${className}`}
      data-lang={lang}
      onClick={() => onClick(title)}
    >
      {leftIcon && <div className="tag-left-icon">{leftIcon}</div>}
      <span className="tag-title" data-lang={lang}>
        {title}
      </span>
      {rightIcon !== "" && <div className="tag-right-icon">{rightIcon}</div>}
    </div>
  );
}

export default Tag;
