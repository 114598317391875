import { ReactComponent as ToolsIcon } from "../../../assets/icons/tool.svg";
import { ReactComponent as ResosIcon } from "../../../assets/icons/resos.svg";
import { ReactComponent as IdeasIcon } from "../../../assets/icons/idea.svg";
import { ReactComponent as AppsIcon } from "../../../assets/icons/apps.svg";

function CategoryItems({ category }) {
  return (
    <span className={`category-icon icon-${category.toLowerCase()}`}>
      {category.toLowerCase() === "tools" ? (
        <ToolsIcon />
      ) : category.toLowerCase() === "resos" ? (
        <ResosIcon />
      ) : category.toLowerCase() === "ideaz" ? (
        <IdeasIcon />
      ) : category.toLowerCase() === "apps" ? (
        <AppsIcon />
      )
      :(
        ""
      )}
      {category}
    </span>
  );
}

export default CategoryItems;
