import { useDispatch, useSelector } from "react-redux";
import SecondaryLayout from "../components/layout/SecondaryLayout";
import Popup from "../components/molecules/Popup";
import "../styles/main.scss";
import React from "react";
import { setSelectLang } from "../redux/actions/env";
import Signup from "../components/templates/signup";
import { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import axios from "axios";
import { BACKEND_URL } from "../config";

function SignUp({ newPopup, popups, closePopup }) {
  const dispatch = useDispatch();
  const [showPage, setShowPage] = useState(false);
  const params = useParams();
  const { search } = useLocation();
  const params1 = new URLSearchParams(search);
  const lang = params1.get("lang");
  dispatch(setSelectLang(lang));
  useEffect(() => {
    axios
      .post(
        `${BACKEND_URL}/auth/verify/${params.token}/email/${params.email}`,
        {}
      )
      .then((res) => {
        if (res.data.status === true) {
          setShowPage(true);
        } else if (res.data.status === false) {
          window.location = "/";
        }
      });
  }, []);
  useEffect(() => {
    document.title = "UUD - Signup";
    return () => {
      document.title = "UUD";
    };
  }, []);

  return (
    <>
      {" "}
      {showPage && (
        <SecondaryLayout currentPage="signup">
          <Signup newPopup={newPopup} />
          <div className="popup-secondary-container">
            {popups.map((popup) => (
              <Popup
                key={popup.id}
                type={popup.type}
                message={popup.message}
                closePopup={() => closePopup(popup.id)}
              />
            ))}
          </div>
        </SecondaryLayout>
      )}
    </>
  );
}

export default SignUp;
