import { useTranslation } from "react-i18next";
import { ReactComponent as LoginIcon } from "../../../assets/icons/login.svg";
import Button from "../../atoms/Button";
import React from "react";

function ResetPasswordDoneCard({ onBackToLogin }) {
  const [t, i18n] = useTranslation();

  return (
    <div className="reset-password-card">
      <p>{t("popup.resetPasswordDone.subTitle")}</p>

      <Button rightIcon={<LoginIcon />} onClick={onBackToLogin}>
        {t("popup.resetPasswordDone.button")}
      </Button>
    </div>
  );
}

export default ResetPasswordDoneCard;
