import { NewsLetterPopup } from "../../components/molecules/NewsLetterPopup";
import "../../styles/events.scss";
import ContactEvent from "../ContactEvent";
import EventContent from "./EventContent";
import HeaderCompo from "./HeaderEvent";
import InfoEvent from "./InfoEvent";
import JoinForm from "./JoinForm";

function About() {
  return (
    <>
      <HeaderCompo />

      <div className="event_hero contact_hero">
        <EventContent
          title="ABOUT US"
          text="
          2013年にハフィッドによって設立された「UXUI Designer 'UUD'」は、東京エリアのUX/UIデザイナーやクリエイティブテクノロジストのためのコミュニティです。<br/>
          当コミュニティでは、定期的にオンサイトでのワークショップ、ディスカッション、プレゼンテーションを開催し、経験や知識、最新のUX/UIデザインのトレンドを共有しています。<br/>
          私たちの目標は、デザイナーがスキルを向上させ、新しいことを学び、ネットワークを広げる手助けをすることです。
          <br/><br/>
          コミュニティ活動に参加したい方は、ぜひオンサイトまたはコンタクトフォームからご連絡ください。<br/>
          また、個人のスピーカーや企業/スタートアップの方でイベントやワークショップを開催したい方も、お気軽にご連絡ください。
          <br/><br/>
          フィードバックやイベントの提案、コラボレーションも歓迎しており、コミュニティの成長と発展にご協力いただけると幸いです。
          皆様と共に、より良いコミュニティを築いていくことを楽しみにしています。
          "
        >
          <div style={{ padding: "24px 0" }}></div>
        <picture className={"big_img_box"} style={{ backgroundColor: "#fff", height: "100%" }}>
        <source media="(max-width: 900px)" srcset={"about-img02.png"} style={{ minHeight: "100%" }}/>
        <img src={"about-img01.png"} />
      </picture>
        </EventContent>


      </div>
    </>
  );
}

export default About;
