import { Buffer } from "buffer";
import axios from "axios";
import { BACKEND_URL } from "../config";
const APP_URL = BACKEND_URL;
const token = localStorage.getItem("token");

export const getLikedItems = async () => {
  const res = await axios.get(`${APP_URL}/profile/my-favs`, {
    headers: {
      Authorization: `Bearer ${token.slice(1, -1)}`,
    },
  });

  return res.data || { myTools: [], myResos: [], myIdeaz: [] };
};

export const getProfile = async (id) => {
  const res = await axios.get(`${APP_URL}/profile/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return res;
};

export const getImageProfile = async (image_name) => {
  const res = await axios.get(`${BACKEND_URL}/profile/image/${image_name}`, {
    responseType: "arraybuffer",
    responseEncoding: "base64",
  });
  const buffer = Buffer.from(res.data, "base64");
  let binary = "";
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return btoa(binary);
};

// toggle hide/show profile Uxer
export const toggleVisibilityUxer = (value) => {
  if (value) {
    const res = axios.post(
      `${BACKEND_URL}/uxers/hide-my-profile`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token.slice(1, -1)}`,
        },
      }
    );
  } else {
    const res = axios.post(
      `${BACKEND_URL}/uxers/show-my-profile`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token.slice(1, -1)}`,
        },
      }
    );
  }
};

// change email
export const changeEmail = async (new_email) => {
  const res = axios.post(
    `${BACKEND_URL}/profile/change-email`,
    {
      newEmail: new_email,
    },
    {
      headers: {
        Authorization: `Bearer ${token.slice(1, -1)}`,
      },
    }
  );

  return res;
};

// change password
export const changePassword = async (last_password, new_password) => {
  const res = axios.post(
    `${BACKEND_URL}/profile/changePassword`,
    {
      oldPassword: last_password,
      newPassword: new_password,
      repeatedNewPassword: new_password,
    },
    {
      headers: {
        Authorization: `Bearer ${token.slice(1, -1)}`,
      },
    }
  );

  return res;
};

// change notification
export const changeNotification = async (index) => {
  if (index === 0) {
    const res = axios.post(
      `${BACKEND_URL}/profile/allow-send-email`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token.slice(1, -1)}`,
        },
      }
    );
  } else {
    const res = axios.post(
      `${BACKEND_URL}/profile/receive-news`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token.slice(1, -1)}`,
        },
      }
    );
  }
};

// change profile info
export const changeProfileInfo = async (data) => {
  const formData = new FormData();

  const picRes = await axios.get(data.profile_img.cropped, {
    responseType: "blob",
  });

  const blob = picRes.data;

  const mimeType = blob.type;
  const fileName = `profile_image.${mimeType.substring(6)}`;
  const file = new File([blob], fileName, { type: mimeType });

  formData.append("profile", file);
  formData.append("email", data.email);
  formData.append("password", data.password);
  formData.append("username", data.username);
  formData.append("fullName", data.fullName);
  formData.append("nationality", data.nationality);
  formData.append("sub_role", data.subRole);
  formData.append("intro", data.intro);
  formData.append("personalSite", data.personalSite);
  formData.append("personalBlog", data.personalBlog);
  formData.append("twitterLink", data.twitterLink);
  formData.append("facebookLink", data.facebookLink);
  formData.append("instagramLink", data.instagramLink);
  formData.append("linkedinLink", data.linkedinLink);
  formData.append("dribbleLink", data.dribbleLink);
  formData.append("behanceLink", data.behanceLink);
  formData.append("experience", data.experience);
  formData.append("position", data.position);
  formData.append("skills", data.skills.join(","));

  const res = await axios.post(`${APP_URL}/profile/`, formData, {
    headers: {
      Authorization: `Bearer ${token.slice(1, -1)}`,
    },
  });

  if (res.data?.messageSuccess) {
    return "success";
  } else {
    return res.data;
  }
};

export const submitRequestUxer = async (userData) => {
  const res = await axios.post(
    `${APP_URL}/uxers/uxers-request`,
    {
      skills: userData.skills ? userData.skills?.join(",") : "",
      position: userData.position,
      experience: userData.experience ? parseInt(userData.experience) : 0,
      spokenLangs: "English",
    },
    {
      headers: {
        Authorization: `Bearer ${token.slice(1, -1)}`,
      },
    }
  );

  return res;
};

export const deleteMyUxer = async () => {
  const res = await axios.post(
    `${APP_URL}/uxers/delete-myaccount`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token.slice(1, -1)}`,
      },
    }
  );

  return res;
};

export const createNewPassword = async (values, id) => {
  const res = await axios.post(
    `${APP_URL}/profile/create-new-password/${id}`,
    values
  );

  if (res) {
    localStorage.setItem("token", JSON.stringify(res.data.myToken));
    return res;
  }

  // .then((res) => {
  //     localStorage.setItem("token", JSON.stringify(res.data.myToken));
  //     console.log(res.data.myToken);
  //     return res;
  // })
  // .catch((error) => {
  //   throw error;
  // });
};
