import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as ItemsIcon } from "../assets/icons/services.svg";
import "../styles/dashboard.scss";
import { ReactComponent as UsersIcon } from "../assets/icons/user_bb.svg";
import { ReactComponent as InfoIcon } from "../assets/icons/analytics_bb.svg";
import { useEffect, useState } from "react";
import DashboardLayout from "../components/layout/DashboardLayout";
import DashboardItems from "../components/templates/DashboardItems";
import DashboardUsers from "../components/templates/DashboardUsers";
import { getAllItems, getAllUsers } from "../services/AdminService";
import { getLocalStorageItem } from "../services/localStorage";

function Dashboard() {
  const [dataItems, setDataItems] = useState();
  const [dataUsers, setDataUsers] = useState();
  const [selectedTab, setSelectedTab] = useState("items");
  const [numberUsers, setNumberUsers] = useState(0);
  const [numberUxers, setNumberUxers] = useState(0);
  const [favs, setFavs] = useState(0);
  const [clicks, setClicks] = useState(0);
  const [clicksG, setClicksG] = useState(0);

  const checkValidation = () => {
    const role = getLocalStorageItem("role");
    if (!role) {
    } else if (!["admin"].includes(role)) {
    }
  };

  useEffect(() => {
    checkValidation();
    document.title = "UUD - Dashboard";
    return () => {
      document.title = "UUD";
    };
  }, []);

  const user = useSelector((state) => state.user);
  const language = useSelector((state) => state.lang);

  const fetchData = async () => {
    try {
      const itemsResponse = await getAllItems();
      const usersResponse = await getAllUsers();

      if (itemsResponse.data.items) {
        setDataItems(itemsResponse.data.items);
      }

      if (usersResponse.data) {
        setDataUsers(usersResponse.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    const updateStatistics = () => {
      setClicks(0);
      setClicksG(0);
      setNumberUsers(0);
      setNumberUxers(0);
      setFavs(0);

      if (dataUsers?.length !== undefined) {
        dataUsers.forEach((el) => {
          setNumberUsers((prev) => prev + 1);

          if (el.uxer) {
            setNumberUxers((prev) => prev + 1);
          }

          setClicks((prev) => prev + el.clicks);
        });
      }

      if (dataItems?.length > 0) {
        dataItems.forEach((el) => {
          setClicksG((prev) => prev + el.clicks);
          setFavs((prev) => prev + el.likes);
        });
      }
    };

    updateStatistics();
  }, [dataItems, dataUsers]);

  useEffect(() => {
    fetchData();
    const interval = setInterval(fetchData, 30000);
    return () => clearInterval(interval);
  }, []);

  const determineTopIcon = () => {
    if (selectedTab === "items") {
      return <ItemsIcon className="top-bar-icon" />;
    } else if (selectedTab === "users") {
      return <UsersIcon className="top-bar-icon" />;
    } else if (selectedTab === "info") {
      return <InfoIcon className="top-bar-icon" />;
    }
    return null;
  };

  return (
    <DashboardLayout
      id="dashboard-items"
      length={
        dataUsers?.length > 0 &&
        dataItems?.length > 0 &&
        selectedTab === "items"
          ? dataItems.length
          : dataUsers?.length
      }
      selectedTab={selectedTab}
      clicks={clicks}
      guestClicks={clicksG}
      uxers={numberUxers}
      users={numberUsers}
      favs={favs}
      setSelectedTab={(res) => setSelectedTab(res)}
      TopIcon={determineTopIcon()}
    >
      {selectedTab === "items" && <DashboardItems />}
      {selectedTab === "users" && <DashboardUsers />}
      {selectedTab === "info" && <DashboardItems />}
    </DashboardLayout>
  );
}

export default Dashboard;
