import HomeIcon from "../atoms/HomeIcon";
import LangugeSwitch from "../atoms/LangugeSwitch";
import React from "react";
import { setSelectLang } from "../../redux/actions/env";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

function SecondaryLeftBar() {
  const dispatch = useDispatch();
  const [t, i18n] = useTranslation();
  const lang = useSelector((state) => state.lang);
  
  return (
    <div className="left-bar-secondary">
      <HomeIcon
        onClick={() => {
          window.location = "/";
        }}
        style={{ flexShrink: 0 }}
      />

      <div className="left-bar-text">
        <span>DESIGNERS HOME!</span>
      </div>

      {/* <LangugeSwitch language={"eng"} color="var(--primary-color)" /> */}

      <LangugeSwitch
        language={i18n.language}
        onClick={() => {
          localStorage.setItem(
            "language",
            i18n.language === "en" ? "jp" : "en"
          );
          i18n.changeLanguage(i18n.language === "en" ? "jp" : "en");

          if (lang === "en") {
            console.log(lang);
            dispatch(setSelectLang("jp"));
          } else if (lang === "jp") {
            console.log(lang);
            dispatch(setSelectLang("en"));
          }
        }}
        color="var(--primary-color)"
      />
      
    </div>
  );
}

export default SecondaryLeftBar;
