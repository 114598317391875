import React from "react";
import { useSelector } from "react-redux/es/hooks/useSelector";

function NavigationButton({
  className = "",
  title,
  icon,
  selected,
  ...otherProps
}) {
  const lang = useSelector((state) => state.lang);

  return (
    <div
      className={`navigation-button ${className} ${selected ? "navigation-selected" : ""
        }`}
      {...otherProps}
      data-lang={lang}
    >
      {icon}
      <span>{title}</span>

    </div>
  );
}

export default NavigationButton;

// Analytics Component

// import React from "react";
// import { useDispatch } from "react-redux";

// function NavigationButton({
//   className = "",
//   title,
//   icon,
//   selected,
//   ...otherProps
// }) {
//   const dispatch = useDispatch();

//   // Function to track the navigation button click event
//   const trackButtonClickEvent = () => {
//     // Track the event using pirsch-extended.js
//     if (typeof pirsch !== "undefined") {
//       pirsch("Navigation Button Clicked", {
//         meta: {
//           buttonTitle: title,
//           selected: selected,
//         },
//       });
//     }

//     // Assuming you want to update the state when the button is clicked
//     dispatch(setSelectMenu(title));
//   };

//   return (
//     <div
//       className={`navigation-button ${className} ${
//         selected ? "navigation-selected" : ""
//       }`}
//       {...otherProps}
//       onClick={trackButtonClickEvent} // Track the event when the button is clicked
//     >
//       {icon}
//       <span>{title}</span>
//     </div>
//   );
// }

// export default NavigationButton;
