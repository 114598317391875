import Tag from "../atoms/Tag";
import { ReactComponent as AllIcon } from "../../assets/icons/all.svg";
import { ReactComponent as FavIcon } from "../../assets/icons/fav.svg";
import { useDispatch, useSelector } from "react-redux";
import { layout, layoutJp } from "../../config";
import { setOpenLoginPopup } from "../../redux/actions/user";
import { useEffect } from "react";
import React from "react";
import pirsch from "../../Analytics";

function TagsFilter({ category, value = "All", onChange = () => {} }) {
  const numberItems = useSelector((state) => state.numberItems);
  const favsItems = useSelector((state) => state.favsItems);
  const user = useSelector((state) => state.user);
  const lang = useSelector((state) => state.lang);
  const dispatch = useDispatch();

  const handleSelect = (val) => {
    if (val === value) {
      onChange("All");
    } else {
      onChange(val);
    }
  };

  const favsCategory = (category) => {
    const capitalized = category.charAt(0).toUpperCase() + category.slice(1);
    return "my" + capitalized;
  };

  return (
    <div className="tags-filter">
      <div className="tags-filter-top" data-lang={lang}>
        <Tag
          title={lang === "en" ? "ALL" : "すべて"}
          selected={value === "All"}
          leftIcon={<AllIcon />}
          rightIcon={numberItems && numberItems[category.toLowerCase()]}
          onClick={() => {
            handleSelect("All");
            pirsch("Tag-ALL()");
          }}
        />
        <Tag
          title={lang === "en" ? "Favs" : "お気に入り"}
          selected={value === "Favs"}
          leftIcon={<FavIcon />}
          rightIcon={
            favsItems[favsCategory(category.toLowerCase())] != null
              ? favsItems[favsCategory(category.toLowerCase())].length
              : "0"
          }
          onClick={() =>{
            user ? handleSelect("Favs") : dispatch(setOpenLoginPopup(true));
            pirsch("Tag-FAVS()");
          }}
          className="favs-tag"
        />
      </div>

      {lang == "en"
        ? layout[category.toLowerCase()].tags.map((tag, key) => {
            return (
              <Tag
                key={key}
                title={tag}
                selected={value === layout[category.toLowerCase()].tags[key]}
                onClick={() => {
                  handleSelect(tag);
                  pirsch(layout[category.toLowerCase()].eventTags[key]);
                }}
              />
            );
          })
        : layoutJp[category.toLowerCase()].tags.map((tag, key) => {
            return (
              <Tag
                key={key}
                title={tag}
                selected={value === layout[category.toLowerCase()].tags[key]}
                onClick={() => {
                  handleSelect(layout[category.toLowerCase()].tags[key]);
                  pirsch(layout[category.toLowerCase()].eventTags[key]);
                }}
              />
            );
          })}



          
    </div>
  );
}

export default TagsFilter;

