import { ReactComponent as Error404Hor } from "../assets/icons/error404.svg";
import { ReactComponent as Error404Ver } from "../assets/icons/error404_02.svg";
import { ReactComponent as BackIcon } from "../assets/icons/back.svg";
import { useEffect, useState } from "react";

import Button from "../components/atoms/Button";
import "../styles/main.scss";
import React from "react";
import { setSelectMenu } from "../redux/actions/env";
import { useDispatch, useSelector } from "react-redux";


function Error404() {

  useEffect(() => {
    document.title = "UUD - 404";
    return () => {
      document.title = "UUD";
    };
  }, []);

  const dispatch = useDispatch();

  return (
    <div className="uxersoon-error-page">
      <div className="error-page-box">
        <div className="logo-box">
          <Error404Hor className="error-page-logo_hor" />
          <Error404Ver className="error-page-logo_ver" />
          <h3>Page Not Found!</h3>
          <h4   onClick={() => {
                  window.location = "/homepage";
                }}
          >ぺーじを見つけません!</h4>
        </div>
        <Button
          rightIcon={<BackIcon />}
          className="error-page_btn"
          onClick={() => {
            window.location = "/";
            dispatch(setSelectMenu("Tools"));
          }}        >
          Back to HOME!
        </Button>
      </div>
    </div>
  );
}

export default Error404;
