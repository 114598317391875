import { useDispatch, useSelector } from "react-redux";
import SecondaryLayout from "../components/layout/SecondaryLayout";
import Popup from "../components/molecules/Popup";
import { ReactComponent as Uxer } from "../assets/icons/uxers.svg";
import { ReactComponent as TimerIcon } from "../assets/icons/timer.svg";
import { ReactComponent as Hand2 } from "../assets/icons/hand2.svg";

import { ReactComponent as UserNameIcon } from "../assets/icons/id_icon.svg";
import { ReactComponent as MailIcon } from "../assets/icons/mail.svg";
import { ReactComponent as BagIcon } from "../assets/icons/bag.svg";
import { ReactComponent as FlagIcon } from "../assets/icons/flag.svg";
import { ReactComponent as LoginIcon } from "../assets/icons/login.svg";
import { ReactComponent as LogoIcon } from "../assets/icons/logo_05.svg";

import Button from "../components/atoms/Button";
import Select from "../components/atoms/Select";
import "../styles/comingSoonCard.scss";
import Input from "../components/atoms/Input";
import { useEffect, useState } from "react";
import React from "react";
import Signup from "../components/templates/signup";
import Layout from "../components/layout/Layout";
import StaticPageTitle from "../components/atoms/StaticPageTitle";
import StaticPageSlogan from "../components/atoms/StaticPageSlogan";
import StaticPageArticle from "../components/organismes/StaticPageArticle";
import StaticPageOrderedList from "../components/organismes/StaticPageOrderedList";
import StaticPageParagraph from "../components/organismes/StaticPageParagraph";
import StaticPageParagraphImage from "../components/organismes/StaticPageParagraphImage";
import StaticPageOrderedList2 from "../components/organismes/StaticPageOrderedList2";
import StaticPageSubscribe from "../components/organismes/StaticPageSubscribe";
import StaticPageBigImage from "../components/organismes/StaticPageBigImage";

function ComingSoon({}) {

  useEffect(() => {
    document.title = "UUD - Coming Soon Form";
    return () => {
      document.title = "UUD";
    };
  }, []);


  const user = useSelector((state) => state.user);
  const [showRightBar, setShowRightBar] = useState(false);
  const [showLeftBar, setShowLeftBar] = useState(false);
  const lang = useSelector((state) => state.lang);

  return (
    <Layout
      id="comingsoon"
      selectedMenu={""}
      currentPage="comingsoon"
      isLogin={user !== "" && user}
      setShowLeftBar={(res) => setShowLeftBar(res)}
      setShowRightBar={(res) => setShowRightBar(res)}
      showRightBar={showRightBar}
      showLeftBar={showLeftBar}
      actionMenu={false}
    >
      <div className="static-page-hero">
        <div className="static-page">
          <StaticPageSlogan
            text={
              <>
                A place that UX/UI Designers could call
                <span className="sp-slogan"> Home!</span>
              </>
            }
          />

          <div className="soon-paragraph-cont" data-lang={lang}>
            <p className="soon-paragraph" data-lang={lang}>
              {lang === "en" ? (
                <>
                  We're thrilled to share a sneak peek of what's coming up on
                  UUD, your go-to platform for UX/UI Designers. <br />
                  Right now, you're exploring as a guest, but guess what?
                  <sapn className="soon-subheader">
                    {" "}
                    Sign-ups are Opening Soon!
                  </sapn>{" "}
                </>
              ) : (
                <>
                  喜んでお知らせいたします！UUDは、UX/UIデザイナーのための必須プラットフォームで、今後の展開の一部をちょっとだけお見せできることに興奮しています。
                  現在はゲストとしてご利用いただいていますが、驚くことに、まもなく
                  <span className="soon-subheader">
                    サインアップを受け付け開始いたします！
                  </span>{" "}
                </>
              )}
            </p>
            <br />
            <StaticPageBigImage
              imgSrc1Small={"img4.png"}
              imgSrc1Large={"img3.png"}
            />
            <br />
            <h4 className="soon-paragraph-title">
              {lang === "en" ? (
                <>What You Get when you Signup:</>
              ) : (
                <>サインアップすると何が手に入ります!</>
              )}
            </h4>
            <p className="soon-paragraph" data-lang={lang}>
              {lang === "en" ? (
                <>
                  <span>My Page </span>Your personal space to save favorite
                  links and your blog, social media, or projects. share them
                  with friends. <br />
                  <span>Bookmarking (Favs)</span> Easily keep track of your
                  most-loved content. <br />
                  <span>UX/UI Designers Directory (UXERS)</span> Connect with
                  new opportunities and fellow UXUI designers.
                </>
              ) : (
                <>
                  <span>マイページ</span>
                  お気に入りのリンクやブログ、ソーシャルメディア、プロジェクトを保存するための個人スペース。それらを友人と共有できます。{" "}
                  <br />
                  <span>ブックマーク(お気に入り)</span>
                  お気に入りのコンテンツを簡単に追跡できます。 <br />
                  <span>UX/UIデザイナーズディレクトリ(UXERS) </span>{" "}
                  新しいチャンスや仲間のUXUIデザイナーとつながることができます。
                </>
              )}
            </p>
            <br />

            <h4 className="soon-paragraph-title" data-lang={lang}>
              {lang === "en" ? <>Upcoming Services:</> : <>来るべきサービス!</>}
            </h4>
            <p className="soon-paragraph" data-lang={lang}>
              {lang === "en" ? (
                <>
                  We're planning to introduce more exciting features:
                  <br />
                  UXUI Designers exclusive <span>Job Listings</span>,{" "}
                  <span>Interviews</span>.<br />
                  <span>Messaging (Inbox)</span> to connect directly with other
                  Users. <br />
                  Also adding more resource categories{" "}
                  <span>Design Mobile Apps</span>
                  <span>Podcast</span>) and more...
                </>
              ) : (
                <>
                  私たちはよりエキサイティングな機能を導入する予定です：
                  <br />
                  UXUIデザイナー専用の <span>求人情報、</span>,{" "}
                  <span>インタビュー</span>.<br />
                  他のユーザーと直接つながるための <span>
                    メッセージング
                  </span>。 <br />
                  さらに、<span>モバイルアプリ</span>
                  <span>ポッドキャスト・Vlog</span>
                  )、などのリソースカテゴリを追加予定です。
                </>
              )}
            </p>
            <br />

            <h4 className="soon-paragraph-title" data-lang={lang}>
              {lang === "en" ? (
                <>Be the First In Line! </>
              ) : (
                <>最初の一人になろう! </>
              )}
            </h4>
            <p className="soon-paragraph" data-lang={lang}>
              {lang === "en" ? (
                <>
                  To be among the first to sign up, fill out the form below with
                  your name, email, role, and country.
                </>
              ) : (
                <>
                  最初にサインアップするために、以下のフォームにお名前、メールアドレス、役職、国を記入してください。
                </>
              )}
            </p>
            <br />

            <div className="soon-form-cont" data-lang={lang}>
              <div className="soon-inputs-cont" data-lang={lang}>
                <Input
                  data-lang={lang}
                  leftIcon={<UserNameIcon />}
                  placeholder={lang === "en" ? "Username" : "ユーザーネーム"}
                  rightIcon={null}
                  maxLength={16}
                  onPaste={(e) => {
                    e.preventDefault();
                  }}
                  value={null}
                  onChange={(e) => {}}
                  error={null}
                />
                <Input
                  id="email"
                  data-lang={lang}
                  leftIcon={<MailIcon />}
                  placeholder={lang === "en" ? "Email" : "メールアドレス"}
                  rightIcon={null}
                  maxLength={16}
                  onPaste={(e) => {
                    e.preventDefault();
                  }}
                  value={null}
                  onChange={(e) => {}}
                  error={null}
                />
                <Input
                  data-lang={lang}
                  leftIcon={<FlagIcon />}
                  placeholder={lang === "en" ? "Your Location" : "現在地"}
                  rightIcon={null}
                  maxLength={16}
                  onPaste={(e) => {
                    e.preventDefault();
                  }}
                  value={null}
                  onChange={(e) => {}}
                  error={null}
                />                <Input
                  data-lang={lang}
                  leftIcon={<BagIcon />}
                  placeholder={lang === "en" ? "Role" : "役職"}
                  rightIcon={null}
                  maxLength={16}
                  onPaste={(e) => {
                    e.preventDefault();
                  }}
                  value={null}
                  onChange={(e) => {}}
                  error={null}
                />

              </div>
              <Button
                rightIcon={<LoginIcon />}
                className=""
                onClick={() => null}
                data-lang={lang}
              >
                {lang === "en" ? "Register" : "登録する"}
              </Button>
            </div>
            <br />
            <p className="soon-paragraph" data-lang={lang}>
              {lang === "en" ? (
                <>
                  We'll send you a direct link 24 hours before sign-ups open.{" "}
                  <br />
                  This way, you can secure your preferred <span>
                    Username
                  </span>.{" "}
                </>
              ) : (
                <>
                  サインアップが開始される24時間前に、直接リンクをお送りします。
                  <br />
                  これにより、希望の<span>ユーザーネーム</span>
                  を確保することができます。
                </>
              )}
            </p>
            <div className="soon-username-sample">
              <span>uud.io/</span>your_username
            </div>
            <br />
            <p className="soon-paragraph" data-lang={lang}>
              {lang === "en" ? (
                <>
                  We're excited to welcome you to the UUD community. <br />
                  Get ready for a journey filled with inspiration and
                  creativity!
                </>
              ) : (
                <>
                  UUDコミュニティへの参加をお待ちしております。
                  <br />
                  インスピレーションと創造性に満ちた旅に備えてください！
                </>
              )}
            </p>
          </div>

          {/* ------------------------------------------------------------------------------------------------------------ */}
        </div>
      </div>
    </Layout>
  );
}

export default ComingSoon;
