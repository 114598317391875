import { useEffect, useRef, useState } from "react";
import RightBarLayout from "./RightBarLayout";
import { layout } from "../../config";
import { Helmet } from "react-helmet";
import SecondaryLeftBar from "./SecondaryLeftBar";
import React from "react";

function SecondaryLayout({
  selectedMenu = "SIGNUP",
  children,
  isLogin,
  id,
  currentPage,
}) {
  const [showRightBar, setShowRightBar] = useState(false);
  const scrollRef = useRef();

  useEffect(() => {
    scrollRef.current.scrollTop = 0;
  }, [selectedMenu]);

  return (
    <div
      id="secondary-layout"
      style={{
        "--primary-color": layout[selectedMenu.toLowerCase()].backgroundColor,
      }}
    >
      <Helmet>
        <meta
          name="theme-color"
          content={layout[selectedMenu.toLowerCase()].backgroundColor}
        />
      </Helmet>

      <div className="layout-container">
        <SecondaryLeftBar />

        <div
          className={`content-wrapper ${
            currentPage == "signup" && "content-wrapper-left"
          }`}
          id={id}
          ref={scrollRef}
        >
          {children}
        </div>

        <RightBarLayout
          isLogin={isLogin}
          showBarMobile={showRightBar}
          setShowBarMobile={setShowRightBar}
          currentPage="signup"
        />
      </div>
    </div>
  );
}

export default SecondaryLayout;
