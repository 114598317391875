export const setItems = (items) => {
  return {
    type: "SETITEMS",
    payload: items,
  };
};

export const setFavsItems = (items) => {
  return {
    type: "SETFAVSITEMS",
    payload: items,
  };
};

export const setNumberItems = (nbItems) => {
  return {
    type: "SETNUMBERITEMS",
    payload: nbItems,
  };
};

export const setUxers = (uxers) => {
  return {
    type: "SETUXERS",
    payload: uxers,
  };
};
