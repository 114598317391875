import axios from 'axios';
import { BACKEND_URL } from "../config";
import { getLocalStorageItem, removeLocalStorageItem } from './localStorage';

export const adminApi = axios.create({
  baseURL: BACKEND_URL,
});

adminApi.interceptors.request.use((config) => {
  const token = getLocalStorageItem('admin-token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

adminApi.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && (error.response.status === 401)) {
      removeLocalStorageItem('role');
      removeLocalStorageItem('admin-token');
      window.location.href = '/';
    }
    return Promise.reject(error);
  }
);