import axios from "axios";

export default function pirsch(name) {
  axios
    .post(`https://api.pirsch.io/event`, {
      event_duration: 0,
      event_meta: {},
      event_name: name,
      identification_code: "Z1sNHnOHQLITjSYOi6Mt8YcFyUsLQqcL",
      referrer: "http%3A%2F%2Fuud.io%2F",
      screen_height: window.innerHeight,
      screen_width: window.innerWidth,
      title: document.href,
      url: window.location.href,
    })
    .then((res) => {})
    .catch((err) => {});
}
