function StatistiqueIcon({ icon, title, data }) {
  return (
    <div className="statistique-icon">
      <div className="statistique-body">
        {icon}
        <div className="statistique-title">{title}</div>
      </div>
      <div className="statistique-data">{data}</div>
    </div>
  );
}

export default StatistiqueIcon;
