import { useDispatch, useSelector } from "react-redux";
import Layout from "../components/layout/Layout";
import { ReactComponent as PrivacyIcon } from "../assets/icons/privacy.svg";
import { ReactComponent as TermsIcons } from "../assets/icons/terms.svg";
import "../styles/main.scss";
import StaticPageParagraph from "../components/organismes/StaticPageParagraph";
import { useEffect, useState } from "react";

function Privacy({ terms = false }) {
  useEffect(() => {
    document.title = "UUD - Privacy/Terms";
    return () => {
      document.title = "UUD";
    };
  }, []);

  const user = useSelector((state) => state.user);
  const [showRightBar, setShowRightBar] = useState(false);
  const [showLeftBar, setShowLeftBar] = useState(false);

  const [isPolicyVisible, setIsPolicyVisible] = useState(!terms);
  const [isTermsVisible, setIsTermsVisible] = useState(terms);
  const [activePolicyIcon, setActivePolicyIcon] = useState(!terms);
  const [activeTermsIcon, setActiveTermsIcon] = useState(terms);
  const lang = useSelector((state) => state.lang);

  const handleClickPolicy = () => {
    setIsPolicyVisible(true);
    setIsTermsVisible(false);
    setActivePolicyIcon(true);
    setActiveTermsIcon(false);
  };

  const handleClickTerms = () => {
    setIsPolicyVisible(false);
    setIsTermsVisible(true);
    setActivePolicyIcon(false);
    setActiveTermsIcon(true);
  };

  return (
    <Layout
      id="privacy"
      selectedMenu={""}
      currentPage="Privacy"
      isLogin={user !== "" && user}
      setShowLeftBar={(res) => setShowLeftBar(res)}
      setShowRightBar={(res) => setShowRightBar(res)}
      showRightBar={showRightBar}
      showLeftBar={showLeftBar}
      actionMenu={false}
    >
      <div className="static-page-privacy-hero">
        <div className="static-page">
          <div className="static-page-innerbox">
            <div className="tab-hero">
              <div
                className={`tab-title-box ${activePolicyIcon ? "active" : ""}`}
                id="Policy"
                onClick={handleClickPolicy}
              >
                <h2>Privacy Policy</h2>
              </div>
              |
              <div
                className={`tab-title-box ${activeTermsIcon ? "active" : ""}`}
                id="Terms"
                onClick={handleClickTerms}
              >
                <h2>Terms of Use</h2>
              </div>
            </div>

            {/* ------ */}
            <div
              id="Policy-content"
              className={isPolicyVisible ? "visible-tab" : "hidden-tab"}
            >
              <StaticPageParagraph
                text={
                  <div className="tab-content-hero">
                    <div className="policy_terms_title" data-lang={lang}>
                      <PrivacyIcon />
                      {lang === "en" ? (
                        <>Privacy Policy</>
                      ) : (
                        <>プライバシーポリシー</>
                      )}
                    </div>
                    {/* ------------------------------------------------------------------------------------ */}
                    {lang === "en" ? (
                      <>
                        {/* ---------------------- ENGLISH */}
                        <p>
                          Thank you for Using UUD (UXUI Designers).
                          <br />
                          We are dedicated to safeguarding your privacy and want
                          you to understand how we collect, use, and protect
                          your personal information. Please take a moment to
                          read this combined Privacy Policy, which applies to
                          both platforms.
                        </p>
                        <br />
                        <span>1. Purpose of Using Personal Information</span>
                        <br />
                        <p>
                          We collect personal information, including names,
                          addresses, phone numbers, email addresses, dates of
                          birth, and credit card numbers, for various purposes,
                          including: - Processing payments and delivering
                          goods/services
                          <br />
                          - Sending informative emails about products, services,
                          and promotions
                          <br />
                          - Delivering prizes to contest winners
                          <br />
                          - Conducting demand research and developing services
                          <br />
                          - Facilitating recruitment activities
                          <br />
                        </p>
                        <br />
                        <span>
                          2. Disclosure and Provision to Third Parties
                        </span>
                        <br />
                        <p>
                          We do not disclose your information to third parties,
                          except:
                          <br />
                          - With your consent
                          <br />
                          - When outsourcing services to provide UUD services
                          <br />
                          - When required by financial institutions for payment
                          processing
                          <br />
                          - In the event of business succession due to mergers,
                          separations, or transfers
                          <br />
                          - To protect human life or rights
                          <br />- In response to requests from judicial or
                          public authorities as per laws and regulations
                        </p>
                        <br />
                        <span>3. Management of Personal Information</span>
                        <br />
                        <p>
                          We employ strict measures to prevent unauthorized
                          access, loss, or alteration of your personal
                          information. Our dedicated manager ensures compliance
                          with laws and regulations. In certain situations, if
                          there is a violation of our terms or operational
                          hindrances, we reserve the right to delete customer
                          information without notice.
                        </p>
                        <br />
                        <span>4. Cookies and Tracking</span>
                        <br />
                        <p>
                          We may collect user behavior information through
                          cookies to analyze access patterns, enhance customer
                          service, and for personalized content. Users can
                          refuse cookies, but some features may require cookie
                          acceptance. We also use cookies and log files for
                          analytics, personalization, and ad targeting. You can
                          manage cookie preferences through your browser
                          settings.
                        </p>
                        <br />
                        <span>5. Data Retention and User Rights</span>
                        <br />
                        <p>
                          We retain your information for necessary periods and
                          respect your rights, including access, rectification,
                          objection, restriction, and data portability. You can
                          also withdraw consent and request deletion of your
                          data, except for legal obligations.
                        </p>
                        <br />
                        <span>6. Protection of Children's Privacy</span>
                        <br />
                        <p>
                          We do not knowingly collect information from children
                          under 13. Parents can contact us to remove their
                          child's information.
                        </p>
                        <br />
                        <span>7. Changes to Privacy Policy</span>
                        <br />
                        <p>
                          We will notify you of any material changes to this
                          policy. Please check our website periodically for
                          updates.
                        </p>
                        <br />
                        <span>8. Contact Information</span>
                        <br />
                        <p>
                          For queries, requests, complaints, or consultations
                          related to personal information, please contact us at:
                          <br />
                          [policy@uud.io]
                        </p>
                        <br />
                        <p>
                          By using our services, you consent to this Privacy
                          Policy and agree to its terms. Thank you for trusting
                          UUD your privacy.
                        </p>
                        {/* --------------------------------- ENGLISH END */}
                      </>
                    ) : (
                      <>
                        {/* ---------------------- JAPANESE */}
                        <p className="paragraph contact-text" data-lang={lang}>
                          UUD（UXUIデザイナー）をご利用いただき、ありがとうございます。
                          <br />
                          当社は、お客様のプライバシーを保護することに専念しており、お客様の個人情報を収集し、使用し、保護する方法を理解していただくために、この統合されたプライバシーポリシーをご一読いただくお時間をいただきたく存じます。このプライバシーポリシーは、両プラットフォームに適用されます。
                        </p>
                        <br />
                        <span>1. 個人情報の利用目的</span>
                        <br />
                        <p className="paragraph contact-text" data-lang={lang}>
                          当社は、お名前、住所、電話番号、メールアドレス、生年月日、クレジットカード番号など、以下の目的で個人情報を収集します：
                          - 支払いの処理と商品/サービスの提供
                          <br />
                          -
                          製品、サービス、プロモーションに関する情報提供のためのメールの送信
                          <br />
                          - コンテストの受賞者に賞品を提供する
                          <br />
                          - 需要調査の実施とサービスの開発
                          <br />
                          - 採用活動のサポート
                          <br />
                        </p>
                        <br />
                        <span>2. 第三者への開示と提供</span>
                        <br />
                        <p className="paragraph contact-text" data-lang={lang}>
                          当社は、お客様の情報を以下の場合を除き、第三者に開示しません：
                          <br />
                          - お客様の同意がある場合
                          <br />
                          -
                          UUDサービスを提供するために外部委託業者にサービスを委託する場合
                          <br />
                          - 支払い処理のために金融機関に必要な場合
                          <br />
                          - 合併、分離、または譲渡による事業の承継が発生した場合
                          <br />
                          - 人の生命や権利を保護する必要がある場合
                          <br />-
                          法律や規制に従って司法または公的機関からの要請に応じる場合
                        </p>
                        <br />
                        <span>3. 個人情報の管理</span>
                        <br />
                        <p className="paragraph contact-text" data-lang={lang}>
                          当社は、お客様の個人情報への不正アクセス、紛失、変更を防ぐための厳格な対策を講じています。専任のマネージャーが法令の遵守を確認しています。
                          <br />
                          特定の状況では、当社の利用規約の違反や運用上の障害がある場合、予告なしにお客様の情報を削除する権利を留保しています。
                        </p>
                        <br />
                        <span>4. クッキーとトラッキング</span>
                        <br />
                        <p className="paragraph contact-text" data-lang={lang}>
                          アクセスパターンの分析、顧客サービスの向上、パーソナライズドコンテンツの提供のために、クッキーを通じてユーザーの行動情報を収集する場合があります。ユーザーはクッキーを拒否することができますが、一部の機能はクッキーの受け入れを必要とする場合があります。また、アナリティクス、パーソナライゼーション、広告ターゲティングのためにクッキーとログファイルを使用します。クッキーの設定は、ブラウザの設定で管理できます。
                        </p>
                        <br />
                        <span>5. データの保持とユーザーの権利</span>
                        <br />
                        <p className="paragraph contact-text" data-lang={lang}>
                          当社は、必要な期間だけお客様の情報を保持し、アクセス、訂正、異議、制限、データの携帯性など、お客様の権利を尊重します。また、法的義務を除いて、同意の撤回とデータの削除をリクエストすることができます。
                        </p>
                        <br />
                        <span>6. 未成年者のプライバシー保護</span>
                        <br />
                        <p className="paragraph contact-text" data-lang={lang}>
                          当社は、13歳未満の子どもから意図的に情報を収集しません。保護者は、自分の子どもの情報を削除するために当社に連絡することができます。
                        </p>
                        <br />
                        <span>7. プライバシーポリシーの変更</span>
                        <br />
                        <p className="paragraph contact-text" data-lang={lang}>
                          本ポリシーに重大な変更がある場合は、お知らせいたします。定期的に当社のウェブサイトを確認して、更新情報をご確認ください。
                        </p>
                        <br />
                        <span>8. 連絡先情報</span>
                        <br />
                        <p className="paragraph contact-text" data-lang={lang}>
                          個人情報に関連するお問い合わせ、リクエスト、苦情、相談がある場合は、以下の連絡先までお問い合わせください：
                          <br />
                          [policy@uud.io]
                        </p>
                        <br />
                        <p className="paragraph contact-text" data-lang={lang}>
                          当社のサービスをご利用いただくことで、このプライバシーポリシーに同意し、その条件に同意したものとみなされます。
                          UUDにお客様のプライバシーをご信頼いただき、ありがとうございます。
                        </p>
                        {/* -------------------------------- JAPANESE  END*/}
                      </>
                    )}
                    {/* ------------------------------------------------------------------------------------ */}
                  </div>
                }
              />
            </div>
            {/* ---------- */}
            <div
              id="Terms-content"
              className={isTermsVisible ? "visible-tab" : "hidden-tab"}
            >
              <StaticPageParagraph
                text={
                  <div className="tab-content-hero">
                    <div className="policy_terms_title" data-lang={lang}>
                      <TermsIcons />
                      {lang === "en" ? <>Terms of Use</> : <>利用規約</>}
                    </div>

                    <div>
                      {/* ------------------------------------------------------------------------------------ */}
                      {lang === "en" ? (
                        <>
                          {/* ---------------------- ENGLISH */}
                          <p>
                            Welcome to UXUI Designers (UUD), your go-to resource
                            platform for UX/UI designers. By accessing or using
                            our services, you agree to comply with and be bound
                            by the following terms and conditions. Please read
                            these Terms of Use carefully before using our
                            platform.
                          </p>
                          <br />

                          <span>1. Acceptance of Terms</span>
                          <br />
                          <p>
                            By accessing or using UUD, you agree to these Terms
                            of Use and our Privacy Policy. If you do not agree
                            with these terms, please do not use our services.
                          </p>
                          <br />

                          <span>2. User Responsibilities</span>
                          <br />
                          <p>
                            You must not engage in any of the following
                            activities while using UUD:{" "}
                          </p>
                          <ul>
                            <li>
                              <span>Unauthorized Access:</span> You must not
                              attempt to gain unauthorized access to any part of
                              UUD, other user accounts, or computer systems or
                              networks connected to UUD through hacking,
                              password mining, or any other means.
                            </li>
                            <li>
                              <span>Malicious Activities:</span> You must not
                              engage in any activity that disrupts, interferes
                              with, or harms the integrity or performance of UUD
                              or its users' experience, including but not
                              limited to distributing malware, spamming, or
                              engaging in denial-of-service attacks.
                            </li>
                            <li>
                              <span>Content Violations:</span> You must not
                              upload, post, or transmit any content that is
                              unlawful, harmful, threatening, abusive,
                              defamatory, obscene, or otherwise objectionable.
                            </li>
                            <li>
                              <span>Impersonation:</span> You must not
                              impersonate any person or entity, or falsely state
                              or otherwise misrepresent your affiliation with a
                              person or entity.
                            </li>
                          </ul>
                          <br />

                          <span>3. Data Removal and Deletion</span>
                          <br />
                          <p>
                            UUD reserves the right to remove, delete, or modify
                            any data, content, or information on the platform
                            without prior notice, at our sole discretion. This
                            includes, but is not limited to, user accounts,
                            posts, and uploaded files.
                          </p>
                          <br />

                          <span>
                            4. User Relations and Information Accuracy
                          </span>
                          <br />
                          <p>
                            UUD is not responsible for the relationships
                            established between users or the accuracy,
                            reliability, or validity of the information provided
                            by users. Users are solely responsible for verifying
                            the authenticity of the information exchanged with
                            other users on UUD.
                          </p>
                          <br />

                          <span>5. Disclaimer of Warranties</span>
                          <br />
                          <p>
                            UUD provides its services on an "as-is" and
                            "as-available" basis without any warranty or
                            condition, express, implied, or statutory. We do not
                            warrant that the services will be uninterrupted,
                            timely, secure, or error-free.
                          </p>
                          <br />

                          <span>6. Limitation of Liability</span>
                          <br />
                          <p>
                            In no event shall UUD be liable for any indirect,
                            incidental, special, consequential, or punitive
                            damages, or any loss of profits or revenues, whether
                            incurred directly or indirectly, or any loss of
                            data, use, goodwill, or other intangible losses,
                            resulting from your use or inability to use our
                            services.
                          </p>
                          <br />

                          <span>7. Changes to Terms of Use</span>
                          <br />
                          <p>
                            We reserve the right to modify these Terms of Use at
                            any time. We will notify you of any changes by
                            posting the updated terms on UUD. Your continued use
                            of our services after the effective date of such
                            changes constitutes your acceptance of the modified
                            terms.
                          </p>
                          <br />

                          <span>8. Contact Information</span>
                          <br />
                          <p>
                            If you have any questions about these Terms of Use,
                            please contact us at: [policy@uud.io]
                            <br />
                            <br />
                            Thank you for using UUD (UXUI Designers). We hope
                            you find our services valuable and enjoy your
                            experience on our platform.
                          </p>
                          {/* --------------------------------- ENGLISH END */}
                        </>
                      ) : (
                        <>
                          {/* ---------------------- JAPANESE */}
                          <p
                            className="paragraph contact-text"
                            data-lang={lang}
                          >
                            <span>ようこそ、UXUIデザイナーズ（UUD）へ</span>
                            <br />
                            UX/UIデザイナーのためのリソースプラットフォーム、UXUIデザイナーズ（UUD）へようこそ。当サービスをご利用いただく際には、以下の利用規約に同意いただく必要があります。ご利用の前に、これらの利用規約をよくお読みください。
                          </p>
                          <br />

                          <span>1. 利用規約の受諾</span>
                          <br />
                          <p
                            className="paragraph contact-text"
                            data-lang={lang}
                          >
                            UUDにアクセスするか、またはUUDのサービスを利用することで、これらの利用規約とプライバシーポリシーに同意したものとみなされます。これらの条件に同意いただけない場合は、当サービスをご利用いただけません。
                          </p>
                          <br />

                          <span>2. ユーザーの責任</span>
                          <br />
                          <p
                            className="paragraph contact-text"
                            data-lang={lang}
                          >
                            UUDを利用する際、以下の活動には一切関与しないでください：
                          </p>
                          <p
                            className="paragraph contact-text"
                            data-lang={lang}
                          >
                            <ul>
                              <li>
                                <span>未許可のアクセス：</span>{" "}
                                UUDの一部、他のユーザーアカウント、またはUUDに接続されたコンピューターシステムやネットワークへの未許可のアクセスを試みてはいけません。これには、ハッキング、パスワードの採掘、その他の手段による試みが含まれます。
                              </li>
                              <li>
                                <span>悪意のある活動：</span>{" "}
                                UUDの整合性やパフォーマンスを妨げ、妨害し、または害する活動には参加してはいけません。これには、マルウェアの配布、スパムの送信、およびサービスの妨害を目的とした攻撃などが含まれます。
                              </li>
                              <li>
                                <span>コンテンツの違反：</span>{" "}
                                違法、有害、脅迫的、虐待的、中傷的、わいせつ、または他に反感を抱かせるようなコンテンツをアップロード、投稿、または送信してはいけません。
                              </li>
                              <li>
                                <span>なりすまし：</span>{" "}
                                他の人物や団体をなりすますことはできません。また、自分が特定の人物や団体と関係があると虚偽の陳述をしたり、他の方法で自身の所属関係を誤って表示してはいけません。
                              </li>
                            </ul>
                          </p>
                          <br />

                          <span>3. データの削除と削除</span>
                          <br />
                          <p
                            className="paragraph contact-text"
                            data-lang={lang}
                          >
                            UUDは、予告なく、独自の裁量でプラットフォーム上のデータ、コンテンツ、または情報を削除、削除、または変更する権利を留保します。これには、ユーザーアカウント、投稿、およびアップロードされたファイルが含まれます。
                          </p>
                          <br />

                          <span>4. ユーザー関係と情報の正確性</span>
                          <br />
                          <p
                            className="paragraph contact-text"
                            data-lang={lang}
                          >
                            UUDは、ユーザー間で確立された関係や、ユーザーが提供する情報の正確性、信頼性、または妥当性について責任を負いません。ユーザーは、UUD上で他のユーザーと交換する情報の真正性を確認する責任を負います。
                          </p>
                          <br />

                          <span>5. 保証の免責事項</span>
                          <br />
                          <p
                            className="paragraph contact-text"
                            data-lang={lang}
                          >
                            UUDは、明示、黙示、または法的な保証または条件なしで、サービスを提供します。サービスが中断されないこと、タイムリーであること、安全であること、またはエラーがないことを保証しません。
                          </p>
                          <br />

                          <span>6. 責任の制限</span>
                          <br />
                          <p
                            className="paragraph contact-text"
                            data-lang={lang}
                          >
                            UUDは、あなたのサービスの利用または利用不能に起因する、間接的な損害、付随的な損害、特別な損害、結果的な損害、罰則的な損害、または利益や収益の損失、直接的または間接的に生じた場合でも、一切の責任を負いません。また、データ、利用、善意、または他の無形の損失の喪失も含まれます。
                          </p>
                          <br />

                          <span>7. 利用規約の変更</span>
                          <br />
                          <p
                            className="paragraph contact-text"
                            data-lang={lang}
                          >
                            当社は、これらの利用規約をいつでも変更する権利を留保します。変更がある場合は、更新された条件をUUDに掲示することでお知らせいたします。変更後も当サービスをご利用いただくことは、変更後の利用規約に同意したものとみなされます。
                          </p>
                          <br />

                          <span>8. 連絡先情報</span>
                          <br />
                          <p
                            className="paragraph contact-text"
                            data-lang={lang}
                          >
                            これらの利用規約に関するご質問がある場合は、以下の連絡先までお問い合わせください：[policy@uud.io]
                            <br />
                            <br />
                            UUD（UXUIデザイナーズ）のご利用ありがとうございます。当プラットフォームでのご利用が有益で、お楽しみいただけることを願っています。
                          </p>
                          {/* -------------------------------- JAPANESE  END*/}
                        </>
                      )}
                      {/* ------------------------------------------------------------------------------------ */}
                    </div>
                  </div>
                }
              />
            </div>
            {/* ------ */}
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Privacy;
