import { useSelector } from "react-redux/es/hooks/useSelector";
import React from "react";

function Button({
  children,
  onClick = () => {},
  leftIcon,
  rightIcon,
  className = "",
}) {
  const lang = useSelector((state) => state.lang);

  return (
    <button
      className={`button ${className}`}
      data-lang={lang}
      onClick={onClick}
    >
      {leftIcon && <div className="button-left-icon">{leftIcon}</div>}

      <span data-lang={lang}>{children}</span>

      {rightIcon && <div className="button-right-icon">{rightIcon}</div>}
    </button>
  );
}

export default Button;
