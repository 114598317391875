export const setUser = (user) => {
  return {
    type: "SETUSER",
    payload: user
  };
}

export const setOpenLoginPopup = (loginPopupStatus) => {
  return {
    type: "SETOPENLOGINPOPUP",
    payload: loginPopupStatus
  };
}

export const setOpenSignupPopup = (signupPopupStatus) => {
  return {
    type: "SETOPENSIGNUPPOPUP",
    payload: signupPopupStatus
  };
}