import ChatAvatar from "../molecules/ChatAvatar";
import { ReactComponent as Hand } from "../../assets/icons/hand1.svg";
import { ReactComponent as Trash } from "../../assets/icons/trash.svg";
import { ReactComponent as Report } from "../../assets/icons/report.svg";
import { ReactComponent as Archive } from "../../assets/icons/archive.svg";
import { ReactComponent as Attach } from "../../assets/icons/attach.svg";
import { deleteChat, toggleBlock, report } from "../../services/ChatService";
import { ReactComponent as Send } from "../../assets/icons/send.svg";
import { ReactComponent as NoResultIcon } from "../../assets/icons/no_favs.svg";
import { ReactComponent as Close } from "../../assets/icons/close.svg";
import PopupCard from "../organismes/popup/PopupCard";
import ChatInput from "../molecules/ChatInput";
import Message from "../organismes/message";
import { useState, useEffect } from "react";
import NoLoggedMessage from "../molecules/NoLoggedMessage";
import NoResultFoundCard from "../molecules/NoResultFoundCard";
import { useSelector } from "react-redux/es/hooks/useSelector";
import jwtDecode from "jwt-decode";
import { getUserInfo } from "../../services/authServices";
import { toggleArchive } from "../../services/ChatService";
import { ReactComponent as DefaultItem } from "../../assets/icons/default_item.svg";
import Popup from "../molecules/Popup";

function MessageGrid({
  messages,
  selectedChat,
  sendMessage,
  handleArchive,
  handleRemove,
  loading,
  setLoading,
  message,
  setMessage,
  archivedMessages,
  handleTyping,
  IsShowPopup,
  popType,
  popMessage,
  popups,
  getSender,
  messageNo,
}) {
  const user = useSelector((state) => state.user);
  const [popup, setPopup] = useState([
    {
      title: "Report",
      desc: "Help us understand the issue. What's the problem with this conversation?",
      checkBox: true,
      switch: true,
    },
    {
      title: "Delete",
      desc: "Help us understand the issue. What's the problem with this conversation?",
      checkBox: false,
      switch: false,
    },
    {
      title: "Block",
      desc: " will no longer be able to message you, and you will not see notifications from Him/Her",
      checkBox: false,
      switch: false,
    },
  ]);
  const [isPopup, setisPopup] = useState(false);
  const [number, setNumber] = useState(0);
  const [sender, setSender] = useState([]);
  const [blockedUsers, setBlockedUsers] = useState([]);

  useEffect(() => {
    if (selectedChat.length !== 0) {
      getUserInfo(
        selectedChat.users.find((other_user) => {
          return other_user.username !== jwtDecode(user).username;
        })._id
      ).then((res) => {
        if (res != undefined) {
          if (res.data.myUser != undefined) {
            setSender(res.data.myUser);
          } else {
            setSender(res.data);
          }
        }
      });
    }
  }, [selectedChat]);

  function handleUnblock(userId) {
    if (blockedUsers.includes(userId)) {
      setBlockedUsers((prev) => {
        let x = prev.filter((el) => {
          return el != userId;
        });
        return [...x];
      });
    }
  }
  function handleblock(userId) {
    if (!blockedUsers.includes(userId)) {
      setBlockedUsers((prev) => {
        prev.push(userId);
        return [...prev];
      });
    }
  }
  function closeIcons() {
    document.querySelectorAll(".message-header-cont").forEach((el) => {
      el.style.transform = "translateY(-80px)";
    });
    setTimeout(() => {
      document.querySelectorAll(".message-header-cont").forEach((el) => {
        el.style.display = "none";
      });
      document.querySelectorAll(".message-main").forEach((el) => {
        el.style.height = "calc(100% - 89.8px)";
      });
    }, 400);
  }

  function ShowReportCard(numbere) {
    if (isPopup) {
      setisPopup(false);
    } else {
      setisPopup(true);
      setNumber(numbere);
    }
  }

  useEffect(() => {
    getUserInfo(jwtDecode(user).id).then((res) => {
      if (res != undefined) {
        if (res.data.myUser != undefined) {
          setBlockedUsers(res.data.myUser.blockedUsers);
        } else {
          setBlockedUsers(res.data.blockedUsers);
        }
      }
    });
  }, []);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 300);
  }, [sender.profile_img]);
  useEffect(() => {
    console.log(messages);
  }, [messages]);
  return (
    <>
      {loading ? (
        <div className="w-full h-full flex justify-center items-center">
          <DefaultItem data-value="redirect" className="text-gray-400 w-28" />
        </div>
      ) : (
        <>
          {selectedChat.length != 0 ? (
            <>
              <div className="message-cont ">
                <div className="message-header-cont">
                  <ChatAvatar
                    size="!h-14 !w-14"
                    hide="true"
                    className="message-header-avatar "
                    country={sender.nationality}
                    nationality
                    name={sender.fullName}
                    username={sender.username}
                    role={sender.sub_role}
                    image={sender.profile_img}
                    userId={
                      selectedChat.length !== 0
                        ? selectedChat.users[0]._id
                        : null
                    }
                    clickable={true}
                  />
                  <div className="message-header-icon-cont">
                    {archivedMessages.includes(selectedChat) ? (
                      <Attach
                        className="message-header-icon"
                        onClick={() => handleArchive(selectedChat)}
                      />
                    ) : (
                      <Archive
                        className="message-header-icon"
                        onClick={() => handleArchive(selectedChat)}
                      />
                    )}

                    <Report
                      className="message-header-icon"
                      onClick={() => ShowReportCard(0)}
                    />
                    {!blockedUsers?.includes(getSender(selectedChat)._id) ? (
                      <Hand
                        className="message-header-icon"
                        onClick={() => ShowReportCard(2)}
                      />
                    ) : (
                      <Send
                        className="message-header-icon"
                        onClick={() =>
                          toggleBlock(getSender(selectedChat)._id, true)
                            .then((res) => {
                              popups("User unblocked successfully", "success");
                              handleUnblock(getSender(selectedChat)._id);
                            })
                            .catch(() =>
                              popups("Failed to block user", "error")
                            )
                        }
                      />
                    )}

                    <Trash
                      className="message-header-icon"
                      onClick={() => ShowReportCard(1)}
                    />
                    <Close
                      className="message-header-icon-close"
                      onClick={closeIcons}
                    />
                  </div>
                </div>
                <div className="message-main">
                  {messages?.length !== 0
                    ? messages?.map((el, key) => {
                        return (
                          <Message
                            content={el.content}
                            key={key}
                            sender={el.sender}
                            updatedAt={el.updatedAt}
                            image={el?.sender?.profile_img}
                          />
                        );
                      })
                    : null}
                </div>
                <ChatInput
                  selectedChat={selectedChat}
                  sendMessage={(res, res0, res1) =>
                    sendMessage(res, res0, res1)
                  }
                  handleUnblock={(res) => handleUnblock(res)}
                  blockedUsers={blockedUsers}
                  message={message}
                  setMessage={(res) => setMessage(res)}
                  handleTyping={(res) => handleTyping(res)}
                />
              </div>
              {isPopup ? (
                <PopupCard
                  title={popup[number].title}
                  desc={popup[number].desc}
                  checkBox={popup[number].checkBox}
                  bswitch={popup[number].switch}
                  ShowReportCard={ShowReportCard}
                  selectedChat={selectedChat}
                  sender={selectedChat.users.find((el) => {
                    return el.username !== jwtDecode(user).username;
                  })}
                  handleRemove={(res) => handleRemove(res)}
                  handleblock={(res) => {
                    handleblock(res);
                  }}
                  popups={(res, res1) => popups(res, res1)}
                />
              ) : null}
            </>
          ) : (
            <NoResultFoundCard
              isPart={true}
              icon={<NoResultIcon className="nofavs_icon" />}
            />
          )}
          {IsShowPopup ? <Popup message={popMessage} type={popType} /> : null}
        </>
      )}
    </>
  );
}

export default MessageGrid;
