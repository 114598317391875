export function setLocalStorageItem(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
  }
  
  export function getLocalStorageItem(key) {
    const data = localStorage.getItem(key);
    if(data) {
      return JSON.parse(data)
    }else {
      return null;
    }
  }
  
  export function removeLocalStorageItem(key) {
    localStorage.removeItem(key);
  }
  
  export function clearLocalStorage() {
    localStorage.clear();
  }  