import NavigationButton from "../atoms/NavigationButton";
import { ReactComponent as ToolsIcon } from "../../assets/icons/tool.svg";
import { ReactComponent as ResosIcon } from "../../assets/icons/resos.svg";
import { ReactComponent as IdeazIcon } from "../../assets/icons/idea.svg";
import { ReactComponent as AppsIcon } from "../../assets/icons/apps.svg";
import { ReactComponent as UxersIcon } from "../../assets/icons/uxers.svg";
import { ReactComponent as MailIcon } from "../../assets/icons/mail_fill.svg";
import { useDispatch } from "react-redux";
import React from "react";
import { setSelectMenu } from "../../redux/actions/env";
import pirsch from "../../Analytics";

function BottomBarLayout({ selectedMenu, currentPage }) {
  const dispatch = useDispatch();

  const changeMenu = (menu) => {
    if (menu == "chat") {
      window.location = "/chat";
    } else {
      if (currentPage != "home") {
        window.location = "/";
      }
      /*dispatch(setSelectMenu(menu));*/
      window.location = `/${menu.toLowerCase()}`;
      pirsch(`Mob - ${menu}`);
    }
  };

  return (
    <div className="bottom-bar">
      <NavigationButton
        selected={selectedMenu === "Tools" && currentPage == "home"}
        className="bottom-navigation"
        title="Tools"
        icon={<ToolsIcon />}
        onClick={() => changeMenu("Tools")}
      />
      <NavigationButton
        selected={selectedMenu === "Resos" && currentPage == "home"}
        className="bottom-navigation"
        title="Resos"
        icon={<ResosIcon />}
        onClick={() => changeMenu("Resos")}
      />
      <NavigationButton
        selected={selectedMenu === "Ideaz" && currentPage == "home"}
        className="bottom-navigation"
        title="Ideaz"
        icon={<IdeazIcon />}
        onClick={() => changeMenu("Ideaz")}
      />
       <NavigationButton
            selected={selectedMenu === "Apps" && currentPage == "home"}
            className="bottom-navigation"
            title="Apps"
            icon={<AppsIcon />}
            onClick={() => changeMenu("Apps")}
          />
      {/*<NavigationButton
        selected={selectedMenu === "Uxers" && currentPage == "home"}
        className="bottom-navigation"
        title="Uxers"
        icon={<UxersIcon />}
        onClick={() => changeMenu("Uxers")}
      />*/}

      {/*<NavigationButton
        className="bottom-navigation bottom-isolate"
        title="Chat"
        icon={<MailIcon />}
        selected={currentPage === "chat"}
        onClick={() => changeMenu("chat")}
      />*/}
    </div>
  );
}

export default BottomBarLayout;
