import React from "react";

const BlogCard = ({
  imageUrl,
  category,
  title,
  authorImage,
  authorName,
  date,
  link,
}) => {
  return (
    <a href={link} className="blog_card_link">
      <div className="blog_card">

        <div className="blog_card_image">
          <img 
          src={process.env.PUBLIC_URL + '/blog_assets/' + imageUrl} 
          alt="Card" 
          />
        </div>

        <div className="blog_card_content">

          <div className="blog_info">
            <p className="blog_category">{category}</p>
            <h3 className="blog_title">{title}</h3>
          </div>

          <div className="blog_author_info">

            <div className="blog_author_box">
              <img src={process.env.PUBLIC_URL + '/blog_assets/' + authorImage} alt="Author" />
              <p className="blog_author_name">{authorName}</p>
            </div>

            <span>|</span>

            <div className="blog_date">{date}</div>
          
          </div>

        </div>
     
      </div>
    </a>
  );
};

export default BlogCard;
