import React from "react";
import { useParams } from "react-router-dom";
import Layout from "../components/layout/Layout";
import UxerCard from "../components/organismes/uxerCard";
import { useEffect, useState } from "react";
import "../styles/main.scss";
import { getUserInfo, likeItem } from "../services/userServices";
import ItemCard from "../components/organismes/ItemCard";
import { useDispatch, useSelector } from "react-redux";
import { setFavsItems } from "../redux/actions/items";
import { ReactComponent as Trash } from "../assets/icons/trash.svg";
import jwtDecode from "jwt-decode";
import { ReactComponent as Logout } from "../assets/icons/logout.svg";
import { ReactComponent as ToolsIcon } from "../assets/icons/tool.svg";
import { ReactComponent as ResosIcon } from "../assets/icons/resos.svg";
import { ReactComponent as IdeazIcon } from "../assets/icons/idea.svg";
import { ReactComponent as AppsIcon } from "../assets/icons/apps.svg";
import { ReactComponent as NoResultIcon } from "../assets/icons/no_favs.svg";
import Button from "../components/atoms/Button";
import pirsch from "../Analytics";
import axios from "axios";
import { BACKEND_URL } from "../config";
import NoResultFoundCard from "../components/molecules/NoResultFoundCard";

function Profile() {
  useEffect(() => {
    document.title = "UUD - Profile";
    return () => {
      document.title = "UUD";
    };
  }, []);

  const dispatch = useDispatch();
  const params = useParams();
  const user = useSelector((state) => state.user);
  const favsItems = useSelector((state) => state.favsItems);
  const [userInfo, setUserInfo] = useState();
  const [favorites, setFavorites] = useState({});
  const [getFavs, setGetFavs] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const lang = useSelector((state) => state.lang);
  const [isScroll, setIsScroll] = useState(false);
  const [showRightBar, setShowRightBar] = useState(false);
  const [showLeftBar, setShowLeftBar] = useState(true);
  const [scrollState, setScrollState] = useState(true);
  const [scrollControll, setScrollControll] = useState(true);
  useEffect(() => {
    const handleScroll = () => {
      console.log("scroll", scrollControll);

      // i want to setSelectedTab when scroll to section tools-tabs / resos-tabs / ideaz-tabs
      if (scrollControll) {
        if (
          document.getElementById("ideaz-tabs") &&
          document.getElementById("ideaz-tabs").getBoundingClientRect().y <= 100
        ) {
          setSelectedTab(2);
        } else if (
          document.getElementById("resos-tabs") &&
          document.getElementById("resos-tabs").getBoundingClientRect().y <= 100
        ) {
          setSelectedTab(1);
        } else if (
          document.getElementById("apps-tabs") &&
          document.getElementById("apps-tabs").getBoundingClientRect().y <= 100
        ) {
          setSelectedTab(2);
        } else {
          setSelectedTab(0);
        }
      }
    };

    if (document.getElementById("profile")) {
      document.getElementById("profile").addEventListener("scroll", () => {
        handleScroll();
      });
    }
  }, [isScroll, document.getElementById("profile"), scrollControll]);

  useEffect(() => {
    console.log("callled", favsItems);
    if (params.username.toLowerCase()) {
      console.log(params.username.toLowerCase());
      getUserInfo(params.username.toLowerCase())
        .then((res) => {
          if (res.data.myUser) {
            res.data = res.data.myUser;
          }

          if (res.data._id) {
            axios
              .get(`${BACKEND_URL}/profile/favs/${res.data._id}`)
              .then((res2) => {
                res.data = {
                  ...res.data,
                  toolsItem: res2.data.myTools,
                  resosItem: res2.data.myResos,
                  ideazItem: res2.data.myIdeaz,
                };
                console.log(res.data);
                setUserInfo(res.data);
              });
          }

          setUserInfo(res.data);
          console.log(res.data);
        })
        .catch((error) => {
          console.log(error);
          window.location = "/404";
        });
    }
  }, [params.username.toLowerCase(), getFavs]);

  const favsCategory = (category) => {
    const capitalized = category.charAt(0).toUpperCase() + category.slice(1);
    return "my" + capitalized;
  };

  const onToggleLike = async (status, item, categorie) => {
    console.log(favsItems);
    if (user) {
      if (status) {
        dispatch(
          setFavsItems({
            ...favsItems,
            [favsCategory(categorie)]:
              favorites[categorie] != null
                ? [...favorites[categorie], item]
                : [item],
          })
        );
        await likeItem(item._id, categorie.toLowerCase());
      } else {
        dispatch(
          setFavsItems({
            ...favsItems,
            [favsCategory(categorie)]:
              favorites[categorie] != null
                ? favorites[categorie].filter((x) => x._id !== item._id)
                : [item],
          })
        );
        await likeItem(item._id, categorie.toLowerCase());
      }
    }
  };

  useEffect(() => {
    setFavorites({
      tools: favsItems["myTools"],
      resos: favsItems["myResos"],
      ideaz: favsItems["myIdeaz"],
      apps: favsItems["myApps"],
    });
    console.log(userInfo, favorites, favsItems);
  }, [favsItems]);

  return (
    <>
      {userInfo && (
        <Layout
          id="profile"
          selectedMenu="profile"
          classNameActionMenu="profile-action-menu"
          isLogin={
            localStorage.getItem("token") &&
            localStorage.getItem("token") != "undefined"
          }
          myProfile={
            user && jwtDecode(user).username === params.username.toLowerCase()
          }
          setShowLeftBar={(res) => setShowLeftBar(res)}
          setShowRightBar={(res) => setShowRightBar(res)}
          showRightBar={showRightBar}
          showLeftBar={showLeftBar}
          filter={
            <div className="flex flex-col justify-between h-full pb-10">
              <div className="flex flex-col items-center ">
                <UxerCard
                  isMe={
                    user &&
                    jwtDecode(user).username === params.username.toLowerCase()
                  }
                  fullName={userInfo.fullName}
                  className="uxer-leftbar-card"
                  username={userInfo.username}
                  subRole={userInfo.sub_role}
                  experience={userInfo.experience}
                  intro={userInfo.intro}
                  profileImage={userInfo.profile_img}
                  personalSite={userInfo.personalSite}
                  personalBlog={userInfo.personalBlog}
                  mediaLinks={userInfo.mediaLinks.map((el) => {
                    return el?.link;
                  })}
                  national={userInfo.nationality}
                />
                <div className="user-card-favsCounts-boxs">
                  <div
                    className={`user-card-favsCount ${
                      selectedTab === 0 ? "selected" : ""
                    }`}
                    onClick={async () => {
                      setSelectedTab(0);

                      setScrollControll(false);
                      document.getElementById("profile").scroll({
                        left: 0,
                        top:
                          document
                            .getElementById("tools-tabs")
                            .getBoundingClientRect().top +
                          document.getElementById("profile").scrollTop -
                          70,
                        behavior: "smooth",
                      });
                    }}
                  >
                    <ToolsIcon />
                    {/* <span className="user-card-favsCount-title">TOOLS</span> */}
                    <span className="user-card-favsCount-number">
                      {user && userInfo._id == jwtDecode(user).id
                        ? favsItems.myTools != null
                          ? favsItems?.myTools?.length
                          : "0"
                        : userInfo?.toolsItem && userInfo?.toolsItem?.length}
                    </span>
                  </div>
                  <div
                    className={`user-card-favsCount ${
                      selectedTab === 1 ? "selected" : ""
                    }`}
                    onClick={async () => {
                      setScrollControll(false);
                      setSelectedTab(0);

                      document.getElementById("profile").scroll({
                        left: 0,
                        top:
                          document
                            .getElementById("resos-tabs")
                            .getBoundingClientRect().top +
                          document.getElementById("profile").scrollTop -
                          70,
                        behavior: "smooth",
                      });
                    }}
                  >
                    <ResosIcon />
                    {/* <span className="user-card-favsCount-title">RESOS</span> */}
                    <span className="user-card-favsCount-number">
                      {user && userInfo._id == jwtDecode(user).id
                        ? favsItems.myResos != null
                          ? favsItems?.myResos?.length
                          : "0"
                        : userInfo?.resosItem && userInfo?.resosItem?.length}
                    </span>
                  </div>

                  {/* Ideaz */}

                  <div
                    className={`user-card-favsCount ${
                      selectedTab === 2 ? "selected" : ""
                    }`}
                    onClick={async () => {
                      setScrollControll(false);
                      setSelectedTab(1);
                      document.getElementById("profile").scroll({
                        left: 0,
                        top:
                          document
                            .getElementById("ideaz-tabs")
                            .getBoundingClientRect().top +
                          document.getElementById("profile").scrollTop -
                          70,
                        behavior: "smooth",
                      });
                    }}
                  >
                    <IdeazIcon />
                    {/* <span className="user-card-favsCount-title">IDEAZ</span> */}
                    <span className="user-card-favsCount-number">
                      {user && userInfo._id == jwtDecode(user).id
                        ? favsItems.myIdeaz != null
                          ? favsItems.myIdeaz.length
                          : "0"
                        : userInfo?.ideazItem && userInfo?.ideazItem?.length}
                    </span>
                  </div>

                  {/* Apps */}

                  {/*  <div

                    className={`user-card-favsCount ${
                      selectedTab === 3 ? "selected" : ""
                    }`}
                    onClick={async () => {
                      setScrollControll(false);
                      setSelectedTab(3);
                      document.getElementById("profile").scroll({
                        left: 0,
                        top:
                          document
                            .getElementById("apps-tabs")
                            .getBoundingClientRect().top +
                          document.getElementById("profile").scrollTop -
                          70,
                        behavior: "smooth",
                      });
                    }}
                  >
                    <AppsIcon />
           {// <span className="user-card-favsCount-title">IDEAZ</span> }

                    <span className="user-card-favsCount-number">
                      {user && userInfo._id == jwtDecode(user).id
                        ? favsItems.myIdeaz != null
                          ? favsItems.myIdeaz.length
                          : "0"
                        : userInfo?.ideazItem && userInfo?.ideazItem?.length}
                    </span>
                  </div>*/}
                </div>
              </div>
              {localStorage.getItem("token") &&
                params.username.toLowerCase() ===
                  jwtDecode(user).username.toLowerCase() && (
                  <div className="logout_btn_box">
                    <Button
                      rightIcon={<Logout />}
                      className="profile-logout-button"
                      onClick={() => {
                        localStorage.removeItem("token");
                        localStorage.removeItem("persist:persist-key");
                        window.location = "/";
                        pirsch("RM-Logout");
                      }}
                      data-lang={lang}
                    >
                      {lang === "en" ? "Logout" : "ログアウト"}
                    </Button>
                  </div>
                )}
            </div>
          }
        >
          <div className="user-tab">
            <div className="user-tab-header">
              <ToolsIcon />
              <span className="user-tab-title">TOOLS</span>
              <span className="user-tab-number">
                {user && userInfo._id == jwtDecode(user).id
                  ? favsItems.myTools != null
                    ? favsItems?.myTools?.length
                    : "0"
                  : userInfo?.toolsItem && userInfo?.toolsItem?.length}
              </span>
            </div>
            <div className="card-grid" id="tools-tabs">
              {user && userInfo._id == jwtDecode(user).id ? (
                favsItems?.myTools?.length ? (
                  favsItems?.myTools?.map((item, key) => {
                    return (
                      <ItemCard
                        className="tools"
                        _id={item._id}
                        key={key}
                        image_src={item.img}
                        title={item.name}
                        subtitle={
                          lang == "en"
                            ? item.description[0]
                            : item.description[1]
                        }
                        platform={item.platform}
                        price={item.price}
                        tags={item.tags}
                        link={item.link}
                        like={
                          favorites["tools"] &&
                          favorites["tools"]
                            .map((x) => x._id)
                            .includes(item._id)
                        }
                        setLike={(status) =>
                          onToggleLike(status, item, "tools")
                        }
                      />
                    );
                  })
                ) : (
                  <>
                    <div className="h-96 w-full">
                      <NoResultFoundCard
                        isPart={true}
                        icon={<NoResultIcon className="nofavs_icon" />}
                      />
                    </div>
                  </>
                )
              ) : userInfo?.toolsItem?.length ? (
                userInfo?.toolsItem?.map((item, key) => {
                  return (
                    <ItemCard
                      className="tools"
                      _id={item._id}
                      key={key}
                      image_src={item.img}
                      title={item.name}
                      subtitle={
                        lang == "en" ? item.description[0] : item.description[1]
                      }
                      platform={item.platform}
                      price={item.price}
                      tags={item.tags}
                      link={item.link}
                      like={
                        favorites["tools"] &&
                        favorites["tools"].map((x) => x._id).includes(item._id)
                      }
                      setLike={(status) => onToggleLike(status, item, "tools")}
                    />
                  );
                })
              ) : (
                <>
                  <div className="h-96 w-full">
                    <NoResultFoundCard
                      isPart={true}
                      icon={<NoResultIcon className="nofavs_icon" />}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="user-tab">
            <div className="user-tab-header">
              <ResosIcon />
              <span className="user-tab-title">RESOS</span>
              <span className="user-tab-number">
                {user && userInfo._id == jwtDecode(user).id
                  ? favsItems.myResos != null
                    ? favsItems?.myResos?.length
                    : "0"
                  : userInfo?.resosItem && userInfo?.resosItem?.length}
              </span>
            </div>
            <div className="card-grid" id="resos-tabs">
              {user && userInfo._id == jwtDecode(user).id ? (
                favsItems?.myResos?.length ? (
                  favsItems?.myResos?.map((item, key) => {
                    return (
                      <ItemCard
                        className="resos"
                        _id={item._id}
                        key={key}
                        image_src={item.img}
                        title={item.name}
                        subtitle={
                          lang == "en"
                            ? item.description[0]
                            : item.description[1]
                        }
                        platform={item.platform}
                        price={item.price}
                        tags={item.tags}
                        link={item.link}
                        like={
                          favorites["resos"] &&
                          favorites["resos"]
                            .map((x) => x._id)
                            .includes(item._id)
                        }
                        setLike={(status) =>
                          onToggleLike(status, item, "resos")
                        }
                      />
                    );
                  })
                ) : (
                  <>
                    <div className="h-96 w-full">
                      <NoResultFoundCard
                        isPart={true}
                        icon={<NoResultIcon className="nofavs_icon" />}
                      />
                    </div>
                  </>
                )
              ) : userInfo?.resosItem?.length ? (
                userInfo?.resosItem?.map((item, key) => {
                  return (
                    <ItemCard
                      className="resos"
                      _id={item._id}
                      key={key}
                      image_src={item.img}
                      title={item.name}
                      subtitle={
                        lang == "en" ? item.description[0] : item.description[1]
                      }
                      platform={item.platform}
                      price={item.price}
                      tags={item.tags}
                      link={item.link}
                      like={
                        favorites["resos"] &&
                        favorites["resos"].map((x) => x._id).includes(item._id)
                      }
                      setLike={(status) => onToggleLike(status, item, "resos")}
                    />
                  );
                })
              ) : (
                <>
                  <div className="h-96 w-full">
                    <NoResultFoundCard
                      isPart={true}
                      icon={<NoResultIcon className="nofavs_icon" />}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="user-tab">
            <div className="user-tab-header">
              <IdeazIcon />
              <span className="user-tab-title">IDEAZ</span>
              <span className="user-tab-number">
                {user && userInfo._id == jwtDecode(user).id
                  ? favsItems.myIdeaz != null
                    ? favsItems.myIdeaz.length
                    : "0"
                  : userInfo?.ideazItem && userInfo?.ideazItem?.length}
              </span>
            </div>
            <div className="card-grid" id="ideaz-tabs">
              {user && userInfo._id == jwtDecode(user).id ? (
                favsItems?.myIdeaz?.length ? (
                  favsItems?.myIdeaz?.map((item, key) => {
                    return (
                      <ItemCard
                        className="ideaz"
                        _id={item._id}
                        key={key}
                        image_src={item.img}
                        title={item.name}
                        subtitle={
                          lang == "en"
                            ? item.description[0]
                            : item.description[1]
                        }
                        platform={item.platform}
                        price={item.price}
                        tags={item.tags}
                        link={item.link}
                        like={
                          favorites["ideaz"] &&
                          favorites["ideaz"]
                            .map((x) => x._id)
                            .includes(item._id)
                        }
                        setLike={(status) =>
                          onToggleLike(status, item, "ideaz")
                        }
                      />
                    );
                  })
                ) : (
                  <>
                    <div className="h-96 w-full">
                      <NoResultFoundCard
                        isPart={true}
                        icon={<NoResultIcon className="nofavs_icon" />}
                      />
                    </div>
                  </>
                )
              ) : userInfo?.ideazItem?.length ? (
                userInfo?.ideazItem?.map((item, key) => {
                  return (
                    <ItemCard
                      className="ideaz"
                      _id={item._id}
                      key={key}
                      image_src={item.img}
                      title={item.name}
                      subtitle={
                        lang == "en" ? item.description[0] : item.description[1]
                      }
                      platform={item.platform}
                      price={item.price}
                      tags={item.tags}
                      link={item.link}
                      like={
                        favorites["ideaz"] &&
                        favorites["ideaz"].map((x) => x._id).includes(item._id)
                      }
                      setLike={(status) => onToggleLike(status, item, "ideaz")}
                    />
                  );
                })
              ) : (
                <>
                  <div className="h-96 w-full">
                    <NoResultFoundCard
                      isPart={true}
                      icon={<NoResultIcon className="nofavs_icon" />}
                    />
                  </div>
                </>
              )}
            </div>
          </div>

          {/* Apps */}
          {/*   <div className="user-tab">
            <div className="user-tab-header">
              <AppsIcon />
              <span className="user-tab-title">Apps</span>
              <span className="user-tab-number">
                {user && userInfo._id == jwtDecode(user).id
                  ? favsItems.myResos != null
                    ? favsItems?.myResos?.length
                    : "0"
                  : userInfo?.resosItem && userInfo?.resosItem?.length}
              </span>
            </div>
            <div className="card-grid" id="apps-tabs">*/}
          {/*user && userInfo._id == jwtDecode(user).id ? (
                favsItems?.myResos?.length ? (
                  favsItems?.myResos?.map((item, key) => {
                    return (
                      <ItemCard
                        className="apps"
                        _id={item._id}
                        key={key}
                        image_src={item.img}
                        title={item.name}
                        subtitle={
                          lang == "en"
                            ? item.description[0]
                            : item.description[1]
                        }
                        platform={item.platform}
                        price={item.price}
                        tags={item.tags}
                        link={item.link}
                        like={
                          favorites["resos"] &&
                          favorites["resos"]
                            .map((x) => x._id)
                            .includes(item._id)
                        }
                        setLike={(status) =>
                          onToggleLike(status, item, "resos")
                        }
                      />
                    );
                  })
                ) : (
                  <>
                    <div className="h-96 w-full">
                      <NoResultFoundCard
                        isPart={true}
                        icon={<NoResultIcon className="nofavs_icon" />}
                      />
                    </div>
                  </>
                )
              ) : userInfo?.resosItem?.length ? (
                userInfo?.resosItem?.map((item, key) => {
                  return (
                    <ItemCard
                      className=""
                      _id={item._id}
                      key={key}
                      image_src={item.img}
                      title={item.name}
                      subtitle={
                        lang == "en" ? item.description[0] : item.description[1]
                      }
                      platform={item.platform}
                      price={item.price}
                      tags={item.tags}
                      link={item.link}
                      like={
                        favorites["resos"] &&
                        favorites["resos"].map((x) => x._id).includes(item._id)
                      }
                      setLike={(status) => onToggleLike(status, item, "resos")}
                    />
                  );
                })
              ) : (
                <>
                  <div className="h-96 w-full">
                    <NoResultFoundCard
                      isPart={true}
                      icon={<NoResultIcon className="nofavs_icon" />}
                    />
                  </div>
                </>
              )*/}
          {/* </div>
            <div className="card-grid">

              <div className="h-96 w-full">*/}
          {/* <NoResultFoundCard
                      isPart={true}
                      icon={<NoResultIcon className="nofavs_icon" />}
                    /> */}
          {/*        </div>

            </div>
          </div>*/}
        </Layout>
      )}
    </>
  );
}

export default Profile;
