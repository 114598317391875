import CheckBox from "../atoms/CheckBox";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

function CheckBoxGroup({
  checks,
  value = [],
  onChange = () => {},
  className = "",
  error,
}) {
  const handleCheck = (val) => {
    let result = [...value];

    if (result.includes(val)) {
      result = result.filter((x) => x !== val);
    } else {
      result = [...result, val];
    }
    console.log(result);
    onChange(result);
  };
  const lang = useSelector((state) => state.lang);

  return (
    <div className={`checkbox-group ${className}`}>
      {checks.map((check, key) => {
        return (
          <CheckBox
            key={key}
            text={lang == "en" || !check.textJp ? check.text : check.textJp}
            checked={value?.includes(check.label || check.text)}
            icon={check.icon || null}
            onClick={() => handleCheck(check.label || check.text)}
            className={error ? "input-error-small" : ""}
            data-lang={lang}
          />
        );
      })}
    </div>
  );
}

export default CheckBoxGroup;
