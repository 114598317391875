import { ReactComponent as NoResultIcon } from "../../assets/icons/noresult.svg";
import React from "react";

function NoResultFoundCard({ isPart, icon }) {
  return (
    <div
      className={`${
        isPart ? "no_result_found_card_part" : "no_result_found_card"
      }`}
    >
      <div className="">{icon}</div>
    </div>
  );
}

export default NoResultFoundCard;
