import { ReactComponent as LogoIcon } from "../../assets/icons/logo_05.svg";
import { ReactComponent as Logo07Icon } from "../../assets/icons/logo_07.svg";
import { ReactComponent as NoResultIcon } from "../../assets/icons/no_favs.svg";
import React from "react";
import { useEffect, useMemo, useState } from "react";
import countries from "../../data/countires.json";
import UxerCard from "../organismes/uxerCard";
import NoResultFoundCard from "../molecules/NoResultFoundCard";
import { useSelector } from "react-redux";
import NoLoggedMessage from "../molecules/NoLoggedMessage";
import LoadingCard from "../molecules/LoadingCard";
import LoadMoreItems from "../molecules/LoadMoreItems";
import TheEndItems from "../molecules/TheEndItems";
import { getUserInfo } from "../../services/authServices";
import jwtDecode from "jwt-decode";

function UxerGrid({ uxers, filterValue, setFilteCount }) {
  const user = useSelector((state) => state.user);
  const [uxer_id, setUxer_id] = useState("");
  const [myUxer, setMyUxer] = useState({});
  const [part, setPart] = useState(1);
  const lang = useSelector((state) => state.lang);

  const filterItems = useMemo(() => {
    return uxers.filter((uxer) => {
      if (filterValue.search) {
        if (
          !(
            uxer.username
              .toLowerCase()
              .includes(filterValue.search.toLowerCase()) ||
            uxer.fullName
              .toLowerCase()
              .includes(filterValue.search.toLowerCase()) ||
            uxer.intro.toLowerCase().includes(filterValue.search.toLowerCase())
          )
        )
          return false;
      }

      if (filterValue.nationality) {
        console.log(countries, filterValue.nationality.toLowerCase());
        let country = countries.filter(
          (countrie) =>
            countrie.value.toLowerCase() ===
            filterValue.nationality.toLowerCase()
        );

        if (country.length > 0) {
          country = country[0].value;
          console.log(uxer.nationality, country);
          if (uxer.nationality !== country) return false;
        }
      }

      if (filterValue.subRole?.length > 0) {
        if (
          !filterValue.subRole
            .map((subR) => subR === uxer.sub_role)
            .includes(true)
        )
          return false;
      }

      if (filterValue.employ?.length > 0) {
        if (
          !filterValue.employ
            .map((emp) => emp === uxer.uxer_id?.position)
            .includes(true)
        )
          return false;
      }

      if (filterValue.competence?.length > 0) {
        if (
          !filterValue.competence
            .map((comp) =>
              uxer.uxer_id?.skills
                ?.map((skill) => comp === skill)
                .includes(true)
            )
            .includes(true)
        ) {
          return false;
        }
      }

      if (filterValue.experience?.length > 0) {
        if (uxer.uxer_id.experience < 5) return false;
      }

      return true;
    });
  }, [filterValue, uxers]);

  useEffect(() => {
    setMyUxer({});
    setUxer_id("");

    if (user) {
      getUserInfo(jwtDecode(user).id).then((res) => {
        if (res.data.myUser) {
          res.data = res.data.myUser;
        }

        console.log("myux", res.data);
        setUxer_id(res.data.uxer_id);
        setMyUxer({
          profile_img: res.data.profile_img,
          username: res.data.username,
          fullName: res.data.fullName,
          experience: res.data.experience,
          sub_role: res.data.sub_role,
          intro: res.data.intro,
          personalSite: res.data.personalSite,
          personalBlog: res.data.personalBlog,
          mediaLinks: res.data.mediaLinks,
          national: res.data.nationality,
          contact: res.data.newMessageEmail,
        });
      });
    }
  }, [user]);
  useEffect(() => {
    setFilteCount(uxer_id ? filterItems.length + 1 : filterItems.length);
  }, [filterItems]);
  return (
    <div
      className={`uxer-grid ${
        filterItems?.length === 0 ? "min-h-full h-full" : ""
      }`}
    >
      {uxer_id && (
        <UxerCard
          isMe={true}
          id={uxer_id}
          profileImage={myUxer.profile_img}
          username={myUxer.username}
          fullName={myUxer.fullName}
          experience={myUxer.experience}
          subRole={myUxer.sub_role}
          intro={myUxer.intro}
          personalSite={myUxer.personalSite}
          personalBlog={myUxer.personalBlog}
          mediaLinks={myUxer.mediaLinks}
          national={myUxer.national}
          _id={myUxer}
          edit={true}
        />
      )}
      {filterItems.slice(0, 60 * part).map((uxer) => {
        return (
          myUxer.username != uxer.username && (
            <UxerCard
              id={uxer._id}
              profileImage={uxer.profile_img}
              username={uxer.username}
              fullName={uxer.fullName}
              experience={uxer.uxer_id.experience}
              subRole={uxer.sub_role}
              intro={uxer.intro}
              personalSite={uxer.personalSite}
              personalBlog={uxer.personalBlog}
              mediaLinks={uxer.mediaLinks}
              national={uxer.nationality}
              contact={uxer.newMessageEmail}
            />
          )
        );
      })}
      {filterItems.length === 0 ? (
        <>
          {uxers.length === 0 ? (
            <>
              <LoadingCard />
            </>
          ) : (
            <>
              {user ? (
                <>
                  <NoResultFoundCard
                    isPart={true}
                    icon={<LogoIcon />}
                    title="hoiashdf"
                  />
                </>
              ) : (
                <>
                  <NoLoggedMessage
                    icon={<Logo07Icon />}
                    className="min-h-full"
                    data-lang={lang}
                    title=""
                  >
                    {lang === "en" ? (
                      <>
                        To access additional results,333333
                        <br /> Please log in or sign up.
                      </>
                    ) : (
                      <>
                        追加の結果にアクセスするには、
                        <br />
                        ログインまたはサインアップしてください。
                      </>
                    )}
                  </NoLoggedMessage>
                </>
              )}
            </>
          )}
        </>
      ) : (
        <>
          {filterItems.length >= 9 ? (
            filterItems.length > 9 * part ? (
              <>
                <LoadMoreItems loadMore={() => setPart(part + 1)} />
              </>
            ) : user ? (
              <TheEndItems />
            ) : (
              <NoLoggedMessage
                icon={<Logo07Icon />}
                className="min-h-full"
                data-lang={lang}
                title=""
              >
                {lang === "en" ? (
                  <>
                    To access the entire UXERS directory,
                    <br /> Please log in or sign up.
                  </>
                ) : (
                  <>
                    UXERSディレクトリ全体にアクセスするには、
                    <br />
                    ログインするかサインアップしてください。
                  </>
                )}
              </NoLoggedMessage>
            )
          ) : (
            <>
              {user ? (
                <></>
              ) : (
                <>
                  <NoLoggedMessage
                    icon={<Logo07Icon />}
                    className="min-h-full"
                    data-lang={lang}
                    title=""
                  >
                    {lang === "en" ? (
                      <>
                        To access additional results,444444
                        <br /> Please log in or sign up.
                      </>
                    ) : (
                      <>
                        追加の結果にアクセスするには、
                        <br />
                        ログインまたはサインアップしてください。
                      </>
                    )}
                  </NoLoggedMessage>
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}

export default UxerGrid;
