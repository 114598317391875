import { useSelector } from "react-redux/es/hooks/useSelector";

function StaticPageParagraph({ 
  text,
  title,
}) {
  const lang = useSelector((state) => state.lang);
  return (
    <>
      <div className="sp-parag-box">
        <div className="paragraph-text-cont">
          <h2 className="paragraph-title" data-lang={lang}>{title}</h2>
          <p className="paragraph">{text}</p>
        </div>
      </div>
      
    </>
  );

}

export default StaticPageParagraph;