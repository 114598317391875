import { ReactComponent as ArrowIcon } from "../../assets/icons/drop_down.svg";
import { useSelector } from "react-redux/es/hooks/useSelector";
import React, { useState, useRef } from "react";

function Select({
  options,
  error,
  leftIcon,
  onChange,
  defaultValue,
  value,
  className = "",
  dropdownIcon = <ArrowIcon />,
  disable,
  mainclass,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const lang = useSelector((state) => state.lang);
  const containerRef = useRef(null);

  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      className={`select-container ${isOpen ? "is-open" : ""}  ${className} ${
        error ? "select-container-error" : ""
      }`}
      ref={containerRef}
    >
      <div
        className={`select-display ${
          disable ? "disable-select" : ""
        } ${mainclass} !bg-black`}
        onClick={() => (disable ? null : setIsOpen(!isOpen))}
      >
        {leftIcon && <div className="select-left-icon">{leftIcon}</div>}
        <span className={`${value ? "selected" : ""}`} data-lang={lang}>
          {value || defaultValue}
        </span>
        {disable ? null : (
          <div className="select-right-icon">{dropdownIcon}</div>
        )}
      </div>
      {isOpen && (
        <div className="custom-dropdown">
          <div className="options">
            {options.map((option, index) => (
              <div
                key={index}
                className="dropdown-option"
                onClick={() => {
                  onChange(option.value);
                  setIsOpen(false);
                }}
              >
                {option.label}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default Select;
