import { ReactComponent as Ok } from "../../../assets/icons/ok.svg";
import { ReactComponent as PasswordIcon } from "../../../assets/icons/password.svg";
import { ReactComponent as NewPasswordIcon } from "../../../assets/icons/new_password.svg";
import { ReactComponent as EyeOnIcon } from "../../../assets/icons/eye_on.svg";
import { ReactComponent as EyeOffIcon } from "../../../assets/icons/eye_off.svg";
import Input from "../../atoms/Input";
import { useState } from "react";
import Button from "../../atoms/Button";
import { useTranslation } from "react-i18next";
import React from "react";

function NewPasswordCard({ onCreateNewPassword, error = false }) {
  const [password, setpassword] = useState(["", ""]);
  const [showPassword, setShowPassword] = useState([false, false]);
  const [t, i18n] = useTranslation();

  return (
    <div className="new-password-card">
      <p>{t("popup.newPassword.subTitle")}</p>
      <div className="new-password-form">
        <Input
          error={error}
          placeholder={t("popup.newPassword.newPassPlaceholder")}
          value={password[0]}
          rightIcon={showPassword[0] ? <EyeOnIcon /> : <EyeOffIcon />}
          onRightIconClick={() =>
            setShowPassword([!showPassword[0], showPassword[1]])
          }
          type={showPassword[0] ? "text" : "password"}
          onChange={(e) => setpassword([e.target.value, password[1]])}
          leftIcon={<PasswordIcon />}
        />
        <Input
          error={error}
          placeholder={t("popup.newPassword.confirmPassPlaceholder")}
          value={password[1]}
          rightIcon={showPassword[1] ? <EyeOnIcon /> : <EyeOffIcon />}
          onRightIconClick={() =>
            setShowPassword([showPassword[0], !showPassword[1]])
          }
          type={showPassword[1] ? "text" : "password"}
          onChange={(e) => setpassword([password[0], e.target.value])}
          leftIcon={<NewPasswordIcon />}
        />
      </div>
      <span className="new-password-notice">
        {t("popup.newPassword.notice")}
      </span>
      <Button
        rightIcon={<Ok />}
        onClick={() => onCreateNewPassword(password[0], password[1])}
      >
        {t("popup.newPassword.button")}
      </Button>
    </div>
  );
}

export default NewPasswordCard;
