import React, { useState } from "react";
import "../../styles/main.scss";
import { ReactComponent as Slogan } from "../../assets/icons/illus_soon2.svg";
import { ReactComponent as BannerBg } from "../../assets/icons/banner_bg.svg";
import { useDispatch, useSelector } from "react-redux";



function SoonBanner({ 
  onClick, // Add onClick as a prop
}) {
  const lang = useSelector((state) => state.lang);

  return (
    <div  onClick={onClick} className="soonbanner_box"  data-lang={lang}>
      <h3><Slogan/></h3>
     
     {lang === "en" ? 
    ( <span> Be the first to know when? <br /> Click for details.</span> )
     : 
     ( <span>一足先に知りたい方へ、詳細はこちら!</span> )
    }
      <h4><BannerBg/></h4>
    </div>

  );
}

export default SoonBanner;