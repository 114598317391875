import React, { useEffect } from "react";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { useRef } from "react";
import { use } from "i18next";
function Input({
  className = "",
  error = false,
  leftIcon,
  rightIcon,
  onRightIconClick,
  disable,
  max,
  innerClass,
  dataLang,
  children,
  filterLength,
  iconClass,
  preInputText = false,
  id,
  value,
  ...props
}) {
  const lang = useSelector((state) => state.lang);
  useEffect(() => {
    console.log(value);
  }, [value]);
  return (
    <div
      className={`input ${error ? "input-error" : ""} ${
        disable ? "input-disable" : ""
      } ${className}`}
      data-lang={lang}
      onClick={() => {
        document.querySelector(`#${id}`)?.focus();
      }}
    >
      <div className={`input-left-icon ${iconClass}`}>{leftIcon}</div>
      {preInputText ? (
        <div className="input-input-cont">
          <div className="input-input-pre-text">{preInputText}</div>

          <input
            className={`input-body ${innerClass} ${
              disable ? "input-disable-placeholder" : ""
            } `}
            id={id}
            disabled={disable ? true : ""}
            value={value ? value : ""}
            {...props}
            {...children}
          />
        </div>
      ) : (
        <input
          className={`input-body ${innerClass} ${
            disable ? "input-disable-placeholder" : ""
          }`}
          value={value ? value : ""}
          id={id}
          disabled={disable ? true : ""}
          {...props}
          {...children}
        />
      )}
      <div className="search_clear_number_box">
        {filterLength != 120 && <span>{filterLength}</span>}
        {rightIcon && (
          <div
            className={`input-right-icon ${
              onRightIconClick ? "input-right-icon-clickable" : ""
            }`}
            onClick={onRightIconClick}
          >
            {rightIcon}
          </div>
        )}
      </div>
    </div>
  );
}

export default Input;
