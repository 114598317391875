import { ReactComponent as Check } from "../../assets/icons/check.svg";
import React from "react";

function RadioBox({
  text,
  checked = false,
  icon = null, // Default to null if not provided
  onClick = () => {},
  className = "", // Default empty string if not provided
}) {
  return (
    <div className={`radiobox`} onClick={onClick}>
      <div className={`radio-box ${checked ? "checked" : ""} ${className}`}>
        <Check />
      </div>
      <span className="radiobox-text">{text}</span>

      {/* Explicit check for undefined or null */}
      {icon !== undefined && icon !== null && <div className="radiobox-right-icon">{icon}</div>}
    </div>
  );
}

export default RadioBox;
