import { ReactComponent as LogoIcon } from "../../assets/icons/logo_05.svg";
import { ReactComponent as PasswordIcon } from "../../assets/icons/password.svg";
import { BACKEND_URL } from "../../config";
import { ReactComponent as Logo07Icon } from "../../assets/icons/logo_07.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import { ReactComponent as Send } from "../../assets/icons/send.svg";
import { ReactComponent as Edit } from "../../assets/icons/edit.svg";
import { ReactComponent as EyeOnIcon } from "../../assets/icons/eye_on.svg";
import { ReactComponent as EyeOffIcon } from "../../assets/icons/eye_off.svg";
import { ReactComponent as Illustra02 } from "../../assets/icons/illustra_02.svg";
import { ReactComponent as Mail } from "../../assets/icons/new_mail.svg";
import axios from "axios";
import React, { useEffect } from "react";
import { ReactComponent as Trash } from "../../assets/icons/trash.svg";
import Button from "../atoms/Button";
import { useDispatch, useSelector } from "react-redux";
import ItemCard from "../organismes/ItemCard";
import Popup from "../../components/molecules/Popup";
import TheEndItems from "../molecules/TheEndItems";
import { ReactComponent as Ok } from "../../assets/icons/ok.svg";
import { useMemo, useState } from "react";
import { ReactComponent as CautionIcon } from "../../assets/icons/caution.svg";
import LoadMoreItems from "../molecules/LoadMoreItems";
import { setFavsItems } from "../../redux/actions/items";
import Input from "../atoms/Input";
import { likeItem } from "../../services/userServices";
import PopupContainer from "../molecules/PopupContainer";
import NoLoggedMessage from "../molecules/NoLoggedMessage";
import NoResultFoundCard from "../molecules/NoResultFoundCard";
import LoadingCard from "../molecules/LoadingCard";
import { setOpenLoginPopup } from "../../redux/actions/user";
import UxerCard from "../organismes/uxerCard";
import { getUserInfo } from "../../services/authServices";
import jwtDecode from "jwt-decode";
import Switch from "../atoms/Switch";
import { use } from "i18next";

function SettingsEmail({ display, setShowLeftBar }) {
  const user = useSelector((state) => state.user);
  const [showDelete, setShowDelete] = useState("");
  const token = localStorage.getItem("token");
  const [userInfo, setUserInfo] = useState();
  const [popType, setPopType] = useState("");
  const [IsShowPopup, settIsShowPopup] = useState(false);
  const [popMessage, setPopMessage] = useState("");
  const [popIcon, setPopIcon] = useState("");
  const [email, setEmail] = useState();
  const [showMessage, setShowMessage] = useState();
  const [updateEmail, setUpdateEmail] = useState();
  const lang = useSelector((state) => state.lang);
  const [opensucessPopup, setOpensucessPopup] = useState(false);
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});
  const handleChange = async (element, dataP) => {
    const newData = { ...data, [`${element}`]: dataP };
    setData(newData);
    console.log(data);
  };
  useEffect(() => {
    getUserInfo(jwtDecode(user).id).then((res) => {
      if (res.data.myUser) {
        res.data = res.data.myUser;
      }
      if (res) {
        setUserInfo(res.data);
      }
    });
  }, []);

  function popups(message, type, icon) {
    settIsShowPopup(true);
    setPopType(type);
    setPopIcon(icon);
    setPopMessage(message);
    setTimeout(() => {
      settIsShowPopup(false);
    }, 4000);
  }
  useEffect(() => {
    setInterval(function () {
      localStorage.setItem("emailsent", 0);
    }, 10 * 60 * 1000);
  }, []);

  function handleSubmit() {
    let errors = valIDateForm(data);
    if (Object.keys(errors).length === 0) {
      axios
        .post(
          `${BACKEND_URL}/profile/change-email`,
          {
            newEmail: data?.email,
            password: data?.password,
            lang,
          },
          {
            headers: {
              Authorization: `Bearer ${token.slice(1, -1)}`,
            },
          }
        )
        .then((res) => {
          if (res.data) {
            if (res.data.status == true) {
              setData({});
              setOpensucessPopup(true);
            } else {
              popups(res.data.message, "error", <CautionIcon />);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      popups("please complete required data", "error", <Ok />);
    }
  }
  const isValidEmail = (email) => {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    );
  };
  const valIDateForm = (data) => {
    const errors = {};

    if (!data.email) {
      errors.email = "Email is required";
    } else if (!isValidEmail(data.email)) {
      errors.email = "InvalID email address";
    }

    if (!data.password || data.password?.length <= 5) {
      errors.password = "Password is required";
    }

    setErrors(errors);
    return errors;
  };
  return (
    <>
      <div
        className={`bg-white h-full settings-cont ${display ? "" : "!hidden"}`}
      >
        <div
          className="close-btn close-btn-settings"
          onClick={() => setShowLeftBar(true)}
        >
          <CloseIcon className="z-0" />
        </div>

        <div className={"settings"}>
          <div className="settings-title-cont">
            <h2 className="settings-title" data-lang={lang}>
              {lang === "en" ? "Change Email" : "メールアドレス変更"}
            </h2>
          </div>

          <div className="settings-input-cont ">
            <p className="setting-text" data-lang={lang}>
              {lang === "en"
                ? "Your current email is :"
                : "現在メールアドレス："}{" "}
              <span className="settings-email-email">
                {!updateEmail
                  ? userInfo != undefined && userInfo.email
                  : updateEmail}
              </span>
            </p>

            <form autocomplete="off" className="settings-email-cont">
              <Input
                leftIcon={<Mail />}
                placeholder={
                  lang === "en" ? "New Email address" : "新しいメールアドレス"
                }
                className="input-half"
                value={data?.email}
                onChange={(e) => {
                  handleChange("email", e.target.value);
                }}
                error={errors.email}
                autoComplete={false}
                autoCorrect={false}
                autoFocus={false}
                id="id16"
              />
              <Input
                leftIcon={<PasswordIcon />}
                className="user-form-body-password"
                placeholder={lang === "en" ? "Current Password" : "パスワード"}
                type={data.passwordShow ? "text" : "password"}
                value={data?.password}
                onRightIconClick={() =>
                  handleChange("passwordShow", !data.passwordShow)
                }
                rightIcon={
                  data.passwordShow ? (
                    <EyeOnIcon className="eyeIcon" />
                  ) : (
                    <EyeOffIcon className="eyeIcon" />
                  )
                }
                onChange={(e) => handleChange("password", e.target.value)}
                error={errors.password}
                autoComplete={false}
                autoCorrect={false}
                id="id17"
                autoFocus={false}
              />
            </form>
            <div className="user-form-body-buttons">
              <Button
                rightIcon={<Ok />}
                className=""
                onClick={() => {
                  handleSubmit();
                }}
              >
                {lang === "en"
                  ? "Change Email Address"
                  : "メールアドレスを変更"}
              </Button>
            </div>
            {showMessage && (
              <p className="setting-text">
                Check your email newemailaddress@mail.comto confirm your new
                address. The change won’t take effect until confirmed.
              </p>
            )}
          </div>
        </div>
      </div>
      {IsShowPopup ? (
        <Popup
          message={popMessage}
          type={popType}
          icon={popIcon}
          closePopup={() => settIsShowPopup(false)}
        />
      ) : null}
      {opensucessPopup && (
        <PopupContainer
          title="Email Changed!"
          logo={<Illustra02 />}
          onClose={() => {
            setOpensucessPopup(false);
            window.location = "/settings/email";
          }}
        >
          <div className="checkemail-popup-text-cont">
            <p>Your email has been successfully changed.</p>
          </div>
        </PopupContainer>
      )}
    </>
  );
}

export default SettingsEmail;
