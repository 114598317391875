
import React from "react";
import { ReactComponent as PlaceholderLogo } from "../../assets/icons/no_logo.svg";


function ItemsImageEdit(props) {
  return (
    <div className="item_photo_hero">
     
        
          { props.showCorped &&
            ((!props.image) ? (
                <div className="cropped-img">
                  <PlaceholderLogo className="no-logo"/>
                  {props.addIcon && (
                    <label htmlFor="profile" className="item_photo_icon" onClick={() => {props.onAddImage()}}>
                      {props.addIcon}
                    </label>
                  )}
                </div>
            ) : (
              <div className="cropped-img">
                <img src={ props.image } alt="icon" />
                <div className="avatar-icon icon trash-icon" onClick={() => {props.onDelete()}}>
                  {props.deleteIcon}
                </div>
              </div>
            ))
          }
        
     
    </div>
  );
}

export default ItemsImageEdit;
