import { ReactComponent as LogoIcon } from "../../assets/icons/logo.svg";
import React from "react";

function TheEndItems() {
  return (
    <div className="the-end-items">
      <span>THE END</span>
      <LogoIcon />
    </div>
  );
}

export default TheEndItems;
