import { ReactComponent as ToolsIcon } from "../../assets/icons/tool.svg";
import { ReactComponent as ResosIcon } from "../../assets/icons/resos.svg";
import { ReactComponent as IdeazIcon } from "../../assets/icons/idea.svg";
import { ReactComponent as AppsIcon } from "../../assets/icons/apps.svg";
import { ReactComponent as UxersIcon } from "../../assets/icons/uxers.svg";
import React from "react";
import LangugeSwitch from "../atoms/LangugeSwitch";
import NavigationButton from "../atoms/NavigationButton";
import HomeIcon from "../atoms/HomeIcon";
import { setSelectLang } from "../../redux/actions/env";
import { useDispatch, useSelector } from "react-redux";
import { setSelectMenu } from "../../redux/actions/env";
import { useTranslation } from "react-i18next";
import axios from "axios";
import pirsch from "../../Analytics";

function LeftBarLayout({ selectedMenu, currentPage }) {
  const dispatch = useDispatch();
  const [t, i18n] = useTranslation();
  const lang = useSelector((state) => state.lang);

  const changeMenu = (menu) => {
    if (currentPage != "home") {
      window.location = "home";
    }
    /*dispatch(setSelectMenu(menu));*/
    window.location = `/${menu.toLowerCase()}`;
    pirsch(`Top-${menu}`);
  };

  return (
    <div className="left-bar">
      <HomeIcon
        style={{ flexShrink: 0 }}
        onClick={() => {
          window.location = "/";
          dispatch(setSelectMenu("Tools"));
        }}
      />

      <div className="left-bar-navigation">
        <>
          <NavigationButton
            selected={selectedMenu === "Tools" && currentPage == "home"}
            title="Tools"
            icon={<ToolsIcon />}
            onClick={() => changeMenu("Tools")}
          />
          <NavigationButton
            selected={selectedMenu === "Resos" && currentPage == "home"}
            title="Resos"
            icon={<ResosIcon />}
            onClick={() => changeMenu("Resos")}
          />
          <NavigationButton
            selected={selectedMenu === "Ideaz" && currentPage == "home"}
            title="ideaz"
            icon={<IdeazIcon />}
            onClick={() => changeMenu("Ideaz")}
          />
          {/*<NavigationButton
            selected={selectedMenu === "Apps" && currentPage == "home"}
            title="Apps"
            icon={<AppsIcon />}
            onClick={() => changeMenu("Apps")}
          />*/}
          {/* <NavigationButton
            selected={selectedMenu === "Uxers" && currentPage == "home"}
            title="uxers"
            icon={<UxersIcon />}
            onClick={() => changeMenu("Uxers")}
          /> */}
        </>
      </div>

      <LangugeSwitch
        language={i18n.language}
        onClick={() => {
          localStorage.setItem(
            "language",
            i18n.language === "en" ? "jp" : "en"
          );
          i18n.changeLanguage(i18n.language === "en" ? "jp" : "en");
          if (lang === "en") {
            console.log(lang);
            dispatch(setSelectLang("jp"));
          } else if (lang === "jp") {
            console.log(lang);
            dispatch(setSelectLang("en"));
          }
        }}
        color="var(--primary-color)"
      />
    </div>
  );
}

export default LeftBarLayout;
