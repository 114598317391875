import { useDispatch, useSelector } from "react-redux";
import SecondaryLayout from "../components/layout/SecondaryLayout";
import Popup from "../components/molecules/Popup";
import { ReactComponent as Uxer } from "../assets/icons/uxers.svg";
import { ReactComponent as TimerIcon } from "../assets/icons/timer.svg";
import { ReactComponent as BagIcon } from "../assets/icons/bag.svg";
import { ReactComponent as Hand2 } from "../assets/icons/hand2.svg";
import Button from "../components/atoms/Button";
import Select from "../components/atoms/Select";
import "../styles/main.scss";
import Input from "../components/atoms/Input";
import { useEffect, useState } from "react";
import React from "react";
import Signup from "../components/templates/signup";
import Layout from "../components/layout/Layout";
import StaticPageTitle from "../components/atoms/StaticPageTitle";
import StaticPageSlogan from "../components/atoms/StaticPageSlogan";
import StaticPageArticle from "../components/organismes/StaticPageArticle";
import StaticPageOrderedList from "../components/organismes/StaticPageOrderedList";
import StaticPageParagraph from "../components/organismes/StaticPageParagraph";
import StaticPageParagraphImage from "../components/organismes/StaticPageParagraphImage";
import StaticPageOrderedList2 from "../components/organismes/StaticPageOrderedList2";
import StaticPageSubscribe from "../components/organismes/StaticPageSubscribe";
import StaticPageBigImage from "../components/organismes/StaticPageBigImage";


function UxerSoon({ }) {

  useEffect(() => {
    document.title = "UUD - About UXERS";
    return () => {
      document.title = "UUD";
    };
  }, []);


  const user = useSelector((state) => state.user);
  const [showRightBar, setShowRightBar] = useState(false);
  const [showLeftBar, setShowLeftBar] = useState(false);

  const list1 = [
    {
      title: "Apply",
      desc: "ddddSpur innovation and divergent thinking by collecting the best of all worlds.",
    },
    {
      title: "Get validate",
      desc: "Real connections lead to friendship, partnerships, career moves, and more. Not the superficial ones where you pass out business cards.",
    },
    {
      title: "Join",
      desc: "Real connections lead to friendship, partnerships, career moves, and more. Not the superficial ones where you pass out business cards.",
    },
  ];

  return (
    <Layout
      id="uxersoon"
      selectedMenu={""}
      currentPage="uxersoon"
      isLogin={user !== "" && user}
      setShowLeftBar={(res) => setShowLeftBar(res)}
      setShowRightBar={(res) => setShowRightBar(res)}
      showRightBar={showRightBar}
      showLeftBar={showLeftBar}
      actionMenu={false}
    >
      <div className="static-page-hero yellow">
        <div className="static-page center">
          {/* ---------------------------------------- */}
          <div className="uxersoon">
            <div className="box">
              <div className="logo-box">
                <Uxer className="uxersoon-logo" />
                <h1>JOIN UXERS <br />Directory</h1>
              </div>

              <p>
                Please ensure that you are logged in or have received an invitation to access all data.Please ensure that you are logged in or have received an invitation...
                <br />
                Please ensure that you are logged in or have received an invitation to access all data. Please ensure that you are logged in or have received an invitation...
                Please ensure that you are logged in or have received an invitation to access all data.Please ensure that you are logged in or have received an invitation...
                <br />
                Please ensure that you are logged in or have received an invitation to access all data. Please ensure that you are logged in or have received an invitation...
              </p>

              <Button
                rightIcon={<Hand2 />}
                className="uxersoon_btn"
                onClick={() => { }}
              >
                Apply to Join UXERS
              </Button>

              {/* <Button
                rightIcon={<Hand2 />}
                className="lined_btn"
                onClick={() => { }}
              >
                How is UXERS  Looks Like
              </Button> */}
            </div>

          </div>
          {/* ---------------------------------------- */}
          <StaticPageBigImage
            className="big-img"
            imgSrc1Small={"img4.png"}
            imgSrc1Large={"img3.png"}
          />
          {/* ---------------------------------------- */}
          <div className="uxersoon">
            <div className="box">
              <h2>JOIN UXERS Directorysss</h2>

              <div className="list-box">
                <div className="list-title-box">
                  <span className="number-list">1</span>
                  <h2>Apply</h2>
                </div>
                <span className="list-text">
                  Please ensure that you are logged in or have received an invitation to access all data. Please ensure that you are logged in or have received an invitation...
                </span>
              </div>

              <div className="list-box">
                <div className="list-title-box">
                  <span className="number-list">2</span>
                  <h2>Get Approved</h2>
                </div>
                <span className="list-text">
                  Please ensure that you are logged in or have received an invitation to access all data. Please ensure that you are logged in or have received an invitation...
                </span>
              </div>

              <div className="list-box">
                <div className="list-title-box">
                  <span className="number-list">2</span>
                  <h2>Join us!</h2>
                </div>
                <span className="list-text">
                  Please ensure that you are logged in or have received an invitation to access all data. Please ensure that you are logged in or have received an invitation...
                </span>
              </div>

              <Button
                rightIcon={<Hand2 />}
                className="uxersoon_btn"
                onClick={() => { }}
              >
                Apply to Join UXERS
              </Button>
              <h3>Apply</h3>
              <p>
                Please ensure that you are logged in or have received an invitation to access all data.Please ensure that you are logged in or have received an invitation...
              </p>
            </div>

            {/* ---------------------------------------- */}
          </div>

        </div>
      </div>


    </Layout>
  );
}

export default UxerSoon;
