import React from 'react';

const ProductHuntBadge = () => {
  return (
    <a href="https://www.producthunt.com/posts/uud?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-uud" target="_blank" rel="noopener noreferrer">
      <img
        src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=431280&theme=light"
        alt="UUD - One-stop destination to fulfil UX/UI Designers aspirations! | Product Hunt"
        style={{ width: '250px', height: '54px' }}
        width="250"
        height="54"
      />
    </a>
  );
};

export default ProductHuntBadge;
