import RoundImage from "../atoms/RoundImage";
import { ReactComponent as AllIcon } from "../../assets/icons/mob_hum.svg";
import { ReactComponent as Dots } from "../../assets/icons/threedots_on.svg";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { ReactComponent as Hum } from "../../assets/icons/all.svg";
import { ReactComponent as Home } from "../../assets/icons/home.svg";
import jwtDecode from "jwt-decode";
import ChatAvatar from "../molecules/ChatAvatar";
import { useEffect, useState } from "react";
import { getUserInfo } from "../../services/authServices";
import React from "react";
import Tag from "../atoms/Tag";
import Popup from "../molecules/Popup";
import PopupContainer from "../molecules/PopupContainer";
import { fetchImage } from "../../services/itemsServices";

function TopBarLayout({
  setShowRightBar,
  setShowLeftBar,
  showLeftBar,
  title,
  statistique,
  icon,
  myProfile = false,
  currentPage,
  selectedChat,
  loading,
}) {
  const user = useSelector((state) => state.user);
  const [sender, setSender] = useState();
  const [image, setImage] = useState();

  useEffect(() => {
    if (selectedChat != undefined) {
      if (selectedChat.length != 0) {
        getUserInfo(
          selectedChat.users.find((other_user) => {
            return other_user.username !== jwtDecode(user).username;
          })._id
        ).then((res) => {
          if (res != undefined) {
            if (res.data.myUser) {
              setSender(res.data.myUser);
            } else {
              setSender(res.data);
            }
          }
        });
      }
    }
  }, [selectedChat]);
  useEffect(() => {
    if (user) {
      getUserInfo(jwtDecode(user).id).then((res) => {
        if (res != undefined) {
          if (res.data.myUser) {
            if (res.data) {
              fetchImage("profile", res?.data?.myUser.profile_img)
                .then((result) => {
                  if (result) {
                    setImage(result);
                  }
                })
                .catch(() => {});
            }
          } else {
            if (res.data) {
              fetchImage("profile", res?.data?.profile_img)
                .then((result) => {
                  if (result) {
                    setImage(result);
                  }
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          }
        }
      });
    }
  }, []);
  function showHeader() {
    document.querySelector(".message-header-cont").style.display = "flex";
    document.querySelector(".message-main").style.height =
      "calc(100% - 153.8px)";
    setTimeout(() => {
      document.querySelector(".message-header-cont").style.transform =
        "translateY(0px)";
    }, 400);
  }
  return (
    <div className={`top-bar ${showLeftBar ? "z-10" : "z-0"}`}>
      <div className="top-bar-left-icon items-center">
        {currentPage === "chat" ? (
          <Hum className="w-10 h-10 " onClick={setShowLeftBar} />
        ) : currentPage === "aboutus" ? (
          <Home onClick={() => (window.location = "/")} />
        ) : currentPage === "signupsoon" ? (
          <Home
            onClick={() => {
              window.location = "/";
            }}
          />
        ) : (
          <AllIcon onClick={setShowLeftBar} />
        )}
      </div>
      <div className="top-bar-title">
        <>
          {currentPage === "chat" ? (
            <>
              {sender != undefined && !loading ? (
                <ChatAvatar
                  size="!h-11 !w-11"
                  className="message-header-avatar "
                  name={sender.fullName}
                  username={sender.username}
                  role={sender.sub_role}
                  image={sender.profile_img}
                  clickable={true}
                  selectedMenu="chat"
                />
              ) : null}
            </>
          ) : currentPage === "settings" ? (
            <span className="title">Settings</span>
          ) : currentPage === "aboutus" ? (
            <span className="title">ABOUT</span>
          ) : (
            title && (
              <span className="title">
                {myProfile ? "MY" : ""} {title}
              </span>
            )
          )}
        </>

        {statistique && currentPage == "home" && (
          <span className="statistique">{statistique}</span>
        )}
        {icon && <span className="icon">{icon}</span>}
      </div>

      {currentPage === "chat" ? (
        <Dots
          className="w-12 cursor-pointer pr-3"
          onClick={!loading ? showHeader : null}
        />
      ) : (
        <RoundImage
          width={40}
          onClick={setShowRightBar}
          className="layout-profile-image profile-image-top"
          imageData={image}
          imageFull={false}
        />
      )}
    </div>
  );
}

export default TopBarLayout;
