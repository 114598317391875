import { ReactComponent as BackIcon } from "../../../assets/icons/back.svg";
import { ReactComponent as CloseIcon } from "../../../assets/icons/close.svg";
import Button from "../../atoms/Button";
import React from "react";
import { useDispatch, useSelector } from "react-redux";


function QuitCard({ onBack, OnExit, text, backBtnText, exitBtnText }) {
  const lang = useSelector((state) => state.lang);

  return (
    <div className="quit-card" data-lang={lang}>
      <p data-lang={lang}>{text}</p>
      <div className="user-form-body-buttons" >
        <Button
          rightIcon={<CloseIcon />}
          onClick={() => onBack()}
          className="noLogged_button noLogged_button_outline w-auto"
        >
       { backBtnText }
        </Button>
        <Button
          rightIcon={<BackIcon />}
          className="noLogged_button"
          onClick={() => OnExit()}
        >
            {exitBtnText}
        </Button>
      </div>
    </div>
  );
}

export default QuitCard;
