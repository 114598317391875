import React from "react";
import SoonBanner from "../organismes/SoonBanner";

import { ReactComponent as SearchIcon } from "../../assets/icons/search.svg";
import { ReactComponent as ClearIcon } from "../../assets/icons/clear.svg";
import { ReactComponent as Uxer2Icon } from "../../assets/icons/uxer_2_on.svg";
import { ReactComponent as UxerIcon } from "../../assets/icons/uxers.svg";
import { ReactComponent as Logo } from "../../assets/icons/logo_05.svg";
import { ReactComponent as BackIcon } from "../../assets/icons/back.svg";
import { ReactComponent as AppleIcon } from "../../assets/icons/apple.svg";
import { ReactComponent as WindowsIcon } from "../../assets/icons/windows.svg";
import { ReactComponent as BrowserIcon } from "../../assets/icons/browser.svg";
import { ReactComponent as LinuxIcon } from "../../assets/icons/linux.svg";
import { ReactComponent as FlagIcon } from "../../assets/icons/flag.svg";
import { ReactComponent as FreeIcon } from "../../assets/icons/free.svg";
import { ReactComponent as PaidIcon } from "../../assets/icons/paid.svg";
import { ReactComponent as FreemiumIcon } from "../../assets/icons/freemium.svg";
import { ReactComponent as AndroidIcon } from "../../assets/icons/android.svg";

import countires from "../../data/countires.json";
import pirsch from "../../Analytics";

import ProductHuntBadge from "../organismes/Producthunt";

import SoonPopup from "../organismes/popup/SoonPopup";
import jwtDecode from "jwt-decode";
import { layout } from "../../config";
import Input from "../atoms/Input";
import NavigationButton from "../atoms/NavigationButton";
import { useEffect, useState } from "react";
import TagsFilter from "../molecules/TagsFilter";
import CheckBoxGroup from "../molecules/CheckBoxGroup";
import RadioBoxGroup from "../molecules/RadioBoxGroup";
import Button from "../atoms/Button";
import LocationDrop from "../atoms/LocationDrop";
import { useSelector } from "react-redux";
import LargePopupContainer from "../molecules/LargePopupContainer";
import { UxersTermsCard } from "../molecules/TermsCard";
import PopupContainer from "../molecules/PopupContainer";
import UxerForm from "../molecules/UxerFormCard";
import { getUserInfo } from "../../services/authServices";
import MediumPopupContainer from "../molecules/MediumPopupContainer";
import {
  changeProfileInfo,
  submitRequestUxer,
} from "../../services/userServices";
import Banner280 from "../organismes/Banner280";

function FilterItems({
  categorie = "Tools",
  setFilterValue,
  className = "",
  newPopup,
  fileCount,
  filterLength,
}) {
  const [openUxerTerms, setOpenUxerTerms] = useState(false);
  const [openUxerForm, setOpenUxerForm] = useState(false);
  const [openUxerSuccess, setOpenUxerSuccess] = useState(false);
  const user = useSelector((state) => state.user);
  const [uxerErrors, setUxerErrors] = useState();
  const [isUxer, setIsUxer] = useState();
  const lang = useSelector((state) => state.lang);
  const [isSoonPopup, setIsSoonPopup] = useState(false);

  const countries = countires;
  const [filterData, setFilterData] = useState({ search: "" });
  useEffect(() => {
    if (uxerErrors) {
      if (Object.keys(uxerErrors).length !== 0) {
        if (Object.keys(uxerErrors).length > 1) {
          newPopup("error", "Please fill out the required fields");
        } else {
          Object.keys(uxerErrors).map(() => {
            newPopup("error", Object.values(uxerErrors)[0]);
          });
        }
      }
    }
  }, [uxerErrors]);
  useEffect(() => {
    setFilterData({ search: "" });
  }, [categorie]);
  useEffect(() => {
    if (user) {
      getUserInfo(jwtDecode(user).id).then((res) => {
        if (res?.data?.myUser?.uxer_id || res?.data?.uxer_id) {
          setIsUxer(true);
        } else {
          setIsUxer(false);
        }
      });
    }
  }, [user]);

  useEffect(() => {
    setFilterValue(filterData);
    console.log(filterData);
  }, [filterData]);

  return (
    <div className={`filter-items ${className}`}>
      <NavigationButton
        title={categorie.toUpperCase()}
        icon={layout[categorie.toLowerCase()].icon}
        className="filter-items-category"
      />
      <div className="filter-items-body">
        <div className="filter-top-inputs">
          <Input
            data-lang={lang}
            leftIcon={<SearchIcon />}
            filterLength={filterLength}
            rightIcon={
              filterData["search"] && <ClearIcon className="clear-icon" />
            }
            placeholder={lang === "en" ? "Search" : "検索"}
            className="search-input"
            iconClass="input-primary-color"
            name="search"
            value={filterData["search"]}
            onRightIconClick={() => {
              setFilterData({ ...filterData, search: "" });
              pirsch("Search-Reset");
            }}
            onChange={(e) =>
              setFilterData({ ...filterData, search: e.target.value })
            }
          />
          {categorie.toLocaleLowerCase() === "uxers" && (
            <LocationDrop
              countries={countries}
              leftIcon={<FlagIcon />}
              onChange={(value) =>
                setFilterData({ ...filterData, nationality: value })
              }
              defaultValue={null}
              primaryColors={true}
            />
          )}
        </div>
        {categorie.toLocaleLowerCase() === "uxers" && (
          <div className="uxer-counter-box">
            <UxerIcon className="uxer-counter-icon" />
            <p className="uxer-counter-text">
              {lang === "en" ? "Total UXERS:" : "総UXERS："}{" "}
              <span className="uxer-counter-number  ">{fileCount}</span>
            </p>
          </div>
        )}
        {layout[categorie.toLowerCase()].tags && (
          <TagsFilter
            category={categorie}
            value={filterData["tags"]}
            onChange={(e) => setFilterData({ ...filterData, tags: e })}
          />
        )}
        {categorie.toLocaleLowerCase() === "tools" && (
          <ToolsPart
            value={filterData}
            setValue={(value) => setFilterData(value)}
          />
        )}
        {categorie.toLocaleLowerCase() === "apps" && (
          <AppsPart
            value={filterData}
            setValue={(value) => setFilterData(value)}
          />
        )}
        {categorie.toLocaleLowerCase() === "uxers" && (
          <UxersPart value={filterData} setValue={setFilterData} />
        )}
        {categorie.toLocaleLowerCase() === "uxers" && (
          <div className="exper-box" data-lang={lang}>
            <div className="exper-circle">7</div>
            {lang === "en" ? "Design years experience" : "デザイン経験年数"}
          </div>
        )}
      </div>

      {categorie === "Uxers" && user && !isUxer && (
        <Button
          className="join-uxer-button"
          rightIcon={<Uxer2Icon />}
          onClick={() => {
            setOpenUxerTerms(true);
            pirsch("Join-UXERS");
          }}
        >
          Are you Designer? <br />
          Join UXERS Now!
        </Button>
      )}

{/* <div className="banner-box">
        <SoonBanner
          onClick={() => {
            window.location = "/signupsoon";

            pirsch("SignupSoon-Banner-E");
          }}
        />
      </div>
       */}
      <div className="banner-box">
          <ProductHuntBadge />
      </div>


      {openUxerTerms && (
        <LargePopupContainer onClose={() => setOpenUxerTerms(false)}>
          <UxersTermsCard
            showButton={true}
            onClick={() => {
              setOpenUxerTerms(false);
              setOpenUxerForm(true);
              pirsch("UXERS-Popup-Terms-Close");
            }}
          />
        </LargePopupContainer>
      )}
      {openUxerForm && (
        <MediumPopupContainer>
          <UxerForm
            onSubmit={(data) => {
              submitRequestUxer(data, lang).then((res) => {
                if (res?.data?.message || res?.data?.messageSuccess) {
                  setOpenUxerForm(false);
                  setOpenUxerSuccess(true);
                } else {
                  newPopup("error", Object.values(res.data)[0]);
                }
              });
            }}
            handleCancle={() => {
              setOpenUxerForm(false);
            }}
            uxerErrors={uxerErrors}
            setUxerErrors={(res) => setUxerErrors(res)}
          />
        </MediumPopupContainer>
      )}
      {openUxerSuccess && (
        <PopupContainer
          title={"Request Sent!"}
          logo={<Logo />}
          onClose={() => {
            setOpenUxerSuccess(false);
          }}
        >
          <div className="success-uxer-card">
            <p>
              Your request has been sent successfully. <br />
              We will email you shortly to inform you whether we have accepted
              or rejected your request.
            </p>
            <Button
              rightIcon={<BackIcon />}
              onClick={() => {
                setOpenUxerSuccess(false);
                pirsch("UXER-RequestSent-Close");
                window.location = "/";
              }}
            >
              Back to UXERS
            </Button>
          </div>
        </PopupContainer>
      )}

      {isSoonPopup && (
        <SoonPopup setIsSoonPopup={(res) => setIsSoonPopup(res)} />
      )}

    </div>
  );
}

function ToolsPart({ value, setValue }) {
  const lang = useSelector((state) => state.lang);
  return (
    <>
      <CheckBoxGroup
        checks={[
          {
            text: "Free",
            textJp: "無料",
            icon: <FreeIcon />,
          },
          {
            text: "Freemium",
            textJp: "フリーミアム",
            icon: <FreemiumIcon />,
          },
          {
            text: "Paid",
            textJp: "有料",
            icon: <PaidIcon />,
          },
        ]}
        value={value["prices"] || []}
        onChange={(result) => {
          setValue({ ...value, prices: result });
        }}
      />

      <RadioBoxGroup
        checks={[
          {
            text: "Apple",
            label: "Apple",
            icon: <AppleIcon />,
          },
          {
            text: "Windows",
            label: "Windows",

            icon: <WindowsIcon />,
          },
          {
            text: "Browser",
            label: "Browser",

            icon: <BrowserIcon />,
          },
          {
            text: "Linux",
            label: "Linux",

            icon: <LinuxIcon />,
          },
        ]}
        value={value["platform"] || ""}
        onChange={(result) => {
          setValue({ ...value, platform: result });
        }}
      />
    </>
  );
}

function AppsPart({ value, setValue }) {
  const lang = useSelector((state) => state.lang);
  return (
    <>
      <RadioBoxGroup
        checks={[
          {
            text: "Apple",
            label: "Apple",
            icon: <AppleIcon />,
          },
          {
            text: "Android",
            label: "Android",

            icon: <AndroidIcon />,
          },
          {
            text: "Windows",
            label: "Windows",

            icon: <WindowsIcon />,
          },
        ]}
        value={value["platform"] || ""}
        onChange={(result) => {
          setValue({ ...value, platform: result });
        }}
      />
        <CheckBoxGroup
        checks={[
          {
            text: "Free",
            textJp: "無料",
            icon: <FreeIcon />,
          },
          {
            text: "Paid",
            textJp: "有料",
            icon: <PaidIcon />,
          },
        ]}
        value={value["prices"] || []}
        onChange={(result) => {
          setValue({ ...value, prices: result });
        }}
      />
    </>
  );
}


function UxersPart({ value, setValue }) {
  const lang = useSelector((state) => state.lang);
  return (
    <>
      <CheckBoxGroup
        className="check-full"
        checks={[
          {
            text: "Product Designer",
            textJp: "プロダクトデザイナー",
          },
          {
            text: "UX Designer",
            textJp: "UXデザイナー",
          },
          {
            text: "UI Designer",
            textJp: "UIデザイナー",
          },
        ]}
        value={value["subRole"] || []}
        onChange={(result) => {
          setValue({ ...value, subRole: result });
        }}
      />
      <CheckBoxGroup
        className="check-full"
        checks={[
          {
            text: "Self-Employee",
            textJp: "事業主",
          },
          {
            text: "Employee",
            textJp: "社員",
          },
          {
            text: "Freelancer",
            textJp: "フリーランサー",
          },
        ]}
        value={value["employ"] || []}
        onChange={(result) => {
          setValue({ ...value, employ: result });
        }}
      />
      <CheckBoxGroup
        className="check-half"
        checks={[
          {
            text: "Branding",
            textJp: "ブランディング",
          },
          {
            text: "Illustration",
            textJp: "イラストレーション",
          },
          {
            text: "Animation",
            textJp: "アニメーション",
          },
          {
            text: "Other Skills",
            textJp: "その他スキル",
          },
        ]}
        value={value["competence"] || []}
        onChange={(result) => {
          setValue({ ...value, competence: result });
        }}
      />
      <CheckBoxGroup
        className="check-full"
        checks={[
          {
            text: "5+ Years Experience",
            textJp: "5年経験以上",
          },
        ]}
        value={value["experience"] || []}
        onChange={(result) => {
          setValue({ ...value, experience: result });
        }}
      />
    </>
  );
}

export default FilterItems;
