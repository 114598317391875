import LangugeSwitch from "../components/atoms/LangugeSwitch";
import RoundImage from "../components/atoms/RoundImage";
import "../styles/main.scss";
import Layout from "../components/layout/Layout";
import { useSelector } from "react-redux/es/hooks/useSelector";
import FilterItems from "../components/templates/filterItems";
import { ReactComponent as CloseIcon } from "../assets/icons/close.svg";
import SettingsLeftBar from "../components/templates/settingsLeftBar";
import SettingsEdit from "../components/templates/settingsEdit";
import UxerForm from "../components/molecules/UxerFormCard";
import SettingsUxers from "../components/templates/settingsUxers";
import SettingsPassword from "../components/templates/settingsPassword";
import SettingsEmail from "../components/templates/settingsEmail";
import { useEffect, useState } from "react";
import SettingsDelete from "../components/templates/settingsDelete";

function Settings({ newPopup, popups, closePopup, selectEmail }) {
  useEffect(() => {
    document.title = "UUD - Setting";
    return () => {
      document.title = "UUD";
    };
  }, []);

  const user = useSelector((state) => state.user);
  const selectMenu = useSelector((state) => state.selectMenu);
  const [showRightBar, setShowRightBar] = useState(false);
  const [showLeftBar, setShowLeftBar] = useState(false);
  const [selected, setSelected] = useState(selectEmail ? 1 : 0);

  function handleClick(index) {
    setSelected(index);
    if (window.innerWidth < 960) {
      setShowLeftBar(false);
    }
  }
  return (
    <Layout
      id="settings"
      selectedMenu={selectMenu}
      currentPage="settings"
      filter={
        <SettingsLeftBar
          selected={selected}
          setSelected={(res) => setSelected(res)}
          handleClick={(res) => handleClick(res)}
        />
      }
      isLogin={
        localStorage.getItem("token") &&
        localStorage.getItem("token") != "undefined"
      }
      setShowLeftBar={(res) => setShowLeftBar(res)}
      setShowRightBar={(res) => setShowRightBar(res)}
      showRightBar={showRightBar}
      showLeftBar={showLeftBar}
    >
      <SettingsEdit
        display={selected == 0}
        setShowLeftBar={(res) => setShowLeftBar(res)}
        newPopup={(res, res1, res2) => newPopup(res, res1, res2)}
      />
      {/* <SettingsUxers
        display={selected == 1}
        setShowLeftBar={(res) => setShowLeftBar(res)}
        newPopup={newPopup}
        selected={selected}
        setSelected={(res) => setSelected(res)}
      /> */}
      <SettingsEmail
        display={selected == 1}
        setShowLeftBar={(res) => setShowLeftBar(res)}
        newPopup={newPopup}
      />
      <SettingsDelete
        display={selected == 3}
        setShowLeftBar={(res) => setShowLeftBar(res)}
        newPopup={newPopup}
      />
      <SettingsPassword
        display={selected == 2}
        setShowLeftBar={(res) => setShowLeftBar(res)}
        newPopup={newPopup}
        closePopup={closePopup}
        popups={popups}
      />
    </Layout>
  );
}

export default Settings;
