import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import React from "react";

function LargePopupContainer({ children, className, onClose }) {
  return (
    <div className={`large-popup-container ${className}`}>
      <div className="popup-card">
        {onClose && (
          <div className="close-btn" onClick={onClose}>
            <CloseIcon />
          </div>
        )}

        {children}
      </div>
    </div>
  );
}

export default LargePopupContainer;
