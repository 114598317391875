import { ReactComponent as LogoIcon } from "../../assets/icons/logo_05.svg";
import { ReactComponent as Logo07Icon } from "../../assets/icons/logo_07.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import { ReactComponent as Send } from "../../assets/icons/send.svg";
import { ReactComponent as PasswordIcon } from "../../assets/icons/password.svg";
import { ReactComponent as NewPasswordIcon } from "../../assets/icons/new_password.svg";
import { ReactComponent as EyeOnIcon } from "../../assets/icons/eye_on.svg";
import { ReactComponent as Illustra02 } from "../../assets/icons/illustra_02.svg";
import { ReactComponent as EyeOffIcon } from "../../assets/icons/eye_off.svg";
import { ReactComponent as Edit } from "../../assets/icons/edit.svg";
import { ReactComponent as CautionIcon } from "../../assets/icons/caution.svg";
import { ReactComponent as Mail } from "../../assets/icons/new_mail.svg";
import React from "react";
import { ReactComponent as Trash } from "../../assets/icons/trash.svg";
import { ReactComponent as Ok } from "../../assets/icons/ok.svg";
import Button from "../atoms/Button";
import { useDispatch, useSelector } from "react-redux";
import ItemCard from "../organismes/ItemCard";
import TheEndItems from "../molecules/TheEndItems";
import { useEffect, useMemo, useState } from "react";
import LoadMoreItems from "../molecules/LoadMoreItems";
import Popup from "../../components/molecules/Popup";
import { setFavsItems } from "../../redux/actions/items";
import Input from "../atoms/Input";
import { likeItem } from "../../services/userServices";
import PopupContainer from "../molecules/PopupContainer";
import { BACKEND_URL } from "../../config";
import NoLoggedMessage from "../molecules/NoLoggedMessage";
import NoResultFoundCard from "../molecules/NoResultFoundCard";
import { ReactComponent as ClearIcon } from "../../assets/icons/clear.svg";
import LoadingCard from "../molecules/LoadingCard";
import { setOpenLoginPopup } from "../../redux/actions/user";
import UxerCard from "../organismes/uxerCard";
import { getUserInfo } from "../../services/authServices";
import axios from "axios";
import jwtDecode from "jwt-decode";
import Switch from "../atoms/Switch";

function SettingsPassword({
  display,
  setShowLeftBar,
  newPopup,
  popups,
  closePopup,
}) {
  const user = useSelector((state) => state.user);
  const [showDelete, setShowDelete] = useState("");
  const [userInfo, setUserInfo] = useState();
  const [opensucessPopup, setOpensucessPopup] = useState(false);
  const [IsShowPopup, settIsShowPopup] = useState(false);
  const token = localStorage.getItem("token");
  const [showMessage, setShowMessage] = useState();
  const [passwordShow, setPasswordShow] = useState([]);
  const [popType, setPopType] = useState("");
  const [popMessage, setPopMessage] = useState("");
  const [popIcon, setPopIcon] = useState("");
  const [password, setPassword] = useState([]);
  const [passwordErrors, setPasswordErrors] = useState(0);
  const lang = useSelector((state) => state.lang);

  function popups(message, type, icon) {
    settIsShowPopup(true);
    setPopType(type);
    setPopIcon(icon);
    setPopMessage(message);
    setTimeout(() => {
      settIsShowPopup(false);
    }, 4000);
  }

  useEffect(() => {
    getUserInfo(jwtDecode(user).id).then((res) => {
      if (res) {
        setUserInfo(res.data);
      }
    });
  }, []);
  async function handleSubmit() {
    if (
      !password[1] ||
      !password[0] ||
      password[1]?.length == 0 ||
      password[0]?.length == 0
    ) {
      popups("Complete the missing field", "error", <CautionIcon />);
      setPasswordErrors((prev) => {
        return prev + 1;
      });
    } else {
      if (password[0] === password[1]) {
        popups("The passwords are same", "error", <CautionIcon />);
        setPasswordErrors((prev) => {
          return prev + 1;
        });
      } else if (password[1].length < 6) {
        popups(
          "The password have to be greater than 6 characters",
          "error",
          <CautionIcon />
        );
        setPasswordErrors((prev) => {
          return prev + 1;
        });
      } else {
        axios
          .post(
            `${BACKEND_URL}/profile/changePassword`,
            {
              oldPassword: password[0],
              newPassword: password[1],
              repeatedNewPassword: password[1],
              lang,
            },
            {
              headers: {
                Authorization: `Bearer ${token.slice(1, -1)}`,
              },
            }
          )
          .then((res) => {
            if (res.data) {
              if (res.data.msg) {
                setPassword(["", ""]);
                setOpensucessPopup(true);
              } else {
                popups(res.data.message, "error", <CautionIcon />);
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  }

  return (
    <>
      <div
        className={`bg-white h-full settings-cont ${display ? "" : "!hidden"}`}
      >
        <div
          className="close-btn close-btn-settings"
          onClick={() => setShowLeftBar(true)}
        >
          <CloseIcon className="z-0" />
        </div>
        <div className={`settings ${display ? "" : "hidden"}`}>
          <div className="settings-title-cont">
            <h2 className="settings-title" data-lang={lang}>
              {lang === "en" ? "Change Password" : "パスワード変更"}
            </h2>
          </div>
          <div className="settings-input-cont">
            <p className="setting-text" data-lang={lang}>
              {lang === "en"
                ? "Your new password must be different to previously used passwords."
                : "新しいパスワードは以前に使用したパスワードと異なるものである必要があります。"}
              <br />
              {lang === "en"
                ? "Password must be at least 6 characters."
                : "パスワードは6文字以上必要です。"}
            </p>
            <div className="flex flex-col gap-3">
              <Input
                leftIcon={<PasswordIcon />}
                className="user-form-body-password"
                placeholder={
                  lang === "en" ? "Current Password" : "現在パスワード"
                }
                type={passwordShow[0] ? "text" : "password"}
                value={password[0]}
                onRightIconClick={() =>
                  passwordShow[0]
                    ? setPasswordShow((prev) => {
                        prev[0] = false;
                        return [...prev];
                      })
                    : setPasswordShow((prev) => {
                        prev[0] = true;
                        return [...prev];
                      })
                }
                rightIcon={
                  passwordShow[0] ? (
                    <EyeOnIcon className="eyeIcon" />
                  ) : (
                    <EyeOffIcon className="eyeIcon" />
                  )
                }
                onChange={(e) => {
                  setPassword((prev) => {
                    prev[0] = e.target.value;
                    return [...prev];
                  });
                  setPasswordErrors(0);
                }}
                error={passwordErrors}
                id="id18"
              />
              <Input
                leftIcon={<NewPasswordIcon />}
                className="user-form-body-password"
                placeholder={
                  lang === "en" ? "New Password" : "新しいパスワード"
                }
                type={passwordShow[1] ? "text" : "password"}
                value={password[1]}
                onRightIconClick={() =>
                  passwordShow[1]
                    ? setPasswordShow((prev) => {
                        prev[1] = false;
                        return [...prev];
                      })
                    : setPasswordShow((prev) => {
                        prev[1] = true;
                        return [...prev];
                      })
                }
                rightIcon={
                  passwordShow[1] ? (
                    <EyeOnIcon className="eyeIcon" />
                  ) : (
                    <EyeOffIcon className="eyeIcon" />
                  )
                }
                onChange={(e) => {
                  setPassword((prev) => {
                    prev[1] = e.target.value;
                    return [...prev];
                  });
                  setPasswordErrors(0);
                }}
                error={passwordErrors}
                id="id19"
              />
            </div>
            <div className="user-form-body-buttons ">
              {/* <Button
                rightIcon={<ClearIcon />}
                onClick={() => {
                  setPassword(["", ""]);
                  console.log(password);
                }}
                className="noLogged_button noLogged_button_outline w-auto los:!min-w-full"
              >
                {lang === "en" ? "Clear" : "クリア"}
              </Button> */}
              <Button
                rightIcon={<Ok />}
                className="button"
                onClick={() => {
                  handleSubmit();
                }}
              >
                {lang === "en" ? "Change Password" : "パスワードを変更"}
              </Button>
            </div>
            {showMessage && (
              <p className="setting-text">
                Check your email newemailaddress@mail.com to confirm your new
                address. The change won’t take effect until confirmed.
              </p>
            )}
          </div>
        </div>
      </div>
      {IsShowPopup ? (
        <Popup
          message={popMessage}
          type={popType}
          icon={popIcon}
          closePopup={() => settIsShowPopup(false)}
        />
      ) : null}
      {opensucessPopup && (
        <PopupContainer
          title="Password Changed!"
          logo={<Illustra02 />}
          onClose={() => {
            setOpensucessPopup(false);
            setPassword([]);
          }}
        >
          <div className="checkemail-popup-text-cont">
            <p>Your password has been successfully changed.</p>
          </div>
        </PopupContainer>
      )}
    </>
  );
}

export default SettingsPassword;
