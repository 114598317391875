import React from "react";
import { useSelector } from "react-redux/es/hooks/useSelector";

function Switch(props) {
  const lang = useSelector((state) => state.lang);

  return (
    <p className={`switch_box ${props.className}`}>
      <div className="checkbox-group">
        <label>
          <input
            type="checkbox"
            checked={props.checked}
            onChange={props.onChange}
            name="termofuse"
          />
          <span
            className={props.error ? "check checkbox-error" : "check"}
          ></span>
        </label>
      </div>
      <span data-lang={lang}>{props.title}</span>
    </p>
  );
}

export default Switch;
