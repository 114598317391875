import React, { Component, useEffect, useState } from "react";
import { ReactComponent as ClearIcon } from "../../assets/icons/clear.svg";
import { useDispatch, useSelector } from "react-redux";
import Select, { components } from "react-select";
import Flag from "../molecules/Flag";

function LocationDrop(props) {
  const [isFocused, setIsFocused] = useState(false);
  const { location, onChange } = props;
  const [placeholder, setPlacehoder] = useState("");
  const [subLocation, setSubLocation] = useState(location);
  const lang = useSelector((state) => state.lang);

  const customOptionRenderer = ({ data, ...rest }) => {
    return (
      <components.Option {...rest}>
        <div className="dropdown-select-inner__option-container">
          <div className="dropdown-select-inner__flag-container">
            <Flag
              code={data.value}
              height="28"
              className="dropdown-select-inner__flag "
              fallback={<span>Unknown</span>}
            />
          </div>
          <span className="dropdown-select-inner__label">{data.label}</span>
        </div>
      </components.Option>
    );
  };

  const customClearIndicatior = (props) => {
    return (
      <components.ClearIndicator {...props}>
        <div className="clean-icon">
          <ClearIcon />
        </div>
      </components.ClearIndicator>
    );
  };

  const customDropDownIndicator = (props) => {
    return placeholder?.length === 0 ? (
      <components.DropdownIndicator
        {...props}
        className={`${props.primaryColors && "dropdown-indicator-primary"}`}
      />
    ) : null;
  };

  const handleChange = (value) => {
    setPlacehoder(value?.label || "");
    setSubLocation(value?.value || "");
    onChange(value?.label || "");
  };
  useEffect(() => {
    if (!props.clear) {
      setPlacehoder("");
      setSubLocation("");
      onChange("");
      handleBlur();
    }
  }, [props.clear]);
  const handleBlur = () => {
    const optionElements = document.querySelectorAll(
      ".dropdown-select-inner__option"
    );
    optionElements.forEach((option) => {
      option.classList.remove("dropdown-select-inner__option--is-focused");
    });
    setIsFocused(false);
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  useEffect(() => {
    if (placeholder === "") {
      setSubLocation("");
      onChange("");
    }

    if (props.defaultValue) {
      setSubLocation(props.defaultValue.value);
      setPlacehoder(props.defaultValue.label);
      onChange(props.defaultValue.label || "");
    }
  }, [props.defaultValue]);

  return (
    <div
      className={`dropdown-select  ${
        isFocused ? "dropdown-focused" : ""
      } primary-${props.primaryColors} `}
      style={props.style}
    >
      {subLocation !== "" ? (
        <div className="flag-container">
          <Flag
            code={subLocation}
            height="26"
            width="26"
            className="flag"
            fallback={
              <div
                className={
                  props.error
                    ? `error-input-icon left-icon ${
                        props.primaryColors && "left-icon-primary"
                      }`
                    : `left-icon ${props.primaryColors && "left-icon-primary"}`
                }
              >
                {props.leftIcon}
              </div>
            }
          />
        </div>
      ) : (
        <div
          className={
            props.error
              ? `error-input-icon left-icon ${
                  props.primaryColors && "left-icon-primary"
                }`
              : `left-icon ${props.primaryColors && "left-icon-primary"}`
          }
        >
          {props.leftIcon}
        </div>
      )}
      <Select
        options={props.countries}
        components={{
          Option: customOptionRenderer,
          DropdownIndicator: customDropDownIndicator,
          IndicatorSeparator: () => null,
          ClearIndicator: customClearIndicatior,
        }}
        // placeholder={
        //   placeholder ? (
        //     <span style={{ color: "#39274E " }}>
        //       { lang === "en" ?
        //     ( "")  :  ("")}</span>

        //   ) : ( lang === "en" ?
        //     ( "Location")  :  ("ロケーション222")
        //   )
        // }

        placeholder={
          placeholder ? (
            <span style={{ color: "#39274E " }} data-lang={lang}>
              {placeholder}
            </span>
          ) : lang === "en" ? (
            "Location"
          ) : (
            "ロケーション"
          )
        }
        onChange={handleChange}
        defaultValue={props.defaultValue?.value}
        className={
          props.error
            ? `input-error dropdown-select-inner ${props.className} ${
                props.primaryColors && "dropdown-select-inner-primary"
              }`
            : `dropdown-select-inner ${props.className} ${
                props.primaryColors && "dropdown-select-inner-primary"
              }`
        }
        classNamePrefix={
          props.primaryColors
            ? "dropdown-select-inner-primary"
            : "dropdown-select-inner"
        }
        onBlur={handleBlur}
        onFocus={handleFocus}
        isClearable={true}
        value={
          props.clear || !placeholder
            ? null
            : { label: placeholder, value: subLocation }
        }
      />
    </div>
  );
}

export default LocationDrop;
