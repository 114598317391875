import { ReactComponent as LogoIcon } from "../assets/icons/logo_05.svg";
import { ReactComponent as Illustar02 } from "../assets/icons/illustra_02.svg";
import { ReactComponent as Illustra0 } from "../assets/icons/illustra_0.svg";
import { ReactComponent as Illustra00 } from "../assets/icons/illustra_00.svg";
import { ReactComponent as Illustar03 } from "../assets/icons/illustra_03.svg";
import { ReactComponent as Illustar04 } from "../assets/icons/illustra_04.svg";
import { ReactComponent as Illustar06 } from "../assets/icons/illustra_06.svg";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setOpenLoginPopup, setUser } from "../redux/actions/user";
import AdminLoginContainer from "../components/molecules/AdminLoginContainer";
import AdminLoginCard from "../components/organismes/popup/AdminLogin";
import { useEffect, useState } from "react";
import { login } from "../services/authServices";
import { setLocalStorageItem } from "../services/localStorage";



const AdminLogin = ({ newPopup, popups, closePopup, menu  }) => {
    const lang = useSelector((state) => state.lang);
    const [error, setError] = useState({});
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();

    useEffect(() => {
        document.title = `UUD - Admin Login`;
      }, []);

      const handleLogin = (email, password) => {
        const popupId = newPopup("loading", "Loading...", false);
    
        login(email, password, lang)
          .then((result) => {
            closePopup(popupId);
            if (result.data.message) {
              console.log(result.data.message);
              newPopup("error", result.data.message, true);
              setError({ ...error, login: true });
            } else {
              if(result.data.role !== 'admin') {
                window.location.href = '/'
              }
              setLocalStorageItem('admin-token', result.data.myToken);
              setLocalStorageItem('role', result.data.role);
              window.location.href = '/dashboard';
              
            }
          })
          .catch((error) => {
            newPopup("error", error?.response?.data.message, true);
          });
      };


    return (
        <AdminLoginContainer
          title={t("popup.login.title")}
          logo={<Illustar06 />}
          big={true}
          onClose={() => dispatch(setOpenLoginPopup(false))}
          className={"signup_top_hero"}
          cardClass={"mail_signup"}
          windowClass={"mail_signup_card"}
          innerClass={"inner_card"}
          illustration={<Illustra0 />}
        >
          <AdminLoginCard
            error={error.login}
            onLogin={(email, password) => handleLogin(email, password)}
          />
        </AdminLoginContainer>
    );
};

export default AdminLogin;