function StaticPageBigImage({
  text,
  imgSrc1Large,
  imgSrc1Small,
  title,
  className = "",
}) {
  return (
    <>
      <picture className={`big-image-box ${className}`}>
        <source media="(max-width: 960px)" srcset={imgSrc1Small} />
        <img src={imgSrc1Large} className="big-image" />
      </picture>
    </>
  );
}

export default StaticPageBigImage;
