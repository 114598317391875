import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Import your translation files
import enTranslations from "./locales/en.json";
import jpTranslations from "./locales/jp.json";

i18n
  .use(initReactI18next) // Passes i18n down to react-i18next
  .init({
    // debug: true,
    // store language on cookie for 1 year if not exist use english
    lng: localStorage.getItem("language") || "en",
    resources: {
      // Include your resources here
      en: {
        translation: enTranslations,
      },
      jp: {
        translation: jpTranslations,
      },
    },
    interpolation: {
      escapeValue: false, // React already safes from xss
    },
  });

export default i18n;
