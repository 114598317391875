import { ReactComponent as LogoIcon } from "../assets/icons/logo_05.svg";
import { ReactComponent as Illustar02 } from "../assets/icons/illustra_02.svg";
import { ReactComponent as CautionIcon } from "../assets/icons/caution.svg";
import { ReactComponent as SendIcon } from "../assets/icons/send.svg";
import { ReactComponent as Illustra0 } from "../assets/icons/illustra_0.svg";
import { ReactComponent as Illustra00 } from "../assets/icons/illustra_00.svg";
import { ReactComponent as Illustra02 } from "../assets/icons/illustra_02.svg";
import { ReactComponent as MailIcon } from "../assets/icons/mail.svg";
import { ReactComponent as Illustra01 } from "../assets/icons/illustra_01.svg";
import { ReactComponent as Illustar03 } from "../assets/icons/illustra_03.svg";
import { ReactComponent as Illustar04 } from "../assets/icons/illustra_04.svg";
import { ReactComponent as Illustar06 } from "../assets/icons/illustra_06.svg";
import Button from "../components/atoms/Button";
import React from "react";
import { getItems, getUxers } from "../services/itemsServices";
import { BACKEND_URL } from "../config";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as LoginIcon } from "../assets/icons/login.svg";
import { ReactComponent as UserNameIcon } from "../assets/icons/id_icon.svg";
import Input from "../components/atoms/Input";
import Layout from "../components/layout/Layout";
import CardGrid from "../components/templates/cardGrid";
import { ReactComponent as Logo } from "../assets/icons/logo.svg";
import { setOpenSignupPopup } from "../redux/actions/user";
import FilterItems from "../components/templates/filterItems";
import "../styles/main.scss";
import { useEffect, useState } from "react";
import jwtDecode from "jwt-decode";
import PopupContainer from "../components/molecules/PopupContainer";
import { setSelectLang } from "../redux/actions/env";
import { setOpenLoginPopup, setUser } from "../redux/actions/user";
import LoginCard from "../components/organismes/popup/LoginCard";
import ForgotPasswordCard from "../components/organismes/popup/ForgotPasswordCard";
import CheckEmailCard from "../components/organismes/popup/CheckEmailCard";
import {
  createNewPassword,
  forgetPassword,
  login,
} from "../services/authServices";
import { useParams, useLocation } from "react-router";
import NewPasswordCard from "../components/organismes/popup/NewPasswordCard";
import ResetPasswordDoneCard from "../components/organismes/popup/ResetPasswordDoneCard";
import Popup from "../components/molecules/Popup";
import UxerGrid from "../components/templates/uxerGrid";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { setSelectMenu } from "../redux/actions/env";
import { getUserInfo } from "../services/authServices";
import SoonPopup from "../components/organismes/popup/SoonPopup";
import { use } from "i18next";
import pirsch from "../Analytics";

function Home({ newPopup, closePopup, menu }) {
  const dispatch = useDispatch();
  const { search } = useLocation();

  const [openSuccess, setOpenSuccess] = useState(
    localStorage.getItem("signUpEmail") ? true : false
  );

  const [popIcon, setPopIcon] = useState("");
  const [popType, setPopType] = useState("");
  const [popMessage, setPopMessage] = useState("");
  const [IsShowPopup, settIsShowPopup] = useState(false);
  const params = useParams();
  const selectMenu = useSelector((state) => state.selectMenu);
  const openLoginPopup = useSelector((state) => state.openLoginPopup);
  const openSignupPopup = useSelector((state) => state.openSignupPopup);
  const user = useSelector((state) => state.user);
  const uxers = useSelector((state) => state.uxers);
  const [forgetPasswordPopup, setForgetPasswordPopup] = useState(false);
  const [checkEmailPopup, setCheckEmailPopup] = useState(false);
  const [filterLength, setFilterLength] = useState([]);
  const [openCheckEmailPopup, setOpenCheckEmailPopup] = useState(false);
  const [newPasswordPopup, setNewPasswordPopup] = useState(false);
  const [filterValue, setFilterValue] = useState({});
  const [emailRecent, setEmailRecent] = useState("");
  const [userId, setUserId] = useState(params.id);
  const [resetToken, setResetToken] = useState(params.resetToken);
  const [token, setToken] = useState(params.token);
  const [showWelcomePopup, setShowWelcomePopup] = useState(false);
  const [resetPasswordDone, setResetPasswordDone] = useState(false);
  const [error, setError] = useState({});
  const { t, i18n } = useTranslation();
  const [showRightBar, setShowRightBar] = useState(false);
  const [showLeftBar, setShowLeftBar] = useState(false);
  const [fileCount, setFilteCount] = useState();
  const [isSoonPopup, setIsSoonPopup] = useState(false);
  const [fullName, setFullName] = useState();
  const lang = useSelector((state) => state.lang);
  const [email, setEmail] = useState();

  const [emailError, setEmailError] = useState(false);
  useEffect(() => {
    const params1 = new URLSearchParams(search);
    console.log(params1.get("lang"));
    const langParam = params1.get("lang");

    if (langParam && langParam != localStorage.getItem("language")) {
      dispatch(setSelectLang(langParam));
      localStorage.setItem("language", langParam);
      window.location.reload();
    }
  }, []);
  function popups(message, type, icon) {
    settIsShowPopup(true);
    setPopType(type);
    setPopIcon(icon);
    setPopMessage(message);
    setTimeout(() => {
      settIsShowPopup(false);
    }, 4000);
  }
  useEffect(() => {
    document.title = `UUD - ${selectMenu.toUpperCase()}`;
  }, [selectMenu]);

  getItems("tools").then((res) => {
    console.log(res);
  });
  useEffect(() => {
    menu && dispatch(setSelectMenu(menu));
  }, []);
  const isValidEmail = (email) => {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    );
  };
  const handleLogin = (email, password) => {
    popups("Loading...", "loading");

    login(email, password, lang)
      .then((result) => {
        if (result.data.message) {
          console.log(result.data.message);
          popups(result.data.message, "error");
          setError({ ...error, login: true });
        } else {
          dispatch(setOpenLoginPopup(false));
          settIsShowPopup(false);
          dispatch(setUser(result.data.myToken));
          if (token) {
            setShowWelcomePopup(true);
          }
        }
      })
      .catch((error) => {
        newPopup("error", error?.response?.data.message, true);
      });
  };
  useEffect(() => {
    if (token) {
      axios
        .post(`${BACKEND_URL}/auth/confirm-account/${token}`)
        .then((res) => {
          setOpenSuccess(false);
          localStorage.removeItem("signUpEmail");
          dispatch(setOpenLoginPopup(true));
        })
        .catch(() => {
          setShowWelcomePopup(false);
        });
    }
  }, [token]);
  useEffect(() => {
    if (user) {
      getUserInfo(jwtDecode(user).id).then((res) => {
        if (res.data) {
          setFullName(res.data.fullName);
        }
      });
    }
  }, [user]);

  useEffect(() => {
    if (resetToken && userId) {
      setNewPasswordPopup(true);
    }
  }, []);

  return (
    <Layout
      id="home"
      selectedMenu={selectMenu}
      currentPage="home"
      filter={
        <FilterItems
          newPopup={newPopup}
          setFilterValue={setFilterValue}
          categorie={selectMenu}
          fileCount={fileCount}
          filterLength={filterLength}
        />
      }
      isLogin={
        localStorage.getItem("token") &&
        localStorage.getItem("token") != "undefined"
      }
      setShowLeftBar={(res) => setShowLeftBar(res)}
      setShowRightBar={(res) => setShowRightBar(res)}
      showRightBar={showRightBar}
      showLeftBar={showLeftBar}
      setIsSoonPopup={(res) => setIsSoonPopup(res)}
    >
      {["Tools", "Resos", "Ideaz", "Apps"].includes(selectMenu) && (
        <CardGrid
          categorie={selectMenu}
          filterValue={filterValue}
          setIsSoonPopup={(res) => setIsSoonPopup(res)}
          setFilterLength={(res) => setFilterLength(res)}
          filterLength={(res) => filterLength(res)}
        />
      )}
      {selectMenu === "Uxers" && (
        <UxerGrid
          uxers={uxers.allUsers}
          filterValue={filterValue}
          setFilteCount={setFilteCount}
        />
      )}
      {/* popup's */}
      {checkEmailPopup && (
        <PopupContainer
          title={t("popup.checkEmail.title")}
          logo={<Illustar02 />}
          onClose={() => setCheckEmailPopup(false)}
        >
          <CheckEmailCard
            email={emailRecent}
            onRecent={() => {
              forgetPassword(emailRecent)
                .then(() => {
                  console.log("ok");
                })
                .catch((error) => {
                  console.log(error);
                });
            }}
            onBack={() => {
              setCheckEmailPopup(false);
              dispatch(setOpenLoginPopup(true));
            }}
          />
        </PopupContainer>
      )}
      {newPasswordPopup && (
        <PopupContainer
          title={t("popup.newPassword.title")}
          logo={<LogoIcon className="forget-svg" />}
          onClose={() => setNewPasswordPopup(false)}
        >
          <NewPasswordCard
            error={error.password}
            onCreateNewPassword={async (newPass, passVerif) => {
              const popupId = newPopup("loading", "Loading...", true);
              if (newPass === passVerif) {
                try {
                  createNewPassword(
                    {
                      password: newPass,
                      resetToken: resetToken,
                      confirmpassword: passVerif,
                    },
                    userId,
                    lang
                  )
                    .then((res) => {
                      if (res) {
                        closePopup(popupId);
                        if (res.data.messageError) {
                          newPopup("error", res.data.messageError, true);
                        } else {
                          setNewPasswordPopup(false);
                          setResetPasswordDone(true);
                        }
                      }
                    })
                    .catch((error) => {
                      setError({ password: true });
                      closePopup(popupId);
                      newPopup("error", "Error!", true);
                    });
                } catch {
                  closePopup(popupId);

                  setError({ password: true });
                  newPopup("error", "Error!", true);
                }
              } else {
                setError({ password: true });
                closePopup(popupId);
                newPopup("error", "Password not same!", true);
              }
            }}
          />
        </PopupContainer>
      )}
      {resetPasswordDone && (
        <PopupContainer
          title={t("popup.resetPasswordDone.title")}
          logo={<LogoIcon className="forget-done-svg" />}
          onClose={() => setResetPasswordDone(false)}
        >
          <ResetPasswordDoneCard
            onBackToLogin={() => {
              setResetPasswordDone(false);
              dispatch(setOpenLoginPopup(true));
            }}
          />
        </PopupContainer>
      )}
      <div className="popup-secondary-container"></div>
      {openSuccess && (
        <PopupContainer
          title={
            lang === "en"
              ? "Confirmation Email Sent!"
              : "確認のメールを送信完了"
          }
          logo={<Logo />}
          onClose={() => {
            setOpenSuccess(false);
            localStorage.removeItem("signUpEmail");
          }}
        >
          <div className="success-uxer-card">
            <p data-lang={lang}>
              {lang === "en" ? (
                <>
                  Welcome aboard! <br />
                  We've sent an email with an account activation link to :
                  <span className="dynamic_text">
                    {" "}
                    {localStorage.getItem("signUpEmail")}{" "}
                  </span>
                  <br />
                  <br />
                  Click the link inside to unlock all the amazing features
                  waiting for you!
                </>
              ) : (
                <>
                  ご登録をありがとうございます。！
                  <br />
                  アカウントのアクティベーションリンクが含まれたメールを以下のメールアドレスに送信しました。
                  <span className="dynamic_text">
                    {" "}
                    {localStorage.getItem("signUpEmail")}{" "}
                  </span>
                  <br />
                  <br />
                  中のリンクをクリックして、ログインしてください！
                </>
              )}
            </p>
          </div>
        </PopupContainer>
      )}
      {showWelcomePopup && (
        <PopupContainer
          title={"Congratulations!"}
          logo={<Logo />}
          onClose={() => {
            setShowWelcomePopup(false);
          }}
        >
          <div className="success-uxer-card mb-4">
            <p>
              Congratulations
              <span className="dynamic_text">
                {" "}
                {fullName ? fullName : null}{" "}
              </span>
              , You have completed your registration successfully!
            </p>
          </div>
        </PopupContainer>
      )}
      {isSoonPopup && (
        <SoonPopup setIsSoonPopup={(res) => setIsSoonPopup(res)} />
      )}
      {openCheckEmailPopup && (
        <PopupContainer
          title={lang === "en" ? "Check your Email" : "メールを確認"}
          logo={<Illustra02 />}
          onClose={() => {
            setOpenCheckEmailPopup(false);
          }}
        >
          <div className="checkemail-popup-text-cont">
            {lang === "en" ? (
              <>
                <p>
                  Thank you! <br />
                  YourEmail <span>{email}</span> has been submitted.
                </p>
                <p>
                  Check your inbox for a confirmation Email to complete the sign
                  up process.
                </p>
                <p>
                  Please note, the confirmation link expires in{" "}
                  <span>48 hours.</span>
                </p>
              </>
            ) : (
              <>
                <p>
                  ご登録をありがとうございます！ <br />
                  お使いのメールアドレス <span>{email}</span> が送信されました。
                </p>
                <p>
                  受信箱に確認メールが届いているか確認してサインアップを完了してください
                  プロセス。
                </p>
                <p>
                  確認リンクの有効期限は次のとおりです。 <span>48時間。</span>
                </p>
              </>
            )}
          </div>
        </PopupContainer>
      )}{" "}
      {IsShowPopup ? (
        <Popup
          message={popMessage}
          type={popType}
          icon={popIcon}
          closePopup={() => settIsShowPopup(false)}
        />
      ) : null}
    </Layout>
  );
}

export default Home;
