const itemsReducer = (
  state = { tools: [], resos: [], ideaz: [], uxers: [], apps: [] },
  action
) => {
  switch (action.type) {
    case "SETITEMS":
      return action.payload;
    default:
      return state;
  }
};

const favsItemsReducer = (
  state = { myTools: [], myResos: [], myIdeaz: [], myApps: [] },
  action
) => {
  switch (action.type) {
    case "SETFAVSITEMS":
      return action.payload;
    default:
      return state;
  }
};

const numberItemsReducer = (
  state = { tools: 0, resos: 0, ideaz: 0, apps: 0, uxers: 0 },
  action
) => {
  switch (action.type) {
    case "SETNUMBERITEMS":
      return action.payload;
    default:
      return state;
  }
};

const uxersReducer = (state = { allUsers: [] }, action) => {
  switch (action.type) {
    case "SETUXERS":
      return action.payload;
    default:
      return state;
  }
};

export { itemsReducer, numberItemsReducer, favsItemsReducer, uxersReducer };
