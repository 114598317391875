import { ReactComponent as SoonIllus } from "../assets/icons/soon.svg";
import "../styles/main.scss";
import React, { useEffect } from "react";

function Soon() {

  useEffect(() => {
    document.title = "UUD - Coming Soon!";
    return () => {
      document.title = "UUD";
    };
  }, []);


  useEffect(() => {
    document.title = "UUD - Coming Soon"; // Set the document title to 'Coming Soon' when the component is mounted
  }, []); // Empty dependency array ensures this effect runs once after the initial render

  return (
    <div className="soon-page">
      <div className="soon-page-box">
        <div className="logo-box">
          <SoonIllus className="soon-page-logo" />
          <h3>COMING SOON</h3>
          <h4>カミングスーン</h4>
        </div>
      </div>
    </div>
  );
}

export default Soon;
