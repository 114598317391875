import "../../styles/events.scss";
import StaticPageBigImage from "../../components/organismes/StaticPageBigImage";
import ContactEvent from "../ContactEvent";

function EventContent({
  title,
  text,
  imgSrc1Small,
  imgSrc1Large,
  customClass = "",
  listItems = [],
  children,
  id,
}) {
  return (
    <div className={`event_content_hero ${customClass}`} id={id}>
      <div className="text_box">
        <div className="image_box"></div>
        <div className="title">{title}</div>
        <div className="text">
          {/* Conditionally render list_box only if listItems is not empty */}
          {listItems.length > 0 && (
            <div className="list_box">
              {listItems.map((item, index) => (
                <div className="list" key={index}>
                  <span>{item.label}</span> {item.value}
                </div>
              ))}
            </div>
          )}

          <div dangerouslySetInnerHTML={{ __html: text }} />
          {children && <>{children}</>}
        </div>
      </div>
    </div>
  );
}

export default EventContent;
