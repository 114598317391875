let token;

try {
  token = localStorage.getItem("token");
} catch (error) {
  token = "";
}

const initialState = token

export const UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SETUSER":
      return action.payload;
    default:
      return state;
  }
}

export const OpenLoginPopupReducer = (state = false, action) => {
  switch (action.type) {
    case "SETOPENLOGINPOPUP":
      return action.payload;
    default:
      return state;
  }
}

export const OpenSignupPopupReducer = (state = false, action) => {
  switch (action.type) {
    case "SETOPENSIGNUPPOPUP":
      return action.payload;
    default:
      return state;
  }
}