import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as Send } from "../assets/icons/send.svg";
import "../styles/main.scss";
import { ReactComponent as Subject } from "../assets/icons/subject.svg";
import { ReactComponent as Check } from "../assets/icons/check.svg";
import Popup from "../components/molecules/Popup";
import { ReactComponent as MailIcon } from "../assets/icons/mail.svg";
import { ReactComponent as Mail2 } from "../assets/icons/mail_fill.svg";
import { ReactComponent as Phone } from "../assets/icons/phone.svg";
import { ReactComponent as Loading } from "../assets/icons/loading.svg";
import { ReactComponent as CautionIcon } from "../assets/icons/caution.svg";
import CheckBox from "../components/atoms/CheckBox";
import PopupContainer from "../components/molecules/PopupContainer";
import { ReactComponent as Logo } from "../assets/icons/logo_05.svg";
import { ReactComponent as UserNameIcon } from "../assets/icons/namecard.svg";
import { ReactComponent as Account } from "../assets/icons/account.svg";
import { ReactComponent as Robot } from "../assets/icons/robot.svg";
import { ReactComponent as Human } from "../assets/icons/human.svg";
import { ReactComponent as Company } from "../assets/icons/company.svg";
import countires from "../data/countires.json";
import Button from "../components/atoms/Button";
import Select from "../components/atoms/Select";
import Input from "../components/atoms/Input";
import React, { useEffect, useState } from "react";
import Layout from "../components/layout/Layout";
import StaticPageTitle from "../components/atoms/StaticPageTitle";
import RadioBoxGroup from "../components/molecules/RadioBoxGroup";
import TextArea from "../components/atoms/TextArea";
import axios from "axios";
import pirsch from "../Analytics";

function ContactUs({}) {
  useEffect(() => {
    document.title = "UUD - Contact us";
    return () => {
      document.title = "UUD";
    };
  }, []);

  const user = useSelector((state) => state.user);
  const [showRightBar, setShowRightBar] = useState(false);
  const [data, setData] = useState({});
  const lang = useSelector((state) => state.lang);
  const [showLeftBar, setShowLeftBar] = useState(false);
  const countries = countires;
  const [value, setValue] = useState();
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});
  const [popMessage, setPopMessage] = useState("");
  const [popIcon, setPopIcon] = useState("");
  const [popType, setPopType] = useState("");
  const [IsShowPopup, settIsShowPopup] = useState(false);
  const [invitationNotValid, setInvitationNotValid] = useState(false);
  const [quitPopup, setQuitPopup] = useState(false);
  const [timeOut, setTimeOut] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);

  const regex = new RegExp("[a-zA-Z0-9_]+$");
  const rolesOptions1 = [
    {
      value:
        lang === "en"
          ? "Feedback/Issues Reporting"
          : "フィードバック/問題の報告",
      label:
        lang === "en"
          ? "Feedback/Issues Reporting"
          : "フィードバック/問題の報告",
    },

    {
      value: lang === "en" ? "Request/Suggestion" : "リクエスト/提案",
      label: lang === "en" ? "Request/Suggestion" : "リクエスト/提案",
    },

    {
      value:
        lang === "en"
          ? "Media and Press Inquiries"
          : "メディアと記者の問い合わせ",
      label:
        lang === "en"
          ? "Media and Press Inquiries"
          : "メディアと記者の問い合わせ",
    },
  ];

  const rolesOptions2 = [
    {
      value: lang === "en" ? "Partnership Offer" : "フィードバック/問題の報告",
      label: lang === "en" ? "Partnership Offer" : "フィードバック/問題の報告",
    },
    {
      value: lang === "en" ? "Sponsoring" : "スポンサー",
      label: lang === "en" ? "Sponsoring" : "スポンサー",
    },
    {
      value:
        lang === "en"
          ? "Be a part of the UUD Team (Join us)"
          : "UUDチームにジョイン",
      label:
        lang === "en"
          ? "Be a part of the UUD Team (Join us)"
          : "UUDチームにジョイン",
    },
    {
      value: lang === "en" ? "Other Offers" : "フィードバック/問題の報告",
      label: lang === "en" ? "Other Offers" : "フィードバック/問題の報告",
    },
  ];
  const [country, setCountry] = useState("");

  //creating function to load ip address from the API
  // const getData = async () => {
  //   console.log(res.data);
  //   setIP(res.data.IPv4);
  // };

  // Updated Code
  useEffect(() => {
    axios
      .get("https://api.country.is/")
      .then((res) => {
        if (res.data.country) {
          setCountry(res.data.country);
        }
      })
      .catch(() => {});
  }, []);

  const handleChange = async (element, dataP) => {
    if (element == "state") {
      setData({ ...data, [`${element}`]: dataP, subRole: "" });
    } else {
      setData({ ...data, [`${element}`]: dataP });
    }
  };
  function popups(message, type, icon) {
    settIsShowPopup(true);
    setPopType(type);
    setPopIcon(icon);
    setPopMessage(message);

    setTimeout(() => {
      settIsShowPopup(false);
    }, 6000);
  }
  const handleSubmit = (e) => {
    e.preventDefault();

    let errors = validateForm(data);
    console.log(errors);
    if (Object.keys(errors).length === 0) {
      const japanDate = new Date();

      // Convert date to Japan's timezone
      const date = new Date(
        japanDate.toLocaleString("en-US", { timeZone: "Asia/Tokyo" })
      );

      const formData = new FormData();
      let fullCountry = countries.find((el) => {
        return el.iso2.toLowerCase() === country.toLowerCase();
      });
      formData.append("country", country ? fullCountry.label : "- - - -");
      formData.append("message", data.message ? data.message : "- - - -");
      formData.append("email", data.email ? data.email : "- - - -");
      formData.append("company", data.company ? data.company : "- - - -");
      formData.append("phone", data.phone ? data.phone : "- - - -");
      formData.append("state", data.state ? data.state : "- - - -");
      formData.append("subRole", data.subRole ? data.subRole : "- - - -");
      formData.append("name", data.fullName ? data.fullName : "- - - -");
      formData.append(
        "date",
        `${date.getDate()}/${
          date.getMonth() + 1
        }/${date.getFullYear()} - ${date.getHours()}:${
          date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()
        }:${
          date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds()
        }`
      );
      formData.append("timezone", "Asia/Tokyo");

      popups("Loading...!", "info", <Loading />);
      axios
        .post(
          "https://script.google.com/macros/s/AKfycbzSjT1j2yuJXcyiZU6qmGN6JWaKJNbOJwpsuhAprh7EqEpjoU7eH0hzw3FfRz1ZoPFxlg/exec",
          formData
        )

        .then((response) => {
          settIsShowPopup(false);
          if (response.data.result == "success") {
            setOpenSuccess(true);
            setTimeout(() => {}, 4000);
            setData({});
          } else {
            popups("Error occurred", "error", <CautionIcon />);
          }
        })
        .catch((error) => console.error("Error!", error.message));
    } else {
      if (Object.keys(errors).length > 1) {
        popups(
          lang === "en"
            ? "Please fill out the required fields"
            : "必須項目を入力してください。",
          "error",
          <CautionIcon />
        );
        console.log(errors);
      } else {
        Object.keys(errors).map(() => {
          popups(Object.values(errors)[0], "error", <CautionIcon />);
        });
      }
    }
  };

  const validateForm = (data) => {
    const errors = {};

    if (!data.email) {
      errors.email = "Email is required";
    } else if (!isValidEmail(data.email)) {
      errors.email = "Invalid email address";
    }

    if (!data.fullName) {
      errors.fullName = "FullName is required";
    }
    if (!data.subRole && data.state != "Say Hello!") {
      errors.subRole = "Role is required";
    }
    if (!data.state) {
      errors.state = "Radio is required";
    }
    if (!data.phone && (data.state == "Partnership" || !data.state)) {
      errors.phone = "Phone Number is required";
    }
    if (!data.company && (data.state == "Partnership" || !data.state)) {
      errors.company = "Company is required";
    }

    if (!data.message) {
      errors.message = "Introduction is required";
    } else if (data.message.length > 750 || data.message.length < 50) {
      errors.message = "Introduction must be between 50 and 750 characters";
    }
    if (!data.checked) {
      errors.checked = "Please confirm that you are human";
    }
    setErrors(errors);
    return errors;
  };

  const isUsernameValid = (username) => {
    return /^[a-z0-9_]+$/.test(username);
  };

  const isValidEmail = (email) => {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    );
  };

  const isValidSocialMediaLink = (url) => {
    return /^(https?|ftp):\/\/([^\s\/]+)((\/\w+)*\/)([\w\-\.]+[^#?\s]+)(.*)?(#[\w\-]+)?$/.test(
      url
    );
  };

  const isValidURL = (url) => {
    return /^(http(s)?:\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)?$/.test(
      url
    );
  };
  useEffect(() => {
    handleChange("state", "About the Service");
    setData((prev) => {
      prev.phone = "";
      return { ...prev };
    });
  }, []);
  return (
    <>
      {" "}
      <Layout
        id="contact"
        selectedMenu={""}
        currentPage="news"
        isLogin={user !== "" && user}
        setShowLeftBar={(res) => setShowLeftBar(res)}
        setShowRightBar={(res) => setShowRightBar(res)}
        showRightBar={showRightBar}
        showLeftBar={showLeftBar}
        actionMenu={false}
      >
        <div className="static-page-hero">
          {/* <div className="contact-page-box"> */}
          <div className="static-page">
            <StaticPageTitle
              icon={<MailIcon className="aboutus-title-icon2" />}
              text={"CONTACT"}
            />
            <div className="contact-cont-box">
              <p className="paragraph contact-text" data-lang={lang}>
                {lang === "en" ? (
                  <>
                    Welcome to our UUD! We're just starting out and value your
                    input. <br />
                    Whether you have feedback, suggestions, or want to partner
                    with us, we're all ears. Feel free to reach out - your
                    contribution matters!
                  </>
                ) : (
                  <>
                    UUDへようこそ!
                    UUDは始めて間もないサービスであり、皆さまのご意見を大切にしています。
                    フィードバック、提案、質問など、またはパートナーシップのご希望があればお気軽にお問い合わせください
                    - 皆さまの声に感謝します！{" "}
                  </>
                )}
              </p>
              <form className="contact-form-box" onSubmit={handleSubmit}>
                <RadioBoxGroup
                  checks={[
                    {
                      text:
                        lang === "en"
                          ? "About the Service"
                          : "サービスについて",
                      label: "About the Service",
                      
                    },
                    {
                      text: lang === "en" ? "Partnership" : "パートナーシップ",
                      label: "Partnership",
                    },
                    {
                      text: lang === "en" ? "Say Hello!" : "その他",
                      label: "Say Hello!",
                    },
                  ]}
                  value={data.state}
                  onChange={(result) => {
                    handleChange("state", result);
                    errors.state = "";
                  }}
                  className="contact-radio-box"
                  error={errors.state}
                />
                <div>
                  <div className="contact-form">
                    {data.state != "Say Hello!" && (
                      <Select
                        leftIcon={<Subject />}
                        options={
                          data.state == "Partnership"
                            ? rolesOptions2
                            : rolesOptions1
                        }
                        onChange={(value) => {
                          handleChange("subRole", value);
                          errors.subRole = "";
                        }}
                        value={data.subRole}
                        defaultValue={
                          lang === "en" ? "Subject" : "お問合せの目的"
                        }
                        error={errors.subRole}
                      />
                    )}
                    <div className="contact-input-box">
                      <Input
                        leftIcon={<UserNameIcon />}
                        // placeholder={"Email or Username"}
                        placeholder={lang === "en" ? "Full Name" : "お名前"}
                        type="text"
                        value={data.fullName}
                        onChange={(e) => {
                          handleChange("fullName", e.target.value);
                          errors.fullName = "";
                        }}
                        error={errors.fullName}
                      />
                      <Input
                        value={data.email}
                        onChange={(e) => {
                          handleChange("email", e.target.value);
                          errors.email = "";
                        }}
                        leftIcon={<MailIcon />}
                        placeholder={
                          lang === "en" ? "Email Address" : "メールアドレス"
                        }
                        error={errors.email}
                      />
                    </div>
                    <div className="contact-input-box">
                      <Input
                        leftIcon={<Phone />}
                        className="phone-input"
                        placeholder={lang === "en" ? "Phone" : "電話番号"}
                        type="text"
                        value={data.phone}
                        onChange={(e) => {
                          if (
                            /^[0-9]+$/.test(e.target.value) ||
                            e.target.value.length == 0
                          ) {
                            handleChange("phone", e.target.value);
                            errors.phone = "";
                          }
                        }}
                        error={
                          data.state == "Partnership" || !data.state
                            ? errors.phone
                            : null
                        }
                      />
                      <Input
                        value={data.company}
                        onChange={(e) => {
                          handleChange("company", e.target.value);
                          errors.company = "";
                        }}
                        leftIcon={<Company />} // innerClass="py-2-5"
                        placeholder={lang === "en" ? "Company" : "会社名"}
                        error={
                          data.state == "Partnership" || !data.state
                            ? errors.company
                            : null
                        }
                        type="text"
                      />
                    </div>
                    <TextArea
                      leftIcon={<Account />}
                      label={
                        lang === "en" ? (
                          <> Message (Min 50 Characters)</>
                        ) : (
                          <> お問合せの内容（Min 50文字） </>
                        )
                      }
                      rightIcon={`${
                        data.message ? 750 - data.message?.length : 750
                      }`}
                      onChange={(e) => {
                        handleChange("message", e.target.value);
                        errors.message = "";
                      }}
                      value={data.message}
                      error={errors.message}
                      maxLength={750}
                      data-lang={lang}
                      className="!h-56"
                    />

                    <div className="contact-btn-box">
                      <CheckBox
                        checked={data.checked}
                        text={
                          lang === "en"
                            ? data.checked
                              ? "I'm a Human!"
                              : "I'm not a Robot"
                            : data.checked
                            ? "私は人間です!"
                            : "私はロボットではない"
                        }
                        icon={
                          data.checked ? (
                            <Human className="contact-checkbox-icon" />
                          ) : (
                            <Robot className="robot-icon" />
                          )
                        }
                        onClick={() => {
                          data.checked
                            ? handleChange("checked", false)
                            : handleChange("checked", true);
                          errors.checked = "";
                        }}
                        data-lang={lang}
                        className={errors.checked ? "input-error-small" : ""}
                        green={true}
                      />

                      {lang === "en" ? (
                        <Button
                          rightIcon={<Send />}
                          className="contact-btn"
                          data-lang={lang}
                          onClick={() => {
                            pirsch("Contact-Send-E");
                          }}
                        >
                          Send
                        </Button>
                      ) : (
                        <Button
                          rightIcon={<Send />}
                          className="contact-btn"
                          data-lang={lang}
                          onClick={() => {
                            pirsch("Contact-Send-J");
                          }}
                        >
                          送信
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Layout>{" "}
      {openSuccess && (
        <PopupContainer
          title={lang === "en" ? "Mail Sent!" : "送信完了！"}
          logo={<Logo />}
          onClose={() => {
            setOpenSuccess(false);
          }}
        >
          <div className="contact-success-card" data-lang={lang}>
            {lang === "en" ? (
              <>
                Thank you for reaching out!
                <br />
                <br />
                We will get in touch with you as soon as possible, depending on
                the nature of your request. Your satisfaction is our priority!
              </>
            ) : (
              <>
                お問い合わせありがとうございます。
                <br />
                担当者よりご連絡させていただきます。
                <br />
                （内容によってはお時間をいただく場合もございます。）
              </>
            )}
          </div>
        </PopupContainer>
      )}
      {IsShowPopup ? (
        <Popup
          message={popMessage}
          type={popType}
          icon={popIcon}
          closePopup={() => settIsShowPopup(false)}
        />
      ) : null}
    </>
  );
}

export default ContactUs;
