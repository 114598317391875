import { ReactComponent as DefaultProfile } from "../../assets/icons/b_logo.svg";
import { ReactComponent as LogoBad } from "../../assets/icons/logo_06.svg";
import { ReactComponent as Logo } from "../../assets/icons/logo.svg";
import { ReactComponent as Illustra05 } from "../../assets/icons/illustra_05.svg";
import { ReactComponent as MailIcon } from "../../assets/icons/mail.svg";
import { ReactComponent as LockIcon } from "../../assets/icons/lock.svg";
import { ReactComponent as Calender } from "../../assets/icons/cal.svg";
import { ReactComponent as Loading } from "../../assets/icons/loading.svg";
import { ReactComponent as CautionIcon } from "../../assets/icons/caution.svg";
import { ReactComponent as Check } from "../../assets/icons/check.svg";
import { ReactComponent as PasswordIcon } from "../../assets/icons/password.svg";
import { ReactComponent as SendIcon } from "../../assets/icons/send.svg";
import { ReactComponent as UserNameIcon } from "../../assets/icons/id_icon.svg";
import { ReactComponent as FullNameIcon } from "../../assets/icons/full_name.svg";
import { ReactComponent as GlobeIcon } from "../../assets/icons/globe.svg";
import { ReactComponent as FlagIcon } from "../../assets/icons/flag.svg";
import { ReactComponent as BlogIcon } from "../../assets/icons/blog.svg";
import React, { useEffect } from "react";
import { ReactComponent as LoginIcon } from "../../assets/icons/login.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import { ReactComponent as BagIcon } from "../../assets/icons/bag.svg";
import { ReactComponent as ImgIcon } from "../../assets/icons/image.svg";
import { ReactComponent as Trash } from "../../assets/icons/trash.svg";
import { ReactComponent as EyeOnIcon } from "../../assets/icons/eye_on.svg";
import { ReactComponent as EyeOffIcon } from "../../assets/icons/eye_off.svg";

import LargePopupContainer from "../molecules/LargePopupContainer";
import { SignupTermsCard } from "../molecules/TermsCard";
import { setOpenLoginPopup, setUser } from "../../redux/actions/user";
import Input from "../atoms/Input";
import RoundImage from "../atoms/RoundImage";
import UxerCard from "../organismes/uxerCard";
import Button from "../atoms/Button";
import PopupContainer from "../molecules/PopupContainer";
import { useState } from "react";
import TextArea from "../atoms/TextArea";
import Select from "../atoms/Select";
import LocationDrop from "../atoms/LocationDrop";
import countires from "../../data/countires.json";
import Switch from "../atoms/Switch";
import AvatarCrop from "../molecules/AvatarCorp";
import { signup } from "../../services/authServices";
import QuitCard from "../organismes/popup/QuitCard";
import { useTranslation } from "react-i18next";
import Popup from "../molecules/Popup";
import { useDispatch, useSelector } from "react-redux";
import CustomIcon from "../../compo/IconCompo";
import { useParams } from "react-router-dom";
import axios from "axios";
import { BACKEND_URL } from "../../config";
import { setSelectLang } from "../../redux/actions/env";

function Signup({ newPopup }) {
  const dispatch = useDispatch();
  const params = useParams();
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});
  const [popMessage, setPopMessage] = useState("");
  const [popIcon, setPopIcon] = useState("");
  const lang = useSelector((state) => state.lang);
  const [popType, setPopType] = useState("");
  const [IsShowPopup, settIsShowPopup] = useState(false);
  const [invitationNotValID, setInvitationNotValID] = useState(false);
  const [quitPopup, setQuitPopup] = useState(false);
  const regex = new RegExp("[a-zA-Z0-9_]+$");
  const countries = countires;
  const [t, i18n] = useTranslation();
  const rolesOptions = [
    { value: "Product Designer", label: "Product Designer" },
    { value: "UX Designer", label: "UX Designer" },
    { value: "UI Designer", label: "UI Designer" },
    { value: "Engineer", label: "Engineer" },
    { value: "Manager", label: "Manager" },
    { value: "Director", label: "Director" },
    { value: "Executive", label: "Executive" },
    { value: "Entrepreneur", label: "Entrepreneur" },
    { value: "Human Resource", label: "Human Resource" },
    { value: "Sales", label: "Sales" },
    { value: "Marketer", label: "Marketer" },
    { value: "Customer Service", label: "Customer Service" },
    { value: "Student", label: "Student" },
    { value: "Others", label: "Others" },
  ];
  const [emptyLink, setEmptyLink] = useState(6);
  const [openTerms, setOpenTerms] = useState(false);
  const [location, setLocation] = useState();
  const [openSuccess, setOpenSuccess] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const locale = queryParams.get("lang");
    if (locale === "jp") {
      localStorage.setItem("language", "jp");
      dispatch(setSelectLang("jp"));
    } else {
      localStorage.setItem("language", "en");
      dispatch(setSelectLang("en"));
    }
  }, []);

  function popups(message, type, icon) {
    settIsShowPopup(true);
    setPopType(type);
    setPopIcon(icon);
    setPopMessage(message);
    setTimeout(() => {
      settIsShowPopup(false);
    }, 4000);
  }
  const handleChange = async (element, dataP) => {
    const newData = { ...data, [`${element}`]: dataP };
    setData(newData);
    console.log(data);
    setErrors((prev) => {
      return { ...prev, [`${element}`]: "" };
    });
    if (element == "nationality") {
      setData((prev) => {
        let x = countries.find((el) => {
          return el.label == prev.nationality;
        });
        return { ...data, ["nationality"]: x ? x?.value : "" };
      });
    }
  };

  const handleCancle = () => {
    setQuitPopup(true);
  };

  const handleSubmit = () => {
    let errors = valIDateForm(data);

    if (Object.keys(errors).length === 0) {
      popups("Loading...", "loading", <Loading />);
      data.personalBlog = data.personalBlog ? data.personalBlog : "";
      data.personalSite = data.personalSite ? data.personalSite : "";
      data.facebook = data.facebook ? data.facebook : "";
      data.dribbble = data.dribbble ? data.dribbble : "";
      data.behance = data.behance ? data.behance : "";
      data.instagram = data.instagram ? data.instagram : "";
      data.linkedin = data.linkedin ? data.linkedin : "";
      data.figma = data.figma ? data.figma : "";
      data.threads = data.threads ? data.threads : "";
      data.x = data.x ? data.x : "";
      data.instagram = data.instagram ? data.instagram : "";
      data.youtube = data.youtube ? data.youtube : "";
      data.tiktok = data.tiktok ? data.tiktok : "";
      data.instagram = data.instagram ? data.instagram : "";
      const formData = new FormData();
      const picRes = axios.get(data.profile_img, {
        responseType: "blob",
      });

      picRes.then((res) => {
        const blob = res.data;
        const mimeType = blob.type;
        const fileName = `profile_image.${mimeType.substring(6)}`;
        const file = new File([blob], fileName, { type: mimeType });
        formData.append("profile", file);
        axios.post(`${BACKEND_URL}/auth/upload`, formData, {}).then((res) => {
          if (res.data.status == true) {
            signup(data, lang, res.data.link)
              .then((res) => {
                console.log(res);
                if (res.data.status == true) {
                  dispatch(setUser(res.data.myToken));
                  localStorage.setItem(
                    "token",
                    JSON.stringify(res.data.myToken)
                  );
                  popups(res.data.message, "success", <CautionIcon />);
                  window.location.href = "/home";
                } else if (res.data.status == false) {
                  popups(res.data.message, "error", <CautionIcon />);
                }
              })
              .catch(() => {});
          } else if (res.data.status == false) {
            popups(
              lang === "en" ? "signup failed" : "必須項目を入力してください。",
              "error",
              <CautionIcon />
            );
          }
        });
      });
    } else {
      if (Object.keys(errors).length > 1) {
        popups(
          lang === "en"
            ? "Please make sure you've filled in all the necessary fields."
            : "必須項目を入力してください。",
          "error",
          <CautionIcon />
        );
      } else {
        Object.keys(errors).map(() => {
          popups(Object.values(errors)[0], "error", <CautionIcon />);
        });
      }
    }
  };

  const valIDateForm = (data) => {
    const errors = {};

    if (!data.profile_img) {
      errors.profile_img = "Please upload a profile image!";
    }

    if (!data.email) {
      errors.email = "Email is required";
    } else if (!isValidEmail(data.email)) {
      errors.email = "InvalID email address";
    }

    if (!data.password || data.password?.length <= 5) {
      errors.password = "Password is required";
    }

    if (!data.username) {
      errors.username = "Username is required";
    } else if (data.username.length > 16 || data.username.length < 5) {
      errors.username = "Username must be between 5 and 16 characters";
    } else if (!isUsernameValid(data.username)) {
      errors.username =
        "Username can only contain letters, numbers, and underscores";
    }

    if (!data.fullName) {
      errors.fullName = "Full name is required";
    }
    console.log(data);

    if (
      data.nationality.replaceAll(" ", "")?.replace(/(\r\n|\n|\r)/gm, "") == ""
    ) {
      errors.nationality = true;
    }

    if (!data.subRole) {
      errors.subRole = "Role is required";
    }
    if (
      data.subRole &&
      !["Student", "Others"].includes(data.subRole) &&
      !data.experience
    ) {
      errors.experience = "Experience is required";
    }

    if (!data.intro) {
      errors.intro = "Introduction is required";
    } else if (data.intro.length > 240 || data.intro.length < 30) {
      errors.intro = "Your introduction should be 30 to 240 characters long.";
    }

    if (!data.terms) {
      errors.terms =
        lang === "en"
          ? "You should accept the terms of use"
          : "利用規約に同意する必要です";
    }

    if (data?.personalSite && !isValidURL(data?.personalSite)) {
      errors.personalSite = "Enter a valid URL";
    }

    if (data?.personalBlog && !isValidURL(data?.personalBlog)) {
      errors.personalBlog = "Enter a valid URL";
    }

    if (data.twitter && !isValidURL(data.twitter)) {
      errors.twitter =
        lang === "en" ? "Enter a valid URL" : "有効なURLを入力してください。";
    }

    if (data.facebook && !isValidURL(data.facebook)) {
      errors.facebook =
        lang === "en" ? "Enter a valid URL" : "有効なURLを入力してください。";
    }

    if (data.instagram && !isValidURL(data.instagram)) {
      errors.instagram =
        lang === "en" ? "Enter a valid URL" : "有効なURLを入力してください。";
    }

    if (data.linkedin && !isValidURL(data.linkedin)) {
      errors.linkedin =
        lang === "en" ? "Enter a valid URL" : "有効なURLを入力してください。";
    }
    if (data.figma && !isValidURL(data.figma)) {
      errors.figma =
        lang === "en" ? "Enter a valid URL" : "有効なURLを入力してください。";
    }
    if (data.x && !isValidURL(data.x)) {
      errors.x =
        lang === "en" ? "Enter a valid URL" : "有効なURLを入力してください。";
    }
    if (data.threads && !isValidURL(data.threads)) {
      errors.threads =
        lang === "en" ? "Enter a valid URL" : "有効なURLを入力してください。";
    }
    if (data.tiktok && !isValidURL(data.tiktok)) {
      errors.tiktok =
        lang === "en" ? "Enter a valid URL" : "有効なURLを入力してください。";
    }
    if (data.youtube && !isValidURL(data.youtube)) {
      errors.youtube =
        lang === "en" ? "Enter a valid URL" : "有効なURLを入力してください。";
    }

    if (data.dribbble && !isValidURL(data.dribbble)) {
      errors.dribbble =
        lang === "en" ? "Enter a valid URL" : "有効なURLを入力してください。";
    }

    if (data.behance && !isValidURL(data.behance)) {
      errors.behance =
        lang === "en" ? "Enter a valid URL" : "有効なURLを入力してください。";
    }

    setErrors(errors);
    return errors;
  };

  const isUsernameValid = (username) => {
    return /^[a-z0-9_]+$/.test(username);
  };

  const isValidEmail = (email) => {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    );
  };

  const isValidSocialMediaLink = (url) => {
    return /^(https?|ftp):\/\/([^\s\/]+)((\/\w+)*\/)([\w\-\.]+[^#?\s]+)(.*)?(#[\w\-]+)?$/.test(
      url
    );
  };

  const isValidURL = (url) => {
    return /^(http(s)?:\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)?$/.test(
      url
    );
  };
  useEffect(() => {
    setData((prev) => {
      return {
        experience: "",
        nationality: "",
        signup_request_id: params.token,
        email: params.email,
        username: "",

        ...prev,
      };
    });
  }, []);
  return (
    <>
      <div className="user-form">
        <div className="user-form-body">
          <div className="user-form-body-header">
            <h2 className="user-form-body-header-title">
              {lang === "en" ? "Sign up" : "会員登録"}
            </h2>
            <p className="user-form-body-header-info" data-lang={lang}>
              {lang === "en" ? (
                <span>Please fill out the form below to sign up.</span>
              ) : (
                <span>以下のフォームに記入して会員登録してください.</span>
              )}
            </p>
          </div>
          <div className="user-form-body-required">
            <div className="user-form-body-round-profile">
              <AvatarCrop
                addIcon={<ImgIcon />}
                deleteIcon={<Trash />}
                className="avatar"
                onChange={(value) => handleChange("profile_img", value)}
                error={errors.profile_img}
                onDelete={() => {
                  handleChange("profile_img", undefined);
                }}
              />
            </div>
            <div className="user-form-body-login">
              <Input
                leftIcon={<MailIcon />}
                className="user-form-body-email"
                // placeholder={"Email or Username"}
                placeholder={
                  lang === "en" ? "Email Address" : "Eメールアドレス"
                }
                disable={true}
                type="email"
                value={data.email}
                onChange={(e) => handleChange("email", e.target.value)}
                error={errors.email}
                rightIcon={<LockIcon />}
                id="id1"
              />
              {/* <p className="user-form-body-restriction-info" data-lang={lang}>
                {lang === "en" ? (
                  <> Password must be at least 6 characters. </>
                ) : (
                  <> パスワードは少なくとも6文字必要です。 </>
                )}
              </p> */}
              <Input
                leftIcon={<PasswordIcon />}
                className="user-form-body-password"
                placeholder={lang === "en" ? "Password" : "パスワード"}
                type={data.passwordShow ? "text" : "password"}
                value={data.password}
                onRightIconClick={() =>
                  handleChange("passwordShow", !data.passwordShow)
                }
                rightIcon={
                  data.passwordShow ? (
                    <EyeOnIcon className="eyeIcon" />
                  ) : (
                    <EyeOffIcon className="eyeIcon" />
                  )
                }
                onChange={(e) => handleChange("password", e.target.value)}
                error={errors.password}
                id="id2"
              />
              {/* </div>
            <div className="user-form-body-more-info"> */}
              <p className="user-form-body-restriction-info" data-lang={lang}>
                {lang === "en" ? (
                  <>
                    {" "}
                    Username can only contain letters, numbers and underscores
                    <span> (max is 16 characters)</span>
                  </>
                ) : (
                  <>
                    {" "}
                    文字、数字、アンダースコアのみ許可{" "}
                    <span className="media-links-note">(5〜16文字)</span>
                  </>
                )}
              </p>
              <Input
                leftIcon={<UserNameIcon />}
                preInputText={"uud.io/"}
                placeholder={lang === "en" ? "Username" : "ユーザーネーム"}
                rightIcon={`${data.username ? 16 - data.username?.length : 16}`}
                maxLength={16}
                onPaste={(e) => {
                  e.preventDefault();
                }}
                value={data.username}
                onChange={(e) => {
                  if (
                    isUsernameValid(e.target.value) ||
                    e.target.value.length == 0
                  ) {
                    handleChange("username", e.target.value);
                  }
                }}
                error={errors.username}
                id="id3"
              />
              <Input
                leftIcon={<FullNameIcon />}
                placeholder={lang === "en" ? "Your Name" : "お名前"}
                value={data.fullName}
                onChange={(e) => {
                  if (e.target.value.length < 22) {
                    handleChange("fullName", e.target.value);
                  }
                }}
                error={errors.fullName}
                id="id4"
              />

              <Select
                leftIcon={<BagIcon />}
                options={rolesOptions}
                onChange={(value) => handleChange("subRole", value)}
                value={data.subRole}
                defaultValue={lang === "en" ? "Role" : "役職"}
                error={errors.subRole}
              />
              {data?.subRole != "Student" && data?.subRole != "Others" && (
                <>
                  <div className="signup-experience-cont">
                    <p className="signup-experience-label" data-lang={lang}>
                      {lang === "en" ? (
                        <>Role experience year:</>
                      ) : (
                        <>役職での経験年数:</>
                      )}
                    </p>
                    <Input
                      leftIcon={<Calender />}
                      placeholder={lang === "en" ? "0" : "0"}
                      value={data.experience}
                      className="experience_input"
                      onChange={(e) => {
                        if (
                          (/^[0-9]+$/.test(e.target.value) &&
                            e.target.value < 101) ||
                          e.target.value.length == 0
                        ) {
                          handleChange("experience", e.target.value);
                        }
                      }}
                      error={errors.experience}
                      id="id5"
                    />
                  </div>
                </>
              )}
              <LocationDrop
                countries={countries}
                onChange={(value) => handleChange("nationality", value)}
                defaultValue={null}
                leftIcon={<FlagIcon />}
                error={errors.nationality}
              />
              <TextArea
                leftIcon={<FullNameIcon />}
                label={
                  lang === "en" ? (
                    <> Intro (Min 30 Characters) </>
                  ) : (
                    <> 自己紹介（30〜240文字） </>
                  )
                }
                rightIcon={`${data.intro ? 240 - data.intro?.length : 240}`}
                onChange={(e) => handleChange("intro", e.target.value)}
                value={data.intro}
                error={errors.intro}
                maxLength={240}
                data-lang={lang}
              />
            </div>
          </div>
          <div className="user-form-body-socialmedia">
            <p className="user-form-body-restriction-info" data-lang={lang}>
              {lang === "en" ? (
                <>
                  {" "}
                  Add your Personal Website/Portfolio/Social media/Blog/Vlog
                  <span className="media-links-note">Option</span>
                </>
              ) : (
                <>
                  {" "}
                  個人サイトやSNSリンクなどを入力{" "}
                  <span className="media-links-note">オプション</span>
                </>
              )}
            </p>

            <Input
              leftIcon={<CustomIcon iconName="web_line" className="icon" />}
              placeholder={
                lang === "en"
                  ? "Personal Website / Online Portfolio"
                  : "ウェブサイト/ブログ"
              }
              onChange={(e) => handleChange("personalSite", e.target.value)}
              value={data.personalSite}
              error={errors.personalSite}
              id="id6"
            />
            <Input
              leftIcon={<CustomIcon iconName="blog_line" className="icon" />}
              placeholder={lang === "en" ? "Blog / Vlog / Podcast" : "ブログ"}
              onChange={(e) => handleChange("personalBlog", e.target.value)}
              value={data.personalBlog}
              error={errors.personalBlog}
              id="id7"
            />
            <Input
              leftIcon={<CustomIcon iconName="figma_line" className="icon" />}
              placeholder={"figma.com/"}
              onChange={(e) => handleChange("figma", e.target.value)}
              value={data.figma}
              error={errors.figma}
              id="id8"
            />
            <Input
              leftIcon={
                <CustomIcon iconName="dribbble_line" className="icon" />
              }
              placeholder={"dribbble.com/"}
              onChange={(e) => handleChange("dribbble", e.target.value)}
              value={data.dribbble}
              error={errors.dribbble}
              id="id9"
            />
            <Input
              leftIcon={<CustomIcon iconName="behance_line" className="icon" />}
              placeholder={"behance.com/"}
              onChange={(e) => handleChange("behance", e.target.value)}
              value={data.behance}
              error={errors.behance}
              id="id10"
            />
            <Input
              leftIcon={
                <CustomIcon iconName="instagram_line" className="icon" />
              }
              placeholder={"instagram.com/"}
              onChange={(e) => handleChange("instagram", e.target.value)}
              value={data.instagram}
              error={errors.instagram}
              id="id11"
            />
            <Input
              leftIcon={
                <CustomIcon iconName="linkedin_line" className="icon" />
              }
              placeholder={"linkedin.com/"}
              onChange={(e) => handleChange("linkedin", e.target.value)}
              value={data.linkedin}
              error={errors.linkedin}
              id="id12"
            />
            <Input
              leftIcon={<CustomIcon iconName="twitter_line" className="icon" />}
              placeholder={"X.com/"}
              onChange={(e) => handleChange("x", e.target.value)}
              value={data.x}
              error={errors.x}
              id="id13"
            />

            <Input
              leftIcon={
                <CustomIcon iconName="facebook_line" className="icon" />
              }
              placeholder={"facebook.com/"}
              onChange={(e) => handleChange("facebook", e.target.value)}
              value={data.facebook}
              error={errors.facebook}
              id="id14"
            />

            <Input
              leftIcon={<CustomIcon iconName="threads_line" className="icon" />}
              placeholder={"threads.com/"}
              onChange={(e) => handleChange("threads", e.target.value)}
              value={data.threads}
              error={errors.threads}
              id="id15"
            />

            <Input
              leftIcon={<CustomIcon iconName="tiktok_line" className="icon" />}
              placeholder={"tiktok.com/"}
              onChange={(e) => handleChange("tiktok", e.target.value)}
              value={data.tiktok}
              error={errors.tiktok}
              id="id16"
            />
            <Input
              leftIcon={<CustomIcon iconName="youtube_line" className="icon" />}
              placeholder={"youtube.com/"}
              onChange={(e) => handleChange("youtube", e.target.value)}
              value={data.youtube}
              error={errors.youtube}
              id="id17"
            />
          </div>
          <Switch
            onChange={() => {
              handleChange("terms", !data.terms);
              setErrors((prev) => {
                prev.terms = "";
                return { ...prev };
              });
            }}
            checked={data.terms}
            title={
              lang === "en" ? (
                <span>
                  I agree to the{" "}
                  <a className="text-link" onClick={() => setOpenTerms(true)}>
                    Terms of Use
                  </a>
                  .
                </span>
              ) : (
                <span className="terms" data-lang={lang}>
                  <a className="text-link" onClick={() => setOpenTerms(true)}>
                    利用規約に
                  </a>
                  同意します。
                </span>
              )
            }
            error={errors.terms}
          />

          <div className="user-form-body-buttons">
            <Button
              rightIcon={<CloseIcon />}
              onClick={() => handleCancle()}
              className="button noLogged_button noLogged_button_outline"
              data-lang={lang}
            >
              {lang === "en" ? "Cancel" : "キャンセル"}
            </Button>

            <Button
              rightIcon={<LoginIcon />}
              className="noLogged_button"
              onClick={() => handleSubmit()}
              data-lang={lang}
            >
              {lang === "en" ? "Sign up" : "登録する"}
            </Button>
          </div>
        </div>
      </div>
      <div className="signup-mockup-card-cont">
        <div className="signup-mockup-card">
          <UxerCard
            test={true}
            fullName={data.fullName}
            className="user_card_view"
            username={data.username}
            subRole={data.subRole}
            experience={data.experience}
            intro={data.intro}
            profileImage={
              data.profile_img ? (
                data.profile_img
              ) : (
                <RoundImage
                  imageFull={true}
                  imageData={null}
                  alt="Cropped Avatar"
                  error={null}
                />
              )
            }
            personalSite={data.personalSite}
            personalBlog={data.personalBlog}
            mediaLinks={[
              data.facebook,
              data.instagram,
              data.linkedin,
              data.dribbble,
              data.behance,
              data.threads,
              data.youtube,
              data.figma,
              data.tiktok,
              data.x,
            ]}
            national={data.nationality}
          />
        </div>
      </div>
      {/* {invitationNotValid && (
        <PopupContainer title="Confirm Quitting Signup" logo={<LogoBad />}>
          <p className="user-form-bad-news-paragrah" data-lang={lang}>
            {lang === "en" ? (
              <>
                Are you sure you want to quit the signup process and close the
                form? <br />
                <br />
                Your information will not be saved, and you won't be able to
                create an account.
              </>
            ) : (
              <>
                サインアッププロセスを終了し、フォームを閉じますか？
                <br />
                <br />
                あなたの情報は保存されず、アカウントを作成することはできません
              </>
            )}
          </p>

          <div>
            <Button
              onClick={() => {
                window.location = "/";
              }}
            >
              {lang === "en" ? "Back to Signup!A" : "サインアップに戻る"}
            </Button>
            <Button
              onClick={() => {
                window.location = "#";
              }}
            >
              Quit
            </Button>
          </div>
        </PopupContainer>
      )} */}
      {quitPopup && (
        <PopupContainer
          title={lang === "en" ? "Are you Sure!" : "会員登録を中止する？"}
          logo={<Illustra05 />}
        >
          <QuitCard
            text={
              <span data-lang={lang}>
                {lang === "en" ? (
                  <>
                    Are you certain you want to cancel the signup process?
                    <br />
                    If you proceed, all the information you've entered will be
                    lost!
                  </>
                ) : (
                  <>
                    会員登録を中止してもよろしいですか？
                    <br />
                    続行すると、入力したすべての情報が失われます！
                  </>
                )}
              </span>
            }
            backBtnText={lang === "en" ? "Quit" : "やめる"}
            exitBtnText={lang === "en" ? "Back to Signup" : "会員登録をつづく"}
            onBack={() => {
              window.location = "/";
            }}
            OnExit={() => {
              setQuitPopup(false);
            }}
          />
        </PopupContainer>
      )}
      {openTerms && (
        <LargePopupContainer onClose={() => setOpenTerms(false)}>
          <SignupTermsCard
            onClick={() => {
              setOpenTerms(false);
            }}
            type="signup"
          />
        </LargePopupContainer>
      )}

      {openSuccess && (
        <PopupContainer
          title={"Email Sent!!"}
          logo={<Logo />}
          onClose={() => {
            setOpenSuccess(false);
          }}
        >
          <div className="success-uxer-card">
            <p>
              An email sent to
              <span className="dynamic_text"> {data.email} </span>
              for confirmation <br />
              Please confirm your account! Welcome aboard! <br />
              We've sent an email with an account activation link to :
              <span className="dynamic_text"> {data.email} </span>
              <br /> <br />
              Click the link inside to unlock all the amazing features waiting
              for you!
            </p>
          </div>
        </PopupContainer>
      )}
      {IsShowPopup ? (
        <Popup
          message={popMessage}
          type={popType}
          icon={popIcon}
          closePopup={() => settIsShowPopup(false)}
        />
      ) : null}
    </>
  );
}

export default Signup;
