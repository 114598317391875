import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { ReactComponent as Illustra00 } from "../../assets/icons/illustra_00.svg";

import React from "react";

function AdminLoginContainer({
  children,
  className,
  logo = "",
  title,
  onClose,
  cardClass,
  titleClass,
  innerClass,
  windowClass,
  big,
  illustration,
}) {
  const lang = useSelector((state) => state.lang);

  return (
    <div className={`popup-container ${className}`}>
      <div className={` ${cardClass}`}>
        <div className={`popup-card ${windowClass}`}>
          {big && <div className="popup-card-image-cont">{illustration}</div>}

          <div className={`popup-card-inner ${innerClass}`}>
            {/* {onClose && (
              <div className="close-btn" onClick={onClose}>
                <CloseIcon />
              </div>
            )} */}

            {(logo || title) && (
              <div className="popup-top-bar">
                {logo && <div className="popup-logo">{logo}</div>}

                {title && (
                  <div className={`popup-title ${titleClass}`} data-lang={lang}>
                    {title}
                  </div>
                )}
              </div>
            )}

            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminLoginContainer;
