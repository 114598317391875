import { ReactComponent as DefaultProfile } from "../../assets/icons/b_logo.svg";
import { ReactComponent as LogoBad } from "../../assets/icons/logo_06.svg";
import { ReactComponent as Logo } from "../../assets/icons/logo.svg";
import { ReactComponent as MailIcon } from "../../assets/icons/mail.svg";
import { ReactComponent as Calender } from "../../assets/icons/cal.svg";
import { ReactComponent as LockIcon } from "../../assets/icons/lock.svg";
import { ReactComponent as Illustra05 } from "../../assets/icons/illustra_05.svg";
import { ReactComponent as Loading } from "../../assets/icons/loading.svg";
import { ReactComponent as CautionIcon } from "../../assets/icons/caution.svg";
import { ReactComponent as Check } from "../../assets/icons/check.svg";
import { ReactComponent as TimerIcon } from "../../assets/icons/timer.svg";
import { ReactComponent as PasswordIcon } from "../../assets/icons/password.svg";

import { ReactComponent as UserNameIcon } from "../../assets/icons/id_icon.svg";
import { ReactComponent as FullNameIcon } from "../../assets/icons/full_name.svg";
import { ReactComponent as GlobeIcon } from "../../assets/icons/globe.svg";
import { ReactComponent as FlagIcon } from "../../assets/icons/flag.svg";
import { ReactComponent as Ok } from "../../assets/icons/ok.svg";
import { ReactComponent as BlogIcon } from "../../assets/icons/blog.svg";
import React from "react";
import { ReactComponent as OK } from "../../assets/icons/ok.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import { ReactComponent as BagIcon } from "../../assets/icons/bag.svg";
import { ReactComponent as ImgIcon } from "../../assets/icons/image.svg";
import { ReactComponent as Trash } from "../../assets/icons/trash.svg";
import { ReactComponent as EyeOnIcon } from "../../assets/icons/eye_on.svg";
import { ReactComponent as EyeOffIcon } from "../../assets/icons/eye_off.svg";
import { ReactComponent as TwitterIcon } from "../../assets/icons/twitter.svg";
import { ReactComponent as FacebookIcon } from "../../assets/icons/facebook.svg";
import { ReactComponent as InstagramIcon } from "../../assets/icons/instagram.svg";
import { ReactComponent as LinkedinIcon } from "../../assets/icons/linkedin.svg";
import { ReactComponent as DribbbleIcon } from "../../assets/icons/dribbble.svg";
import { ReactComponent as BehanceIcon } from "../../assets/icons/behance.svg";
import LargePopupContainer from "../molecules/LargePopupContainer";
import { changeProfileInfo } from "../../services/userServices";
import RadioBoxGroup from "../molecules/RadioBoxGroup";
import CheckBoxGroup from "../molecules/CheckBoxGroup";
import { SignupTermsCard } from "../molecules/TermsCard";
import { setOpenLoginPopup, setUser } from "../../redux/actions/user";
import Input from "../atoms/Input";
import Button from "../atoms/Button";
import PopupContainer from "../molecules/PopupContainer";
import CustomIcon from "../../compo/IconCompo";
import TextArea from "../atoms/TextArea";
import Select from "../atoms/Select";
import LocationDrop from "../atoms/LocationDrop";
import { fetchImage } from "../../services/itemsServices";
import countires from "../../data/countires.json";
import Switch from "../atoms/Switch";
import { BACKEND_URL } from "../../config";
import AvatarCrop from "../molecules/AvatarCorp";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getUserInfo } from "../../services/authServices";
import jwtDecode from "jwt-decode";
import { signup } from "../../services/authServices";
import QuitCard from "../organismes/popup/QuitCard";
import { useTranslation } from "react-i18next";
import Popup from "../molecules/Popup";
import { useDispatch } from "react-redux";
import axios from "axios";

function SettingsEdit({ newPopup, display, setShowLeftBar }) {
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const [data, setData] = useState({});
  const [image, setImage] = useState();
  const [errors, setErrors] = useState({});
  const [popMessage, setPopMessage] = useState("");
  const [nationality, setNationality] = useState();
  const [popIcon, setPopIcon] = useState("");
  const [intro, setIntro] = useState("");
  const user = useSelector((state) => state.user);
  const [showDelete, setShowDelete] = useState("");
  const [popType, setPopType] = useState("");
  const [IsShowPopup, settIsShowPopup] = useState(false);
  const [invitationNotValid, setInvitationNotValid] = useState(false);
  const [quitPopup, setQuitPopup] = useState(false);
  const regex = new RegExp("[a-zA-Z0-9_]+$");
  const [isUxer, setIsUxer] = useState();
  const countries = countires;
  const [t, i18n] = useTranslation();
  const [forceUpdate, setForceUpdate] = useState(0);
  const rolesOptions = [
    { value: "Product Designer", label: "Product Designer" },
    { value: "UX Designer", label: "UX Designer" },
    { value: "UI Designer", label: "UI Designer" },
    { value: "Engineer", label: "Engineer" },
    { value: "Manager", label: "Manager" },
    { value: "Director", label: "Director" },
    { value: "Executive", label: "Executive" },
    { value: "Entrepreneur", label: "Entrepreneur" },
    { value: "Human Resource", label: "Human Resource" },
    { value: "Sales", label: "Sales" },
    { value: "Marketer", label: "Marketer" },
    { value: "Customer Service", label: "Customer Service" },
    { value: "Student", label: "Student" },
    { value: "Others", label: "Others" },
  ];
  const [emptyLink, setEmptyLink] = useState(6);
  const [openSuccess, setOpenSuccess] = useState(false);
  const lang = useSelector((state) => state.lang);
  const [changed, setChanged] = useState(false);
  const [oldData, setOldData] = useState();

  function popups(message, type, icon) {
    settIsShowPopup(true);
    setPopType(type);
    setPopIcon(icon);
    setPopMessage(message);
    setTimeout(() => {
      settIsShowPopup(false);
    }, 4000);
  }
  const handleChange = async (element, dataP) => {
    const newData = { ...data, [`${element}`]: dataP };
    setData(newData);

    setEmptyLink(
      6 -
        [
          newData.twitterLink,
          newData.facebookLink,
          newData.instagramLink,
          newData.linkedinLink,
          newData.dribbleLink,
          newData.behanceLink,
        ].filter((x) => x).length
    );
  };

  const handleCancle = () => {
    resetFill();
  };

  const handleSubmit = async (e) => {
    const validator = await validateForm(data);

    if (Object.keys(validator).length > 0) {
      return;
    }

    data.personalBlog = data.personalBlog ?? "";
    data.personalSite = data.personalSite ?? "";
    data.twitter = data.twitter ?? "";
    data.facebookLink = data.facebookLink ?? "";
    data.dribbleLink = data.dribbleLink ?? "";
    data.behanceLink = data.behanceLink ?? "";
    data.instagramLink = data.instagramLink ?? "";
    data.linkedinLink = data.linkedinLink ?? "";

    try {
      const picRes = await axios.get(data?.profile_img, {
        responseType: "blob",
      });
      const blob = picRes.data;

      const mimeType = blob.type;
      const fileName = `profile_image.${mimeType.substring(6)}`;
      const file = new File([blob], fileName, { type: mimeType });

      const formData = new FormData();
      formData.append("profile", file);

      const profileUpdateRes = await axios.post(
        `${BACKEND_URL}/profile/image/update`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token.slice(1, -1)}`,
          },
        }
      );

      if (profileUpdateRes.data.status) {
        const changeProfileRes = await changeProfileInfo(data, lang);
        if (changeProfileRes?.data.status) {
          popups("Profile updated successfully", "success", <Ok />);
          setUser((prev) => ({ ...prev, username: data.username }));
          setForceUpdate(forceUpdate + 1);
          window.location.reload();
        } else {
          popups(
            changeProfileRes?.data.message ||
              "Unable to update profile at the moment. Please try again",
            "error",
            <CautionIcon />
          );
        }
      } else {
        popups(
          lang === "en" ? "Update failed" : "必須項目を入力してください。",
          "error",
          <CautionIcon />
        );
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const getNationality = (national) => {
    let res = "";
    countires.forEach((country) => {
      if (country.value === national) {
        res = country;
      }
    });

    return res;
  };

  const getNationalityFromLabel = (national) => {
    const res = countires.find((country) => {
      if (country.label === national) {
        console.log(country);
        return country;
      }
    });
    return res;
  };
  const validateForm = (data) => {
    const errors = {};

    if (!data?.profile_img) {
      errors.profile_img = "Profile Image is required";
    }

    if (!data?.email) {
      errors.email = "Email is required";
    } else if (!isValidEmail(data?.email)) {
      errors.email = "Invalid email address";
    }

    if (!data?.username) {
      errors.username = "Username is required";
    } else if (data?.username.length > 16 || data?.username.length < 5) {
      errors.username = "Username must be between 5 and 16 characters";
    } else if (!isUsernameValid(data?.username)) {
      errors.username =
        "Username can only contain letters, numbers, and underscores";
    }

    if (!data?.fullName) {
      errors.fullName = "Full name is required";
    }
    if (
      data?.subRole &&
      !["Student", "Others"].includes(data?.subRole) &&
      !data?.experience
    ) {
      errors.experience = "Experience is required";
    }

    // if (!data?.position && isUxer) {
    //   errors.position = "Position is required";
    // }
    // if ((data?.skills?.length == 0 || data?.skills == undefined) && isUxer) {
    //   errors.skills = "Skill is required";
    // }

    if (
      data?.subRole &&
      !["Student", "Others"].includes(data?.subRole) &&
      (parseInt(data?.experience) < 0 || parseInt(data?.experience) > 60)
    ) {
      errors.experience = "Enter a valid Experience";
    }

    if (!nationality || !data?.nationality) {
      errors.nationality = "Location is required";
    }

    if (!data?.subRole) {
      errors.subRole = "Role is required";
    }

    if (!data?.intro) {
      errors.intro = "Introduction is required";
    } else if (data?.intro.length > 240 || data?.intro.length < 30) {
      errors.intro = "Introduction must be between 30 and 240 characters";
    }

    if (data?.personalSite && !isValidURL(data?.personalSite)) {
      errors.personalSite = "Enter a valid URL";
    }

    if (data?.personalBlog && !isValidURL(data?.personalBlog)) {
      errors.personalBlog = "Enter a valid URL";
    }

    if (data?.twitter && !isValidURL(data?.twitter)) {
      errors.twitter =
        lang === "en" ? "Enter a valid URL" : "有効なURLを入力してください。";
    }

    if (data?.facebook && !isValidURL(data?.facebook)) {
      errors.facebook =
        lang === "en" ? "Enter a valid URL" : "有効なURLを入力してください。";
    }

    if (data?.instagram && !isValidURL(data?.instagram)) {
      errors.instagram =
        lang === "en" ? "Enter a valid URL" : "有効なURLを入力してください。";
    }

    if (data?.linkedin && !isValidURL(data?.linkedin)) {
      errors.linkedin =
        lang === "en" ? "Enter a valid URL" : "有効なURLを入力してください。";
    }
    if (data?.figma && !isValidURL(data?.figma)) {
      errors.figma =
        lang === "en" ? "Enter a valid URL" : "有効なURLを入力してください。";
    }
    if (data?.x && !isValidURL(data?.x)) {
      errors.x =
        lang === "en" ? "Enter a valid URL" : "有効なURLを入力してください。";
    }
    if (data?.threads && !isValidURL(data?.threads)) {
      errors.threads =
        lang === "en" ? "Enter a valid URL" : "有効なURLを入力してください。";
    }
    if (data?.tiktok && !isValidURL(data?.tiktok)) {
      errors.tiktok =
        lang === "en" ? "Enter a valid URL" : "有効なURLを入力してください。";
    }
    if (data?.youtube && !isValidURL(data?.youtube)) {
      errors.youtube =
        lang === "en" ? "Enter a valid URL" : "有効なURLを入力してください。";
    }

    if (data?.dribbble && !isValidURL(data?.dribbble)) {
      errors.dribbble =
        lang === "en" ? "Enter a valid URL" : "有効なURLを入力してください。";
    }

    if (data?.behance && !isValidURL(data?.behance)) {
      errors.behance =
        lang === "en" ? "Enter a valid URL" : "有効なURLを入力してください。";
    }
    if (Object.keys(errors).length > 1) {
      popups("Please fill out the required fields", "error", <CautionIcon />);
    } else {
      Object.keys(errors).map(() => {
        popups(Object.values(errors)[0], "error", <CautionIcon />);
      });
    }
    setErrors(errors);
    return errors;
  };

  const isUsernameValid = (username) => {
    return /^[a-z0-9_]+$/.test(username);
  };

  const isValidEmail = (email) => {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    );
  };

  const isValidSocialMediaLink = (url) => {
    return /^(https?|ftp):\/\/([^\s\/]+)((\/\w+)*\/)([\w\-\.]+[^#?\s]+)(.*)?(#[\w\-]+)?$/.test(
      url
    );
  };

  const isValidURL = (url) => {
    return /^(http(s)?:\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)?$/.test(
      url
    );
  };
  useEffect(() => {
    let isMounted = true;

    const processUserData = (data) => {
      const myUser = data?.myUser || {};

      setIsUxer(!!(myUser.uxer_id || data?.uxer_id));

      const getLink = (index) => myUser.mediaLinks?.[index]?.link || "";

      setOldData({
        ...myUser,
        personalBlog: myUser.personalBlog || "",
        personalSite: myUser.personalSite || "",
        subRole: myUser.sub_role || "",
        facebook: getLink(0),
        instagram: getLink(1),
        linkedin: getLink(2),
        dribbble: getLink(3),
        behance: getLink(4),
        threads: getLink(5),
        youtube: getLink(6),
        figma: getLink(7),
        tiktok: getLink(8),
        x: getLink(9),
        nationality: getNationality(myUser.nationality),
      });

      setNationality(getNationality(myUser.nationality));
      setChanged(true);

      const emptyLinkCount =
        6 - myUser.mediaLinks?.filter((x) => x && x.link).length;
      setEmptyLink(emptyLinkCount);
    };

    getUserInfo(jwtDecode(user).id)
      .then((res) => {
        if (isMounted) {
          processUserData(res.data);
        }
      })
      .catch((err) => {
        console.log("Error:", err);
      });

    return () => {
      isMounted = false;
    };
  }, [user]);

  function resetFill() {
    setData(oldData);
  }
  useEffect(() => {
    resetFill();
  }, [oldData]);

  useEffect(() => {
    if (data?.intro && intro == "") {
      setIntro(
        data?.intro.length -
          Math.round(
            (data?.intro.length -
              data?.intro?.replace(/(?:\r\n|\r|\n)/g, "").length) /
              2
          )
      );
    }
  }, [data]);
  // useEffect(() => {
  //   if (data?.profile_img && data?.profile_img.length < 150) {
  //     fetchImage("profile", data?.profile_img)
  //       .then((result) => {
  //         if (result != undefined) {
  //           setImage(result);
  //           console.log(data);
  //           handleChange("profile_img", `data:image/png;base64,${result}`);
  //         }
  //       })
  //       .catch((err) => {
  //         console.log("rrmme", err);
  //       });
  //   }
  //   console.log(changed);
  // }, [data]);

  return (
    <>
      <div
        className="close-btn close-btn-settings"
        onClick={() => setShowLeftBar(true)}
      >
        <CloseIcon className="z-0" />
      </div>
      {display && (
        <>
          <div className="user-form">
            <div className="settings-title-cont">
              <h2 className="settings-title" data-lang={lang}>
                {lang === "en" ? "Edit Profile" : "プロフィール変更"}
              </h2>
            </div>
            <div className="user-form-body">
              <div className="user-form-body-required">
                <div className="user-form-body-round-profile">
                  <AvatarCrop
                    addIcon={<ImgIcon />}
                    deleteIcon={<Trash />}
                    fullSrc={true}
                    croppedImage={data?.profile_img}
                    className="avatar"
                    onChange={(value) => handleChange("profile_img", value)}
                    error={errors.profile_img}
                    onDelete={() => {
                      handleChange("profile_img", undefined);
                    }}
                  />
                </div>
                <div className="user-form-body-login">
                  {/* </div>
            <div className="user-form-body-more-info"> */}

                  <Input
                    leftIcon={<UserNameIcon />}
                    preInputText={"uud.io/"}
                    placeholder={lang === "en" ? "Username" : "ユーザーネーム"}
                    rightIcon={`${
                      data?.username ? 16 - data?.username?.length : 16
                    }`}
                    maxLength={16}
                    onPaste={(e) => {
                      e.preventDefault();
                    }}
                    value={data?.username}
                    onChange={(e) => {
                      if (
                        isUsernameValid(e.target.value) ||
                        e.target.value.length == 0
                      ) {
                        handleChange("username", e.target.value);
                      }
                    }}
                    error={errors.username}
                    autoComplete={false}
                    autoCorrect={false}
                    id="id1"
                    autoFocus={false}
                  />
                  <Input
                    leftIcon={<FullNameIcon />}
                    placeholder={lang === "en" ? "Your Name" : "お名前"}
                    value={data?.fullName}
                    onChange={(e) => {
                      if (e.target.value.length < 22) {
                        handleChange("fullName", e.target.value);
                      }
                    }}
                    error={errors.fullName}
                    autoComplete={false}
                    autoCorrect={false}
                    id="id2"
                    autoFocus={false}
                  />

                  <Select
                    leftIcon={<BagIcon />}
                    options={rolesOptions}
                    onChange={(value) => handleChange("subRole", value)}
                    value={data?.subRole}
                    defaultValue={lang === "en" ? "Role" : "役職"}
                    error={errors.subRole}
                  />
                  {data?.subRole != "Student" && data?.subRole != "Others" && (
                    <>
                      <div className="signup-experience-cont">
                        <p className="signup-experience-label" data-lang={lang}>
                          {lang === "en" ? (
                            <>Role experience year:</>
                          ) : (
                            <>役職での経験年数:</>
                          )}
                        </p>
                        <Input
                          leftIcon={<Calender />}
                          placeholder={lang === "en" ? "0" : "0"}
                          value={data?.experience}
                          className="experience_input"
                          onChange={(e) => {
                            if (
                              (/^[0-9]+$/.test(e.target.value) &&
                                e.target.value < 101) ||
                              e.target.value.length == 0
                            ) {
                              handleChange("experience", e.target.value);
                            }
                          }}
                          error={errors.experience}
                          autoComplete={false}
                          autoCorrect={false}
                          autoFocus={false}
                          id="id3"
                        />
                      </div>
                    </>
                  )}
                  <LocationDrop
                    countries={countries}
                    onChange={(value) => {
                      handleChange(
                        "nationality",
                        getNationalityFromLabel(value)
                      );
                    }}
                    defaultValue={nationality}
                    leftIcon={<FlagIcon />}
                    error={errors.nationality}
                  />
                  <TextArea
                    leftIcon={<FullNameIcon />}
                    label={
                      lang === "en" ? (
                        <> Intro (Min 30 Characters) </>
                      ) : (
                        <> 自己紹介（30〜240文字） </>
                      )
                    }
                    rightIcon={`${
                      data?.intro ? 240 - data?.intro?.length : 240
                    }`}
                    onChange={(e) => handleChange("intro", e.target.value)}
                    value={data?.intro}
                    error={errors.intro}
                    maxLength={240}
                    data-lang={lang}
                  />
                </div>
              </div>
              <div className="user-form-body-socialmedia">
                <p className="user-form-body-restriction-info" data-lang={lang}>
                  {lang === "en" ? (
                    <>
                      Add your Personal Website/Portfolio/Social media/Blog/Vlog{" "}
                      <span className="media-links-note">Option</span>
                    </>
                  ) : (
                    <>
                      個人サイト / ポートフォリオを入力して下さい
                      <br />
                      <span className="media-links-note">オプション</span>
                    </>
                  )}
                </p>

                <Input
                  leftIcon={<CustomIcon iconName="web_line" className="icon" />}
                  placeholder={"Personal Website / Online Portfolio"}
                  onChange={(e) => handleChange("personalSite", e.target.value)}
                  value={data?.personalSite}
                  error={errors.personalSite}
                  autoComplete={false}
                  autoCorrect={false}
                  autoFocus={false}
                  id="id4"
                />
                <Input
                  leftIcon={
                    <CustomIcon iconName="blog_line" className="icon" />
                  }
                  placeholder={"Blog / Vlog / Podcast"}
                  onChange={(e) => handleChange("personalBlog", e.target.value)}
                  value={data?.personalBlog}
                  error={errors.personalBlog}
                  autoComplete={false}
                  autoCorrect={false}
                  autoFocus={false}
                  id="id5"
                />
                <Input
                  leftIcon={
                    <CustomIcon iconName="figma_line" className="icon" />
                  }
                  placeholder={"figma.com"}
                  onChange={(e) => handleChange("figma", e.target.value)}
                  value={data?.figma}
                  error={errors.figma}
                  autoComplete={false}
                  autoCorrect={false}
                  autoFocus={false}
                  id="id6"
                />
                <Input
                  leftIcon={
                    <CustomIcon iconName="dribbble_line" className="icon" />
                  }
                  placeholder={"dribbble.com"}
                  onChange={(e) => handleChange("dribbble", e.target.value)}
                  value={data?.dribbble}
                  error={errors.dribbble}
                  autoComplete={false}
                  autoCorrect={false}
                  autoFocus={false}
                  id="id7"
                />
                <Input
                  leftIcon={
                    <CustomIcon iconName="behance_line" className="icon" />
                  }
                  placeholder={"behance.com"}
                  onChange={(e) => handleChange("behance", e.target.value)}
                  value={data?.behance}
                  error={errors.behance}
                  autoComplete={false}
                  autoCorrect={false}
                  autoFocus={false}
                  id="id8"
                />
                <Input
                  leftIcon={
                    <CustomIcon iconName="instagram_line" className="icon" />
                  }
                  placeholder={"instagram.com"}
                  onChange={(e) => handleChange("instagram", e.target.value)}
                  value={data?.instagram}
                  error={errors.instagram}
                  autoComplete={false}
                  autoCorrect={false}
                  autoFocus={false}
                  id="id9"
                />
                <Input
                  leftIcon={
                    <CustomIcon iconName="linkedin_line" className="icon" />
                  }
                  placeholder={"linkedin.com"}
                  onChange={(e) => handleChange("linkedin", e.target.value)}
                  value={data?.linkedin}
                  error={errors.linkedin}
                  autoComplete={false}
                  autoCorrect={false}
                  autoFocus={false}
                  id="id10"
                />
                <Input
                  leftIcon={
                    <CustomIcon iconName="twitter_line" className="icon" />
                  }
                  placeholder={"x.com"}
                  onChange={(e) => handleChange("x", e.target.value)}
                  value={data?.x}
                  error={errors.x}
                  autoComplete={false}
                  autoCorrect={false}
                  autoFocus={false}
                  id="id11"
                />

                <Input
                  leftIcon={
                    <CustomIcon iconName="facebook_line" className="icon" />
                  }
                  placeholder={"facebook.com"}
                  onChange={(e) => handleChange("facebook", e.target.value)}
                  value={data?.facebook}
                  error={errors.facebook}
                  autoComplete={false}
                  autoCorrect={false}
                  autoFocus={false}
                  id="id12"
                />

                <Input
                  leftIcon={
                    <CustomIcon iconName="threads_line" className="icon" />
                  }
                  placeholder={"threads.com"}
                  onChange={(e) => handleChange("threads", e.target.value)}
                  value={data?.threads}
                  error={errors.threads}
                  autoComplete={false}
                  autoCorrect={false}
                  autoFocus={false}
                  id="id13"
                />

                <Input
                  leftIcon={
                    <CustomIcon iconName="tiktok_line" className="icon" />
                  }
                  placeholder={"tiktok.com"}
                  onChange={(e) => handleChange("tiktok", e.target.value)}
                  value={data?.tiktok}
                  error={errors.tiktok}
                  autoComplete={false}
                  autoCorrect={false}
                  autoFocus={false}
                  id="id14"
                />
                <Input
                  leftIcon={
                    <CustomIcon iconName="youtube_line" className="icon" />
                  }
                  type={"text"}
                  placeholder={"youtube.com"}
                  onChange={(e) => handleChange("youtube", e.target.value)}
                  value={data?.youtube}
                  error={errors.youtube}
                  autoComplete={"new"}
                  autoCorrect={false}
                  autoFocus={false}
                  id="id15"
                />
              </div>

              <div className="user-form-body-buttons">
                <Button
                  rightIcon={<CloseIcon />}
                  onClick={() => handleCancle()}
                  className="noLogged_button noLogged_button_outline"
                  data-lang={lang}
                >
                  {lang === "en" ? "Cancel" : "キャンセル"}
                </Button>

                <Button
                  rightIcon={<OK />}
                  className="noLogged_button"
                  onClick={() => handleSubmit()}
                  data-lang={lang}
                >
                  {lang === "en" ? "Save Changes" : "保存する"}
                </Button>
              </div>
            </div>
          </div>

          {/* {invitationNotValid && (
        <PopupContainer title="Confirm Quitting Signup" logo={<LogoBad />}>
          <p className="user-form-bad-news-paragrah" data-lang={lang}>
            {lang === "en" ? (
              <>
                Are you sure you want to quit the signup process and close the
                form? <br />
                <br />
                Your information will not be saved, and you won't be able to
                create an account.
              </>
            ) : (
              <>
                サインアッププロセスを終了し、フォームを閉じますか？
                <br />
                <br />
                あなたの情報は保存されず、アカウントを作成することはできません
              </>
            )}
          </p>

          <div>
            <Button
              onClick={() => {
                window.location = "/";
              }}
            >
              {lang === "en" ? "Back to Signup!A" : "サインアップに戻る"}
            </Button>
            <Button
              onClick={() => {
                window.location = "#";
              }}
            >
              Quit
            </Button>
          </div>
        </PopupContainer>
      )} */}
          {quitPopup && (
            <PopupContainer
              title={lang === "en" ? "Are you Sure!" : "サインアップをやめる？"}
              logo={<Illustra05 />}
            >
              <QuitCard
                text={
                  <span data-lang={lang}>
                    {lang === "en" ? (
                      <>
                        Are you certain you want to cancel the profile update
                        process?
                        <br />
                        If you proceed, all the information you've entered will
                        be lost!
                      </>
                    ) : (
                      <>
                        プロファイルの更新プロセスをキャンセルしてもよろしいですか?
                        続行すると、入力した情報はすべて失われます。
                      </>
                    )}
                  </span>
                }
                backBtnText={lang === "en" ? "Quit" : "やめる"}
                exitBtnText={lang === "en" ? "Back to Updating" : "更新に戻る"}
                onBack={() => {
                  setQuitPopup(false);
                  setForceUpdate(forceUpdate + 1);
                }}
                OnExit={() => {
                  setQuitPopup(false);
                }}
              />
            </PopupContainer>
          )}

          {openSuccess && (
            <PopupContainer
              title={
                lang === "en" ? "Profile Updated!" : "プロフィール更新済み！"
              }
              logo={<Logo />}
              onClose={() => {
                setOpenSuccess(false);
                window.location.reload();
              }}
            >
              <div className="success-uxer-card">
                <p>
                  {lang === "en" ? (
                    <>Your profile is updated successfully.</>
                  ) : (
                    <>プロフィールが正常に更新されました。</>
                  )}
                </p>
              </div>
            </PopupContainer>
          )}
          {IsShowPopup ? (
            <Popup
              message={popMessage}
              type={popType}
              icon={popIcon}
              closePopup={() => settIsShowPopup(false)}
            />
          ) : null}
        </>
      )}
    </>
  );
}

export default SettingsEdit;
