import { ReactComponent as CautionIcon } from "../../assets/icons/caution.svg";
import { ReactComponent as SubtractIcon } from "../../assets/icons/close_s.svg";
import React from "react";

function Popup({
  type = "success",
  message = "",
  closePopup = () => {},
  className = "",
  icon,
}) {
  return (
    <div className={`popup popup-${type} w-96 mt-5 fixed top-0`}>
      {type !== "loading" && <div className="popup-left-icon">{icon}</div>}

      <span className="popup-message">{message}</span>

      {type !== "loading" && (
        <div className="popup-close" onClick={closePopup}>
          <SubtractIcon />
        </div>
      )}
    </div>
  );
}

export default Popup;
