import ChatAvatar from "../molecules/ChatAvatar";

import { ReactComponent as MessageBack } from "../../assets/icons/message_back.svg";
import { getUserInfo } from "../../services/authServices";
import { useEffect, useState } from "react";
import React from "react";

function LeftBarMessage({
  opened,
  back,
  latestMessage,
  latestMessagedate,
  handleChatChange,
  userId,
  selected,
  isSeen,
}) {
  const [user, setUser] = useState([]);
  const [dates, setDates] = useState("");

  useEffect(() => {
    getUserInfo(userId).then((res) => {
      if (res != undefined) {
        if (res.data.myUser != undefined) {
          setUser(res.data.myUser);
        } else {
          setUser(res.data);
        }
      }
    });
  }, []);
  useEffect(() => {
    var date1 = new Date(latestMessagedate);
    var date2 = new Date();

    // To calculate the time difference of two dates
    var Difference_In_Time = date2.getTime() - date1.getTime();

    // To calculate the no. of days between two dates
    var Difference_In_Days = Difference_In_Time / (1000 * 3000 * 24);
    //To display the final no. of days (result)
    if (Difference_In_Days < 1 && Difference_In_Time / 60000 < 1) {
      setDates("now");
    } else if (
      Difference_In_Days < 1 &&
      Difference_In_Time / 60000 > 1 &&
      Difference_In_Time / 3600000 < 1
    ) {
      setDates(Math.round(Difference_In_Time / 60000) + " min");
    } else if (Difference_In_Days < 1 && Difference_In_Time / 3600000 > 1) {
      setDates(Math.round(Difference_In_Time / 3600000) + " hr");
    } else if (Difference_In_Days > 1) {
      setDates(Math.round(Difference_In_Days) + " day");
    } else if (Difference_In_Days >= 365) {
      setDates(Math.round(Difference_In_Days / 365) + " year");
    }
  }, [latestMessagedate]);

  return (
    <div
      className={`leftbar-message ${selected ? "bg-gray" : ""}`}
      onClick={handleChatChange}
    >
      <div className="leftbar-message-info">
        <ChatAvatar
          small={true}
          size="!h-12 !w-12"
          name={user !== undefined ? user.fullName : ""}
          role={user !== undefined ? user.sub_role : ""}
          image={user !== undefined ? user.profile_img : ""}
          userId={user !== undefined ? user._id : ""}
        />
        <p className="leftbar-message-date">{dates.toString()} </p>
      </div>
      <div className="leftbar-message-text-cont ">
        {isSeen ? (
          <p className="leftbar-message-text-opened whitespace-nowrap">
            {latestMessage.length > 30
              ? latestMessage.substring(0, 30) + "..."
              : latestMessage}
          </p>
        ) : (
          <p className="leftbar-message-text whitespace-nowrap ">
            {latestMessage.length > 30
              ? latestMessage.substring(0, 30) + "..."
              : latestMessage}
          </p>
        )}
        {back && <MessageBack className="leftbar-message-text-back " />}
      </div>
    </div>
  );
}

export default LeftBarMessage;
