import ChatAvatar from "../molecules/ChatAvatar";
import { ReactComponent as Norif } from "../../assets/icons/norif_off.svg";
import { ReactComponent as MessageBack } from "../../assets/icons/message_back.svg";
import RoundImage from "../atoms/RoundImage";
import { useEffect, useState } from "react";
import { fetchImage } from "../../services/itemsServices";
import { useSelector } from "react-redux/es/hooks/useSelector";
import jwtDecode from "jwt-decode";
import { ReactComponent as DefaultProfile } from "../../assets/icons/default_profile.svg";
import React from "react";
import { getUserInfo } from "../../services/authServices";
function Message({ content, sender, updatedAt, image }) {
  const user = useSelector((state) => state.user);
  const [dates, setDates] = useState();
  const month = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  useEffect(() => {
    if (updatedAt != undefined) {
      setDates(new Date(updatedAt));
    }
  }, [updatedAt]);
  return (
    <div className="main-message">
      <div className="main-message-avatar-cont">
        {" "}
        <RoundImage
          imageFull={true}
          imageData={
            "https://api.uud.io/images/profile/image_1724444352132.png"
          }
          alt="Cropped Avatar"
          size="!h-12 !w-12"
          grey="grey-border"
          noImage={
            <DefaultProfile
              data-value="redirect"
              className="default-item-photo"
            />
          }
        />
      </div>
      <div className="main-message-data-cont">
        <div className="main-message-info-cont">
          <p className="main-message-info-name">
            {sender ? sender.username : null}
          </p>
          <div className="main-message-info-date-cont">
            <p className="main-message-info-date">
              {dates ? (
                <>
                  {month[dates.getMonth()]} {dates.getDate()},{" "}
                  {dates.getFullYear()}
                </>
              ) : null}
            </p>
            <p className="main-message-info-date">
              {dates ? (
                <>
                  {dates.getHours() < 12
                    ? dates.getHours()
                    : dates.getHours() - 12}
                  :
                  {dates.getMinutes() < 10
                    ? "0" + dates.getMinutes()
                    : dates.getMinutes()}{" "}
                  {dates.getHours() < 12 ? "AM" : "PM"}
                </>
              ) : null}
            </p>
          </div>
        </div>
        <p className="main-message-text">{content}</p>
      </div>
    </div>
  );
}
export default Message;
