import LeftBar from "./dashboard/LeftBar";
import TopBar from "./dashboard/TopBar";

function DashboardLayout({
  id,
  children,
  data,
  selectedTab,
  setSelectedTab,
  length,
  topTitle,
  favs,
  clicks,
  uxers,
  users,
  guestClicks,
  TopIcon,
}) {
  return (
    <div className="layout-dashboard" style={{ "--primary-color": "#FF6A56" }}>
      <LeftBar
        selectedTab={selectedTab}
        setSelectedTab={(res) => setSelectedTab(res)}
      />
      <div className="page-area" id={id}>
        <TopBar
          numberItems={length}
          title={selectedTab}
          clicks={clicks}
          uxers={uxers}
          users={users}
          favs={favs}
          guestClicks={guestClicks}
          TopIcon={TopIcon}
        />
        <div className="content">{children}</div>
      </div>
    </div>
  );
}

export default DashboardLayout;
