import { useState } from "react";
import { ReactComponent as MailIcon } from "../.././../assets/icons/mail.svg";
import { ReactComponent as SendIcon } from "../../../assets/icons/send.svg";
import Input from "../../atoms/Input";
import Button from "../../atoms/Button";
import { useTranslation } from "react-i18next";
import React from "react";
import { useDispatch, useSelector } from "react-redux";


function ForgotPasswordCard({ onBack, onSubmit, error = false }) {
  const [email, setEmail] = useState("");
  const [t, i18n] = useTranslation();
  const lang = useSelector((state) => state.lang);
  return (
    <div className="forgot-password-card" data-lang={lang}>
      <p data-lang={lang}>{lang === "en" ?  "Enter the email address linked to your account, and we will send you a secure link to reset your password." 
      : <>
      アカウントにリンクされているメールアドレスを入力してください。<br /> 
      パスワードをリセットするための安全なリンクを送信いたします。
      </>}
      }</p>
      <Input
        error={error}
        placeholder={lang === "en" ? "Email" : "Eメール"}
        value={email}
        onChange={(e) => {
          setEmail(e.target.value);
        }}
        leftIcon={<MailIcon />}
      />

      <Button rightIcon={<SendIcon />} onClick={() => onSubmit(email)} data-lang={lang}
            >
              {lang === "en" ? "Send" : "送信"}
      </Button>

      
      <span className="back-link" data-lang={lang}>
        {
              lang === "en" ? (
                <>Back to <span className="text-link" onClick={onBack}>Login </span></>
              ) : (
                <><span className="text-link" onClick={onBack}>ログイン</span>に戻る</>
              )
            }
      </span>
    </div>
  );
}

export default ForgotPasswordCard;
