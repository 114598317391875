import LangugeSwitch from "../components/atoms/LangugeSwitch";
import React from "react";
import RoundImage from "../components/atoms/RoundImage";

function Compos() {
  return (
    <div>
      <RoundImage />
      <LangugeSwitch language={"eng"} onClick={() => {}} />
    </div>
  );
}

export default Compos;
