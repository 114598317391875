import { useEffect, useRef, useState } from "react";
import { ReactComponent as FavIcon } from "../../assets/icons/fav.svg";
import { ReactComponent as DefaultItem } from "../../assets/icons/default_item.svg";
import React from "react";
import { useSelector } from "react-redux/es/hooks/useSelector";
import RoundImage from "../atoms/RoundImage";
import CardSpecs from "../molecules/CardSpecs";
import { fetchImage } from "../../services/itemsServices";
import axios from "axios";
import pirsch from "../../Analytics";
import { BACKEND_URL } from "../../config";

function ItemCard({
  _id = "",
  image_src = "",
  title = "",
  subtitle = "",
  platform = [],
  price = "",
  tags = [],
  link = "",
  like,
  setLike,
  className = "",
  favs = true,
  mainImage,
  edit,
  favsOnClick,
  category,
  linkAndroid,
  linkWindows,
  ...otherProps
}) {
  const [image, setImage] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const lang = useSelector((state) => state.lang);
  const itemRef = useRef(null);
  const token = localStorage.getItem("token");

  const handleClick = (e) => {
    e.preventDefault();
    let dataValue = e.target.getAttribute("data-value");
    if (dataValue === "redirect") {
      pirsch("Cards-Items-Clicks");
      window.open(link, "_blank");
      axios.post(
        `${BACKEND_URL}/items/click-item/${_id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token?.slice(1, -1)}`,
          },
        }
      );
    }
  };

  // if is observe
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      {
        rootMargin: "0px 0px 2000px 0px",
      }
    );

    if (itemRef.current) {
      observer.observe(itemRef.current);
    }

    return () => observer.disconnect();
  }, []);

  // if change category
  useEffect(() => {
    setImage("");
    let cancelRequest = false;

    if (isVisible) {
      fetchImage("items", _id)
        .then((res) => {
          if (!cancelRequest) {
            setImage(res);
          }
        })
        .catch((err) => {});
    }

    return () => {
      let source = axios.CancelToken.source();
      source.cancel("Component got unmounted");
      cancelRequest = true;
    };
  }, [_id, image_src, isVisible]);

  return (
    <>
      {category === "apps" ? (
        <div
          className={`${
            category == "apps" ? " item-card-apps" : "item-card"
          } ${className}`}
          {...otherProps}
          ref={itemRef}
        >
          {favs && (
            <div
              className={`item-card-like ${
                like ? "item-liked" : "item-not-liked"
              }`}
              onClick={() => {
                setLike(!like);
              }}
            >
              <FavIcon />
            </div>
          )}

          <RoundImage
            imageFull={edit}
            type="svg+xml"
            className="item-card-logo"
            imageData={edit ? mainImage : image}
            noImage={
              <DefaultItem
                data-value="redirect"
                className="default-item-photo"
              />
            }
          />

          <div className="item-card-info">
            <span className="item-card-title" data-lang={lang}>
              {title}
            </span>
            <span className="item-card-subtitle" data-lang={lang}>
              {subtitle}
            </span>
          </div>

          <CardSpecs
            price={price || []}
            platform={platform || []}
            tags={tags}
            category={category}
            linkAndroid={linkAndroid}
            linkWindows={linkWindows}
            link={link}
          />
        </div>
      ) : (
        <div
          className={`item-card ${className}`}
          {...otherProps}
          data-value="redirect"
          onClick={handleClick}
          ref={itemRef}
        >
          {favs && (
            <div
              className={`item-card-like ${
                like ? "item-liked" : "item-not-liked"
              }`}
              onClick={() => {
                setLike(!like);
              }}
            >
              <FavIcon />
            </div>
          )}

          <RoundImage
            imageFull={edit}
            data-value="redirect"
            type="svg+xml"
            className="item-card-logo"
            imageData={edit ? mainImage : image}
            noImage={
              <DefaultItem
                data-value="redirect"
                className="default-item-photo"
              />
            }
          />

          <div className="item-card-info" data-value="redirect">
            <span
              className="item-card-title"
              data-value="redirect"
              data-lang={lang}
            >
              {title}
            </span>
            <span
              className="item-card-subtitle"
              data-value="redirect"
              data-lang={lang}
            >
              {subtitle}
            </span>
          </div>

          <CardSpecs
            price={price || []}
            platform={platform || []}
            tags={tags}
            category={category}
            linkAndroid={linkAndroid}
            linkWindows={linkWindows}
            link={link}
          />
        </div>
      )}
    </>
  );
}

export default ItemCard;
