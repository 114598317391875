import axios from "axios";
import { BACKEND_URL } from "../config";
const token = localStorage.getItem("token");

export const getLikedItems = async () => {
  if (token) {
    const res = await axios.get(`${BACKEND_URL}/profile/my-favs`, {
      headers: {
        Authorization: `Bearer ${token.slice(1, -1)}`,
      },
    });

    if (res.data) {
      return Object.fromEntries(
        Object.entries(res.data).map(([key, value]) => [
          key,
          value.length > 0 && Array.isArray(value) == true
            ? value.filter((item) => item !== null)
            : null,
        ])
      );
    }

    return { myTools: [], myResos: [], myIdeaz: [] };
  }
};

export const likeItem = async (id_item, categorie) => {
  let url = `${BACKEND_URL}/items/${id_item}`;

  if (categorie === "tools") {
    url += "/64761102ba22fed734b9de7a";
  } else if (categorie === "resos") {
    url += "/64761222ba22fed734b9de7d";
  } else {
    url += "/64761240ba22fed734b9de81";
  }

  console.log(token);
  if (token) {
    const res = await axios.post(
      url,
      {},
      {
        headers: {
          Authorization: `Bearer ${token.slice(1, -1)}`,
        },
      }
    );
    return res;
  }
};

// change profile info
export const changeProfileInfo = async (data, lang) => {
  const reqData = {
    username: data.username,
    fullName: data.fullName,
    nationality: data.nationality.value,
    intro: data.intro,
    personalSite: data.personalSite,
    personalBlog: data.personalBlog,
    lang: lang,
    sub_role: data.subRole,
    experience: !data.experience ? 0 : data.experience,
    mediaLinks: [
      {
        type: "facebook",
        link: data.facebook,
      },
      {
        type: "instagram",
        link: data.instagram,
      },
      {
        type: "linkedin",
        link: data.linkedin,
      },
      {
        type: "dribbble",
        link: data.dribbble,
      },
      {
        type: "behance",
        link: data.behance,
      },
      {
        type: "threads",
        link: data.threads,
      },
      {
        type: "youtube",
        link: data.youtube,
      },
      {
        type: "figma",
        link: data.figma,
      },
      {
        type: "tiktok",
        link: data.tiktok,
      },
      {
        type: "x",
        link: data.x,
      },
    ],
  };
  const response = await axios.put(`${BACKEND_URL}/profile`, reqData, {
    headers: {
      Authorization: `Bearer ${token.slice(1, -1)}`,
    },
  });
  return response;
};

// submit request uxer
export const submitRequestUxer = async (userData, lang) => {
  if (token) {
    const res = await axios.post(
      `${BACKEND_URL}/uxers/uxers-request`,
      {
        skills: userData.skills ? userData.skills?.join(",") : "",
        position: userData.position,
        experience: userData.experience ? parseInt(userData.experience) : 0,
        spokenLangs: "English",
      },
      {
        headers: {
          Authorization: `Bearer ${token.slice(1, -1)}`,
        },
      }
    );

    return res;
  }
};

// get user info
export const getUserInfo = async (username) => {
  const res = await axios.get(`${BACKEND_URL}/profile/user/${username}`);

  return res;
};
