import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as Send } from "../assets/icons/send.svg";
import "../styles/main.scss";
import { ReactComponent as Subject } from "../assets/icons/subject.svg";
import { ReactComponent as Check } from "../assets/icons/check.svg";
import Popup from "../components/molecules/Popup";
import { ReactComponent as MailIcon } from "../assets/icons/mail.svg";
import { ReactComponent as Mail2 } from "../assets/icons/mail_fill.svg";
import { ReactComponent as Phone } from "../assets/icons/phone.svg";
import { ReactComponent as Loading } from "../assets/icons/loading.svg";
import { ReactComponent as CautionIcon } from "../assets/icons/caution.svg";
import CheckBox from "../components/atoms/CheckBox";
import PopupContainer from "../components/molecules/PopupContainer";
import { ReactComponent as Logo } from "../assets/icons/logo_05.svg";
import { ReactComponent as UserNameIcon } from "../assets/icons/namecard.svg";
import { ReactComponent as Account } from "../assets/icons/account.svg";
import { ReactComponent as Robot } from "../assets/icons/robot.svg";
import { ReactComponent as Human } from "../assets/icons/human.svg";
import { ReactComponent as Company } from "../assets/icons/company.svg";
import countires from "../data/countires.json";
import Button from "../components/atoms/Button";
import Select from "../components/atoms/Select";
import Input from "../components/atoms/Input";
import React, { useEffect, useState } from "react";
import Layout from "../components/layout/Layout";
import StaticPageTitle from "../components/atoms/StaticPageTitle";
// import EventContent from "../pages/events/EventContent";
import RadioBoxGroup from "../components/molecules/RadioBoxGroup";
import TextArea from "../components/atoms/TextArea";
import axios from "axios";
import pirsch from "../Analytics";

function ContactEvent({}) {
  useEffect(() => {
    document.title = "UUD - Contact us";
    return () => {
      document.title = "UUD";
    };
  }, []);

  const user = useSelector((state) => state.user);
  const [showRightBar, setShowRightBar] = useState(false);
  const [data, setData] = useState({});
  const lang = useSelector((state) => state.lang);
  const [showLeftBar, setShowLeftBar] = useState(false);
  const countries = countires;
  const [value, setValue] = useState();
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});
  const [popMessage, setPopMessage] = useState("");
  const [popIcon, setPopIcon] = useState("");
  const [popType, setPopType] = useState("");
  const [IsShowPopup, settIsShowPopup] = useState(false);
  const [invitationNotValid, setInvitationNotValid] = useState(false);
  const [quitPopup, setQuitPopup] = useState(false);
  const [timeOut, setTimeOut] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);

  const regex = new RegExp("[a-zA-Z0-9_]+$");
  const rolesOptions1 = [
    { value: "イベント・ワークショップへのフィードバック", label: "イベント・ワークショップへのフィードバック" },
    { value: "コミュニティチームへの参加リクエスト", label: "コミュニティチームへの参加リクエスト" },
    { value: "活動参加・イベント提案のご連絡", label: "活動参加・イベント提案のご連絡" },
    { value: "スポンサーシップ・コラボレーションのご案内", label: "スポンサーシップ・コラボレーションのご案内" },
  ];

  const rolesOptions2 = [
    {
      value: lang === "jp" ? "Partnership Offer" : "フィードバック/問題の報告",
      label: lang === "jp" ? "Partnership Offer" : "フィードバック/問題の報告",
    },
  ];
  const [country, setCountry] = useState("");
  useEffect(() => {
    axios
      .get("https://api.country.is/")
      .then((res) => {
        if (res.data.country) {
          setCountry(res.data.country);
        }
      })
      .catch(() => {});
  }, []);

  const handleChange = async (element, dataP) => {
    if (element == "state") {
      setData({ ...data, [`${element}`]: dataP, subRole: "" });
    } else {
      setData({ ...data, [`${element}`]: dataP });
    }
  };
  function popups(message, type, icon) {
    settIsShowPopup(true);
    setPopType(type);
    setPopIcon(icon);
    setPopMessage(message);

    setTimeout(() => {
      settIsShowPopup(false);
    }, 6000);
  }
  const handleSubmit = (e) => {
    e.preventDefault();

    let errors = validateForm(data);
    console.log(errors);
    if (Object.keys(errors).length === 0) {
      const japanDate = new Date();

      // Convert date to Japan's timezone
      const date = new Date(
        japanDate.toLocaleString("en-US", { timeZone: "Asia/Tokyo" })
      );

      const formData = new FormData();
      let fullCountry = countries.find((el) => {
        return el.iso2.toLowerCase() == country.toLowerCase();
      });
      formData.append("country", country ? fullCountry.label : "- - - -");
      formData.append("message", data.message ? data.message : "- - - -");
      formData.append("email", data.email ? data.email : "- - - -");
      formData.append("company", data.company ? data.company : "- - - -");
      formData.append("phone", data.phone ? data.phone : "- - - -");
      formData.append("subRole", data.subRole ? data.subRole : "- - - -");
      formData.append("name", data.fullName ? data.fullName : "- - - -");
      formData.append(
        "date",
        `${date.getDate()}/${
          date.getMonth() + 1
        }/${date.getFullYear()} - ${date.getHours()}:${
          date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()
        }:${
          date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds()
        }`
      );
      formData.append("timezone", "Asia/Tokyo");

      popups("送信中...", "info", <Loading />);
      axios
        .post(
          "https://script.google.com/macros/s/AKfycbxTT1UGiwVm9gGXsiOnoX-1i6A0XpMVNBl3Fx21OWKkrqMZTo8HoY9CE-96IiAuGVSABA/exec",
          formData
        )

        .then((response) => {
          settIsShowPopup(false);
          if (response.data.result == "success") {
            setOpenSuccess(true);
            setData({});
          } else {
            popups("Error occurred", "error", <CautionIcon />);
          }
        })
        .catch((error) => console.error("Error!", error.message));
    } else {
      if (Object.keys(errors).length > 1) {
        popups(
          lang === "en"
            ? "Please fill out the required fields"
            : "必須項目を入力してください。",
          "error",
          <CautionIcon />
        );
        console.log(errors);
      } else {
        Object.keys(errors).map(() => {
          popups(Object.values(errors)[0], "error", <CautionIcon />);
        });
      }
    }
  };

  const validateForm = (data) => {
    const errors = {};

    if (!data.email) {
      errors.email = "Email is required";
    } else if (!isValidEmail(data.email)) {
      errors.email = "Invalid email address";
    }

    if (!data.fullName) {
      errors.fullName = "FullName is required";
    }
    if (!data.subRole) {
      errors.subRole = "Role is required";
    }

    if (!data.phone) {
      errors.phone = "Phone Number is required";
    }
    if (!data.company) {
      errors.company = "Company is required";
    }

    if (!data.message) {
      errors.message = "Introduction is required";
    } else if (data.message.length > 750 || data.message.length < 50) {
      errors.message = "Introduction must be between 50 and 750 characters";
    }
    if (!data.checked) {
      errors.checked = "Please confirm that you are human";
    }
    setErrors(errors);
    return errors;
  };

  const isUsernameValid = (username) => {
    return /^[a-z0-9_]+$/.test(username);
  };

  const isValidEmail = (email) => {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    );
  };

  const isValidSocialMediaLink = (url) => {
    return /^(https?|ftp):\/\/([^\s\/]+)((\/\w+)*\/)([\w\-\.]+[^#?\s]+)(.*)?(#[\w\-]+)?$/.test(
      url
    );
  };

  const isValidURL = (url) => {
    return /^(http(s)?:\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)?$/.test(
      url
    );
  };
  useEffect(() => {
    handleChange("state", "About the Service");
    setData((prev) => {
      prev.phone = "";
      return { ...prev };
    });
  }, []);
  return (
    <>
      <form className="contact-form-box" onSubmit={handleSubmit}>
        <div>
          <div className="contact-form">
            {
              <Select
                leftIcon={<Subject />}
                options={rolesOptions1}
                onChange={(value) => {
                  handleChange("subRole", value);
                  errors.subRole = "";
                }}
                value={data.subRole}
                defaultValue={lang === "En" ? "Subject" : "お問合せの目的"}
                error={errors.subRole}
              />
            }
            <div className="contact-input-box">
              <Input
                leftIcon={<UserNameIcon />}
                // placeholder={"Email or Username"}
                placeholder={lang === "En" ? "Full Name" : "お名前"}
                type="text"
                value={data.fullName}
                onChange={(e) => {
                  handleChange("fullName", e.target.value);
                  errors.fullName = "";
                }}
                error={errors.fullName}
              />
              <Input
                value={data.email}
                onChange={(e) => {
                  handleChange("email", e.target.value);
                  errors.email = "";
                }}
                leftIcon={<MailIcon />}
                className="en"
                placeholder={lang === "En" ? "Email Address" : "メールアドレス"}
                error={errors.email}
              />
            </div>
            <div className="contact-input-box">
              <Input
                leftIcon={<Phone />}
                className="en"
                placeholder={lang === "En" ? "Phone" : "電話番号"}
                type="text"
                value={data.phone}
                onChange={(e) => {
                  if (
                    /^[0-9]+$/.test(e.target.value) ||
                    e.target.value.length == 0
                  ) {
                    handleChange("phone", e.target.value);
                    errors.phone = "";
                  }
                }}
                error={errors.phone}
              />
              <Input
                value={data.company}
                onChange={(e) => {
                  handleChange("company", e.target.value);
                  errors.company = "";
                }}
                leftIcon={<Company />} // innerClass="py-2-5"
                placeholder={lang === "En" ? "Company" : "会社名"}
                error={errors.company}
                type="text"
              />
            </div>
            <TextArea
              leftIcon={<Account />}
              label={
                lang === "En" ? (
                  <> Message (Min 50 Characters)</>
                ) : (
                  <> お問合せの内容 (Min 50文字) </>
                )
              }
              rightIcon={`${data.message ? 750 - data.message?.length : 750}`}
              onChange={(e) => {
                handleChange("message", e.target.value);
                errors.message = "";
              }}
              value={data.message}
              error={errors.message}
              maxLength={750}
              data-lang={lang}
              className="!h-56"
            />

            <div className="contact-btn-box">
              <CheckBox
                checked={data.checked}
                text={
                  lang === "en"
                    ? data.checked
                      ? "I'm a Human!"
                      : "I'm not a Robot"
                    : data.checked
                    ? "私は人間です!"
                    : "私はロボットではない"
                }
                icon={
                  data.checked ? (
                    <Human className="contact-checkbox-icon" />
                  ) : (
                    <Robot className="robot-icon" />
                  )
                }
                onClick={() => {
                  data.checked
                    ? handleChange("checked", false)
                    : handleChange("checked", true);
                  errors.checked = "";
                }}
                data-lang={lang}
                className={errors.checked ? "input-error-small" : ""}
                green={true}
              />

              {lang === "En" ? (
                <Button
                  rightIcon={<Send />}
                  className="contact-btn"
                  data-lang={lang}
                  onClick={() => {
                    pirsch("Contact-Send-E");
                  }}
                >
                  Send
                </Button>
              ) : (
                <Button
                  rightIcon={<Send />}
                  className="contact-btn"
                  data-lang={lang}
                  onClick={() => {
                    pirsch("Contact-Send-J");
                  }}
                >
                  送信
                </Button>
              )}
            </div>
          </div>
        </div>
      </form>

      {openSuccess && (
        <PopupContainer
          title={lang === "En" ? "Mail Sent!" : "送信完了！"}
          logo={<Logo />}
          onClose={() => {
            setOpenSuccess(false);
          }}
        >
          <div className="contact-success-card" data-lang={lang}>
            {lang === "En" ? (
              <>
                Thank you for reaching out!
                <br />
                <br />
                We will get in touch with you as soon as possible, depending on
                the nature of your request. Your satisfaction is our priority!
              </>
            ) : (
              <>
                お問い合わせありがとうございます。
                <br />
                担当者よりご連絡させていただきます。
                <br />
                （内容によってはお時間をいただく場合もございます。）
              </>
            )}
          </div>
        </PopupContainer>
      )}
      {IsShowPopup ? (
        <Popup
          message={popMessage}
          type={popType}
          icon={popIcon}
          closePopup={() => settIsShowPopup(false)}
        />
      ) : null}
    </>
  );
}

export default ContactEvent;
