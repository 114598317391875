import { useDispatch, useSelector } from "react-redux";
import SecondaryLayout from "../components/layout/SecondaryLayout";
import Popup from "../components/molecules/Popup";
import { ReactComponent as About } from "../assets/icons/about_big.svg";
import { ReactComponent as TimerIcon } from "../assets/icons/timer.svg";
import { ReactComponent as BagIcon } from "../assets/icons/bag.svg";
import { ReactComponent as News } from "../assets/icons/news.svg";
import { ReactComponent as Hand2 } from "../assets/icons/hand2.svg";
import "../styles/main.scss";
import Button from "../components/atoms/Button";
import Select from "../components/atoms/Select";
import Input from "../components/atoms/Input";
import { useEffect, useState } from "react";
import React from "react";
import Signup from "../components/templates/signup";
import Layout from "../components/layout/Layout";
import StaticPageTitle from "../components/atoms/StaticPageTitle";
import StaticPageSlogan from "../components/atoms/StaticPageSlogan";
import StaticPageArticle from "../components/organismes/StaticPageArticle";
import StaticPageOrderedList from "../components/organismes/StaticPageOrderedList";
import StaticPageParagraph from "../components/organismes/StaticPageParagraph";
import StaticPageParagraphImage from "../components/organismes/StaticPageParagraphImage";
import StaticPageOrderedList2 from "../components/organismes/StaticPageOrderedList2";
import StaticPageSubscribe from "../components/organismes/StaticPageSubscribe";
import StaticPageBigImage from "../components/organismes/StaticPageBigImage";

function AboutUs({ }) {

  useEffect(() => {
    document.title = "UUD - About";
    return () => {
      document.title = "UUD";
    };
  }, []);

  const user = useSelector((state) => state.user);
  const [showRightBar, setShowRightBar] = useState(false);
  const [showLeftBar, setShowLeftBar] = useState(false);
  const lang = useSelector((state) => state.lang);

  return (
    <Layout
      id="aboutus"
      selectedMenu={""}
      currentPage="aboutus"
      isLogin={user !== "" && user}
      setShowLeftBar={(res) => setShowLeftBar(res)}
      setShowRightBar={(res) => setShowRightBar(res)}
      showRightBar={showRightBar}
      showLeftBar={showLeftBar}
      actionMenu={false}
    >
      <div className="static-page-hero">
        <div className="static-page">
          <StaticPageTitle icon={<About />} text={"ABOUT US"} />
          <StaticPageSlogan
            text={
              <>
                A place that UX/UI Designers could call
                <span className="sp-slogan"> Home!</span>
              </>
            }
          />

          <StaticPageParagraph
            title="OUR STORY!"
            text={<>
              <div className="paragraph_static_page" data-lang={lang}>

                {lang === "en" ? (
                  <>
                    UUD originated as a vibrant UXUI Design community in Tokyo, Japan, managed by a international team primarily consisting of UXUI Designers, including the founder.
                    <br /> <br /> <br />
                    Our story began as a local hub for UXUI designers, organizing offline activities such as events, workshops, and meetups.

                    <StaticPageBigImage
                      imgSrc1Small={"about-img02.png"}
                      imgSrc1Large={"about-img01.png"}
                    />

                    As passionate UXUI designers ourselves, we immersed ourselves in the community, gaining valuable insights into their needs, challenges, and aspirations.
                    <br /> <br /> <br /> <br /> <br /> <br />
                    This firsthand experience inspired us to develop a platform that could cater to all these aspects and create an ideal space for UXUI Designers—a place they could truly call  <span style={{ color: "tomato" }}>HOME! </span>     <br />
                    Our motto,  <span style={{ color: "tomato" }}>"A place UXUI designers could call HOME!" </span> encapsulates our mission. We aim to make UUD a comprehensive hub where UXUI designers can find everything they need in one place: educational content, diverse resources, information, inspiration, and networking opportunities.  <br />
                    Our goal is to fuel their creativity and enhance their skills, providing a supportive environment to thrive.
                    <br /> <br /> <br /> <br /><br /> <br />
                    At UUD, we are more than just a platform; we are a passionate community of UXUI designers, founded by designers and run for designers.  <br />
                    Our platform not only offers a wide range of services and resources but also reflects the experiences and aspirations of the UXUI design community.
                    <br /> <br /> <br /> <br /><br /> <br />
                    With a solid foundation rooted in understanding the UXUI design community deeply, we provide an array of services, tools, and resources. <br />
                    Moreover, we eagerly listen to our users, addressing their specific needs and aspirations directly.
                    <br /> <br /> <br /> <br /><br /> <br />
                    We invite you to join us on this transformative journey, whether you are a seasoned UXUI Designer, a newcomer embarking on their UXUI Design path, or even someone without a design background but deeply passionate about UXUI Design, just like us.
                    In UUD, you will find the support and resources you need to excel.
                    <br /> <br /> <br /> <br /><br /> <br />
                    Because UUD is the<span style={{ color: "tomato" }}>"HOME!" </span> every UXUI designer deserves.

                  </>
                ) : (
                  <>
                    UUDは、東京、日本で生まれた、アクティブなUXUIデザインコミュニティです。<br />
                    主にUXUIデザイナーで構成される情熱的でインターナショナルなチームで、ファウンダーも含まれています。
                    <br /> <br />
                    私たちの物語は、UXUIデザイナーのためのローカルな拠点として始まりました。イベントやワークショップ、交流会など、オフラインの活動を通じてコミュニティを活気づけ、参加者と交流してきました。

                    <StaticPageBigImage
                      imgSrc1Small={"about-img02.png"}
                      imgSrc1Large={"about-img01.png"}
                    />
                    <br />

                    UUDのチームは自身もUXUIデザイナーとして情熱を持っており、その経験を通じてデザインのコミュニティのニーズ、課題、そして夢について貴重な洞察を得ました。<br />
                    この経験が私たちを鼓舞し、UXUIデザイナーたちが真に<span style={{ color: "tomato" }}>「HOME!」</span>と呼べる場所を作ることを決意させました！
                    <br /> <br /> <br /> <br />
                    私たちのモットーは、<span style={{ color: "tomato" }}>"A place UXUI designers could call HOME!" </span>（UXUIデザイナーのための居場所）という使命を表しています。<br />
                    私たちの目標は、UXUIデザイナーが必要とする全てをワンストップで見つけられる包括的な拠点を提供することです。教育コンテンツ、多様なリソース、情報、インスピレーション、そしてネットワーキングの機会など。<br />
                    UXUIデザイナーの創造性を刺激し、スキルを向上させ、より発展するためのサポート環境を提供することです。
                    <br /> <br /> <br /> <br />
                    UUDは、単なるプラットフォームではなく、UXUIデザイナーによって設立され、UXUIデザイナーのために運営される情熱的なコミュニティです。<br />
                    UUDは、幅広いサービスとリソースを提供するだけでなく、UXUIデザインコミュニティの経験と夢を反映しています。
                    <br /> <br /> <br /> <br />
                    UXUIデザインコミュニティを深く理解した堅実な基盤を持ちながら、さまざまなサービス、ツール、リソースを提供しています。さらに、ユーザーの声を熱心に聞き、特定のニーズと夢に直接対応しています。
                    <br /> <br /> <br /> <br />
                    あなたも、この変革の旅にお誘いします。<br />
                    経験豊富なUXUIデザイナーも、UXUIデザインの道を歩み始める初心者も、デザインのバックグラウンドがない人も、UXUIデザインに情熱を持っている人であれば誰でも歓迎します。<br />
                    UUDは皆さんがより良くなるために必要なサポートとリソースをいつでも提供します。
                    <br /> <br /> <br /> <br />
                    UUDは、すべてのUXUIデザイナーにとっての<span style={{ color: "tomato" }}>「HOME!」</span>です。

                  </>
                )}

              </div>
              <br />

            </>}
          />

          {/* <StaticPageParagraph
            title="OUR MISSION"

            text={<>
              <div className="paragraph_static_page" data-lang={lang}>
             
                {lang === "en" ? (
                  <>
                    <span className={"mvv"}>
                      To be the ultimate destination for UXUI designers, offering unparalleled support and resources to meet their every need.
                    </span>
                    <br />
                    Our goal is to be the top choice for UXUI designers, providing a streamlined hub that enhances skills, fosters innovation, and boosts professional growth. <br />
                    We empower designers by simplifying their creative journeys, opening new doors to opportunities, and strengthening the UXUI design community.
                  </>
                ) : (
                  <>
                    <span className={"mvv"}>
                      To be the ultimate destination for UXUI designers, offering unparalleled support and resources to meet their every need.
                    </span>
                    <br />
                    Our goal is to be the top choice for UXUI designers, providing a streamlined hub that enhances skills, fosters innovation, and boosts professional growth. <br />
                    We empower designers by simplifying their creative journeys, opening new doors to opportunities, and strengthening the UXUI design community.
                  </>
                  )}
             
              </div>
            </>
            }
          /> */}
          {/* 
          <StaticPageParagraph
            title="OUR VISION"

            text={<>
              <div className="paragraph_static_page" data-lang={lang}>

                {lang === "en" ? (
                  <>
                    <span className={"mvv"}>
                      To emerge as the ultimate destination indispensable to every UX/UI designer, serving as the definitive hub for all their requirements.
                    </span>
                    <br />
                    UUD aims to establish itself as an indispensable platform for every UX/UI designer, akin to a guiding North Star that provides direction and invaluable insights.<br />
                    We aspire to be the definitive one-stop destination, universally recognized by designers, ensuring they have seamless access to essential tools, knowledge, and extensive networks.
                  </>
                ) : (
                  <>
                    <span className={"mvv"}>
                      To emerge as the ultimate destination indispensable to every UX/UI designer, serving as the definitive hub for all their requirements.
                    </span>
                    <br />
                    UUD aims to establish itself as an indispensable platform for every UX/UI designer, akin to a guiding North Star that provides direction and invaluable insights.<br />
                    We aspire to be the definitive one-stop destination, universally recognized by designers, ensuring they have seamless access to essential tools, knowledge, and extensive networks.
                  </>
                  )}

              </div>
            </>
            }
          /> */}

          {/* <StaticPageParagraph
            title="OUR VALUES"

            text={<>
              <div className="paragraph_static_page" data-lang={lang}>

                {lang === "en" ? (
                  <>
                    <span style={{ color: "tomato", fontSize: "24px" }}>BE UNIQUE:</span>
                    <br />
                    We celebrate the diversity of styles and perspectives in UXUI design at UUD.<br />
                    By nurturing individuality, we encourage designers to think creatively, fostering a vibrant community of innovative ideas.<br />
                    Embracing uniqueness leads to outside-the-box thinking, enriching our design landscape.
                    <br />
                    <span style={{ color: "tomato", fontSize: "24px" }}>STAY HUNGRY:</span>
                    <br />
                    We value the hunger for knowledge and improvement. We inspire designers to remain curious, eager to learn, and evolve continually.<br />
                    This thirst for growth propels us forward, driving exploration into new territories in UXUI design.
                    It not only enhances individual skills but also elevates the entire community.
                    <br />
                    <span style={{ color: "tomato", fontSize: "24px" }}>BE TRAILBLAZER:</span>
                    <br />
                    We values being pioneers in UXUI design, introducing fresh concepts and approaches.<br />
                    We urge designers to be trailblazers, initiating change and setting new standards.<br />
                    By championing innovation, we inspire others with groundbreaking ideas, propelling the entire community forward and shaping the future of UXUI design.
                  </>
                  ) : (
                    <>
                    <span style={{ color: "tomato", fontSize: "24px" }}>BE UNIQUE:</span>
                    <br />
                    We celebrate the diversity of styles and perspectives in UXUI design at UUD.<br />
                    By nurturing individuality, we encourage designers to think creatively, fostering a vibrant community of innovative ideas.<br />
                    Embracing uniqueness leads to outside-the-box thinking, enriching our design landscape.
                    <br />
                    <span style={{ color: "tomato", fontSize: "24px" }}>STAY HUNGRY:</span>
                    <br />
                    We value the hunger for knowledge and improvement. We inspire designers to remain curious, eager to learn, and evolve continually.<br />
                    This thirst for growth propels us forward, driving exploration into new territories in UXUI design.
                    It not only enhances individual skills but also elevates the entire community.
                    <br />
                    <span style={{ color: "tomato", fontSize: "24px" }}>Be Trailblazer:</span>
                    <br />
                    We values being pioneers in UXUI design, introducing fresh concepts and approaches.<br />
                    We urge designers to be trailblazers, initiating change and setting new standards.<br />
                    By championing innovation, we inspire others with groundbreaking ideas, propelling the entire community forward and shaping the future of UXUI design.
                  </>
                  )}

              </div>
            </>
            }
          /> */}
          {/* 
          <StaticPageBigImage
            imgSrc1Small={"img4.png"}
            imgSrc1Large={"img3.png"}
          /> */}

          {/* <StaticPageOrderedList list={list1} title="OUR VALUES!" /> */}

          {/* <StaticPageArticle
          className="switch-staticpagearticle"
            title="BE A PERT OF THE COMMUNITY"
            text={
              <div>
                eew;dkewkd pewodpewk In 2016 brothers Alex and Anthony Zaccaria
                and their business partner Nick Humphreys found a <br />
                <br />
                common pain point when running their digital agency, Bolster,
                managing social media accounts for their clients.
              </div>
            }
            imgSrc1Small={"img1.png"}
            imgSrc1Large={"img2.png"}
          /> */}


          {/* ------------------------------------------------------------------------------------------------------------ */}


        </div>
      </div>
    </Layout>
  );
}

export default AboutUs;
