import StatistiqueIcon from "../../molecules/StatistiqueIcon";
import { ReactComponent as Hand4Icon } from "../../../assets/icons/hand4.svg";
import { ReactComponent as FavIcon } from "../../../assets/icons/fav_line.svg";
import { ReactComponent as UxerIcon } from "../../../assets/icons/uxer_2_on.svg";
import { ReactComponent as UsersIcon } from "../../../assets/icons/users2.svg";
import DashboardDateCompo from "../../molecules/DashboardDate";
import CustomIcon from '../../../compo/IconCompo';

function TopBar({
  numberItems,
  title,
  favs,
  clicks,
  uxers,
  users,
  guestClicks,
  TopIcon,
}) {
  return (
    <div className="top-menu-bar">
      <div className="top-bar-left-state">
        {TopIcon}
        <div className="top-title-box">
          <span className="state-title">{title.toUpperCase()}</span>
          <span className="state-body">{numberItems}</span>
        </div>
      </div>

      <div className="top-bar-right-state">
        <StatistiqueIcon icon={<CustomIcon iconName="hand4" />}  title={<>Guests<br/> CLICKS</>}data={guestClicks} />
        <StatistiqueIcon icon={<CustomIcon iconName="hand4" />}  title={<>Users<br/> CLICKS</>} data={clicks} />
        <StatistiqueIcon icon={<CustomIcon iconName="fav_line" />}  title={<>Total<br/> FAVS</>} data={favs} />
        {/* <StatistiqueIcon icon={<CustomIcon iconName="uxer_2_on" />}  title={<>Total<br/> UXERS</>} data={uxers} /> */}
        <StatistiqueIcon icon={<CustomIcon iconName="users2" />}  title={<>Total<br/> USERS</>} data={users} />
      </div>

      <DashboardDateCompo/>

    </div>
  );
}

export default TopBar;
