import { combineReducers } from "redux";
import {
  favsItemsReducer,
  itemsReducer,
  numberItemsReducer,
  uxersReducer,
} from "./itemsReducer";
import { selectMenuReducer, selectLangReducer } from "./envReducer";
import { OpenLoginPopupReducer, OpenSignupPopupReducer, UserReducer } from "./userReducer";

const allReudcer = combineReducers({
  numberItems: numberItemsReducer,
  items: itemsReducer,
  selectMenu: selectMenuReducer,
  lang: selectLangReducer,
  user: UserReducer,
  openLoginPopup: OpenLoginPopupReducer,
  favsItems: favsItemsReducer,
  uxers: uxersReducer,
  openSignupPopup: OpenSignupPopupReducer
});

export default allReudcer;
